import { Chip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Trigger } from 'api/resources';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { hasViewOnlyPermission } from 'utils/permissions';
import { UIElement } from '../../../api/moduleElement';
import { MODULE_TYPES } from '../../../constants';
import { VariablesPreview } from '../../app-previews/common/variable';
import APIBoxForm from '../../panels/APIBoxForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { questionStyle } from '../../utils/styles';
import { ModuleElementContext } from '../ModuleBoard';

export interface FormValues {
  id?: string;
  title: string;
  variable: string;
  container: any;
  trigger?: Trigger;
  module?: number;
  position?: number;
  api_resource: any;
}

interface APIBoxCardProps {
  element: UIElement;
  containerId?: number;
}

// TODO: it has issues related group card, variable
export const APIBoxCard = ({ element, containerId }: APIBoxCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo<FormValues>(
    () => ({
      title: element.title ?? '',
      variable: element.variable_item ? element.variable_item.name : '',
      container: element.container,
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      position: element.position,
      api_resource: element.api_resource,
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const apiResource = form.watch('api_resource');
  const trigger = form.watch('trigger');
  const [triggerId, setTriggerId] = useState<string | undefined>();
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const variable = form.watch('variable');
  const variables = useMemo(
    () => [
      {
        type: 'API',
        name: variable,
      },
    ],
    [variable]
  );

  // TODO: need refactoring
  //choices trigger element
  let cardStyle = {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '10px',
    marginBottom: '0px',
    paddingBottom: '10px',
    border: 'none',
    borderRadius: '30px',
    width: isContainer ? '100%' : '576px',
    backgroundColor: isContainer ? 'transparent' : '#FFFFFF',
    boxShadow: isContainer
      ? 'none'
      : '0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.06)',
  };

  return (
    <>
      <div
        data-testid='choice-panel__test'
        style={
          {
            position: panelOpen ? 'relative' : '',
            zIndex: panelOpen ? '30' : '',
            maxWidth: isContainer ? '100%' : '580px',
            marginLeft: !isContainer && '25px',
            marginBottom: 15,
          } as React.CSSProperties
        }
      >
        <Typography
          component={'div'}
          color='textPrimary'
          style={{
            padding: '10px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#FFFFFF',
          }}
        >
          {!(isContainer && trigger?.title === 'Always On') && (
            <Chip
              style={{
                backgroundColor: trigger ? '#08A88E' : 'red',
                color: 'white',
                marginLeft: '22px',
                height: '24px',
              }}
              size='small'
              label={trigger?.title || 'Not Assigned'}
            />
          )}
        </Typography>

        <Card
          className='item-to-hover'
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(module?.type) && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}

              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>

              {form.watch('title')}
            </Typography>
            {!!apiResource && (
              <div className='mt-3'>
                <p>Title: {apiResource.title}</p>
                <p>URL: {apiResource.url}</p>
              </div>
            )}
          </CardContent>
        </Card>
        {!isContainer && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='w-[620px] overflow-x-visible bg-white'>
          <APIBoxForm
            handleRichText={() => {}}
            handleSelectedItem={() => {}}
            startPreview={() => {}}
            handleAddTitle={(title: string) => form.setValue('title', title)}
            handleAddTrigger={(triggerId: string) => setTriggerId(triggerId)}
            moduleId={module?.type === MODULE_TYPES.ALGO ? module?.id : undefined}
            calculatorId={module?.type === MODULE_TYPES.CALCULATOR ? module.id : undefined}
            position={element.position}
            toggleModal={handlePanelClose}
            apiBoxId={element.id}
            container={form.watch('container')}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
