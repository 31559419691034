import {
  LAB_DATA_SUGGESTION,
  MEDICATION_SUGGESTION,
  PROBLEM_LIST_SUGGESTION,
  SUGGESTION,
  VITAL_SIGN_SUGGESTION,
} from '../../../hooks/useEHRVariables';
import React, { createContext, ReactNode } from 'react';
import { SuggestionTypes } from '../tiptap/tiptapInterfaces';
import { Suggestion, useSuggestions } from '../../../hooks/useSuggestions';

export interface MentionContext {
  headings: Readonly<Suggestion[]>;
  suggestions: Suggestion[];
  excludedSuggestionTypes: SuggestionTypes[];
}

export const MentionContext = createContext<MentionContext>({
  headings: [],
  suggestions: [],
  excludedSuggestionTypes: [],
});

export const MentionProvider: React.FC<{
  children: ReactNode;
  excludedSuggestionTypes: SuggestionTypes[];
}> = ({ children, excludedSuggestionTypes }) => {
  const suggestions = useSuggestions();
  const filteredSuggestions = suggestions.filter(
    (suggestion) => !excludedSuggestionTypes.includes(suggestion.type)
  );
  const filteredHeadings = Headings.filter(
    (heading) => !excludedSuggestionTypes.includes(heading.type)
  );
  return (
    <MentionContext.Provider
      value={{
        headings: filteredHeadings,
        suggestions: filteredSuggestions,
        excludedSuggestionTypes,
      }}
    >
      {children}
    </MentionContext.Provider>
  );
};

export const Headings: Suggestion[] = [
  { name: 'Variable', code: 'heading', type: 'variable' },
  { name: 'Formula', code: 'heading', type: 'formula' },
  { name: 'Numeric', code: 'heading', type: 'number' },
  { name: 'Conditional Text', code: 'heading', type: 'conditional_text' },
  { name: 'Infobox', code: 'heading', type: 'infobox' },
  { name: 'Reference', code: 'heading', type: 'reference' },
  { name: 'Knowledge Base', code: 'heading', type: 'knowledge_base' },
  ...SUGGESTION,
  ...LAB_DATA_SUGGESTION,
  ...VITAL_SIGN_SUGGESTION,
  ...MEDICATION_SUGGESTION,
  ...PROBLEM_LIST_SUGGESTION,
  { name: 'Individual Order', code: 'heading', type: 'ehr_order' },
];
