import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { Header } from '../../utils/panels/Header';
import { Body2, Caption2 } from '../../utils/typo';
import RequiredMark from '../../utils/requiredMark';
import Input from '../../utils/Input';
import Button from '../../utils/Button';
import { Tiptap } from '../../utils/tiptap/Tiptap';
import { Icons } from 'components/utils/Icons';
import { Medication, MedicationRequest, ehrAPI } from 'api/ehr';
import { useMutation } from '@tanstack/react-query';
import { SAVE_BUTTON } from '../../../constants';
import LoadingSpinner from '../../loader/LoadingSpinner';
import { toast } from 'react-toastify';
import { CustomToast } from '../../utils/toast-message';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';

interface EHROrderFormProps {
  medication?: Medication;
  refetch: () => void;
  onClose: () => void;
  modalOpen: boolean;
  toggleModal: () => void;
}

export const EHROrderForm = ({
  medication,
  refetch,
  onClose,
  modalOpen,
  toggleModal,
}: EHROrderFormProps) => {
  const defaultValues: MedicationRequest = useMemo(
    () => ({
      unique_code: medication?.unique_code,
      name: medication?.name ?? '',
      code: medication?.code ?? '',
      indication: medication?.indication ?? '',
    }),
    [medication]
  );

  const { watch, register, handleSubmit, setValue, reset } = useForm<MedicationRequest>({
    defaultValues,
  });
  const name = watch('name');
  const code = watch('code');

  const { mutate, isLoading } = useMutation(ehrAPI.upsertMedication, {
    onSuccess: () => {
      refetch();
      modalOpen && toggleModal();
      onClose();
    },
  });

  const onSubmit = (data: MedicationRequest) => {
    if (isLoading) return;
    if (!name || !code) {
      toast.error(CustomToast, { data: 'Please fill out the required fields.' });
    }
    mutate(data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [medication]);

  return (
    <>
      <div className='flex h-full w-[412px] flex-col gap-[16px]'>
        <Header
          className='sticky top-0 bg-white px-[12px] pt-[20px]'
          title='EHR Order'
          toggleModal={toggleModal}
        />
        <form
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
          className='flex grow flex-col justify-between'
        >
          <div className='px-[16px]'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Order Name</Body2>
                <RequiredMark />
              </div>
              <Input
                {...register('name', {
                  maxLength: 25,
                  required: true,
                })}
                maxLength={25}
                required
              />
              <div className='text-right text-[12px]'>{watch('name').length}/25</div>
            </div>

            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Code</Body2>
                <RequiredMark />
              </div>
              <Input
                {...register('code', {
                  maxLength: 100,
                  required: true,
                })}
                maxLength={100}
                required
              />
            </div>
            <div className='mt-[24px] space-y-[5px]'>
              <div className='relative flex items-center gap-1'>
                <Body2 className='text-gray-900'>Indicator</Body2>
                <Icons.Info className='peer' />
                <div className='invisible absolute -left-[13px] top-full z-10 w-[280px] rounded bg-gray-800 px-[12px] py-[8px] peer-hover:visible'>
                  <Caption2 className='text-white'>
                    Brief definition of the underlined word above can go up to 2 lines.
                  </Caption2>
                </div>
              </div>
              <Tiptap
                autofocus
                minimalEditor
                onUpdate={(editor) => setValue('indication', editor.getJSON())}
                initialContent={watch('indication')}
                wrapperClassNames='flex-grow h-56'
                richTextButtonsShowList={[
                  'textStyles',
                  'bold',
                  'italic',
                  'highlight',
                  'bulletList',
                  'orderedList',
                ]}
                placeholder='Type or paste your source of knowledge.'
              />
            </div>
          </div>

          <div className='sticky bottom-0 right-0 z-[9] flex w-full items-center justify-end bg-white px-[12px] pb-[22px]'>
            <div className='flex gap-3'>
              <Button.Reverse onClick={onClose} className='!px-2 !py-2.5 font-bold' type='button'>
                Cancel
              </Button.Reverse>
              <Button type='submit' className='!px-6 !py-2.5 font-bold' disabled={!name || !code}>
                {isLoading ? <LoadingSpinner size='small' /> : SAVE_BUTTON}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <ConfirmModal
        preset='unsaved'
        open={modalOpen}
        toggleModal={toggleModal}
        toggleModalPanel={onClose}
        handleSubmit={handleSubmit(onSubmit)}
        panelForm
      />
    </>
  );
};
