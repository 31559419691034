import { Menu } from '@headlessui/react';
import { Trigger } from 'api/resources';
import clsx from 'clsx';
import { Icons } from 'components/utils/Icons';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { hasViewOnlyPermission } from 'utils/permissions';
import { TRIGGER_ALWAYS_ON_TEXT } from 'v2/utils/constant';
import { UIElement } from '../../../api/moduleElement';
import { AlgoMoreIcon, CircleIcon } from '../../../assets/icons/algoCardIcons';
import { GENDER, GENDER_VALUES, MODULE_TYPES } from '../../../constants';
import { VariablesPreview } from '../../app-previews/common/variable';
import ValuePanelForm from '../../panels/ValuePanelForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { Body1, Body2, H4 } from '../../utils/typo';
import { ModuleElementContext } from '../ModuleBoard';

interface FormValues {
  id?: string;
  name: string;
  is_submitable: boolean;
  panel_item: any[];
  trigger?: Trigger;
  module?: number;
  calculator?: number;
  position?: number;
  container?: number;
}

interface NumbersCardProps {
  element: UIElement;
  containerId?: number;
}

export const NumbersCard = ({ element, containerId }: NumbersCardProps) => {
  const isContainer = !!containerId;
  const { module } = useContext(ModuleContext);
  const { refetchModuleElements, duplicateUIElement, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);

  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo<FormValues>(
    () => ({
      name: element.name ?? '',
      is_submitable: !!element.is_submitable,
      panel_item: element.panel_item ?? [],
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      container: element.container,
      position: element.position,
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const [triggerId, setTriggerId] = useState<string | undefined>();
  const trigger = form.watch('trigger');
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const panelItem = form.watch('panel_item');
  const variables = useMemo(
    () =>
      panelItem.map((item) => ({
        type: 'number',
        name: item.title || item.name,
      })),
    [panelItem]
  );

  return (
    <>
      <div
        data-testid='value-panel__test'
        className={clsx(
          '!mb-4 max-h-fit',
          panelOpen ? 'relative z-[30]' : '',
          isContainer ? 'w-full' : 'max-w-[580px]'
        )}
      >
        <Card
          className={clsx(
            'item-to-hover max-h-fit !rounded-xl !border-2 border-none p-3 hover:!bg-primary-200',
            isContainer
              ? 'w-full !border-none !bg-transparent !shadow-none'
              : 'max-w-[580px] !bg-white !bg-opacity-90',
            panelOpen ? 'dark:border-08A88E  !border-primary-600' : ''
          )}
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
        >
          <CardBody className='!m-0 !p-0'>
            <div>
              <div className='right item-to-show'>
                <Menu>
                  <Menu.Button>
                    {!hasViewOnlyPermission(module?.type) && <AlgoMoreIcon />}
                  </Menu.Button>
                  <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
                    <Menu.Item
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      as='p'
                      onClick={() => {
                        setPanelOpen(true);
                      }}
                    >
                      <Body2>Edit</Body2>
                    </Menu.Item>
                    <Menu.Item
                      as='p'
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      <Body2>Delete</Body2>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>

              {!(isContainer && trigger?.title === TRIGGER_ALWAYS_ON_TEXT) && (
                <div className='flex flex-row items-center'>
                  <span className='max-w-fit rounded-sm bg-green-500 px-2 py-[4px] text-xs font-normal leading-5 tracking-tighter text-white'>
                    {trigger ? trigger.title || 'Title is not assigned' : 'Not Assigned'}
                  </span>
                </div>
              )}
              <H4 className='my-2'>{form.watch('name')}</H4>
            </div>

            {form.watch('panel_item').map((numeric: any, index) => (
              <div key={index} className='flex flex-col'>
                <div className='flex'>
                  <Body2 className='mb-2 text-gray-700'>
                    {(numeric.title || numeric.name).charAt(0).toUpperCase() +
                      (numeric.title || numeric.name).slice(1)}
                  </Body2>
                  <Body2>{numeric.is_optional && ' (optional)'}</Body2>
                  <Body2 className='ml-[2px]'>{numeric.infobox && <Icons.InfoBoxIcon />}</Body2>
                </div>
                {/* Checking if the numeric code corresponds to the gender(sex) identifier */}
                {numeric.code === GENDER.SEX ? (
                  <div className='mb-3 flex space-x-4'>
                    {Object.keys(GENDER_VALUES).map((genderKey, index) => (
                      <div
                        key={index}
                        className='flex w-1/2 items-center rounded-md bg-gray-100 px-2 py-2'
                      >
                        <CircleIcon />
                        <Body1 className='ml-1'>{genderKey}</Body1>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='relative flex'>
                    <input
                      type='text'
                      value={numeric.default_value}
                      className='!box-border rounded border !border-gray-300 bg-white !px-[12px] !py-[10px]'
                      readOnly
                    />
                    <div className={clsx('pointer-events-none absolute right-[12px] top-[12px]')}>
                      <Body2 className='pr-1.5'>{numeric.unit}</Body2>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {form.watch('is_submitable') && !isContainer && (
              <div className='text-center'>
                <button
                  id='btn'
                  type='button'
                  name='action'
                  className='h-10 w-[164px] rounded !bg-green-500 '
                >
                  Next
                </button>
              </div>
            )}
          </CardBody>
        </Card>
        {!isContainer && <VariablesPreview variables={variables} />}
        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='w-[620px] overflow-x-visible bg-white'>
          <ValuePanelForm
            handleSelectedItem={() => {}}
            startBackdrop={() => {}}
            stopBackdrop={() => {}}
            startPreview={() => {}}
            handleAddTitle={(title: string) => form.setValue('name', title)}
            handleAddTrigger={(triggerId: string) => setTriggerId(triggerId)}
            handleAddItems={(items: any[]) => form.setValue('panel_item', items)}
            handleShowNext={(isSubmitable: boolean) => form.setValue('is_submitable', isSubmitable)}
            moduleId={module?.type === MODULE_TYPES.ALGO ? module?.id : undefined}
            calculatorId={module?.type === MODULE_TYPES.CALCULATOR ? module?.id : undefined}
            container={containerId}
            position={element.position}
            valuePanelId={element.id}
            toggleModal={handlePanelClose}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
