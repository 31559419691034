import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import Textarea from 'components/utils/textarea';
import { useEffect, useState } from 'react';

interface TerminologyEditorProps {
  onChange?: (loinc: string[], text: string[], regex: string[]) => void;
  defaultLoinc?: string[];
  defaultText?: string[];
  defaultRegex?: string[];
}

const TERM_TYPE = {
  LOINC: 'loinc',
  TEXT: 'text',
  REGEX: 'regex',
};

const TerminologyEditor = ({
  onChange,
  defaultLoinc = [],
  defaultText = [],
  defaultRegex = [],
}: TerminologyEditorProps) => {
  const [loinc, setLoinc] = useState<string[]>(defaultLoinc);
  const [text, setText] = useState<string[]>(defaultText);
  const [regex, setRegex] = useState<string[]>(defaultRegex);

  useEffect(() => {
    if (defaultLoinc.length > 0) setLoinc(defaultLoinc);
    if (defaultText.length > 0) setText(defaultText);
    if (defaultRegex.length > 0) setRegex(defaultRegex);
  }, [defaultLoinc, defaultText, defaultRegex]);

  const onChangeHandler = (category: string, value: string) => {
    const valueArray = value
      .split(/,|\n/)
      .map((v) => v.trim())
      .filter((v) => !!v);
    switch (category) {
      case TERM_TYPE.LOINC:
        setLoinc(valueArray);
        break;
      case TERM_TYPE.TEXT:
        setText(valueArray);
        break;
      case TERM_TYPE.REGEX:
        setRegex(valueArray);
        break;
    }
  };

  useEffect(() => {
    onChange?.(loinc, text, regex);
  }, [onChange, loinc, text, regex]);

  return (
    <div className='flex gap-2 h-44'>
      <div className='w-1/2'>
        <Tab.Group as='div' className='flex flex-col h-full'>
          <Tab.List className='flex space-x-1 rounded bg-blue-50 p-1'>
            <Tab
              className={({ selected }) =>
                clsx(
                  'w-full rounded',
                  { 'bg-blue-100': selected },
                  'focus:bg-blue-200' // due to material UI
                )
              }
            >
              LOINC
            </Tab>
            <Tab
              className={({ selected }) =>
                clsx(
                  'w-full rounded',
                  { 'bg-blue-100': selected },
                  'focus:bg-blue-200' // due to material UI
                )
              }
            >
              Text
            </Tab>
            <Tab
              className={({ selected }) =>
                clsx(
                  'w-full rounded',
                  { 'bg-blue-100': selected },
                  'focus:bg-blue-200' // due to material UI
                )
              }
            >
              REGEX
            </Tab>
          </Tab.List>
          <Tab.Panels className='mt-2 grow'>
            <Tab.Panel className='h-full'>
              <Textarea
                className='h-full'
                defaultValue={loinc.join('\n')}
                onChange={(e) => onChangeHandler(TERM_TYPE.LOINC, e.currentTarget.value)}
              />
            </Tab.Panel>
            <Tab.Panel className='h-full'>
              <Textarea
                className='h-full'
                defaultValue={text.join('\n')}
                onChange={(e) => onChangeHandler(TERM_TYPE.TEXT, e.currentTarget.value)}
              />
            </Tab.Panel>
            <Tab.Panel className='h-full'>
              <Textarea
                className='h-full'
                defaultValue={regex.join('\n')}
                onChange={(e) => onChangeHandler(TERM_TYPE.REGEX, e.currentTarget.value)}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className='w-1/2 items-stretch'>
        <div className='h-full border border-gray-300 rounded overflow-y-scroll px-3 py-2 space-y-4 bg-gray-100'>
          {loinc.length > 0 && (
            <div>
              <div className='text-xs font-bold'>LOINC:</div>
              {loinc.join(', ')}
            </div>
          )}
          {text.length > 0 && (
            <div>
              <div className='text-xs font-bold'>TEXT:</div>
              {text.join(', ')}
            </div>
          )}
          {regex.length > 0 && (
            <div>
              <div className='text-xs font-bold'>REGEX:</div>
              {regex.join(', ')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TerminologyEditor;
