import AlgoBoard from 'components/module-detail/AlgoBoard';
import ModuleForm from 'components/module-detail/OldModuleForm';
import Simulate from 'components/module-detail/Simulate';
import {
  ALGO_CREAT_ROUTE,
  ALGO_FLOW_ROUTE,
  ALGO_RESOURCES_ROUTE,
  ALGO_ROUTE,
  ALGO_ROUTE_OLD1,
  ALGO_ROUTE_OLD2,
  MODULE_TYPES,
  SIMULATE_ROUTE,
} from '../../constants';

const ALGO_ROUTES = [
  {
    type: 'PRIVATE',
    props: {
      path: ALGO_CREAT_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.ALGO,
      },
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: ALGO_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.ALGO,
      },
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: ALGO_FLOW_ROUTE,
      component: AlgoBoard,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: ALGO_RESOURCES_ROUTE,
      component: AlgoBoard,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: `${ALGO_ROUTE_OLD1}/:moduleId`,
      to: ALGO_ROUTE,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: `${ALGO_ROUTE_OLD2}/:moduleId`,
      to: ALGO_ROUTE,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: `/create-algorithm`,
      to: ALGO_CREAT_ROUTE,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: `/update-algorithm/:moduleId`,
      to: ALGO_ROUTE,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: `/update-algo/:moduleId`,
      to: ALGO_ROUTE,
    },
  },
  {
    type: 'REDIRECT',
    props: {
      path: '/create-algo',
      to: ALGO_CREAT_ROUTE,
    },
  },
  {
    type: 'PUBLIC',
    props: {
      path: SIMULATE_ROUTE,
      component: Simulate,
    },
  },
];

export default ALGO_ROUTES;
