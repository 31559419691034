import { Menu } from '@headlessui/react';
import { AlgoDragIcon, AlgoMoreIcon, CircleIcon } from 'assets/icons/algoCardIcons';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import clsx from 'clsx';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Body1, Body2, H4 } from 'components/utils/typo';
import { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { hasViewOnlyPermission } from 'utils/permissions';
import { GENDER, GENDER_VALUES } from '../../constants';
import { VariablesPreview } from './common/variable';
interface ValuePanelProps {
  editMode: any;
  container: any;
  deleteCard: (panelId?: any) => void;
  panelId: any;
  name: any;
  items: any;
  trigger: any;
  containerCard: any;
  update: () => void;
  editorItems: any;
  editorTitle: any;
  editorShowNext: any;
  editorTrigger: any;
  type?: any;
  showNext?: any;
}

interface ValuePanelState {
  modal: boolean;
  deleteModal: boolean;
  editMode: boolean;
  anchorEl: any;
}

class ValuePanel extends Component<ValuePanelProps, ValuePanelState> {
  constructor(props: ValuePanelProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      editMode: false,
      anchorEl: null,
    };
  }

  setAnchorEl = (e) => this.setState({ anchorEl: e.target });

  handleClose = () => this.setState({ anchorEl: null });

  onEditClick = () => {
    this.props.update();
    this.handleClose();
  };

  onDeleteClick = () => {
    this.toggleModal('deleteModal');
    this.handleClose();
  };

  // for open/close modal
  toggleModal = (modalName) => {
    this.setState(
      (previous) => ({ [modalName]: !previous[modalName] }) as Pick<ValuePanelState, any>
    );
  };

  handleEditMode = () => {
    this.setState((previous) => ({ editMode: !previous.editMode }));
  };

  render() {
    const items =
      this.state.editMode || this.props.editMode
        ? this.props.editorItems
        : this.props.items
        ? this.props.items
        : [];

    const trigger =
      this.state.editMode || this.props.editMode ? this.props.editorTrigger : this.props.trigger;

    const variables = items.map((item) => ({
      type: 'number',
      name: item.title || item.name,
    }));
    // Function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
      <div
        data-testid='value-panel__test'
        className={clsx(
          '!mb-4 max-h-fit',
          this.state.editMode || this.props.editMode ? 'relative z-[13]' : '',
          this.props.container ? 'w-full' : 'max-w-[580px]'
        )}
      >
        <Card
          className={clsx(
            'item-to-hover max-h-fit !rounded-xl !border-2 border-none px-4 py-[10px] hover:!bg-primary-200',
            this.props.container
              ? 'w-full !border-none !bg-transparent !shadow-none'
              : 'max-w-[580px] !bg-white !bg-opacity-90',
            this.state.editMode || this.props.editMode
              ? 'dark:border-08A88E  !border-primary-600'
              : ''
          )}
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
        >
          <CardBody className='!m-0 !p-0'>
            <div>
              <div className='right item-to-show'>
                <Menu>
                  <Menu.Button>
                    {!hasViewOnlyPermission(this.props.type) && (
                      <div onClick={this.setAnchorEl}>
                        <AlgoMoreIcon />
                      </div>
                    )}
                  </Menu.Button>
                  <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
                    <Menu.Item
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      as='p'
                      onClick={this.onEditClick}
                    >
                      <Body2>Edit</Body2>
                    </Menu.Item>
                    <Menu.Item
                      as='p'
                      className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                      onClick={this.onDeleteClick}
                    >
                      <Body2>Delete</Body2>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>

              {!this.props.containerCard && (
                <div className='flex flex-row items-center'>
                  <AlgoDragIcon />
                  <span className='max-w-fit rounded-sm bg-green-500 px-2 py-[4px] text-xs font-normal leading-5 tracking-tighter text-white'>
                    {trigger
                      ? this.props.containerCard
                        ? trigger.title !== 'Always On' &&
                          (trigger.title || 'Title is not assigned')
                        : trigger.title || 'Title is not assigned'
                      : 'Not Assigned'}
                  </span>
                </div>
              )}
              <H4 className='my-2'>
                {this.state.editMode || this.props.editMode
                  ? this.props.editorTitle
                  : this.props.name}
              </H4>
            </div>

            {items.map((numeric, index) => (
              <div key={index} className='flex flex-col'>
                <div className='flex'>
                  <Body2 className='mb-2 text-gray-700'>
                    {capitalizeFirstLetter(numeric.title || numeric.name)}
                  </Body2>
                  <Body2>{numeric.is_optional && ' (optional)'}</Body2>
                  <Body2>{numeric.infobox && <InfoBoxIcon />}</Body2>
                </div>
                {/* Checking if the numeric code corresponds to the gender(sex) identifier */}
                {numeric.code === GENDER.SEX ? (
                  <div className='mb-3 flex space-x-4'>
                    {Object.keys(GENDER_VALUES).map((genderKey, index) => (
                      <div
                        key={index}
                        className='flex w-1/2 items-center rounded-md bg-gray-100 px-2 py-2'
                      >
                        <CircleIcon />
                        <Body1 className='ml-1'>{genderKey}</Body1>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='relative flex'>
                    <input
                      type='text'
                      value={numeric.default_value}
                      className='!box-border rounded border !border-gray-300 bg-white !px-[12px] !py-[10px]'
                      readOnly
                    />
                    <div className={clsx('pointer-events-none absolute right-[12px] top-[12px]')}>
                      <Body2 className='pr-1.5'>{numeric.unit}</Body2>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {(this.state.editMode || this.props.editMode
              ? this.props.editorShowNext
              : this.props.showNext) && (
              <div className='text-center'>
                <button
                  id='btn'
                  type='button'
                  name='action'
                  className='h-10 w-[164px] rounded bg-primary-bg-strong '
                >
                  Next
                </button>
              </div>
            )}
          </CardBody>
        </Card>
        {!this.props.containerCard && <VariablesPreview variables={variables} />}
        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => this.toggleModal('deleteModal')}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleModal('deleteModal');
          }}
        />
      </div>
    );
  }
}

export default ValuePanel;
