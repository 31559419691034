import { Popover, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { Icons } from '../../utils/Icons';
import React, { Fragment } from 'react';

interface FloatingActionButtonProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  // TODO: add onShareToTeam function
  onShare?: () => void;
}
export const FloatingActionButton = ({
  open,
  onClose,
  onEdit,
  onDelete,
  onShare = () => {},
}: FloatingActionButtonProps) => {
  return (
    <Popover className='relative' onClick={(e) => e.stopPropagation()}>
      {({ close }) => (
        <>
          <Popover.Button className='absolute right-2 top-2 hidden justify-center transition-all focus:bg-transparent group-hover:inline'>
            <Icons.FloatingButton className='rounded bg-gray-300 fill-white hover:bg-gray-700' />
          </Popover.Button>
          <Popover.Overlay className='fixed inset-0 z-10 bg-transparent' />
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute right-2 top-8 z-10' onClick={close}>
              <ul className='overflow-hidden whitespace-nowrap rounded-lg bg-white py-[4px] text-[15px] text-body-1 shadow-lg ring-1 ring-black/5'>
                <li className='px-[12px] py-[10px] hover:bg-primary-200' onClick={onEdit}>
                  Edit
                </li>
                <li className='px-[12px] py-[10px] hover:bg-primary-200' onClick={onShare}>
                  Share to team
                </li>
                <li className='px-[12px] py-[10px] hover:bg-primary-200' onClick={onDelete}>
                  Delete
                </li>
              </ul>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
