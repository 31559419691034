import {
  ButtonHTMLAttributes,
  createContext,
  forwardRef,
  HTMLProps,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  ReferenceType,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStatus,
  useFloatingNodeId,
  useFloatingParentNodeId,
  FloatingTree,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import { twJoin, twMerge } from 'tailwind-merge';
import { PANEL_Z_INDEX } from '../../../v2/utils/constant';

const FloatingContext = createContext<{
  setFloating: (node: HTMLElement | null) => void;
  setReference: (node: ReferenceType | null) => void;
  getReferenceProps: (userProps?: React.HTMLProps<Element> | undefined) => Record<string, unknown>;
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
  open: boolean;
  status: 'unmounted' | 'initial' | 'open' | 'close';
  childOpen: boolean;
  setChildOpen: (hasChild: boolean) => void;
}>({
  setFloating: () => {},
  setReference: () => {},
  getReferenceProps: () => ({}),
  getFloatingProps: () => ({}),
  open: false,
  status: 'unmounted',
  childOpen: false,
  setChildOpen: () => {},
});

interface SlidePanelProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
}
// TODO: add confirm modal feature
export const Slide = ({ open, onOpenChange, children }: SlidePanelProps) => {
  const [childOpen, setChildOpen] = useState(false);
  const { setChildOpen: contextSetChildOpen } = useContext(FloatingContext);
  const parentId = useFloatingParentNodeId();
  const isNest = parentId != null;
  const {
    refs: { setReference, setFloating, reference },
    context,
  } = useFloating({
    open,
    onOpenChange,
  });

  const role = useRole(context);
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    enabled: !childOpen,
    outsidePressEvent: 'click',
    outsidePress: (event) => !(event.target as Element).closest('.Toastify'),
  });
  const { isMounted, status } = useTransitionStatus(context, {
    duration: 300,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click, role]);

  const value = {
    setFloating,
    setReference,
    getFloatingProps,
    getReferenceProps,
    open: isMounted,
    status,
    childOpen,
    setChildOpen,
  };

  useEffect(() => {
    contextSetChildOpen(open);
  }, [open]);

  return (
    <>
      {!reference.current && <div ref={setReference} />}
      <FloatingContext.Provider value={value}>
        {!isNest ? <FloatingTree>{children}</FloatingTree> : <>{children}</>}
      </FloatingContext.Provider>
    </>
  );
};

interface SlideButtonArgs {
  ref: (node: ReferenceType | null) => void;
  getProps: (userProps?: HTMLProps<Element> | undefined) => Record<string, unknown>;
}

type SlideButtonProps = {
  children: ReactNode | ((args: SlideButtonArgs) => ReactNode);
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const SlideButton = forwardRef<HTMLButtonElement, SlideButtonProps>(
  ({ children, ...restProps }, forwardRef) => {
    const { setReference: ref, getReferenceProps: getProps } = useContext(FloatingContext);

    const mergedRefs = useMergeRefs([ref, forwardRef]);

    return typeof children === 'function' ? (
      children({ ref, getProps })
    ) : (
      <button type='button' ref={mergedRefs} {...restProps} {...getProps()}>
        {children}
      </button>
    );
  }
);

const SlidePanel = ({
  children,
  className,
  lockScroll,
}: {
  children: ReactNode;
  className?: string;
  lockScroll?: boolean;
}) => {
  const {
    status,
    open,
    setFloating: ref,
    getFloatingProps: getProps,
  } = useContext(FloatingContext);

  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const isNest = parentId != null;

  const component = (
    <FloatingNode id={nodeId}>
      {open && (
        <>
          <FloatingOverlay
            className={twJoin(
              'transition duration-300',
              isNest ? 'bg-gray-bg-dim' : 'bg-gray-bg-bright',
              status !== 'open' && 'bg-transparent',
              isNest && 'z-10'
            )}
            lockScroll={lockScroll}
          />
          <div
            ref={ref}
            {...getProps()}
            className={twMerge(
              'fixed top-0 h-full pt-[51px] shadow-06',
              isNest ? 'left-0 -translate-x-full' : 'right-0',
              'transform transition duration-300 ease-in-out',
              status !== 'open' && 'translate-x-full',
              isNest ? '-z-10' : PANEL_Z_INDEX
            )}
          >
            <div className={twMerge('relative h-full overflow-y-auto bg-white', className)}>
              {children}
            </div>
          </div>
        </>
      )}
    </FloatingNode>
  );

  return <>{isNest ? <>{component}</> : <FloatingPortal>{component}</FloatingPortal>}</>;
};

Slide.Button = SlideButton;
Slide.Panel = SlidePanel;
