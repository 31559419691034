import { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { SuggestionTypeEnum, SuggestionTypes } from '../tiptapInterfaces';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  FloatingRootContext,
  offset,
  ReferenceType,
  useFloating,
} from '@floating-ui/react';
import { useEditorContext } from '../Tiptap';
import { UploadMedia } from './UploadMedia';
import { PANEL_Z_INDEX } from '../../../../v2/utils/constant';
import { LinkPreviewData } from '../custom-extensions/link-preview/LinkNode';
import { twJoin } from 'tailwind-merge';
import { Footer } from '../../panels/Footer';
import { VariableHandler } from './suggestion-handler/VariableHandler';
import { ConditionalTextHandler } from './suggestion-handler/ConditionalTextHandler';
import { InfoboxHandler } from './suggestion-handler/InfoboxHandler';
import { LinkHandler } from './suggestion-handler/LinkHandler';
import { ContactNumberHandler } from './suggestion-handler/ContactNumberHandler';
import { ReferenceHandler } from './suggestion-handler/ReferenceHandler';
import { EHRDataHandler } from './suggestion-handler/EHRDataHandler';
import { EHROrderHandler } from './suggestion-handler/EHROrderHandler';

interface MentionPopoverProps extends HTMLAttributes<HTMLDivElement> {
  suggestionType: SuggestionTypes;
  rootContext: FloatingRootContext<ReferenceType>;
  onClose: () => void;
}

export const MentionPopover = forwardRef<HTMLDivElement, MentionPopoverProps>(
  ({ suggestionType, rootContext, onClose, ...props }, ref) => {
    const editor = useEditorContext();

    const { floatingStyles } = useFloating({
      rootContext,
      middleware: [offset(5), flip()],
      placement: 'bottom-start',
      whileElementsMounted: autoUpdate,
    });

    // TODO: need to remove when type is not media
    const mediaRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      !!mediaRef.current && suggestionType === 'media' && mediaRef.current.click();
    }, [mediaRef]);
    return (
      <>
        {suggestionType === 'media' ? (
          <UploadMedia editor={editor} mediaRef={mediaRef} />
        ) : (
          <FloatingPortal>
            <div ref={ref} className={PANEL_Z_INDEX} style={floatingStyles} {...props}>
              <MentionActionPanel onClose={onClose} suggestionType={suggestionType} />
            </div>
          </FloatingPortal>
        )}
      </>
    );
  }
);

const MentionActionPanel = ({
  onClose,
  suggestionType,
}: {
  onClose: () => void;
  suggestionType: SuggestionTypes;
}) => {
  const editor = useEditorContext();
  const [content, setContent] = useState<Record<string, any>>({});
  const [disabled, setDisabled] = useState(false);
  const handleAdd = () => {
    if (suggestionType === SuggestionTypeEnum.LINK) {
      editor
        .chain()
        .focus()
        .setCustomLink(content as LinkPreviewData)
        .run();
    } else {
      editor.commands.insertContent({
        type: 'mention',
        attrs: {
          ...content,
          type: content.type ?? suggestionType,
        },
      });
    }
    onClose();
  };
  return (
    <div className='flex w-[340px] flex-col gap-4 !rounded bg-white shadow-06'>
      <div className='flex h-11 w-full items-center justify-center bg-gray-100'>
        <p
          className={twJoin(
            'text-caption-1 text-gray-900',
            '!mb-0' // due to materialize css and bootstrap
          )}
        >
          {PopoverTitleBySuggestionType[suggestionType]}
        </p>
      </div>
      <div className='flex w-full flex-col gap-2 !px-4 !pb-4'>
        <CompositeSuggestionRenderer
          onChange={setContent}
          onDisabledChange={setDisabled}
          suggestionType={suggestionType}
        />
        <Footer
          onClick={handleAdd}
          onClose={onClose}
          disabled={disabled}
          className='px-0 pb-0'
          saveButtonLabel='Add'
        />
      </div>
    </div>
  );
};

export interface SuggestionHandlerProps {
  onChange: (content: Record<string, any>) => void;
  onDisabledChange: (disabled: boolean) => void;
}

interface CompositeSuggestionRendererProps extends SuggestionHandlerProps {
  suggestionType: SuggestionTypes;
}

const CompositeSuggestionRenderer = ({
  suggestionType,
  onChange,
  onDisabledChange,
}: CompositeSuggestionRendererProps) => {
  switch (suggestionType) {
    case 'variable':
      return <VariableHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'conditional_text':
      return <ConditionalTextHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'infobox':
      return <InfoboxHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'link':
      return <LinkHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'contact_number':
      return <ContactNumberHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'reference':
      return <ReferenceHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'ehr_data':
      return <EHRDataHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    case 'ehr_order':
      return <EHROrderHandler onChange={onChange} onDisabledChange={onDisabledChange} />;
    default:
      return null;
  }
};

const PopoverTitleBySuggestionType: Partial<Record<SuggestionTypes, string>> = {
  variable: 'Add Variable',
  conditional_text: 'Add Conditional Text',
  infobox: 'Add Infobox',
  link: 'Add Link',
  contact_number: 'Add Phone Number',
  reference: 'Add Reference',
  ehr_data: 'Add EHR Data',
  ehr_order: 'Add EHR Order',
};
