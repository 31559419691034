import ModuleForm from 'components/module-detail/OldModuleForm';
import CalculatorBoard from '../../components/calculator/CalculatorBoard';
import {
  CALC_CREATE_ROUTE,
  CALC_EQUATIONS_ROUTE,
  CALC_FLOW_ROUTE,
  CALC_OUTPUTS_ROUTE,
  CALC_RESOURCES_ROUTE,
  CALC_ROUTE,
  MODULE_TYPES,
} from '../../constants';

const CaculatorRoutes = [
  {
    type: 'PRIVATE',
    props: {
      path: CALC_CREATE_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.CALCULATOR,
      },
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALC_ROUTE,
      component: ModuleForm,
      componentProps: {
        moduleType: MODULE_TYPES.CALCULATOR,
      },
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALC_FLOW_ROUTE,
      component: CalculatorBoard,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALC_EQUATIONS_ROUTE,
      component: CalculatorBoard,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALC_OUTPUTS_ROUTE,
      component: CalculatorBoard,
    },
  },
  {
    type: 'PRIVATE',
    props: {
      path: CALC_RESOURCES_ROUTE,
      component: CalculatorBoard,
    },
  },
];

export default CaculatorRoutes;
