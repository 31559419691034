import { Box, CircularProgress, Popover } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getCalculators } from 'actions/calculators/getCalculators';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getTriggers } from 'actions/resources/getTriggers';
import AddIcon from 'assets/icons/addIcon';
import axios from 'axios';
import clsx from 'clsx';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import AppearingSituation from 'components/utils/AppearingSituation';
import SectionDropdown from 'components/utils/SectionDropdown';
import ToggleSwitch from 'components/utils/ToggleSwitch';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import ExampleNToolsList from 'components/utils/example-n-tools/ExampleNToolsList';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import {
  AddButton,
  AddLabel,
  CancelButton,
  CreateButton,
  Examples,
  StyledFormGroupMB30,
} from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, CARD_MESSAGE, SAVE_BUTTON } from 'constants/variables';
import { withFeaturePermission } from 'hooks/useAuthentication';
import { debounce } from 'lodash';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { TeamWithMates } from '../../api/team';
import { DESCRIPTION_PANEL_API_URL, MODULE_TYPES } from '../../constants';
import { withTeam } from '../../hooks/useTeam';
import { FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { DescriptionPanelBetaFormDispatchProps } from './types';

interface DescriptionPanelBetaFormState {
  textModal: boolean;
  updateTextModal: boolean;
  infoboxModal: boolean;
  updateInfoboxModal: boolean;
  toolInfoboxModal: boolean;
  updateToolInfoboxModal: boolean;
  imageModal: boolean;
  updateImageModal: boolean;
  calculatorModal: boolean;
  createInfoboxModal: boolean;
  createReferenceModal: boolean;
  refInfoDropdown: any;
  showCalcTypes: any;
  variables: any;
  variable: string;
  isLoading: boolean;
  numerics: any;

  pk: string;
  title: string;
  description_text_short: string;
  description_text_long: string;
  new_description_text_content_short: string;
  shortDescTiptap: any;
  longDescTiptap: any;
  temp_text_short: string;
  temp_text_long: string;
  new_description_text_content_long: string;
  is_introduction: boolean;
  images: any;
  examples: any;
  infoboxes: any;
  tools: any;
  trigger: string;
  module: string;
  descriptionType: string;
  panel_type: string;
  textEditorsuggestions: any;
  containerCard: any;
  position: any;
  isLongDescEnabled: boolean;
  openDescriptionPopup: boolean;
  shortCharacterLength: number;
  longCharacterLength: number;
  maxTextLimit: number;
  isFullHeight: boolean;
}

interface DescriptionPanelBetaFormProps {
  moduleId: string;
  handleShortDescription: (text: any, e: any) => void;
  handleLongDescription: (text: any, e: any) => void;
  handleDescriptionTiptap: (json: any, type: string) => void;
  handeLongDescriptionToggle: (isLongDescEnabled: boolean) => void;
  formState: any;
  descriptionPanelId: string;
  triggerState: any;
  position: any;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  handleAddVariable: (variable: any) => void;
  startPreview: (component: string) => void;
  handleAddTitle: (title: string) => void;
  handleAddDescription: (desc: string) => void;
  handleAddTools: (tools: any) => void;
  handleAddExamples: (examples: any) => void;
  handleAddDescriptionType: (type: string) => void;
  infoboxState: any;
  mediaState: any;
  noteGeneratorState: any;
  calculatorState: any;
  teamCalculatorState: any;
  container: any;
  toggleModal: () => void;
  resetState: () => void;
  getTriggers: () => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  setIsFormDisabled: (val) => void;
  team: TeamWithMates;
}

type Props = DescriptionPanelBetaFormDispatchProps & DescriptionPanelBetaFormProps;

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

class DescriptionPanelBetaForm extends Component<Props, DescriptionPanelBetaFormState> {
  debouncedOnShortTextChange: any;
  debouncedOnLongTextChange: any;
  debShortDescTiptapChange: any;
  debLongDescTiptapChange: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      textModal: false,
      updateTextModal: false,
      infoboxModal: false,
      updateInfoboxModal: false,
      toolInfoboxModal: false,
      updateToolInfoboxModal: false,
      imageModal: false,
      updateImageModal: false,
      calculatorModal: false,
      createInfoboxModal: false,
      createReferenceModal: false,
      refInfoDropdown: null,
      showCalcTypes: null,
      variables: [],
      numerics: [],
      containerCard: '',
      variable: '',
      isLoading: false,

      pk: '',
      title: '',
      description_text_short: '',
      description_text_long: '',
      new_description_text_content_short: '',
      temp_text_short: '',
      temp_text_long: '',
      new_description_text_content_long: '',
      is_introduction: false,
      images: [],
      examples: [],
      infoboxes: [],
      tools: [],
      trigger: '',
      module: '',
      descriptionType: 'clarification',
      panel_type: 'B', //Panel type is 'B: Beta' for beta description panel

      textEditorsuggestions: [],
      position: null,
      isLongDescEnabled: false,
      openDescriptionPopup: false,
      shortCharacterLength: 450,
      longCharacterLength: 1400,
      maxTextLimit: 1400,
      isFullHeight: false,
      shortDescTiptap: {},
      longDescTiptap: {},
    };

    this.debouncedOnShortTextChange = debounce(this.onShortTextChange, 300);
    this.debouncedOnLongTextChange = debounce(this.onLongTextChange, 300);
    this.debShortDescTiptapChange = debounce(this.onShortTiptapChange, 300);
    this.debLongDescTiptapChange = debounce(this.onLongTiptapChange, 300);
  }

  onLongTiptapChange = (editor) => {
    this.setState({ longDescTiptap: editor.getJSON() });
    this.props.handleDescriptionTiptap(editor.getJSON(), 'long');
  };

  onShortTiptapChange = (editor) => {
    this.setState({ shortDescTiptap: editor.getJSON() });
    // TODO: can delete this after AVONG-2352
    this.setState({ description_text_short: editor.getText() });
    this.props.handleDescriptionTiptap(editor.getJSON(), 'short');
  };

  componentWillUnmount() {
    this.debouncedOnShortTextChange.cancel();
    this.debouncedOnLongTextChange.cancel();
  }

  changeMaxTextLimit = (length: number) => {
    this.setState(
      (previous) =>
        ({ maxTextLimit: previous.maxTextLimit + length }) as Pick<
          DescriptionPanelBetaFormState,
          any
        >
    );
  };

  populateSuggestions = async () => {
    // suggestions for text field
    const suggestions = await getSuggestions(this.props.moduleId);
    const variables = await getVariables(this.props.moduleId);
    const numerics = await getNumerics(this.props.moduleId);
    this.setState({ textEditorsuggestions: suggestions, variables, numerics });
  };

  // updates the state on field input of short descriptio text
  onShortTextChange = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      new_description_text_content_short: e,
      description_text_short: text,
    });
    this.props.handleShortDescription(text, e);
  };

  onLongTextChange = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      description_text_long: text,
      new_description_text_content_long: e,
    });
    this.props.handleLongDescription(text, e);
  };

  setVariableName = (variable: string) => {
    this.setState({ variable });
    this.props.handleAddVariable?.(variable);
  };

  closeDescriptionPopup = (action: string) => {
    if (this.state.isLongDescEnabled === true) {
      this.setState({ openDescriptionPopup: false });
    } else {
      this.setState(
        {
          isLongDescEnabled: action === 'expand' ? true : false,
          openDescriptionPopup: false,
        },
        () => this.props.handeLongDescriptionToggle(this.state.isLongDescEnabled)
      );
    }
  };

  helpInfoData = getHelpInfoData('DescriptionPanel');

  // checks url and updates state accordingly (edit or create)
  componentDidMount() {
    // TODO: remove when redux data is depracated
    this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);

    this.props.setIsFormDisabled(false);

    if (!this.props.descriptionPanelId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data: any) => data.title === 'Always On'
      );
      this.setState(
        {
          trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
          position: this.props.position,
        },
        () => this.setDefaultDescription()
      );

      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview('renderDescv2Preview');
    }

    if (this.props.descriptionPanelId) {
      axios.get(DESCRIPTION_PANEL_API_URL + this.props.descriptionPanelId + '/').then((res) => {
        this.updateState(res.data);
      });
    }
    this.populateSuggestions();
  }

  setDefaultDescription = () => {
    const short_description = 'This is card description';
    const long_description =
      'These are the card details. This section is optional and only needed if card contents requires more details than the description section allows';
    const short_desc_json = {
      blocks: [
        {
          key: 'opse',
          data: {},
          text: 'This is card description',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    };
    const long_desc_json = {
      blocks: [
        {
          key: '1uiqv',
          data: {},
          text: 'These are the card details. This section is optional and only needed if card contents requires more details than the description section allows',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    };

    this.setState(
      {
        isLongDescEnabled: false,
      },
      () => {
        this.props.handleAddTitle('Card Title Placeholder');
        this.props.handleShortDescription(short_description, short_desc_json);
        this.props.handleLongDescription(long_description, long_desc_json);
        this.props.handeLongDescriptionToggle(false);
      }
    );
  };

  updateState = (data: any) => {
    this.setState({
      pk: data.id,
      title: data.title,
      variable: data?.variable_item?.name,
      new_description_text_content_short: data.new_description_text_content_short || '',
      new_description_text_content_long: data.new_description_text_content_long || '',
      temp_text_short: data.new_description_text_content_short || '',
      temp_text_long: data.new_description_text_content_long || '',
      is_introduction: data.is_introduction,
      trigger: data.trigger ? data.trigger : '',
      module: data.module,
      descriptionType: data.is_action ? 'action' : 'clarification',
      infoboxes: data.infobox_detail,
      tools: data.tools,
      examples: data.examples,
      position: data.position,
      isLongDescEnabled: data.is_long_desc_enabled,
      shortDescTiptap: data.short_desc_json_tiptap || {},
      longDescTiptap: data.long_desc_json_tiptap || {},
    });

    this.props.handleAddTitle(data.title);
    this.props.handleAddDescription(data.description_text);
    this.props.handleLongDescription(
      data.description_text_long,
      data.new_description_text_content_long
    );
    this.props.handleShortDescription(
      data.description_text_short,
      data.new_description_text_content_short
    );
    this.props.handeLongDescriptionToggle(data.is_long_desc_enabled);
    this.props.handleAddTrigger(data.trigger || '');
    this.props.handleAddTools(data.tools);
    this.props.handleAddExamples(data.examples);
    this.props.handleAddDescriptionType(data.is_action ? 'action' : 'clarification');
  };

  deleteExample = (example: any) => {
    let examples = this.state.examples.filter((el) => el.id !== example.id);
    this.setState({ examples: examples });
    this.props.handleAddExamples(examples);
  };

  updateExamples = (newList: any) => {
    this.setState({ examples: newList });
    this.props.handleAddExamples(newList);
  };

  updateTools = (newList: any) => {
    this.setState({ tools: newList });
    this.props.handleAddTools(newList);
  };

  deleteTool = (tool: any) => {
    let tools = this.state.tools.filter((el) => el.id !== tool.id);
    this.setState({ tools: tools });
    this.props.handleAddTools(tools);
  };

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    }
    this.setState({ [e.target.name]: e.target.value } as Pick<DescriptionPanelBetaFormState, any>);
  };

  // for open or close modal
  toggleModal = (modalName: string) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<DescriptionPanelBetaFormState, any>
    );
  };

  // add text item to example
  addText = () => {
    let text = {
      id: generate_ID(),
      section: 'E',
      text: {
        statement: 'Text Item',
      },
    };

    let examples = this.state.examples.concat(text);
    this.setState({ examples: examples });
    this.props.handleAddExamples(examples);
  };

  // add infobox to example
  addInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'E',
      infobox: {},
    };

    let examples = this.state.examples.concat(infobox);
    this.setState({ examples: examples });
    this.props.handleAddExamples(examples);
  };

  addToolNoteGenerator = () => {
    let noteGenerator = {
      id: generate_ID(),
      label: '',
      section: 'T',
      infobox: {
        infobox_type: 'NG',
      },
    };

    let tools = this.state.tools.concat(noteGenerator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addToolInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'T',
      infobox: {},
    };

    let tools = this.state.tools.concat(infobox);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addImage = () => {
    let image = {
      id: generate_ID(),
      label: '',
      section: 'T',
      image: {},
    };

    let tools = this.state.tools.concat(image);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addLegacyCalculator = (calculatorObj: any) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      legacy_calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  addCalculator = (calculatorObj: any) => {
    let calculator = {
      id: generate_ID(),
      label: calculatorObj.title,
      calculator: calculatorObj ? calculatorObj : {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props.handleAddTools(tools);
  };

  updateExample = (id: string, value: any, inputName: string) => {
    const updatedExamples = this.state.examples.map((example) => {
      let updatedItem = example;

      if (example.id === id) {
        if (inputName === 'label') {
          // text item doesn't have label
          if (example.text) {
            updatedItem = {
              ...example,
              text: {
                statement: value,
              },
            };
          } else {
            updatedItem = {
              ...example,
              label: value,
            };
          }
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...example,
            infobox: infoboxObj || {},
            label: !example.label && infoboxObj ? infoboxObj.title : example.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...example,
            image: imageObj || {},
            label: !example.label && imageObj ? imageObj.title : example.label,
          };
          delete updatedItem.infobox;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ examples: updatedExamples });
    this.props.handleAddExamples(updatedExamples);
  };

  updateTool = (id: string, value: any, inputName: string) => {
    const updatedTools = this.state.tools.map((tool: any) => {
      let updatedItem = tool;

      if (tool.id === id) {
        if (inputName === 'label') {
          updatedItem = {
            ...tool,
            label: value,
          };
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...tool,
            infobox: infoboxObj || {},
            label: !tool.label && infoboxObj ? infoboxObj.title : tool.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'noteGenerator') {
          let noteGeneratorObj = this.props.noteGeneratorState.noteGenerators.find(
            (noteGenerator) => noteGenerator.id === value
          );
          updatedItem = {
            ...tool,
            infobox: noteGeneratorObj || {},
            label: !tool.label && noteGeneratorObj ? noteGeneratorObj.title : tool.label,
          };
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...tool,
            image: imageObj || {},
            label: !tool.label && imageObj ? imageObj.title : tool.label,
          };
          delete updatedItem.infobox;
        } else if (inputName === 'legacy_calc') {
          let calcObj = this.props.calculatorState.calculators.find((calc) => calc.id === value);
          updatedItem = {
            ...tool,
            legacy_calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.title : tool.label,
          };
          delete updatedItem.calculator;
        } else if (inputName === 'calc') {
          const calcs = [
            ...(this.props.teamCalculatorState?.teamCalculators || []),
            ...(this.props.teamCalculatorState?.universalCalculators || []),
          ];
          let calcObj = calcs.find((calc) => calc.id === value);
          updatedItem = {
            ...tool,
            calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.name : tool.label,
          };
          delete updatedItem.legacy_calculator;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ tools: updatedTools });
    this.props.handleAddTools(updatedTools);
  };
  getShortCharactersLength = (length: number) => {
    this.setState({ shortCharacterLength: length });
  };
  getLongCharactersLength = (length: number) => {
    this.setState({ longCharacterLength: length });
  };

  getPayload = () => {
    let payload = {
      title: this.state.title,
      variable: this.state.variable,
      description_text_short: this.state.description_text_short, //Making sure the length of short desc is always 200
      description_text_long: this.state.description_text_long,
      new_description_text_content_short: this.state.new_description_text_content_short,
      new_description_text_content_long: this.state.new_description_text_content_long,
      panel_type: this.state.panel_type,
      is_introduction: this.state.is_introduction,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      is_action: this.state.descriptionType === 'action' ? true : false,
      question_items: this.state.examples.concat(this.state.tools),
      position: this.state.position,
      is_long_desc_enabled: this.state.isLongDescEnabled,
      container: this.props.container,
      short_desc_json_tiptap: this.state.shortDescTiptap,
      long_desc_json_tiptap: this.state.longDescTiptap,
    };

    return payload;
  };

  isExampleOrToolEmpty = () => {
    for (let i = 0; i < this.state.examples.length; i++) {
      const example = this.state.examples[i];

      if (
        (example.text && !example.text.statement) ||
        (example.infobox && (!example.label || Object.keys(example.infobox).length === 0))
      ) {
        return true;
      }
    }

    for (let i = 0; i < this.state.tools.length; i++) {
      const tool = this.state.tools[i];

      if (
        !tool.label ||
        (tool.infobox && Object.keys(tool.infobox).length === 0) ||
        (tool.image && Object.keys(tool.image).length === 0) ||
        (tool.legacy_calculator && Object.keys(tool.legacy_calculator).length === 0) ||
        (tool.calculator && Object.keys(tool.calculator).length === 0)
      ) {
        return true;
      }
    }

    return false;
  };

  createDescriptionPanel = () => {
    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }

    let payload = this.getPayload();
    this.props.toggleModal();
    axios.post(DESCRIPTION_PANEL_API_URL, payload).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  editDescriptionPanel = () => {
    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });

      return;
    }

    let payload = this.getPayload();
    this.props.toggleModal();
    axios.put(DESCRIPTION_PANEL_API_URL + this.state.pk + '/', payload).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  getSelectedTrigger = (data: any) => {
    this.setState({ trigger: data });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  toggleSelection = () => {
    this.setState({
      descriptionType: this.state.descriptionType === 'action' ? 'clarification' : 'action',
    });
    this.props.handleAddDescriptionType(
      this.state.descriptionType === 'action' ? 'clarification' : 'action'
    );
  };

  enableLongDescription = () => {
    this.setState(
      (previous) => ({
        isLongDescEnabled: !previous.isLongDescEnabled,
      }),
      () => this.props.handeLongDescriptionToggle(this.state.isLongDescEnabled)
    );
  };

  showWarning = () => {
    if (this.state.isLongDescEnabled === false) {
      this.setState({ openDescriptionPopup: true });
    }
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { title } = this.state;
    const { descriptionPanelId, modal, closeUiElementPane } = this.props;

    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the message title field.' });
      return;
    }

    this.setState({ isLoading: true });
    if (!this.state.variable) {
      const variable = await getVariableName(this.state.title);
      this.setState({ variable });
    }

    if (descriptionPanelId) {
      this.editDescriptionPanel();
    } else {
      this.createDescriptionPanel();
    }

    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    const description_text_short = this.state.temp_text_short;
    const description_text_long = this.state.temp_text_long;
    const infoboxProps = {
      isFullHeight: this.state.isFullHeight,
      createModal: true,
      toggleModal: () => this.toggleModal('createInfoboxModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };

    const refManagerFormProps = {
      toggleModal: () => this.toggleModal('createReferenceModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };

    const richTextButtonShowList = [
      ToolbarButton.TEXT_STYLE,
      ToolbarButton.BOLD,
      ToolbarButton.ITALIC,
      ToolbarButton.HIGHLIGHT,
      ToolbarButton.BULLETED_LIST,
      ToolbarButton.NUMBERED_LIST,
      ToolbarButton.INFOBOX,
      ToolbarButton.INSERT_LINK,
      ToolbarButton.MEDIA,
      ToolbarButton.PHONE_NUMBER,
      ToolbarButton.VARIABLES,
      ToolbarButton.CONDITIONAL_TEXT,
    ];

    return (
      <div className='row mt-14'>
        <Overlay show={isFormDisabled} />
        <div className='side-panel-form'>
          <Header title={CARD_MESSAGE} toggleModal={this.props.toggleModal} />
          <Form
            className={clsx('flex h-full flex-col px-3 py-0', {
              'pointer-events-none': isFormDisabled,
              'pointer-events-auto': !isFormDisabled,
            })}
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <FeaturedVariableGenerator
              tagTitle='Message'
              question={this.state.title}
              variable={this.state.variable}
              setVariableName={this.setVariableName}
            />

            {/* This is for short description */}
            {helpInfoData['description_text_short'] && (
              <StyledFormGroupMB30 style={{ marginTop: '20px', marginBottom: '15px' }}>
                <FieldLabel
                  detail={helpInfoData?.description_text_short?.detail}
                  label={helpInfoData?.description_text_short?.label}
                />

                <AvoEditor
                  getCharactersLength={this.getShortCharactersLength}
                  moduleId={this.props.moduleId}
                  suggestions={this.state.textEditorsuggestions}
                  setValue={this.debouncedOnShortTextChange}
                  prevValue={description_text_short}
                  MAX_TEXT_LIMIT={helpInfoData['description_text_short'].character_limit}
                  MAX_NO_OF_LINES={15}
                  placeholder='This is card description'
                  showWarning={this.showWarning}
                  variables={this.state.variables}
                  numerics={this.state.numerics}
                  richTextButtonShowList={richTextButtonShowList}
                  maxLines={15}
                  wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                  onUpdate={this.debShortDescTiptapChange}
                  suggestionsToExclude={suggestionsToExclude}
                  initialContent={this.state.shortDescTiptap}
                  richTextButtonsShowListTiptap={richTextButtonsShowList}
                  onLimitReached={this.showWarning}
                  maxCharacters={helpInfoData?.description_text_short?.character_limit}
                  characterLimitTotalCount={this.state.shortCharacterLength}
                  characterLimitCurrentCount={this.state.description_text_short?.length}
                />

                <ConfirmModal
                  open={this.state.openDescriptionPopup}
                  content={`The short description area has the character limit 
                  of ${helpInfoData['description_text_short']?.character_limit}.
                  Instead, you can add more text in an extra
                  text area, which users can click to expand and read.
                  Do you want to add the text area?`}
                  confirmText='Expand'
                  cancelText='Ignore'
                  toggleModal={this.closeDescriptionPopup}
                  performAction={() => this.closeDescriptionPopup('expand')}
                />
              </StyledFormGroupMB30>
            )}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <FieldLabel label='Add More Details' />
              <div style={{ marginLeft: 'auto' }}>
                <ToggleSwitch
                  checked={this.state.isLongDescEnabled}
                  toggleSwitch={() => this.enableLongDescription()}
                />
              </div>
            </Box>

            {/* This is for long description */}
            {helpInfoData['description_text_long'] && this.state.isLongDescEnabled && (
              <StyledFormGroupMB30>
                <FieldLabel
                  detail={helpInfoData?.description_text_long?.detail}
                  label={helpInfoData?.description_text_long?.label}
                />
                <AvoEditor
                  getCharactersLength={this.getLongCharactersLength}
                  moduleId={this.props.moduleId}
                  suggestions={this.state.textEditorsuggestions}
                  setValue={(e) => this.debouncedOnLongTextChange(e)}
                  prevValue={description_text_long}
                  isReadOnly={!this.state.isLongDescEnabled}
                  MAX_TEXT_LIMIT={helpInfoData['description_text_long']?.character_limit}
                  MAX_NO_OF_LINES={99999}
                  placeholder='These are the card details. This section is optional and only needed if card contents requires more details than the description section allows'
                  variables={this.state.variables}
                  numerics={this.state.numerics}
                  richTextButtonShowList={richTextButtonShowList}
                  suggestionsToExclude={suggestionsToExclude}
                  maxLines={99999}
                  wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                  onUpdate={this.debLongDescTiptapChange}
                  initialContent={this.state.longDescTiptap}
                  richTextButtonsShowListTiptap={richTextButtonsShowList}
                  maxCharacters={helpInfoData?.description_text_long?.character_limit}
                  characterLimitTotalCount={this.state.longCharacterLength}
                  characterLimitCurrentCount={this.state.description_text_long?.length}
                />
              </StyledFormGroupMB30>
            )}

            {!!this.props.descriptionPanelId && this.state.examples?.length > 0 && (
              <Box>
                <StyledFormGroupMB30>
                  <FieldLabel
                    label={helpInfoData?.examples?.label}
                    detail={helpInfoData?.examples?.detail}
                  />
                  <Examples
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <div className='example-n-tools-textBox m-4 mt-2'>
                      <ExampleNToolsList
                        moduleId={this.props.moduleId}
                        list={this.state.examples}
                        handleDeleteClick={this.deleteExample}
                        handleListChange={this.updateExample}
                        updateListItems={this.updateExamples}
                      />
                    </div>
                    <div className='row d-flex align-items-center ml-2  mt-2'>
                      <AddLabel className='ml-4 mr-3 mt-2'>Add</AddLabel>
                      <AddButton
                        style={{ width: '95px', display: 'inline-flex' }}
                        disableRipple
                        disableFocusRipple
                        startIcon={<AddIcon />}
                        className=' waves-effect waves-light  mr-2'
                        onClick={this.addText}
                      >
                        Text
                      </AddButton>
                      <AddButton
                        style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                        startIcon={<AddIcon />}
                        className=' waves-effect waves-light mr-2'
                        onClick={this.addInfoBox}
                      >
                        InfoBox / Media
                      </AddButton>
                    </div>
                  </Examples>
                </StyledFormGroupMB30>
              </Box>
            )}
            <Box>
              <FieldLabel label={helpInfoData?.tools?.label} detail={helpInfoData?.tools?.detail} />
              <Examples
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <div className='example-n-tools-textBox m-4 mt-2'>
                  <ExampleNToolsList
                    moduleId={this.props.moduleId}
                    list={this.state.tools}
                    handleDeleteClick={this.deleteTool}
                    handleListChange={this.updateTool}
                    updateListItems={this.updateTools}
                  />
                </div>

                <div className='row d-flex align-items-center ml-2  mt-2'>
                  <AddLabel className='ml-4 mr-3 mt-2'>Add</AddLabel>
                  <AddButton
                    style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                    startIcon={<AddIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={this.addToolInfoBox}
                  >
                    InfoBox / Media
                  </AddButton>
                  <AddButton
                    style={{ width: '150px', maxWidth: '150px', display: 'inline-flex' }}
                    startIcon={<AddIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={(e) => this.setState({ showCalcTypes: e.target })}
                  >
                    Calculator
                  </AddButton>
                  <Popover
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id='simple-menu'
                    anchorEl={this.state.showCalcTypes}
                    // keepMounted
                    open={Boolean(this.state.showCalcTypes)}
                    onClose={(e) => this.setState({ showCalcTypes: null })}
                  >
                    <SectionDropdown
                      sectionDropdown
                      section1='Team Calculators'
                      section2='Universal Calculators'
                      sectionOneItems={this.props.teamCalculatorState.teamCalculators}
                      sectionTwoItems={this.props.calculatorState.calculators}
                      universalCalculators={this.props.teamCalculatorState?.universalCalculators}
                      sectionOneClick={(obj) => {
                        this.addCalculator(obj);
                        this.setState({ showCalcTypes: null });
                      }}
                      sectionTwoClick={(obj) => {
                        this.addLegacyCalculator(obj);
                        this.setState({ showCalcTypes: null });
                      }}
                    />
                  </Popover>
                  <AddButton
                    style={{
                      maxWidth: '200px',
                      minWidth: '190px',
                      margin: '5px 0px 0px 65px',
                      display: 'inline-flex',
                    }}
                    startIcon={<AddIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={this.addToolNoteGenerator}
                  >
                    Note Generator
                  </AddButton>
                </div>
              </Examples>
            </Box>

            <SlidingPane
              isOpen={this.state.createInfoboxModal}
              onRequestClose={() => this.toggleModal('createInfoboxModal')}
              overlayClassName='infobox-overlay-for-zindex'
              from='right'
              hideHeader
              className='no-padding add-info-box sliding-panel-shadow mt-5'
              width='1210px'
            >
              <InfoBoxForm {...infoboxProps} />
            </SlidingPane>

            <SlidingPane
              isOpen={this.state.createReferenceModal}
              onRequestClose={() => this.toggleModal('createReferenceModal')}
              from='bottom'
              hideHeader
              className='sliding-pan-modal mt-5'
              title={
                <div>
                  Add Reference
                  <HighlightOffIcon
                    className='backIcon'
                    onClick={() => this.toggleModal('createReferenceModal')}
                  ></HighlightOffIcon>
                </div>
              }
              //   subtitle='Build a Trigger'
              width='587px'
              closeIcon={
                <div>
                  <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
                </div>
              }
            >
              <RefManagerForm {...refManagerFormProps} />
            </SlidingPane>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.props.toggleModal} className='ml-auto mr-4'>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton disabled={!this.state.title} type='submit'>
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({ ...state });

const mapDispatchToProps = (dispatch, DescriptionPanelBetaFormProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  getCalculators: () => dispatch(getCalculators()),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

const suggestionsToExclude: SuggestionTypes[] = ['knowledge_base'];

const FeaturedVariableGenerator = withFeaturePermission(VariableAutoGenerator);
export default withTeam(connect(mapStateToProps, mapDispatchToProps)(DescriptionPanelBetaForm));
