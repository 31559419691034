import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { ConditionalText, ConditionalTextRequest, resourcesAPI } from '../../../api/resources';
import { useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../constants';
import { useLocation } from 'react-router';

interface UseConditionalTexts {
  data: ConditionalText[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ConditionalText[]>>;
  postConditionalText: UseMutateFunction<any, unknown, ConditionalTextRequest>;
  putConditionalText: UseMutateFunction<any, unknown, ConditionalTextRequest>;
  isMutationLoading: boolean;
}

export const useConditionalTexts = (): UseConditionalTexts => {
  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const { moduleId } = useParams<{ moduleId: string }>();

  const { data, refetch } = useQuery(
    ['conditional-texts', moduleId],
    moduleType === MODULE_TYPES.ALGO
      ? resourcesAPI.getConditionalTexts
      : resourcesAPI.getCalculatorConditionalTexts,
    {
      initialData: [],
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
    }
  );

  const { mutate: postConditionalText, isLoading: isPostLoading } = useMutation(
    resourcesAPI.postConditionalText,
    { onSuccess: () => refetch }
  );
  const { mutate: putConditionalText, isLoading: isPutLoading } = useMutation(
    resourcesAPI.putConditionalText,
    { onSuccess: () => refetch }
  );

  return {
    data,
    refetch,
    postConditionalText,
    putConditionalText,
    isMutationLoading: isPostLoading || isPutLoading,
  };
};
