import { Box, CircularProgress } from '@material-ui/core';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getConditionalTexts } from 'actions/resources/getConditionalTexts';
import { getFormulas } from 'actions/resources/getFormulas';
import { getTriggers } from 'actions/resources/getTriggers';
import { gptPromptTemplatesAPI } from 'api/gptbox-template';
import axios from 'axios';
import clsx from 'clsx';
import AppearingSituation from 'components/utils/AppearingSituation';
import Checkbox from 'components/utils/Checkbox';
import Label from 'components/utils/Label';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import { StyledFormGroupMB30 } from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, CARD_GPT_BOX, SAVE_BUTTON } from 'constants/variables';
import { EditorState, convertToRaw } from 'draft-js';
import { withKnowledgeBase } from 'hooks/useEHRVariables';
import { getEncoding } from 'js-tiktoken';
import { cloneDeep, debounce } from 'lodash';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import {
  DEFAULT_EDITOR_JSON,
  DEFAULT_TIPTAP_JSON,
  GPT_BOX_API_URL,
  MODULE_TYPES,
} from '../../constants';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { GPTBoxFormDispatchProps } from './types';
export const encoding = getEncoding('cl100k_base');

const richTextButtonsShowList: RichTextMenuButtonTypes[] = ['more'];
// we should remove more tokens for system prompt
// max count changes when different service is used other than openai gpt4-turbo
// 128k (max context) - 4096 (max output) = 123904 (max input)
// conditional text calculation is not correct anyway so we are giving enough buffer for error range
const MAX_INPUT_TOKENS = 100000;

interface PromptTemplateType {
  id: number;
  title: string;
  template: string;
}

interface GPTBoxFormState {
  title: string;
  syncVariableName: boolean;
  trigger: any;
  module: any;
  containerCard: any;
  position: any;

  textEditorsuggestions: any;
  promptTemplates: PromptTemplateType[];
  selectedTemplate: number | null;
  promptJSONTiptap: any;
  variable: string;
  variables: any;
  numerics: any;
  isLongDescEnabled: boolean;
  promptText: string;
  temp_promptJson: any;
  promptJson: any;
  maxTokensExcludedConditionalText: number;
  isLoading: boolean;
  kbAdded: boolean;
  is_hide: boolean;
}

export interface GPTBoxFormProps {
  handleRichText: (value: any) => void;
  handleRichTextTiptap: (value: any) => void;
  handleAddTitle: (title: string) => void;
  formState: any;
  teamState: any;
  knowledgeBases: any;
  gptBoxId: number;
  triggerState: any;
  position: number;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  handleAddVariable: (variable: any) => void;
  startPreview: (component?: any) => void;
  moduleId: string;
  toggleModal: () => void;
  resetState: () => void;
  container: any;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  setIsFormDisabled: (val) => void;
}

type Props = GPTBoxFormDispatchProps & GPTBoxFormProps;

class GPTBoxForm extends Component<Props, GPTBoxFormState> {
  debouncedOnShortTextChange: any;
  debOnPrompTiptapChange: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      title: '',
      syncVariableName: false,
      trigger: null,
      module: null,
      position: null,
      containerCard: '',
      textEditorsuggestions: [],
      promptTemplates: [],
      selectedTemplate: null,
      variable: '',
      variables: [],
      numerics: [],
      isLongDescEnabled: false,
      promptText: '',
      temp_promptJson: '',
      promptJson: convertToRaw(EditorState.createEmpty().getCurrentContent()),
      promptJSONTiptap: '',
      maxTokensExcludedConditionalText: MAX_INPUT_TOKENS,
      isLoading: false,
      kbAdded: false,
      is_hide: false,
    };

    this.debouncedOnShortTextChange = debounce(this.onShortTextChange, 300);
    this.debOnPrompTiptapChange = debounce(this.onPromptTiptapChange, 300);
  }

  setDefaultDescription = () => {
    const short_desc_json = {
      blocks: [
        {
          key: 'opse',
          data: {},
          text: '(The text will be generated based on the prompt you entered.)',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    };

    this.setState(
      {
        isLongDescEnabled: false,
      },
      () => {
        this.props.handleRichText(short_desc_json);
        this.props.handleAddTitle('Card Title Placeholder');
      }
    );
  };

  calculateUsedTokenCount = (text) => {
    return encoding.encode(text).length;
  };

  onTemplateChange = (value: number) => {
    const obj = this.state.promptTemplates.find(
      (template) => template.id === value
    ) as PromptTemplateType;
    const draftJson = cloneDeep(DEFAULT_EDITOR_JSON);
    draftJson.blocks[0].text = obj.template;

    const tiptapJson = cloneDeep(DEFAULT_TIPTAP_JSON);
    tiptapJson.content[0].content[0].text = obj.template;

    this.onShortTextChange(draftJson);
    this.setState({
      temp_promptJson: draftJson,
      selectedTemplate: value,
      promptJSONTiptap: tiptapJson,
    });
  };

  helpInfoData = getHelpInfoData('GPTBox');

  async componentDidMount() {
    const { getTriggers, moduleId, getFormulas, getConditionalTexts } = this.props;
    // TODO: remove when redux data is depracated
    getTriggers(MODULE_TYPES.ALGO, null, false, moduleId);
    getConditionalTexts(MODULE_TYPES.ALGO, null, moduleId);
    getFormulas(MODULE_TYPES.ALGO, null, moduleId);

    this.props.setIsFormDisabled(false);
    const promptTemplates = await gptPromptTemplatesAPI.getTemplates();
    this.setState({ promptTemplates });

    if (!this.props.gptBoxId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );

      this.setState(
        {
          trigger: alwayOnTrigger?.id || '',
          position: this.props.position,
        },
        () => this.setDefaultDescription()
      );

      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview();
    }

    // if update request encountered
    if (this.props.gptBoxId) {
      axios.get(GPT_BOX_API_URL + this.props.gptBoxId + '/').then((res) => {
        this.setState({
          title: res.data?.title,
          promptText: res.data?.prompt,
          temp_promptJson: res.data?.prompt_json,
          position: res.data?.position,
          trigger: res.data?.trigger || '',
          module: res.data?.module,
          variable: res.data?.variable_item?.name,
          selectedTemplate: res.data?.prompt_template,
          promptJSONTiptap: res.data?.prompt_json_tiptap || '',
          is_hide: res.data?.is_hide || false,
        });
        this.props.handleAddTitle(res.data.title);
        this.props.handleAddTrigger(res.data.trigger || '');
      });
    }
    this.populateSuggestions();
  }

  componentDidUpdate(prevProps: Props) {
    const knowledgeBases = this.props.knowledgeBases;
    if (
      prevProps.knowledgeBases !== knowledgeBases &&
      knowledgeBases.length > 1 &&
      !this.state.kbAdded
    ) {
      this.setState((prev) => ({
        textEditorsuggestions: [...prev.textEditorsuggestions, ...this.props.knowledgeBases],
        kbAdded: true,
      }));
    }
  }

  componentWillUnmount() {
    this.debouncedOnShortTextChange.cancel();
  }

  checkTokenCountAndUpdateIfChanged = (maxTokensExcludedConditionalText) => {
    this.setState({ maxTokensExcludedConditionalText });
  };

  setVariableName = (variable: string) => {
    this.setState({ variable });
    this.props.handleAddVariable?.(variable);
  };

  // updates the state on field input
  onTitleChange = (e) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<GPTBoxFormState, any>);
  };

  getPayload = () => {
    return {
      title: this.state.title,
      prompt: this.state.promptText,
      prompt_json: this.state.promptJson,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      position: this.state.position,
      container: this.props.container,
      variable: this.state.variable,
      prompt_template: this.state.selectedTemplate,
      prompt_json_tiptap: this.state.promptJSONTiptap,
      is_hide: this.state.is_hide,
    };
  };

  createGPTBox = () => {
    this.props.toggleModal();
    axios.post(GPT_BOX_API_URL, this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  editGPTBox = () => {
    const link = GPT_BOX_API_URL + this.props.gptBoxId + '/';
    this.props.toggleModal();

    axios.put(link, this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
    });
  };

  populateSuggestions = async () => {
    // suggestions for text field
    const suggestions = await getSuggestions(this.props.moduleId);
    const variables = await getVariables(this.props.moduleId);
    const numerics = await getNumerics(this.props.moduleId);
    this.setState({
      textEditorsuggestions: suggestions,
      variables,
      numerics,
    });
  };

  onShortTextChange = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      promptJson: e,
      promptText: text,
    });
    this.props.handleRichText(e);
  };

  onPromptTiptapChange = (editor) => {
    this.setState({
      promptText: editor.getText(),
      promptJSONTiptap: editor.getJSON(),
    });
    this.props.handleRichTextTiptap(editor.getJSON());
  };

  getSelectedTrigger = (data: any) => {
    this.setState({ trigger: data });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { title, promptText, promptJSONTiptap } = this.state;
    const { gptBoxId, modal, closeUiElementPane } = this.props;
    if (title === '' || promptText === '') {
      toast.error(CustomToast, { data: 'Please fill out the Gpt box title/propmt field.' });
      return;
    }
    this.setState({ isLoading: true });
    if (!this.state.variable) {
      const variable = await getVariableName(this.state.title);
      this.setState({ variable });
    }

    if (gptBoxId) {
      this.editGPTBox();
    } else {
      this.createGPTBox();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div className='row mt-14 h-[97%] overflow-visible'>
        <Overlay show={isFormDisabled} />
        <div className='side-panel-form' style={{ overflow: 'visible' }}>
          <Header title={CARD_GPT_BOX} toggleModal={this.props.toggleModal} />
          <Form
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            className={clsx('sectionDivider flex h-full flex-col px-3', {
              'pointer-events-none': isFormDisabled,
              'pointer-events-auto': !isFormDisabled,
            })}
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <InputField
                dataTestId='data-title-input'
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onTitleChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <VariableAutoGenerator
              tagTitle='AI Output'
              variable={this.state.variable}
              question={this.state.title}
              setVariableName={(variable) => this.setVariableName(variable)}
            />

            <StyledFormGroupMB30 style={{ marginBottom: 0, marginTop: '20px' }}>
              <FieldLabel
                detail={helpInfoData?.prompt?.detail}
                label={helpInfoData?.prompt?.label}
              />

              {this.state.promptTemplates?.length > 0 && (
                <DropdownSingle
                  placeholder='Select Template'
                  onChange={(val) => this.onTemplateChange(val as number)}
                  value={this.state.selectedTemplate as number}
                  options={this.state.promptTemplates}
                />
              )}

              <AvoEditor
                gptBox
                getCharactersLength={this.checkTokenCountAndUpdateIfChanged}
                moduleId={this.props.moduleId}
                suggestions={this.state.textEditorsuggestions}
                setValue={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.debouncedOnShortTextChange(e)
                }
                prevValue={this.state.temp_promptJson}
                placeholder='Write a prompt.'
                variables={this.state.variables}
                numerics={this.state.numerics}
                knowledgeBases={this.props.knowledgeBases}
                MAX_TEXT_LIMIT={MAX_INPUT_TOKENS}
                MAX_NO_OF_LINES={99999}
                calculateContentTextLength={this.calculateUsedTokenCount}
                richTextButtonShowList={[ToolbarButton.VARIABLES, ToolbarButton.CONDITIONAL_TEXT]}
                charCountMode='tokenSize'
                wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                maxCharacters={MAX_INPUT_TOKENS}
                onUpdate={this.debOnPrompTiptapChange}
                initialContent={this.state.promptJSONTiptap}
                suggestionsToExclude={suggestionsToExclude}
                richTextButtonsShowListTiptap={richTextButtonsShowList}
                characterLimitTotalCount={this.state.maxTokensExcludedConditionalText}
                characterLimitCurrentCount={this.calculateUsedTokenCount(this.state.promptText)}
                characterLimitUnit={'tokens'}
              />
              <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                <Label className='flex items-center gap-1'>
                  <Checkbox
                    checked={this.state.is_hide}
                    onChange={(e) => this.setState({ is_hide: e.target.checked })}
                  />
                  <span>Hide Card</span>
                </Label>
              </StaffOnlyWrapper>
            </StyledFormGroupMB30>

            <div style={{ marginBottom: 180 }}></div>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.props.toggleModal} style={{ marginRight: '40px' }}>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton
                  disabled={!this.state.title || !this.state.promptText}
                  type='submit'
                  name='action'
                >
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={!!this.props.modal}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

const mapDispatchToProps = (dispatch, GPTBoxFormProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  getConditionalTexts: (moduleType, mirrorId, moduleId) =>
    dispatch(getConditionalTexts(moduleType, mirrorId, moduleId)),
  getFormulas: (type, mirrorId, moduleId) => dispatch(getFormulas(type, mirrorId, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

const suggestionsToExclude: SuggestionTypes[] = [
  'reference',
  'infobox',
  'link',
  'media',
  'contact_number',
  'ehr_order',
];

export default withKnowledgeBase(connect(mapStateToProps, mapDispatchToProps)(GPTBoxForm));
