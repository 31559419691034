import axios from 'axios';
import * as Sentry from '@sentry/react';
import { POSITION_IDX_ADJUSTMENT } from 'components/utils/ConversationBoardUtil';
import { MODULE_API_URL, SIMULATION_TOKEN_API_URL } from 'constants/index';
import { MODULE_TYPES } from 'constants/moduleStatuses';

export const simulateInWebapp = (moduleId: number | string, type: string) => {
  Sentry.withScope((scope) => {
    scope.setTag('moduleId', moduleId);
    scope.setTag('type', type);
    scope.setLevel('debug');
    let startTime: number = performance.now(); // Measure start time
    axios.get(SIMULATION_TOKEN_API_URL).then((result) => {
      let endTime: number = performance.now();
      Sentry.addBreadcrumb({
        category: 'log',
        message: 'Get simulation token',
        data: {
          startTime: startTime,
          endTime: endTime,
          elapsedTime: endTime - startTime,
        },
      });

      startTime = performance.now();
      let link = '';
      switch (type) {
        case MODULE_TYPES.CALCULATOR:
          link = `${process.env.REACT_APP_WEB_APP_URL}/calc_simulation?simulate=${result.data.simulation_token}&calculatorId=${moduleId}`;
          break;
        case MODULE_TYPES.ALGO:
          link = `${process.env.REACT_APP_WEB_APP_URL}/simulation?simulate=${result.data.simulation_token}&moduleId=${moduleId}`;
          break;
        default:
          break;
      }

      const url = `${window.origin}/simulate?link=${window.encodeURIComponent(link)}`;
      window.open(
        url,
        'avoWindow',
        `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=${window.screen.width},
        height=${window.screen.height}`
      );
      endTime = performance.now();
      Sentry.addBreadcrumb({
        category: 'log',
        message: 'Open simulator',
        data: {
          startTime: startTime,
          endTime: endTime,
          elapsedTime: endTime - startTime,
        },
      });
      Sentry.captureMessage('loading simulator', scope);
    });
  });
};

export const setDraggableId = (item) => {
  const id = item.editMode
    ? item.id
    : 'resourcetype' in item
    ? item.id
    : 'container_' + item.id.toString();

  return id ? id.toString() : ''; // draggableId should be string
};

// TODO: maybe we will delete 'ifChildCardMoved' and
// recursive code which is to change child card position
export const updatePositionValueByArrayElementOrder = (uiElements, ifChildCardMoved: boolean) => {
  // during debuging found undefind values in uiElements so filtering it
  const cards = [...uiElements].filter((card) => card !== undefined);
  let positionKeyValue = {};
  let position = POSITION_IDX_ADJUSTMENT;

  for (let i = 0; i < cards.length; i++) {
    cards[i].position = position;
    /* TODO: on drag end to create new card, 
      positions are updated again but new card is still editing,
      it excludes from the position update. but we better refactor 
      as the logic is unnecessarily overly complex*/
    if (cards[i].id !== 'initial_id') {
      positionKeyValue[cards[i].id] = position;
    }
    position++;

    if (isGroupCard(cards[i]) && ifChildCardMoved) {
      const data = updatePositionValueByArrayElementOrder(cards[i].ui_elements, false);
      cards[i].ui_elements = data.cards;
      positionKeyValue = { ...positionKeyValue, ...data.positionKeyValue };
    }
  }

  return { cards, positionKeyValue };
};

export const isGroupCard = (uiElement) => {
  if (uiElement.ui_elements) {
    return true;
  }

  return false;
};

// TODO: combine 'savePosition' into a single integrated method
// and move it to src/api directory
export const savePositionInDB = async (moduleId, payload) => {
  const res = await axios.put(`${MODULE_API_URL}${moduleId}/ui_element_position/`, {
    positions: payload,
  });
  return res;
};

export const DnDTypes = {
  CHANGE_POSITION_IN_GROUP_CARD: 'CHANGE_POSITION_IN_GROUP_CARD',
  CHANGE_POSITION: 'CHANGE_POSITION',
  CREATE_NEW_CARD: 'CREATE_NEW_CARD',
  NO_EFFECT: '',
};

export const checkDnDTypes = (result): string => {
  const { source, destination } = result;
  if (!destination || !source) {
    return DnDTypes.NO_EFFECT;
  }

  const dragFrom = source.droppableId;
  const dragTo = destination.droppableId;
  const cardPositionChanged = source.index !== destination.index;
  // droppableId only in GroupCard is droppableXXXX
  // so check if 'droppable' is included to decide if drag/drop is inside GroupCard
  const ifDragInGroupCard = dragFrom.includes('droppable');

  if (dragFrom === 'ConversationItem' && dragTo === 'ConversationCard') {
    return DnDTypes.CREATE_NEW_CARD;
  }

  if (dragFrom === 'ConversationCard' && dragTo === 'ConversationCard' && cardPositionChanged) {
    return DnDTypes.CHANGE_POSITION;
  }

  if (dragFrom === dragTo && ifDragInGroupCard && cardPositionChanged) {
    return DnDTypes.CHANGE_POSITION_IN_GROUP_CARD;
  }

  return DnDTypes.NO_EFFECT;
};
