import calculatorIcon from '@iconify/icons-mdi/calculator';
import { Icon } from '@iconify/react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ImageIcon from 'assets/icons/imageIcon';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { Trigger } from './Trigger';

interface DescriptionPanelProps {
  editMode: any;
  editorTrigger: any;
  container: any;
  editorElements: any;
  elements: any;
  containerCard: any;
  descriptionJSONTiptap?: any;
  type: any;
  action: any;
  introCard: any;
  editorAction: any;
  update: () => void;
  editorTitle: any;
  title: any;
  editordescriptionJSON: any;
  descriptionJSON: any;
  deleteCard: Function;
  panelId: any;
  trigger: any;
}
interface DescriptionPanelState {
  modal: boolean;
  deleteModal: boolean;
  editMode: boolean;
  anchorEl: any;
}
class DescriptionPanel extends Component<DescriptionPanelProps, DescriptionPanelState> {
  constructor(props: DescriptionPanelProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      editMode: false,
      anchorEl: null,
    };
  }
  setAnchorEl = (e) => {
    this.setState({ anchorEl: e.target });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleEditMode = () => {
    this.setState((previous) => ({ editMode: !previous.editMode }));
  };
  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  // for open/close modal
  toggleModal = () => {
    this.setState((previous) => ({
      modal: !previous.modal,
    }));
  };

  render() {
    const trigger =
      this.state.editMode || this.props.editMode ? this.props.editorTrigger : this.props.trigger;
    let cardStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginBottom: '20px',
      width: this.props.container ? '100%' : '576px',
      borderRadius: '30px',
      backgroundColor: this.props.container ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      boxShadow: this.props.container ? 'none' : '0px 4px 25px rgba(0, 0, 0, 0.15)',
    };
    let questionStyle = {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '31px',

      color: '#000000',
    };

    let elements =
      this.state.editMode || this.props.editMode
        ? this.props.editorElements
        : this.props.elements
        ? this.props.elements
        : [];

    let examples: Array<{ label: any; type: any }> = [];
    let tools: Array<{ label: any; type: any }> = [];

    elements?.forEach((item) => {
      if (item?.section === 'T') {
        tools.push({
          label:
            item?.label ||
            item?.image?.representation_phrase ||
            item?.legacy_calculator?.title ||
            item?.calculator?.name ||
            item?.infobox?.shortened_title,
          type: item?.image
            ? 'image'
            : item?.legacy_calculator
            ? 'legacy_calculator'
            : item?.calculator
            ? 'calculator'
            : item?.infobox
            ? 'infobox'
            : 'No type found',
        });
      } else if (item?.section === 'E') {
        examples.push({
          label:
            item?.text?.statement ||
            item?.label ||
            item?.infobox?.shortened_title ||
            item?.image?.representation_phrase,
          type: item?.text
            ? 'text'
            : item?.infobox
            ? 'infobox'
            : item?.image
            ? 'image'
            : 'No type found',
        });
      }
    });

    return (
      <div
        style={
          {
            position: this.state.editMode || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editMode || this.props.editMode ? '12' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            // marginLeft: !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <Trigger trigger={trigger} containerCard={this.props.containerCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'div'} color='textPrimary' gutterBottom style={questionStyle}>
              {this.props.introCard && 'Introduction'}
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                {!this.props.introCard && (
                  <MenuItem
                    onClick={() => {
                      this.toggleDeleteModal();
                      this.handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
              {(this.state.editMode || this.props.editMode
                ? this.props.editorAction
                : this.props.action) &&
                !this.props.introCard && <ErrorOutlineIcon style={{ fill: 'orange' }} />}{' '}
              {this.props.introCard
                ? ''
                : this.state.editMode || this.props.editMode
                ? this.props.editorTitle
                : this.props.title}
            </Typography>
            <div style={{ marginBottom: 18, display: 'flex', wordBreak: 'break-all' }}>
              <AvoEditorPreview
                contentTiptap={this.props.descriptionJSONTiptap}
                contentDraftJS={
                  this.state.editMode || this.props.editMode
                    ? this.props.editordescriptionJSON
                    : this.props.descriptionJSON
                }
              />
            </div>
            {examples.length > 0 && (
              <Box
                style={{
                  border: '0.75px solid #B3B3B3',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '58px',
                  marginTop: 10,
                  marginBottom: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                {examples.map((example, index) => {
                  return example.type === 'text' ? (
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#000000',
                      }}
                    >
                      {example.label}

                      {examples[index + 1] && <span style={{ marginRight: 4 }}>,</span>}
                    </Typography>
                  ) : example.type === 'infobox' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : example.type === 'image' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : null;
                })}
              </Box>
            )}
            {tools.map((tool, index) => {
              return tool.type === 'infobox' ? (
                <Chip
                  style={{
                    marginTop: '17px',
                    backgroundColor: '#FFFFFF',
                    color: '#08A88E',
                    fontSize: '16px',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 600,

                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<InfoBoxIcon />}
                  label={tool.label}
                />
              ) : tool.type === 'image' ? (
                <Chip
                  style={{
                    marginTop: '17px',
                    backgroundColor: '#FFFFFF',
                    color: '#08A88E',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    alignItems: 'center',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<ImageIcon />}
                  label={tool.label}
                />
              ) : tool.type === 'legacy_calculator' ? (
                <Chip
                  style={{
                    marginTop: '17px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: '#FFFFFF',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label}
                />
              ) : tool.type === 'calculator' ? (
                <Chip
                  style={{
                    marginTop: '17px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#08A88E',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  icon={<Icon icon={calculatorIcon} />}
                  label={tool.label}
                />
              ) : tool.type === 'text' ? (
                <Chip
                  style={{
                    marginTop: '17px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    backgroundColor: 'transparent',
                    color: '#000000',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  size='small'
                  key={index}
                  label={tool.label}
                />
              ) : null;
            })}
          </CardContent>
        </Card>
        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}
export default DescriptionPanel;
