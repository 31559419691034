import { APIResourceContainer } from 'components/resources/api/APIResourceContainer';
import { useAuthentication } from 'hooks/useAuthentication';
import { ReactNode } from 'react';
import { useParams } from 'react-router';
import { Link, generatePath } from 'react-router-dom';
import { ALGO_FLOW_ROUTE, ALGO_RESOURCES_ROUTE } from '../../../constants';
import { ModuleBoard } from '../../module/ModuleBoard';
import { CalculatorIntegrationResourceTable } from '../../resources/calculator-integration/CalculatorIntegrationTable';
import { ConditionalTextResourceTable } from '../../resources/conditional-texts/ConditionalTextTable';
import { CustomNumericResourceTable } from '../../resources/custom-numerics/CustomNumericTable';
import { FormulaResourceTable } from '../../resources/formula-board/FormulaTable';
import { InfoboxResourceTable } from '../../resources/infobox/InfoboxTable';
import { ReferenceManagerResourceTable } from '../../resources/reference-manager/ReferenceManagerTable';
import { SmartNoteResourceTable } from '../../resources/smart-note/SmartNoteTable';
import { TriggerResourceTable } from '../../resources/triggers/TriggerTable';
import { H3 } from '../../utils/typo';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from '../../utils/StaffOnlyWrapper';

export type ResourceTypes =
  | 'triggers'
  | 'infoboxes'
  | 'formulas'
  | 'references'
  | 'smart-notes'
  | 'custom-numbers'
  | 'integrations'
  | 'conditional-texts'
  | 'api-resources';

export const ALGO_RESOURCES: Record<ResourceTypes, ResourceTabInfo> = {
  triggers: {
    label: 'Triggers',
    component: <TriggerResourceTable />,
  },
  infoboxes: {
    label: 'Infoboxes / Media',
    component: <InfoboxResourceTable />,
  },
  formulas: {
    label: 'Formulas',
    component: <FormulaResourceTable />,
  },
  references: {
    label: 'Reference Managers',
    component: <ReferenceManagerResourceTable />,
  },
  'smart-notes': {
    label: 'Smart Notes',
    component: <SmartNoteResourceTable />,
  },
  'custom-numbers': {
    label: 'Custom Numbers',
    component: <CustomNumericResourceTable />,
  },
  integrations: {
    label: 'Integrations',
    component: <CalculatorIntegrationResourceTable />,
  },
  'conditional-texts': {
    label: 'Conditional Texts',
    component: <ConditionalTextResourceTable />,
  },
  'api-resources': {
    label: 'APIs',
    component: <APIResourceContainer />,
  },
};

interface ResourceTabInfo {
  label: string;
  component: ReactNode;
}

const AlgoTabs = () => {
  const { resource } = useParams<{ resource: ResourceTypes | undefined }>();
  const { moduleId } = useParams<{ moduleId: string }>();

  const { authentication } = useAuthentication();
  const isStaff = !!authentication.user?.is_staff;

  return (
    <div className='flex w-full'>
      <div className='mt-[80px] w-[200px]'>
        <div className='fixed flex w-44 flex-col items-end space-y-5'>
          {!resource ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Flow</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(ALGO_FLOW_ROUTE, { moduleId })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Flow</H3>
              </div>
            </Link>
          )}
          {!!resource ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Resources</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(ALGO_RESOURCES_ROUTE, { moduleId, resource: 'triggers' })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Resources</H3>
              </div>
            </Link>
          )}
          {!!resource &&
            Object.keys(ALGO_RESOURCES)
              .filter((resourceName) => filterApiResourceTab(resourceName, isStaff))
              .map((resourceName, index) => (
                <>
                  {StaffOnlyWrapIf({
                    condition: resourceName === 'api-resources',
                    options: {
                      type: StaffOnlyBadgeType.BETA,
                      y: 5,
                    },
                    element:
                      resource === resourceName ? (
                        <p className='text-primary-600'>{ALGO_RESOURCES[resourceName].label}</p>
                      ) : (
                        <Link
                          to={generatePath(ALGO_RESOURCES_ROUTE, {
                            moduleId,
                            resource: resourceName,
                          })}
                          className='text-black hover:text-primary-600'
                        >
                          {ALGO_RESOURCES[resourceName].label}
                        </Link>
                      ),
                  })}
                </>
              ))}
        </div>
      </div>

      <div className='w-full'>
        {!resource ? <ModuleBoard /> : ALGO_RESOURCES[resource].component}
      </div>
    </div>
  );
};

export default AlgoTabs;

// TODO: remove this when open this to public
function filterApiResourceTab(resourceName: string, isStaff: boolean) {
  if (resourceName === 'api-resources' && !isStaff) return false;
  return true;
}
