import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { APIResource } from 'components/resources/api/types';
import {
  API_RESOURCE_API_URL,
  CALC_INTEGRATION_API_URL,
  CALCULATOR_API_URL,
  CONDITIONAL_TEXT_API_URL,
  CUSTOM_NUMERIC_API_URL,
  FORMULA_API_URL,
  INFOBOX_API_URL,
  INFOBOX_IMAGE_API_URL,
  MODULE_API_URL,
  MODULE_TYPES,
  REFERENCE_MANAGER_API_URL,
  TRIGGER_API_URL,
} from '../constants';

export interface WithLastUpdated {
  last_updated: string;
}

// TODO: Clear typing for resources
export interface Trigger extends WithLastUpdated {
  id: string;
  title: string;
  condition?: string;
  candidate_trigger: boolean;
}

export interface Infobox extends WithLastUpdated {
  id: string;
  title: string;
  type: string;
  new_text_content: any;
  text_json_tiptap: any;
}

export interface Media extends WithLastUpdated {
  id: string;
  title: string;
  type: string;
  new_caption_content: any;
  caption_json_tiptap: any;
}

export interface Formula extends WithLastUpdated {
  id: string;
  name: string;
  formula: string;
  new_formula_content: any;
  formula_json_tiptap: any;
}

export interface ReferenceManager extends WithLastUpdated {
  id: string;
  source: string;
  url: string;
  is_shortened_source_enabled: boolean;
  shortened_source: string;
}

export interface SmartNote extends WithLastUpdated {
  id: string;
  title: string;
  new_text_content: any;
  text_json_tiptap: any;
  infobox_type: string;
}

export interface CustomNumeric extends WithLastUpdated {
  id: string;
  name: string;
  upper_limit: number;
  lower_limit: number;
  unit: string;
}

export interface Integration extends WithLastUpdated {
  id: string;
  integrated_calculator: string;
  integrated_calculator_info: IntegratedCalculatorInfo;
  module: number;
  numerical_output_mappings: any;
  categorical_output_mappings: any;
}

interface IntegratedCalculatorInfo {
  id: number;
  name: string;
}

export interface ConditionalText extends WithLastUpdated {
  id: string;
  name: string;
  conditions: any[];
  is_name_optional?: boolean;
}

export interface ConditionalTextRequest extends Omit<ConditionalText, 'id' | 'last_updated'> {
  id?: string;
  module?: number | string;
  calculator?: number | string;
  conditions: any[];
}

interface ApiResourceRequest extends WithLastUpdated {
  id: number;
  created: string;
  unique_code: string;
  title: string;
  url: string;
  method: string;
  authorization: any; // TODO: tiptap, any to some type
  content_type: string;
  body: any; // TODO: tiptap, any to some type
  output_type: string;
  output_type_definition: string;
  output_you_want_to_use: string;
  module: number;
}

export const resourcesAPI = {
  getTriggers: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Trigger[]>(`${MODULE_API_URL}${queryKey[1]}/triggers`).then((res) => res.data),
  deleteTrigger: (triggerId: string) => axios.delete(TRIGGER_API_URL + triggerId + '/'),
  getInfoboxes: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Infobox[]>(`${MODULE_API_URL}${queryKey[1]}/infoboxes`).then((res) => res.data),
  deleteInfobox: (infoboxId: string) => axios.delete(INFOBOX_API_URL + infoboxId + '/'),
  getMedia: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Media[]>(`${MODULE_API_URL}${queryKey[1]}/images`).then((res) => res.data),
  deleteMedia: (mediaId: string) => axios.delete(INFOBOX_IMAGE_API_URL + mediaId + '/'),
  getFormulas: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Formula[]>(`${MODULE_API_URL}${queryKey[1]}/formulas`).then((res) => res.data),
  deleteFormula: (formulaId: string) => axios.delete(FORMULA_API_URL + formulaId + '/'),
  getReferences: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ReferenceManager[]>(`${MODULE_API_URL}${queryKey[1]}/reference_managers`)
      .then((res) => res.data),
  deleteReference: (referenceId: string) =>
    axios.delete(REFERENCE_MANAGER_API_URL + referenceId + '/'),
  getSmartNotes: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<SmartNote[]>(`${MODULE_API_URL}${queryKey[1]}/note_generator`)
      .then((res) => res.data),
  deleteSmartNotes: (smartNoteId: string) => axios.delete(INFOBOX_API_URL + smartNoteId + '/'),
  getCustomNumerics: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<CustomNumeric[]>(`${MODULE_API_URL}${queryKey[1]}/custom_numerics`)
      .then((res) => res.data),
  deleteCustomNumeric: (customNumericId: string) =>
    axios.delete(CUSTOM_NUMERIC_API_URL + customNumericId + '/'),
  getIntegrations: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<Integration[]>(`${MODULE_API_URL}${queryKey[1]}/calculator_integrations`)
      .then((res) => res.data),
  deleteIntegration: (integrationId: string) =>
    axios.delete(CALC_INTEGRATION_API_URL + integrationId + '/'),
  getConditionalTexts: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ConditionalText[]>(`${MODULE_API_URL}${queryKey[1]}/conditional_texts`)
      .then((res) => res.data),
  deleteConditionalText: (conditionalTextId: string) =>
    axios.delete(CONDITIONAL_TEXT_API_URL + conditionalTextId + '/'),
  postConditionalText: (conditionalTextRequest: ConditionalTextRequest) =>
    axios.post(CONDITIONAL_TEXT_API_URL, conditionalTextRequest).then((res) => res.data),
  putConditionalText: (conditionalTextRequest: ConditionalTextRequest) =>
    axios
      .put(`${CONDITIONAL_TEXT_API_URL}${conditionalTextRequest.id}/`, conditionalTextRequest)
      .then((res) => res.data),
  getApiResources: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<APIResource[]>(
        `${queryKey[0] === MODULE_TYPES.ALGO ? MODULE_API_URL : CALCULATOR_API_URL}${
          queryKey[1]
        }/api_resources`
      )
      .then((res) => res.data),
  deleteApiResource: (apiResourceId: string) =>
    axios.delete(API_RESOURCE_API_URL + apiResourceId + '/'),
  postApiResource: (apiResourceRequest: ApiResourceRequest) =>
    axios.post(API_RESOURCE_API_URL, apiResourceRequest).then((res) => res.data),
  putApiResource: (apiResourceRequest: ApiResourceRequest) =>
    axios
      .put(`${API_RESOURCE_API_URL}${apiResourceRequest.id}/`, apiResourceRequest)
      .then((res) => res.data),

  // GET calculator resources
  getCalculatorTriggers: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Trigger[]>(`${CALCULATOR_API_URL}${queryKey[1]}/triggers`).then((res) => res.data),
  getCalculatorInfoboxes: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Infobox[]>(`${CALCULATOR_API_URL}${queryKey[1]}/infoboxes`).then((res) => res.data),
  getCalculatorMedia: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Media[]>(`${CALCULATOR_API_URL}${queryKey[1]}/images`).then((res) => res.data),
  getCalculatorFormulas: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Formula[]>(`${CALCULATOR_API_URL}${queryKey[1]}/formulas`).then((res) => res.data),
  getCalculatorReferences: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ReferenceManager[]>(`${CALCULATOR_API_URL}${queryKey[1]}/references`)
      .then((res) => res.data),
  getCalculatorSmartNotes: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<SmartNote[]>(`${CALCULATOR_API_URL}${queryKey[1]}/note_generator`)
      .then((res) => res.data),
  getCalculatorCustomNumerics: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<CustomNumeric[]>(`${CALCULATOR_API_URL}${queryKey[1]}/custom_numerics`)
      .then((res) => res.data),
  getCalculatorConditionalTexts: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ConditionalText[]>(`${CALCULATOR_API_URL}${queryKey[1]}/conditional_texts`)
      .then((res) => res.data),
  getVariables: ({ queryKey }: QueryFunctionContext) => {
    let API_URL =
      queryKey[1] === MODULE_TYPES.CALCULATOR
        ? `${CALCULATOR_API_URL}${queryKey[2]}/variables`
        : `${MODULE_API_URL}${queryKey[2]}/variables`;

    API_URL = queryKey[3] === 'for_text_editor' ? `${API_URL}?for_text_editor=True` : API_URL;
    return axios.get(API_URL).then((res) => res.data);
  },
  getNumerics: ({ queryKey }: QueryFunctionContext) => {
    const API_URL =
      queryKey[1] === MODULE_TYPES.CALCULATOR
        ? `${CALCULATOR_API_URL}${queryKey[2]}/numerics`
        : `${MODULE_API_URL}${queryKey[2]}/numerics`;
    return axios.get(API_URL).then((res) => res.data);
  },
};
