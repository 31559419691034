import clsx from 'clsx';
import { HTMLAttributes, forwardRef } from 'react';

interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  readOnly?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, readOnly, children, ...rest }, ref) => {
    return (
      <textarea
        ref={ref}
        readOnly={readOnly}
        className={clsx(
          className,
          'w-full rounded border border-gray-300 px-[12px] py-[10px] outline-none focus:border-primary-500'
        )}
        {...rest}
      >
        {children}
      </textarea>
    );
  }
);

export default Textarea;
