import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import { CSSProperties, Fragment, useEffect, useRef, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { twJoin } from 'tailwind-merge';
import Button from './Button';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { LAB_DATA_CUSTOM_RANGE_CODE, NOTE_CUSTOM_RANGE_CODE } from '../../hooks/useEHRVariables';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from './StaffOnlyWrapper';

const VIRTUALIZE_SIZE = 50;

type ComboboxValueType = string | number;

interface DropdownValue {
  value: ComboboxValueType;
  label: string;
  type?: string;
}

interface OptionItemProps {
  option: DropdownValue;
  onEdit?: (id: string) => void;
  style?: CSSProperties;
}

interface OptionsProps {
  options: DropdownValue[];
  onEdit?: (id: string) => void;
  selectedValue?: ComboboxValueType;
}

interface OptionsBoxProps extends OptionsProps {
  query: string;
  allowCustomValue: boolean;
  selectedValue: ComboboxValueType;
  createButtonLabel?: string;
  onCreate?: () => void;
  onEdit?: (id: string) => void;
}

export interface ComboBoxProps extends OptionsProps {
  selectedValue?: ComboboxValueType;
  onChange?: (option: ComboboxValueType) => void;
  placeholder?: string;
  disabled?: boolean;
  allowCustomValue?: boolean;
  createButtonLabel?: string;
  onCreate?: () => void;
  onEdit?: (id: string) => void;
}

const OptionItem = ({ option, onEdit, style }: OptionItemProps) => {
  const isHeading = option?.type === 'heading';
  return (
    <Combobox.Option
      style={style}
      value={option.value}
      disabled={isHeading}
      className={({ active }) =>
        clsx('!px-3 !py-2.5', {
          'bg-primary-200': active,
          'cursor-pointer': !isHeading,
          'border-t-2 border-solid border-gray-200': isHeading,
        })
      }
    >
      {({ selected }) => (
        <div className='flex justify-between'>
          <div
            className={clsx(
              'truncate',
              isHeading ? 'text-caption-2 text-gray-700' : 'text-gray-900'
            )}
          >
            {typeof option.value === 'string' &&
            [
              'note_recent_1_day',
              'lab_data_recent_1_day',
              NOTE_CUSTOM_RANGE_CODE,
              LAB_DATA_CUSTOM_RANGE_CODE,
            ].includes(option.value) ? (
              <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>{option.label}</StaffOnlyWrapper>
            ) : (
              option.label
            )}
          </div>
          <div className='flex justify-between gap-2'>
            {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
            {onEdit && (
              <Button.Reverse
                type='button'
                className='w-fit p-0'
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(option.value as string);
                }}
              >
                Edit
              </Button.Reverse>
            )}
          </div>
        </div>
      )}
    </Combobox.Option>
  );
};

const NormalOptions = ({ options, onEdit }: OptionsProps) => {
  return options.map((option) => <OptionItem key={option.value} option={option} onEdit={onEdit} />);
};

const VirtualiezedOptions = ({ options, selectedValue, onEdit }: OptionsProps) => {
  const selectedIndex = options.findIndex((option) => option.value === selectedValue);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          scrollToIndex={selectedIndex}
          scrollToAlignment='center'
          height={height}
          width={width}
          rowCount={options.length}
          rowHeight={41}
          rowRenderer={({ key, index, isScrolling, isVisible, style }) => (
            <OptionItem key={key} option={options[index]} onEdit={onEdit} style={style} />
          )}
        />
      )}
    </AutoSizer>
  );
};

const OptionsBox = ({
  options,
  query,
  selectedValue,
  allowCustomValue,
  createButtonLabel,
  onCreate,
  onEdit,
}: OptionsBoxProps) => {
  const needVirtualized = options.length > VIRTUALIZE_SIZE;
  return (
    <Combobox.Options
      className={twJoin(
        'z-10 my-0 w-full rounded bg-white shadow-04',
        needVirtualized ? 'h-[267px] overflow-hidden' : 'max-h-[267px] overflow-auto'
      )}
    >
      {onCreate && (
        <button
          onClick={(e) => {
            e.preventDefault();
            onCreate();
          }}
          className='flex w-full items-center gap-1 self-stretch !px-3 !py-2.5 hover:bg-primary-200'
        >
          <PlusIcon className='text-gary-800 h-4 w-4' />
          <p className='truncate text-button-1 text-gray-900'>{createButtonLabel}</p>
        </button>
      )}
      {options.length === 0 && query !== '' ? (
        allowCustomValue ? (
          <OptionItem option={{ value: query, label: query }} />
        ) : (
          <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
            Nothing found.
          </div>
        )
      ) : !onCreate && options.length === 0 ? (
        <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>No item.</div>
      ) : needVirtualized ? (
        <VirtualiezedOptions options={options} selectedValue={selectedValue} onEdit={onEdit} />
      ) : (
        <NormalOptions options={options} onEdit={onEdit} />
      )}
    </Combobox.Options>
  );
};

export const ComboBox = ({
  selectedValue = '',
  options,
  onChange,
  disabled,
  onCreate,
  onEdit,
  createButtonLabel,
  allowCustomValue = false,
  placeholder = 'Type here to search',
}: ComboBoxProps) => {
  const DEFAULT_SELECTED_VALUE = { label: '', value: '' };
  const [selectedOption, setSelectedOption] = useState<DropdownValue>(DEFAULT_SELECTED_VALUE);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!selectedValue || options.length === 0) {
      setSelectedOption(DEFAULT_SELECTED_VALUE);
      return;
    }
    const findOption = options.find((option) => option.value === selectedValue);
    if (!!findOption) setSelectedOption(findOption);
  }, [selectedValue, options]);

  const [query, setQuery] = useState('');

  const filteredOption =
    query === ''
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const onSelect = (value: ComboboxValueType) => {
    onChange?.(value);
    const findValue = filteredOption.find((option) => option.value === value);
    if (findValue) {
      setSelectedOption(findValue);
    } else if (allowCustomValue) {
      setSelectedOption({ label: value as string, value });
    }
    setTimeout(() => inputRef.current?.blur(), 200);
  };

  const onFocus = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
  };

  const onBlur = () => {
    if (!inputRef.current) return;
    inputRef.current.value = selectedOption?.label;
  };

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [flip(), offset(3)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Combobox value={selectedOption?.value} onChange={onSelect} disabled={disabled}>
      {({ open }) => (
        <div className='relative w-full'>
          <Combobox.Button
            ref={refs.setReference}
            className={twJoin(
              'flex w-full items-center overflow-hidden rounded border border-gray-300',
              disabled && 'bg-gray-100'
            )}
          >
            <div className='grow'>
              <Combobox.Input
                ref={inputRef}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                className={twJoin(
                  'w-full grow truncate px-3 py-2 text-gray-900 outline-none',
                  '!mb-0 !box-border !h-auto !border-b-0 shadow-none focus:outline-none' // because of materialize.css
                )}
                autoComplete='off'
                displayValue={() => selectedOption?.label}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
            <div className={twJoin('mr-2 transition-all', open && 'rotate-180')}>
              <DropdownArrowIcon />
            </div>
          </Combobox.Button>
          <Transition
            as={Fragment}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            afterLeave={() => setQuery('')}
          >
            <div className='relative z-10 w-full' ref={refs.setFloating} style={floatingStyles}>
              <OptionsBox
                query={query}
                onEdit={onEdit}
                onCreate={onCreate}
                options={filteredOption}
                selectedValue={selectedValue}
                allowCustomValue={allowCustomValue}
                createButtonLabel={createButtonLabel}
              />
            </div>
          </Transition>
        </div>
      )}
    </Combobox>
  );
};
