import createAlignmentPlugin from '@draft-js-plugins/alignment';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import Menu from '@material-ui/core/Menu';
import PhoneIconForText from 'assets/icons/phoneIconForText';
import RedirectIcon from 'assets/icons/RedirectIcon';
import { encoding } from 'components/panels/GPTBoxForm';
import ConditionalTextForm from 'components/resources/conditional-texts/form';
import ImageForm from 'components/resources/image-Infobox/imageForm';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import {
  ContentBlock,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  genKey,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import {
  LAB_DATA_CUSTOM_RANGE_CODE,
  LAB_DATA_SUGGESTION,
  LAB_DATA_TYPE,
  MEDICATION_SUGGESTION,
  MEDICATION_TYPE,
  NOTE_CUSTOM_RANGE_CODE,
  NOTE_TYPE,
  PROBLEM_LIST_SUGGESTION,
  PROBLEM_LIST_TYPE,
  SUGGESTION,
  VITAL_SIGN_CUSTOM_RANGE_CODE,
  VITAL_SIGN_SUGGESTION,
  VITAL_SIGN_TYPE,
  withEHRVariables,
} from '../../../hooks/useEHRVariables';
import { INNER_INFOBOX_Z_INDEX } from '../../../v2/utils/constant';
import MenuDropdown from '../MenuDropdown';
import { MentionContext } from '../module/MentionContext';
import './draftjsEditorStyles.css';
import Entry from './mentionEntry';
import ConditionalTextMenu from './menus/conditional-text';
import onCopy from './onCopy';
import onCut from './onCut';
import onPaste from './onPaste';
import SearchableDropdown from './searchableDropdown';
import { conditionalTextStyle, styleMap } from './styles';
import ToolbarButtons from './toolbarButtons';

class AutoSuggest extends React.Component {
  static contextType = MentionContext;

  constructor(props) {
    super(props);

    this.state = {
      editorState: !!props.prevValue
        ? EditorState.createWithContent(convertFromRaw(props.prevValue))
        : EditorState.createEmpty(),
      suggestions: [],
      currentConditionalTexts: [],
      tempEl: null,
      flag: true,
      mentionClicked: false,
      updateInfobox: false,
      updateMedia: false,
      updateinfoDropdownEl: null,
      updateConditionalTextEl: null,
      openMentionPopup: false,
      modifiedTextLimit: this.props.MAX_TEXT_LIMIT,
      selectedItem: '',
      searchInput: '',
      text: '',
      isEditInfoMedia: false,
      createInfoboxModal: false,
      editInfoboxModal: false,
      editMediaModal: false,
      positionModified: false,
    };
    this.focusPlugin = createFocusPlugin();
    this.resizeablePlugin = createResizeablePlugin();
    this.alignmentPlugin = createAlignmentPlugin();
    const decorator = composeDecorators(
      this.resizeablePlugin.decorator,
      this.alignmentPlugin.decorator,
      this.focusPlugin.decorator
    );
    this.imagePlugin = createImagePlugin({ decorator });
    this.mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
      mentionComponent: (mentionProps) => {
        let ignoreKWs = [
          'VARIABLES',
          'INFOBOXES',
          'REFERENCES',
          'FORMULAE',
          'NUMERICS',
          'MEDIA',
          'KNOWLEDGE_BASES',
        ];
        if (ignoreKWs.includes(mentionProps.mention.code)) return null;

        let isPhoneNo = mentionProps.mention.code === 'phoneNumber',
          isLink = ['embedded', 'unembedded', 'module', 'calculator'].includes(
            mentionProps.mention.type
          ),
          isInfobox = mentionProps.mention.code.includes('infobox'),
          isMedia = mentionProps.mention.code.includes('image'),
          isMultiVariable =
            mentionProps.mention.code.includes('variable') &&
            mentionProps.mention.type === 'multi_variable',
          isChoiceVariable =
            mentionProps.mention.code.includes('variable') &&
            mentionProps.mention.type === 'choice_variable',
          isTextVariable =
            mentionProps.mention.code.includes('variable') &&
            (mentionProps.mention.type === 'text_input_variable' ||
              mentionProps.mention.type === 'gptbox_variable' ||
              mentionProps.mention.type === 'apibox_variable'),
          isCustomNumeric = mentionProps.mention.code.includes('customnumeric'),
          isNumeric = mentionProps.mention.code.includes('numeric'),
          isFormula = mentionProps.mention.code.includes('formula'),
          isConditionalText = mentionProps.mention.code.includes('conditionaltext');

        const isInvalidOrder =
          mentionProps.mention.type === 'ehr_order' &&
          !this.context.suggestions.some((order) => order.code === mentionProps.mention.code);

        let normalStyle = {
          textDecorationLine: isPhoneNo ? 'none' : 'underline',
          color:
            mentionProps.mention.code.includes('_delet_code') || isInvalidOrder
              ? '#FF0000'
              : '#08A88E',
        };

        const openMentionPopup = (e) => {
          this.setState({
            selectedItem: mentionProps.mention.code,
            text: mentionProps.mention.name,
            mentionProps: mentionProps,
            mentionClicked: true,
            tempEl: e.target,
            updateInfobox: isInfobox,
            updateMedia: isMedia,
            isMultiVariable,
            isChoiceVariable,
            isTextVariable,
            isNumeric,
            isFormula,
            isCustomNumeric,
            isConditionalText,
          });
        };

        return (
          <span>
            {isLink ? (
              <span
                style={{
                  ...normalStyle,
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <div style={{ marginRight: 5 }}>
                  {' '}
                  <RedirectIcon />
                </div>

                {mentionProps.children}
              </span>
            ) : (
              <span
                className='inline-flex'
                style={
                  isConditionalText ||
                  isChoiceVariable ||
                  isMultiVariable ||
                  isTextVariable ||
                  isNumeric ||
                  isCustomNumeric
                    ? {
                        ...conditionalTextStyle,
                        color: mentionProps.mention.code.includes('_delet_code')
                          ? '#FF0000'
                          : '#08A88E',
                      }
                    : normalStyle
                }
                onMouseDown={(e) => {
                  if (
                    isInfobox ||
                    isMedia ||
                    isChoiceVariable ||
                    isMultiVariable ||
                    isTextVariable ||
                    isNumeric ||
                    isCustomNumeric ||
                    isFormula ||
                    isConditionalText
                  ) {
                    openMentionPopup(e);
                  } else if (isLink) {
                    window.location.replace = mentionProps.mention.link;
                  }
                }}
              >
                {isPhoneNo && <PhoneIconForText style={{ marginRight: 5, marginBottom: 3 }} />}
                {mentionProps.children}
              </span>
            )}
          </span>
        );
      },
    });

    this.toolbarPlugin = createToolbarPlugin();
    this.linkPlugin = createLinkPlugin();
  }

  getConditionalTextLength = () => {
    const conditionalTexts = this.state.currentConditionalTexts;
    let count = 0,
      combinedText = '';
    conditionalTexts.forEach((text) => {
      count = count + text.name.length;
      combinedText = `${combinedText} ${text.name}`;
    });

    return this.props.gptBox ? encoding.encode(combinedText).length : count;
  };

  componentDidUpdate(prevProps) {
    // get previous value of editor in case update form is opened and set editor state.
    const prevValue =
      this.props.prevValue !== 'undefined' && this.props.prevValue !== null
        ? this.props.prevValue
        : null;
    let editorState;
    if (this.props.isNested && this.state.flag && prevValue) {
      editorState = EditorState.createWithContent(convertFromRaw(prevValue));
      this.setState({
        editorState: editorState,
        flag: false,
      });
    } else if (prevProps.prevValue !== this.props.prevValue && prevValue) {
      editorState = EditorState.createWithContent(convertFromRaw(prevValue));
      this.setState({ editorState: editorState });
    }
  }

  onMentionOpenChange = (open) => this.setState({ openMentionPopup: open });

  calculateContentTextLength = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (this.props.calculateContentTextLength) {
      return this.props.calculateContentTextLength(text);
    } else {
      return text.length;
    }
  };

  onChange = (editorState, e) => {
    const contentState = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    const plainText = contentState.getPlainText('');
    const noOfLines = plainText.split('\n').length;
    const contentTextLength = this.calculateContentTextLength(editorState);

    if (this.props.MAX_NO_OF_LINES && this.state.modifiedTextLimit) {
      if (
        contentState === oldContent ||
        (contentTextLength + 1 <= this.state.modifiedTextLimit &&
          noOfLines <= this.props.MAX_NO_OF_LINES)
      ) {
        this.setState({ editorState }, () => {
          this.props.setValue(this.onExtractData());
          // Issue:
          // 1. If user clicks on mention (infobox/media to update it's label)
          // without clicking inside text editor then mention name will not update.
          // 2. If the cursor in text editor and clicked mention are not on same line
          // then it will also not update mention label.

          // Reason:
          // This happens because editor's selection state is on another point when mention
          // clicked and updated.

          // Solution:
          // In order to fix this issue, we have to wait for editor's state to update in order
          // to get the latest selection state when user clicks on mention. After the updation of
          // editor's state, show popup to update mention's label.
          if (this.state.mentionClicked) {
            const isVariable =
              this.state.isChoiceVariable ||
              this.state.isMultiVariable ||
              this.state.isTextVariable ||
              this.state.isNumeric ||
              this.state.isCustomNumeric ||
              this.state.isFormula;
            const isInfobox = this.state.updateInfobox || this.state.updateMedia;
            const isConditionalText = this.state.isConditionalText;

            setTimeout(() => {
              this.setState((prevState) => ({
                updateinfoDropdownEl: isInfobox ? prevState.tempEl : null,
                updateVariableEl: isVariable ? prevState.tempEl : null,
                updateConditionalTextEl: isConditionalText ? prevState.tempEl : null,
                mentionClicked: false,
              }));
            }, 200);
          }
        });
      } else {
        this.props.showWarning();
        const editorState = EditorState.undo(
          EditorState.push(
            this.state.editorState,
            ContentState.createFromText(oldContent.getPlainText()),
            'delete-character'
          )
        );
        this.setState({ editorState }, () => this.props.setValue(this.onExtractData()));
      }
    } else {
      this.setState({ editorState }, () => {
        this.props.setValue(this.onExtractData());
        // Issue:
        // 1. If user clicks on mention (infobox/media to update it's label)
        // without clicking inside text editor then mention name will not update.
        // 2. If the cursor in text editor and clicked mention are not on same line
        // then it will also not update mention label.

        // Reason:
        // This happens because editor's selection state is on another point when mention
        // was clicked and updated.

        // Solution:
        // In order to fix this issue, we have to wait for editor's state to update in order
        // to get the latest selection state when user clicks on mention. After the updation of
        // editor's state, show popup to update mention's label.
        if (this.state.mentionClicked) {
          const isVariable =
            this.state.isMultiVariable ||
            this.state.isChoiceVariable ||
            this.state.isTextVariable ||
            this.state.isNumeric ||
            this.state.isCustomNumeric ||
            this.state.isFormula;
          const isInfobox = this.state.updateInfobox || this.state.updateMedia;
          const isConditionalText = this.state.isConditionalText;

          setTimeout(() => {
            this.setState((prevState) => ({
              updateinfoDropdownEl: isInfobox ? prevState.tempEl : null,
              updateVariableEl: isVariable ? prevState.tempEl : null,
              updateConditionalTextEl: isConditionalText ? prevState.tempEl : null,
              mentionClicked: false,
            }));
          }, 200);
        }
      });
    }
  };

  toggleModal = (modalName) => {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
    }));
  };

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = (chars) => {
    const totalLength =
      this.state.editorState.getCurrentContent().getPlainText().length + chars.length;
    return totalLength > this.state.modifiedTextLimit;
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const plainText = currentContent.getPlainText('');
    const selectedTextLength = this._getLengthOfSelectedText();

    const currentContentLength = this.props.gptBox
      ? encoding.encode(plainText).length
      : plainText.length;

    const pastedTextLength = this.props.gptBox
      ? encoding.encode(pastedText).length
      : pastedText.length;

    const NoOfLinesOfCurrentContent = plainText.split('\n').length;
    const NoOfLinesOfPastedText = pastedText.split('\n').length;

    if (
      currentContentLength + pastedTextLength - selectedTextLength > this.props.MAX_TEXT_LIMIT ||
      NoOfLinesOfCurrentContent + NoOfLinesOfPastedText > this.props.MAX_NO_OF_LINES
    ) {
      const slicedText = this.props.gptBox
        ? encoding.decode(
            encoding
              .encode(pastedText)
              .slice(0, [this.state.modifiedTextLimit - currentContentLength - 1])
          )
        : pastedText.substring(0, this.state.modifiedTextLimit - currentContentLength - 1);

      const { editorState } = this.state;
      const newState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        slicedText
      );
      this.onChange(EditorState.push(editorState, newState, 'insert-fragment'));
      this.props.showWarning();
      return 'handled';
    }
  };

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List' ? list.size : list.length);

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) => !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1
    );
    const length = size(filteredSuggestions) < 100 ? size(filteredSuggestions) : 100;
    return filteredSuggestions.slice(0, length);
  };

  onSearchChange = ({ value }) => {
    const filteredLabData = this.props.labData
      .map((item) => ({
        ...item,
        name: item.code === 'heading' ? item.name : `${item.name} ${item.type.replace('_', ' ')}`,
        aliasName: item.name,
      }))
      .filter((item) => item.code !== LAB_DATA_CUSTOM_RANGE_CODE);
    const filteredNotes = this.props.notes
      .map((item) => ({
        ...item,
        name:
          item.code === 'heading' && item.id !== 5
            ? item.name
            : `${item.name} ${item.type.replace('_', ' ')}`,
        aliasName: item.name,
      }))
      .filter((item) => item.code !== NOTE_CUSTOM_RANGE_CODE);
    const filteredVitalSigns = this.props.vitalSigns
      .map((item) => ({
        ...item,
        name: item.code === 'heading' ? item.name : `${item.name} ${item.type.replace('_', ' ')}`,
        aliasName: item.name,
      }))
      .filter((item) => item.code !== VITAL_SIGN_CUSTOM_RANGE_CODE);
    this.setState({
      suggestions: this.customSuggestionsFilter(value, [
        ...this.props.suggestions,
        ...(this.props.knowledgeBases ?? []),
        ...filteredLabData,
        ...filteredNotes,
        ...filteredVitalSigns,
        ...this.props.medications,
        ...this.props.problemList,
        ...(!this.context.excludedSuggestionTypes.includes('ehr_order')
          ? [
              ...this.context.headings.filter((heading) => heading.type === 'ehr_order'),
              ...this.context.suggestions.filter((suggestion) => suggestion.type === 'ehr_order'),
            ]
          : []),
      ]),
    });
  };

  onExtractData = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    //checking how many conditional texts are inside current text area
    if (Object.keys(raw.entityMap).length > 0 && !this.props.disableConditionalText) {
      const models = new Map(Object.entries(raw.entityMap));
      const modelArray = Array.from(models.values());
      if (this.props.maintainVariables) {
        const variablesArray = modelArray
          .filter(
            (v) =>
              v.type === '#mention' &&
              !v.data.mention.code?.includes('conditionaltext') &&
              !v.data.mention.code?.includes('phoneNumber') &&
              !v.data.mention.type?.includes('module') &&
              !v.data.mention.type?.includes('link') &&
              !v.data.mention.type?.includes('unembedded')
          )
          .map((v) => v.data.mention);
        this.props.setVariables(variablesArray);
      }
      const conditionalTextArray = modelArray
        .filter((v) => v.type === '#mention' && v.data.mention.code.includes('conditionaltext'))
        .map((v) => v.data.mention);
      this.setState(
        {
          currentConditionalTexts: conditionalTextArray,
        },
        () => {
          this.setState({
            modifiedTextLimit: this.props.MAX_TEXT_LIMIT + this.getConditionalTextLength(),
          });
          if (this.props.MAX_TEXT_LIMIT && !this.props.disableConditionalText) {
            this.props.getCharactersLength(
              this.props.MAX_TEXT_LIMIT + this.getConditionalTextLength()
            );
          }
        }
      );
    } else {
      this.setState({ currentConditionalTexts: [] });
      if (this.props.MAX_TEXT_LIMIT && !this.props.disableConditionalText) {
        this.props.getCharactersLength(this.props.MAX_TEXT_LIMIT);
      }
    }

    return raw;
  };

  handleKeyCommand = (command) => {
    // inline formatting key commands handles bold, italic, highlighthing etc
    let editorState = RichUtils.handleKeyCommand(this.state.editorState, command);

    if (!editorState && command === 'highlight') {
      editorState = RichUtils.toggleInlineStyle(this.state.editorState, 'HIGHLIGHT');
    }

    if (editorState) {
      this.setState({ editorState });
      return 'handled';
    }

    return 'not-handled';
  };

  keyBindingFunction = (event) => {
    if (KeyBindingUtil.hasCommandModifier(event) && event.ctrlKey && event.key === 'h') {
      return 'highlight';
    }

    // Disable bold, italic, underline feature in intro card text
    if (
      this.props.isIntroductionCard &&
      KeyBindingUtil.hasCommandModifier(event) &&
      event.ctrlKey &&
      ['U', 'u', 'B', 'b'].includes(event.key)
    ) {
      return 'dont-handle';
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      return undefined;
    }

    return getDefaultKeyBinding(event);
  };

  highlightText = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({
        editorState: RichUtils.toggleInlineStyle(prevState.editorState, 'HIGHLIGHT'),
      }),
      () => this.props.setValue(this.onExtractData())
    );
  };

  applyHeader = (e) => {
    e.preventDefault();
    setTimeout(() => {
      this.editorRef.focus();
      this.setState(
        (prevState) => ({
          editorState: RichUtils.toggleInlineStyle(prevState.editorState, 'HEADER'),
        }),
        () => this.props.setValue(this.onExtractData())
      );
    }, 10);
  };

  addMention = (mentionObj) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const stateWithRemovedText = Modifier.removeRange(
      contentState,
      editorState.getSelection(),
      null
    );

    let newEditorState = EditorState.push(editorState, stateWithRemovedText);
    const stateWithEntity = stateWithRemovedText.createEntity('#mention', 'IMMUTABLE', {
      mention: mentionObj,
    });
    const entityKey = stateWithEntity.getLastCreatedEntityKey();
    const mentionName = [NOTE_TYPE, LAB_DATA_TYPE, VITAL_SIGN_TYPE].includes(mentionObj.type)
      ? [NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
          mentionObj.code
        )
        ? `Last ${mentionObj.days} days ${mentionObj.type.replace('_', ' ')}`
        : `${mentionObj.name} ${mentionObj.type.replace('_', ' ')}`
      : mentionObj.type === MEDICATION_TYPE
      ? `Maximum ${mentionObj.amount} medications`
      : mentionObj.name;

    const stateWithNewText = Modifier.insertText(
      stateWithEntity,
      newEditorState.getSelection(),
      mentionName,
      null,
      entityKey
    );

    newEditorState = EditorState.push(newEditorState, stateWithNewText);
    const selection = editorState.getSelection();
    newEditorState = EditorState.forceSelection(newEditorState, selection);

    this.setState({ editorState: EditorState.moveFocusToEnd(newEditorState) }, () => {
      setTimeout(() => {
        this.editorRef.focus();
      }, 200);

      this.props.setValue(this.onExtractData());
    });
  };

  addNewBlock = () => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selection.getEndKey());
    const blockMap = contentState.getBlockMap();

    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(function (v) {
      return v === currentBlock;
    });

    const blocksAfter = blockMap
      .toSeq()
      .skipUntil(function (v) {
        return v === currentBlock;
      })
      .rest();

    const newBlockKey = genKey();
    let newBlocks = [
      [currentBlock.getKey(), currentBlock],
      [
        newBlockKey,
        new ContentBlock({
          key: newBlockKey,
          type: 'unordered-list-item',
          text: '',
        }),
      ],
    ];
    const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();

    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection,
    });
    //after

    let newBlockEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
    return EditorState.moveFocusToEnd(newBlockEditorState);
  };

  addConditionalText = (conditionalText) => {
    const { editorState } = this.state;
    let newEditorState;

    if (this.state.isBulletEnabled === true) {
      newEditorState = this.addNewBlock(conditionalText);
      const stateWithEntity = newEditorState
        .getCurrentContent()
        .createEntity('#mention', 'IMMUTABLE', {
          mention: {
            code: conditionalText.unique_code,
            name: conditionalText.name,
          },
        });

      const entityKey = stateWithEntity.getLastCreatedEntityKey();

      // add mention in editor state
      const stateWithText = Modifier.insertText(
        stateWithEntity,
        newEditorState.getSelection(),
        conditionalText.name,
        null,
        entityKey
      );
      newEditorState = EditorState.push(editorState, stateWithText);
    } else {
      const stateWithEntity = editorState
        .getCurrentContent()
        .createEntity('#mention', 'IMMUTABLE', {
          mention: {
            code: conditionalText.unique_code,
            name: conditionalText.name,
          },
        });

      const entityKey = stateWithEntity.getLastCreatedEntityKey();

      // add mention in editor state
      const stateWithText = Modifier.insertText(
        stateWithEntity,
        editorState.getSelection(),
        conditionalText.name,
        null,
        entityKey
      );

      newEditorState = EditorState.push(editorState, stateWithText);
    }
    this.setState(
      {
        editorState: EditorState.moveFocusToEnd(newEditorState),
      },
      () => {
        setTimeout(() => {
          this.editorRef.focus();
        }, 200);
        this.props.setValue(this.onExtractData());
      }
    );
  };

  deleteMention = () => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();

    const block = _.get(this.state.mentionProps, ['children', 0, 'props', 'block']);
    const blockKey = block.getKey();
    const getBlock = getRangesForDraftEntity(block, this.state.mentionProps?.entityKey);

    const entitySelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: getBlock.start,
      focusKey: blockKey,
      focusOffset: getBlock.end,
      hasFocus: true,
      isBackward: false,
    });

    const setEntityPositionFromClickedButton = entitySelection.merge({
      anchorOffset: _.get(_.first(getBlock), 'start'),
      focusOffset: _.get(_.first(getBlock), 'end'),
    });

    const stateWithRemovedText = Modifier.removeRange(
      contentState,
      setEntityPositionFromClickedButton,
      'backward'
    );
    let newEditorState = EditorState.push(editorState, stateWithRemovedText, 'remove-range');
    return { newEditorState, stateWithRemovedText };
  };

  updateMention = (obj) => {
    const { editorState } = this.state;
    let { newEditorState, stateWithRemovedText } = this.deleteMention();

    const newContentState = stateWithRemovedText.mergeEntityData(
      this.state.mentionProps?.entityKey,
      {
        mention: obj,
      }
    );

    // add updated/new mention code in block text
    const stateWithNewText = Modifier.insertText(
      newContentState,
      newEditorState.getSelection(),
      obj.name,
      null,
      this.state.mentionProps?.entityKey
    );

    newEditorState = EditorState.push(newEditorState, stateWithNewText);
    const selection = editorState.getSelection();
    newEditorState = EditorState.forceSelection(newEditorState, selection);

    this.setState(
      {
        editorState: newEditorState,
        updateinfoDropdownEl: null,
        updateVariableEl: null,
        updateConditionalTextEl: null,
        isConditionalText: false,
        updateInfobox: false,
        updateMedia: false,
        isChoiceVariable: false,
        isTextVariable: false,
        isMultiVariable: false,
        isNumeric: false,
        isCustomNumeric: false,
        isFormula: false,
        mentionProps: null,
        searchInput: '',
        selectedItem: '',
        text: '',
      },
      () => {
        this.props.setValue(this.onExtractData());
      }
    );
  };

  // to support nested bullets
  onTab = (e) => {
    //prevent cursor from selecting the next interactive element
    e.preventDefault();

    // assign a constant for the new editorState
    const newState = RichUtils.onTab(e, this.state.editorState, 4);

    // if a new editor state exists, set editor state to new state
    // and return 'handled', otherwise return 'not-handled
    if (newState) {
      this.setState({ editorState: newState });
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  toggleCreateModal = () => {
    if (this.state.updateInfobox || this.state.updateMedia) {
      this.setState((previous) => ({
        createInfoboxModal: !previous.createInfoboxModal,
        updateinfoDropdownEl: null,
      }));
    } else if (this.state.isConditionalText) {
      this.setState((previous) => ({
        createCTModal: !previous.createCTModal,
        updateConditionalTextEl: null,
      }));
    }
  };

  toggleInfoEditModal = (id) => {
    this.setState((previous) => ({
      editInfoboxModal: !previous.editInfoboxModal,
      selectedItem: id,
      updateinfoDropdownEl: null,
    }));
  };

  toggleEditMediaModal = (id) => {
    this.setState((previous) => ({
      editMediaModal: !previous.editMediaModal,
      selectedItem: id,
      updateinfoDropdownEl: null,
    }));
  };

  toggleEditModal = (id) => {
    if (this.state.isConditionalText) {
      this.setState((previous) => ({
        editCTModal: !previous.editCTModal,
        selectedItem: id,
        updateConditionalTextEl: null,
      }));
    }
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { Toolbar } = this.toolbarPlugin;
    const { AlignmentTool } = this.alignmentPlugin;
    const plugins = [
      this.mentionPlugin,
      this.toolbarPlugin,
      this.linkPlugin,
      this.imagePlugin,
      this.alignmentPlugin,
      this.focusPlugin,
      this.resizeablePlugin,
    ];
    const infoboxes = this.props.infoboxState.infoBoxes || [];
    const media = this.props.mediaState.images || [];
    const formulas = this.props.formulaState.formulas || [];
    const customNumerics = this.props.customNumericState.customNumerics || [];
    const conditionalTexts = this.props.conditionalTextState?.conditionalTexts || [];
    const numerics = this.props.numerics || [];
    const variables = this.props.variables || [];
    const choiceVariables = variables.filter((variable) => variable.type === 'choice_variable');
    const multiVariables = variables.filter((variable) => variable.type === 'multi_variable');
    const textVariables = variables.filter(
      (variable) =>
        (variable.type === 'text_input_variable' ||
          variable.type === 'gptbox_variable' ||
          variable.type === 'apibox_variable') &&
        variable.option_type === 'text'
    );
    const knowledgeBases = this.props.knowledgeBases || [];

    let editorStyle = {
      background: this.props.isReadOnly ? '#F8F8F8' : '#FFFFFF',
      opacity: this.props.isReadOnly && 0.5,
      color: this.props.isReadOnly ? '#C5C5C5' : '#515151',
      padding: this.props.isFormula && '16px 16px 10px',
      paddingTop: !this.props.isFormula && !this.props.isTextInput ? '25px' : '16px',
      whiteSpace: 'nowrap',
      maxWidth: this.props.fullWidth && '100%',
      minWidth: this.props.fullWidth && '100%',
      minHeight: this.props.increaseHeight && '700px',
    };

    let sectionStyle = {
      minHeight: this.props.increaseHeight
        ? 'inherit'
        : !this.props.isFormula && !this.props.isTextInput && '154px',
      maxHeight: '340px',
      paddingLeft: !this.props.isFormula && '30px',
      paddingRight: '16px',
      overflow: 'auto',
    };

    return (
      <>
        <div className='editor' style={editorStyle}>
          <section style={sectionStyle}>
            <Editor
              ref={(ref) => (this.editorRef = ref)}
              editorState={this.state.editorState}
              onChange={this.onChange}
              plugins={plugins}
              readOnly={this.props.isReadOnly}
              handleBeforeInput={this._handleBeforeInput}
              handlePastedText={this._handlePastedText}
              customStyleMap={styleMap}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.keyBindingFunction}
              placeholder={this.props.placeholder}
              onTab={this.onTab}
              spellCheck={true}
              onCopy={onCopy}
              onCut={onCut}
              onPaste={onPaste}
            />
            <AlignmentTool />
            {!this.props.disableMentionPlugin && (
              <MentionSuggestions
                open={this.state.openMentionPopup}
                onOpenChange={this.onMentionOpenChange}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                entryComponent={Entry}
              />
            )}
          </section>

          {!this.props.isFormula && (
            <Toolbar>
              {
                // may be use React.Fragment instead of div to improve perfomance after React 16
                (externalProps) => (
                  <ToolbarButtons
                    hasEHRVariables={this.props.hasEHRVariables ?? true}
                    fromAiKnowledgeBase={this.props.fromAiKnowledgeBase}
                    isTextInput={this.props.isTextInput}
                    gptBox={this.props.gptBox}
                    addMention={this.addMention}
                    addConditionalText={this.addConditionalText}
                    applyHeader={this.applyHeader}
                    conditionalText={this.props.conditionalText}
                    draftJsToolbarProps={externalProps}
                    editorState={this.state.editorState}
                    highlightText={this.highlightText}
                    isIntroductionCard={this.props.isIntroductionCard}
                    isReadOnly={this.props.isReadOnly}
                    infoboxes={infoboxes}
                    media={media}
                    conditionalTexts={conditionalTexts}
                    numerics={this.props.numerics}
                    type={this.props.type}
                    variables={variables}
                    knowledgeBases={knowledgeBases}
                    onExtractData={this.onExtractData}
                    pasteData={this.pasteData}
                    imageModifier={this.imagePlugin.addImage}
                    onChange={this.onChange}
                    richTextButtonShowList={this.props.richTextButtonShowList}
                  />
                )
              }
            </Toolbar>
          )}
        </div>

        <MenuDropdown
          dropDownEl={this.state.updateinfoDropdownEl}
          selEl={() => this.setState({ updateinfoDropdownEl: null })}
          searchInput={this.state.searchInput}
          setSearchInput={(searchInput) => this.setState({ searchInput })}
          selectedItem={this.state.selectedItem}
          setSelectedItem={(selectedItem) => this.setState({ selectedItem })}
          text={this.state.text}
          setText={(text) => this.setState({ text })}
          items={[...infoboxes, ...media]}
          addItem={this.updateMention}
          isEditInfoMedia={true}
          toggleCreateModal={this.toggleCreateModal}
          toggleEditInfoboxModal={this.toggleInfoEditModal}
          toggleEditMediaModal={this.toggleEditMediaModal}
        />

        <ConditionalTextMenu
          dropDownEl={this.state.updateConditionalTextEl}
          closeMenu={() => this.setState({ updateConditionalTextEl: null })}
          selectedItem={this.state.selectedItem}
          setSelectedItem={(selectedItem) => this.setState({ selectedItem })}
          addItem={this.updateMention}
          toggleCreateModal={this.toggleCreateModal}
          toggleEditModal={this.toggleEditModal}
        />

        <Menu
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
          anchorEl={this.state.updateVariableEl}
          open={Boolean(this.state.updateVariableEl)}
          onClose={() => this.setState({ updateVariableEl: null })}
        >
          <SearchableDropdown
            text={this.state.text}
            addItem={this.updateMention}
            selectedItem={this.state?.mentionProps?.mention}
            isVariable={
              this.state.isChoiceVariable || this.state.isMultiVariable || this.state.isTextVariable
            }
            items={
              this.state.isChoiceVariable
                ? choiceVariables
                : this.state.isMultiVariable
                ? multiVariables
                : this.state.isTextVariable
                ? textVariables
                : this.state.isCustomNumeric
                ? customNumerics
                : this.state.isNumeric
                ? numerics
                : this.state.isFormula
                ? formulas
                : []
            }
          />
        </Menu>

        {/* Sliding Pane for Create Infobox */}
        <SlidingPane
          isOpen={this.state.createInfoboxModal}
          onRequestClose={() => this.toggleCreateModal('createInfoboxModal')}
          overlayClassName={
            this.props.conditionalText
              ? 'conditional-text-overlay-for-zindex'
              : 'infobox-overlay-for-zindex'
          }
          from='right'
          className='no-padding add-info-box sliding-panel-shadow'
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              isFullHeight={true}
              createModal={true}
              conditionalText={this.props.conditionalText}
              type={this.props.type}
              toggleModal={() => this.toggleModal('createInfoboxModal')}
              // no need to call save position as we didn't modify the position of
              // infobox in flow section while editing panel. savePosition returns a
              // array of promises. That's why returning empty array here.
              savePosition={() => []}
              populateSuggestions={this.populateSuggestions}
            />
          </div>
        </SlidingPane>

        {/* Sliding Pane for Edit Infobox */}
        <SlidingPane
          isOpen={this.state.editInfoboxModal}
          onRequestClose={this.toggleInfoEditModal}
          from='right'
          className='no-padding sliding-panel-shadow'
          overlayClassName={
            this.props.conditionalText
              ? 'conditional-text-overlay-for-zindex'
              : INNER_INFOBOX_Z_INDEX
          }
          width='1210px'
          hideHeader
        >
          <div className='mt-14'>
            <InfoBoxForm
              conditionalText={this.props.conditionalText}
              type={this.props.type}
              toggleModal={this.toggleInfoEditModal}
              infoBoxId={this.state.selectedItem}
              savePosition={() => []}
              isFullHeight={true}
            />
          </div>
        </SlidingPane>

        {/* Sliding Pane for Edit Media */}
        <SlidingPane
          isOpen={this.state.editMediaModal}
          onRequestClose={this.toggleEditMediaModal}
          from='right'
          hideHeader
          className='no-padding sliding-panel-shadow'
          width='622px'
        >
          <div className='mt-14'>
            <ImageForm
              toggleModal={this.toggleEditMediaModal}
              type={this.props.type}
              imageId={this.state.selectedItem}
              savePosition={() => []}
              isFullHeight={true}
            />
          </div>
        </SlidingPane>

        {/* Sliding pane for Creating new conditional text */}
        <SlidingPane
          isOpen={this.state.createCTModal}
          onRequestClose={() => this.toggleCreateModal('createCTModal')}
          from='right'
          className='no-padding sliding-panel-shadow conditional-text-popup-infobox'
          overlayClassName='sliding-panel-cards conditional-text-popup-overlay conditional-text-overlay-for-zindex'
          width='627px'
          hideHeader
        >
          <div className='mt-14'>
            <ConditionalTextForm
              toggleModal={() => this.toggleCreateModal('createCTModal')}
              savePosition={() => []}
            />
          </div>
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.editCTModal}
          onRequestClose={this.toggleEditModal}
          from='right'
          className='no-padding sliding-panel-shadow conditional-text-popup-infobox'
          overlayClassName='sliding-panel-cards conditional-text-popup-overlay conditional-text-overlay-for-zindex'
          width='627px'
          hideHeader
        >
          <div className='mt-14'>
            <ConditionalTextForm
              conditionalTextId={this.state.selectedItem}
              toggleModal={() => this.toggleEditModal(this.state.selectedItem)}
              savePosition={() => []}
            />
          </div>
        </SlidingPane>
      </>
    );
  }
}

// TODO: try to convert this file to TypeScript
AutoSuggest.propTypes = {
  suggestions: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  prevValue: PropTypes.any.isRequired,
  variables: PropTypes.array,
  numerics: PropTypes.array,
  isReadOnly: PropTypes.bool,
  getCharactersLength: PropTypes.func,
  MAX_TEXT_LIMIT: PropTypes.number,
  MAX_NO_OF_LINES: PropTypes.number,
  placeholder: PropTypes.string,
  showWarning: PropTypes.func,
  gptBox: PropTypes.bool,
  calculateContentTextLength: PropTypes.func,
  maintainVariables: PropTypes.bool,
  isTextInput: PropTypes.bool,
  conditionalText: PropTypes.bool,
  knowledgeBases: PropTypes.array,
  type: PropTypes.string,
  hasEHRVariables: PropTypes.bool,
  isFormula: PropTypes.bool,
  isNested: PropTypes.bool,
  disableMentionPlugin: PropTypes.bool,
  setVariables: PropTypes.func,
  disableConditionalText: PropTypes.bool,
  richTextButtonShowList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  mediaState: state.mediaState,
  infoboxState: state.infoboxState,
  draftJsonState: state.draftJsonState,
  conditionalTextState: state.conditionalTextState,
  formulaState: state.formulaState,
  customNumericState: state.customNumericState,
});

export default withEHRVariables(
  connect(mapStateToProps, {})(AutoSuggest),
  [...SUGGESTION],
  [...LAB_DATA_SUGGESTION],
  [...VITAL_SIGN_SUGGESTION],
  [...MEDICATION_SUGGESTION],
  [...PROBLEM_LIST_SUGGESTION]
);
