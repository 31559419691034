import { useMutation } from '@tanstack/react-query';
import { KnowledgeBasePDF, KnowledgeBasePDFPost, knowledgebaseAPI } from 'api/knowledgebase';
import Input from 'components/utils/Input';
import { Body1, H3 } from 'components/utils/typo';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';

interface UploadPDFProps {
  onUploading?: () => void;
  onUploaded?: () => void;
}

export const UploadPDF = ({ onUploading, onUploaded }: UploadPDFProps) => {
  const { moduleId } = useParams<{ moduleId: string }>();

  const { mutateAsync: getPresignedPost } = useMutation(
    (data: KnowledgeBasePDF) => knowledgebaseAPI.getPdfPresignedPost(parseInt(moduleId), data),
    { onError: onUploaded }
  );
  const { mutateAsync: uploadPDF } = useMutation(knowledgebaseAPI.uploadPdfPresignedPost, {
    onError: onUploaded,
  });
  const { mutateAsync: queueJob } = useMutation(
    (data: KnowledgeBasePDFPost) => knowledgebaseAPI.queuePdfParsingJob(parseInt(moduleId), data),
    { onError: onUploaded }
  );

  const { getInputProps, getRootProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': [],
    },
    onDrop: async (acceptedFiles) => {
      const pdf = acceptedFiles[0];
      if (!pdf) return;
      onUploading?.();
      // get presigned post
      const presignedPost = await getPresignedPost({ file_name: pdf.name });

      // upload pdf
      const formData = new FormData();
      Object.keys(presignedPost.fields).forEach((key) => {
        formData.append(key, presignedPost.fields[key]);
      });
      formData.append('file', pdf);
      await uploadPDF({ url: presignedPost.url, formData });

      // pdfParsing job start
      await queueJob({ file_name: pdf.name, file_size: pdf.size });
      onUploaded?.();
    },
  });

  return (
    <div
      {...getRootProps()}
      className='flex flex-grow cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-100'
    >
      <H3 className='text-center text-gray-700'>Or, import text from PDF</H3>
      <Body1 className='text-center text-gray-700'>
        Drag and Drop a PDF here or
        <span className='text-primary-600'> Upload a file</span>
      </Body1>
      <Input
        type='file'
        name='pdf'
        className='hidden'
        accept='application/pdf'
        {...getInputProps()}
      />
    </div>
  );
};
