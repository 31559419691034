import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ComboBox } from 'components/utils/ComboBox';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import {
  NumberFormattingOptions,
  SuggestionTypeEnum,
  TiptapVariableTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { SuggestionHandlerProps } from '../MentionPopover';
import { MentionContext } from '../../../module/MentionContext';

interface NumberFormattingOptionsProps {
  title: string;
  options: NumberFormattingOptions;
  onClick: (key: string) => void;
  selected: string | number;
}

export const VariableHandler = ({ onDisabledChange, onChange }: SuggestionHandlerProps) => {
  const { suggestions } = useContext(MentionContext);
  const filteredSuggestions = useMemo(() => {
    const suggestionType = SuggestionTypeEnum.VARIABLE;

    return suggestions.filter(
      (suggestion) =>
        suggestion.type.includes(suggestionType) ||
        suggestion.type === SuggestionTypeEnum.DATA_EXTRACTOR
    );
  }, [suggestions]);

  const [variable, setVariable] = useState<string>();
  const [variableType, setVariableType] = useState<string>('');
  const [decimal, setDecimal] = useState<string>('0');
  const [rounding, setRounding] = useState<string>('ceil');
  const isNumber = useMemo(() => ['number', 'formula'].includes(variableType), [variableType]);

  const variableOptions = useMemo(() => {
    const varOptions = filteredSuggestions.filter((option) => option.type === variableType);
    return varOptions.map((option) => ({
      value: (option.code || option.unique_code) as string,
      label: option.name,
    }));
  }, [variableType]);

  const filteredVariableTypes = useMemo(() => {
    return VARIABLE_TYPES.filter((type) =>
      filteredSuggestions.some((option) => option.type === type.id)
    );
  }, [filteredSuggestions]);

  const changeVariableType = (value: string) => {
    setVariable('');
    setVariableType(value);
  };

  useEffect(() => {
    const option = filteredSuggestions.find(
      (option) => (option.code || option.unique_code) === variable
    );
    if (!option || !variable) {
      onDisabledChange(true);
      return;
    }

    onChange({
      ...option,
      decimalPlaces: isNumber ? decimal : undefined,
      decimalRounding: isNumber ? rounding : undefined,
    });
    onDisabledChange(false);
  }, [filteredSuggestions, variable, isNumber, decimal, rounding]);

  return (
    <>
      <DropdownSingle
        value={variableType}
        options={filteredVariableTypes}
        placeholder='Select Variable Type'
        onChange={(value) => changeVariableType(value as string)}
      />
      <ComboBox
        selectedValue={variable}
        options={variableOptions}
        disabled={!variableType}
        onChange={(value) => setVariable(value as string)}
      />
      {isNumber && (
        <div className='flex gap-5'>
          <FormattingOptions
            title='Show'
            selected={decimal}
            onClick={setDecimal}
            options={DecimalOptions}
          />
          <FormattingOptions
            title='Rounded'
            selected={rounding}
            onClick={setRounding}
            options={RoundingOptions}
          />
        </div>
      )}
    </>
  );
};

const FormattingOptions = ({ title, options, onClick, selected }: NumberFormattingOptionsProps) => {
  return (
    <div className='flex'>
      <p className='!mr-2 text-body-2 text-gray-700'>{title}</p>
      <Menu as='div'>
        <Menu.Button className='flex text-button-1 text-primary-600'>
          {options?.[selected]}
          <ChevronDownIcon className='h-5 w-5' />
        </Menu.Button>
        <Menu.Items className='absolute flex w-24 flex-col items-start gap-1 rounded bg-white !py-2 shadow-06'>
          {Object.entries(options as NumberFormattingOptions).map(([key, value]) => (
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => onClick(key)}
                  className={twMerge('w-full cursor-pointer !px-3', active && 'bg-gray-100')}
                >
                  {value}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

const RoundingOptions: NumberFormattingOptions = {
  ceil: 'Up',
  floor: 'Down',
  round: 'Rounded',
};

const DecimalOptions: NumberFormattingOptions = {
  0: 'Integer',
  1: '1 Decimal',
  2: '2 Decimal',
};

const VARIABLE_TYPES: TiptapVariableTypes[] = [
  {
    id: 'choice_variable',
    title: 'Multiple Choice',
  },
  {
    id: 'multi_variable',
    title: 'Checkboxes',
  },
  {
    id: 'gptbox_variable',
    title: 'GPT Box',
  },
  {
    id: 'text_input_variable',
    title: 'Text',
  },
  {
    id: 'message_variable',
    title: 'Message Variable',
  },
  {
    id: 'formula',
    title: 'Formula',
  },
  {
    id: 'number',
    title: 'Number',
  },
  {
    id: 'ambient_variable',
    title: 'Ambient Listening',
  },
  {
    id: SuggestionTypeEnum.DATA_EXTRACTOR,
    title: 'Data Extractor',
  },
  {
    id: 'apibox_variable',
    title: 'API Box',
  },
];
