import { useQuery } from '@tanstack/react-query';
import { resourcesAPI } from 'api/resources';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { CustomToast } from 'components/utils/toast-message';
import { useContext, useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { getSuggestions } from 'utils/suggestions';
import { APIResourceForm } from './APIResourceForm';
import { APIResource } from './types';

// TODO: separate ui with business logic
export const APIResourceContainer = () => {
  // const { moduleId } = useParams<{ moduleId: string }>();
  const { module } = useContext(ModuleContext);

  // TODO: Dahun's comment(https://github.com/avoMD/builder-frontend/pull/870#discussion_r1643951913)
  const { data: apiResources, refetch: setApiResources } = useQuery(
    [module?.type, module?.id, 'api-resources'],
    resourcesAPI.getApiResources,
    {
      enabled: !!module,
      initialData: [],
    }
  );
  const [selectedResource, setSelectedResource] = useState(null);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>();
  const [showFullData, setShowFullData] = useState<boolean[]>([]);

  useEffect(() => {
    if (!module) return;
    getSuggestions(module.id, true, module.type).then((res) => {
      setSuggestions(res);
    });
  }, [module]);

  const handleEditClick = (resource) => {
    setSelectedResource(resource);
    setIsPaneOpen(true);
  };

  const handleDeleteClick = async (resource: APIResource) => {
    if (!window.confirm('Are you sure you want to delete this API resource?')) return;

    const result = await resourcesAPI.deleteApiResource(String(resource.id));
    if (result.status >= 200 && result.status < 300) {
      alert('Deleted!');
      setApiResources();
    } else {
      console.error(result);
      alert('Failed to delete');
    }
  };

  const handleCreateClick = () => {
    setSelectedResource(null);
    setIsPaneOpen(true);
  };

  const handleFormClose = () => {
    setIsPaneOpen(false);
  };

  const handleSeeMoreClick = (index: number) => {
    setShowFullData((prevShowFullData) => {
      const newShowFullData = [...prevShowFullData];
      newShowFullData[index] = true;
      return newShowFullData;
    });
  };

  const handleHideClick = (index: number) => {
    setShowFullData((prevShowFullData) => {
      const newShowFullData = [...prevShowFullData];
      newShowFullData[index] = false;
      return newShowFullData;
    });
  };

  const handleSaveResource = async (updatedResource) => {
    try {
      if (!updatedResource.title) {
        toast.warning(CustomToast, { data: 'Please fill out title' });
        return;
      }

      const msg = updatedResource.id ? 'Updated' : 'Created';

      const response = updatedResource.id
        ? resourcesAPI.putApiResource(updatedResource)
        : resourcesAPI.postApiResource(updatedResource);

      const result = await response;
      if (result) {
        alert(msg);
        handleFormClose();
        setApiResources();
      }
    } catch (error) {
      console.error('Error saving resource:', error);
      toast.error(CustomToast, { data: 'Error saving API resource' });
    }
  };

  // TODO: use ResourceTable component
  return (
    <div>
      <div className='flex items-center justify-between pt-[80px]'>
        <h1 className='text-2xl font-bold'>API Resources</h1>
        <button
          className='mr-2 rounded bg-green-500 px-4 py-2 text-white'
          onClick={handleCreateClick}
        >
          Create
        </button>
      </div>

      <ul className='grid grid-cols-2 gap-4'>
        {apiResources.map((resource, index) => (
          <li key={resource.id} className='rounded border p-4'>
            <div>
              <h3 className='mb-2 text-center font-bold'>{resource.title}</h3>
            </div>
            <div>
              <span className='font-bold'>URL:</span>
              <p className='truncate'>{resource.url}</p>
            </div>
            <div>
              <span className='font-bold'>method:</span> {resource.method}
            </div>
            <div className='w-full'>
              <span className='font-bold'>Authorization:</span>{' '}
              <AvoEditorPreview contentTiptap={resource.authorization} contentDraftJS={''} />
            </div>
            <div>
              <span className='font-bold'>Content Type:</span> {resource.content_type}
            </div>
            <div>
              <span className='font-bold'>Body:</span>
              <AvoEditorPreview contentTiptap={resource.body} contentDraftJS={''} />
            </div>
            <div>
              <span className='font-bold'>Output Type:</span> {resource.output_type}
            </div>
            <div>
              <span className='font-bold'>Output Type Definition:</span>
              {resource.output_type_definition.length > 200 ? (
                <div>
                  <pre className='whitespace-pre-wrap'>
                    {showFullData[index]
                      ? resource.output_type_definition
                      : resource.output_type_definition.substring(0, 200)}
                  </pre>
                  {showFullData[index] ? (
                    <button
                      onClick={() => handleHideClick(index)}
                      className='mt-2 rounded !bg-red-500 px-2 py-1 text-white'
                    >
                      Hide
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSeeMoreClick(index)}
                      className='mt-2 rounded bg-green-500 px-2 py-1 text-white'
                    >
                      See More
                    </button>
                  )}
                </div>
              ) : (
                <pre className='whitespace-pre-wrap'>{resource.output_type_definition}</pre>
              )}
            </div>
            <div>
              <span className='font-bold'>Output You Want to Use:</span>{' '}
              {resource.output_you_want_to_use}
            </div>
            <button
              className='mt-2 rounded bg-green-500 px-2 py-1 text-white'
              onClick={() => handleEditClick(resource)}
            >
              Edit
            </button>
            <button
              className='mt-2 rounded bg-green-500 px-2 py-1 text-white'
              onClick={() => handleDeleteClick(resource)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>

      <SlidingPane
        isOpen={isPaneOpen}
        title={selectedResource ? 'Edit API Resource' : 'Create API Resource'}
        onRequestClose={handleFormClose}
        width='620px'
        className='no-padding add-info-box sliding-panel-shadow mt-5'
      >
        <APIResourceForm
          moduleId={module?.id}
          moduleType={module?.type}
          suggestions={suggestions}
          resource={selectedResource}
          onSave={handleSaveResource}
          onCancel={handleFormClose}
        />
      </SlidingPane>
    </div>
  );
};
