import { Box, Button, Container, Divider, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Theme, createTheme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from 'assets/icons/searchIcon';
import axios from 'axios';
import Footer from 'components/layout/Footer';
import { EntireScreenLoading } from 'components/loader/EntireScreenLoading';
import { StyledBadge } from 'components/module-detail/AvoAssistantForm';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import {
  CancelButton,
  CreateButton,
  StyledInput,
} from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { H2 } from 'components/utils/typo';
import 'css/custom.css';
import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from 'react-sortable-tree';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ROLES, TeamWithMates } from '../../api/team';
import { SEARCH_TAG_API_URL, SYNC_TAG_TREES_API_URL, TEAM_API_URL } from '../../constants';
import { withTeam } from '../../hooks/useTeam';
import { Icons } from '../utils/Icons';
import './overrides.css';
import NodeRendererDefault from './treeNode';
const theme = createTheme({
  palette: {
    primary: {
      main: '#08A88E',
    },
    secondary: {
      main: '#08A88E',
    },
  },
});

export const StyledContainer = withStyles((theme) => ({
  root: {
    paddingTop: '10px',
    borderRadius: '0px',
    width: '100%',

    marginTop: '17px',
    color: 'black',
  },
}))(Container);
export const NotificationBox = withStyles((theme) => ({
  root: {
    width: '734px',
    minHeight: '48px',
    border: '1px solid #8FAEEB',
    background: '#EDF5FF',
    borderLeft: '3px solid #0043CE',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center ',
    paddingLeft: 17,
    paddingRight: 21,
  },
}))(Box);

interface IOSSwitchProps {
  classes: {
    root: string;
    switchBase: string;
    thumb: string;
    track: string;
    checked: string;
    focusVisible: string;
  };
  // Add any other props that the Switch component accepts
  [key: string]: any;
}

const IOSSwitch = withStyles((theme: Theme) => ({
  root: {
    marginLeft: 16,
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#23C29D',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#23C29D',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#C5D1D8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: IOSSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const BehaviourButton = withStyles((theme) => ({
  root: {
    background: '#08A88E',
    borderRadius: '6px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    //maxWidth: '123px',
    minWidth: '111px',
    height: '40px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'white',
    marginRight: '10px',
    // marginTop: '20px',
    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
}))(Button);

interface tagsListingState {
  tagTrees: any;
  value: number;
  loading: boolean;
  currentNode: any;
  tagId: string;
  treeData: [
    {
      expanded?: boolean;
      code?: any;
      title: string;
      children: any[];
    },
  ];
  showNotification: boolean;
  rowInfo: any;
  currentIndex: any;
  currentID: any;
  dummmy: any;
  createTreePanel: boolean;
  createNodePanel: boolean;
  modal: boolean;
  activeState: string;
  isLoading: boolean;
  editMode: boolean;
  deleteNodeModal: boolean;
  title: string;
  searchFocusIndex: number;
  searchString: string;
}

interface tagsListingProps {
  team: TeamWithMates;
  defaultTag?: boolean;
}

class TagsTreeListing extends Component<tagsListingProps, tagsListingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tagTrees: [],
      value: 0,
      loading: false,
      currentNode: {},
      tagId: '',
      treeData: [
        {
          expanded: false,
          code: '',
          title: '',
          children: [],
        },
      ],
      showNotification: false,
      rowInfo: null,
      currentIndex: null,
      currentID: null,
      dummmy: [],
      createTreePanel: false,
      createNodePanel: false,
      modal: false,
      activeState: 'TeamManagement',
      isLoading: true,
      editMode: false,
      deleteNodeModal: false,
      title: '',
      searchFocusIndex: 0,
      searchString: '',
    };
  }

  componentDidMount() {
    this.resetState();
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copy = this.state.treeData;
    copy[0].title = e.target.value;
    this.setState({ treeData: copy });
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.team !== this.props.team) {
      this.resetState();
    }
  }
  closeTreePanel = () => {
    this.setState(
      (previous) =>
        ({
          createTreePanel: !previous.createTreePanel,
          title: '',
          treeData: [{ title: '', children: [] }],
        }) as Pick<tagsListingState, any>
    );
  };
  closeNodePanel = () => {
    this.setState((previous) => ({
      createNodePanel: !previous.createNodePanel,
      currentID: null,
      currentIndex: null,
      rowInfo: null,
      editMode: false,
      title: '',
    }));
  };
  // for open/close modal
  toggleModal = (id: number) => {
    this.setState(
      (previous) =>
        ({
          modal: !previous.modal,
          tagId: id,
        }) as Pick<tagsListingState, any>
    );
  };
  onNodeTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  resetState() {
    // send request to django endpoint to fetch list of all s of selected team
    axios.get(TEAM_API_URL + this.props.team.id + '/serach_tags').then((res) => {
      const adminTreeItems = this.props.defaultTag
        ? res.data.filter((item) => item.is_admin_tree)
        : res.data.filter((item) => !item.is_admin_tree);
      this.setState({
        tagTrees: adminTreeItems,
        isLoading: false,
        treeData: [{ title: '', children: [] }],
        title: '',
        value: this.props.defaultTag ? 1 : 0,
      });
    });
  }
  customSearchMethod = ({ node, searchQuery }) => {
    return searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  };
  handlePublishTeams = (treeIndex: number, id: string) => {
    let treeCopy = this.state.tagTrees;
    treeCopy[treeIndex].is_published = !treeCopy[treeIndex].is_published;
    this.setState({ tagTrees: treeCopy }, () => {
      this.updateTagTree(id, treeIndex);
    });
  };

  removeNode = (path, index, info) => {
    if (info.parentNode) {
      const copyData = this.state.tagTrees;
      copyData[index].tag_tree = removeNodeAtPath({
        treeData: copyData[index].tag_tree,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      this.setState({
        tagTrees: copyData,
      });
      this.updateTagTree(this.state.currentID, this.state.currentIndex);
    } else {
      axios.delete(`${SEARCH_TAG_API_URL}${this.state.currentID}/`).then(() => {
        this.resetState();
      });
    }
  };
  expandAndCollapse = (expanded) => {
    let newList = this.state.tagTrees;
    let toggledList = newList.map((tree) => {
      tree.tag_tree = toggleExpandedForAll({
        treeData: tree.tag_tree,
        expanded,
      });
      return tree;
    });
    this.setState({
      tagTrees: toggledList,
    });
  };
  toggleCreateTreePanel = () => {
    this.setState((previous) => ({
      createTreePanel: !previous.createTreePanel,
    }));
  };
  toggleCreateNodePanel = (rowInfo, index, id, editMode) => {
    this.setState((previous) => ({
      createNodePanel: !previous.createNodePanel,
      rowInfo: rowInfo,
      currentIndex: index,
      currentID: id,
      title: editMode ? rowInfo.node.title : '',
      editMode: editMode,
    }));
  };
  toggleDeleteNodeModal = () => {
    this.setState((previous) => ({
      deleteNodeModal: !previous.deleteNodeModal,
    }));
  };
  deleteSearchTag = () => {
    this.toggleModal(0);

    // delete channel object and reload list
    axios.delete(`${SEARCH_TAG_API_URL}${this.state.tagId}/`).then(() => {
      this.resetState();
    });
  };
  // customSearchMethod = ({ node, searchQuery }) => {
  //   return searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  // };
  selectThis = (node: any, path: any) => {
    this.setState({ currentNode: node, path: path } as Pick<tagsListingState, any>);
  };
  handleChange = (treeData: any, index: number, id: string) => {
    const copyData = this.state.tagTrees;
    copyData[index].tag_tree = treeData;
    this.setState({ tagTrees: copyData });
  };

  insertNewNode = () => {
    this.setState((prevState) => ({
      treeData: insertNode({
        treeData: prevState.treeData,
        depth: 0,
        minimumTreeIndex: prevState.treeData.length,
        newNode: { title: this.state.title, children: [] },
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData,
    }));
  };

  insertNodeUnderParent(rowInfo, index) {
    const copyTreeData = this.state.tagTrees;
    const isAdminTree = this.state.tagTrees[index].is_admin_tree;
    const isAdminTeam = this.props.team.team_type === 'A' ? true : false;

    const newTree = addNodeUnderParent({
      treeData: this.state.tagTrees[index].tag_tree,
      newNode: {
        title: this.state.title,
        children: [],
        is_custom_node: isAdminTree && !isAdminTeam ? true : false,
        code: uuidv4(),
        expanded: true,
      },
      expandParent: true,
      parentKey: rowInfo ? rowInfo.treeIndex : undefined,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    copyTreeData[index].tag_tree = newTree.treeData;
    this.setState({ tagTrees: copyTreeData, showNotification: !isAdminTeam ? true : false });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 6000);

    // if (this.state.currentIndex && this.state.currentID)
    this.updateTagTree(this.state.currentID, this.state.currentIndex);
  }
  updateTagTree = (id, index) => {
    let payload = {
      tag_tree: this.state.tagTrees[index].tag_tree,
      team: this.props.team.id,
      is_published: this.state.tagTrees[index].is_published,
    };
    //payload.tag_tree[0].checked = false;

    axios.put(`${SEARCH_TAG_API_URL}${id}/`, payload).then((res) => {
      this.resetState();
    });
  };
  handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState({ value: value });
  };
  pushNode = (getNodeKey: any) => {
    const treeData = this.state.treeData;
    treeData[0].title = this.state.title;
    this.setState({ treeData: treeData });
    const title = this.state.title;
    // const checked = false;
    const copyData = this.state.tagTrees;
    copyData[this.state.currentIndex].tag_tree = changeNodeAtPath({
      treeData: copyData[this.state.currentIndex].tag_tree,
      path: this.state.rowInfo.path,
      getNodeKey,
      newNode: { ...this.state.rowInfo.node, title },
    });

    this.setState({ tagTrees: copyData });
    this.updateTagTree(this.state.currentID, this.state.currentIndex);
  };

  syncTagTrees = () => {
    this.setState({ loading: true });
    axios.post(SYNC_TAG_TREES_API_URL + this.props.team.id).then(() => {
      this.setState({ loading: false });
      toast.success(CustomToast, { data: 'Tags are synced successfully' });
    });
  };
  createTagTree = () => {
    const tree = this.state.treeData;

    tree[0].code = uuidv4();
    tree[0].expanded = true;
    let payload = {
      tag_tree: tree,
      team: this.props.team.id,
    };

    axios.post(SEARCH_TAG_API_URL, payload).then((res) => {
      this.resetState();
    });
    this.closeTreePanel();
  };
  render() {
    const tagTrees = this.state.tagTrees;
    const { searchString, searchFocusIndex } = this.state;
    const getNodeKey = ({ treeIndex }) => treeIndex;

    const team = this.props.team || {};
    const isAdmin = this.props.team.current_teammate?.role === ROLES.Admin;
    const isAdminTeam = this.props.team.team_type === 'A' ? true : false;

    return (
      <React.Fragment>
        {globalStyles}
        <div className='ht-100'>
          <div className='second-container'>
            <div className='flex items-center justify-between'>
              <H2>
                {isAdminTeam
                  ? 'Tag Management'
                  : this.props.defaultTag
                  ? 'Default Tags'
                  : 'Custom Tags'}
              </H2>
              <Grid item className='ml-auto'>
                {(isAdminTeam || this.state.value === 0) && (
                  <>
                    <Button
                      startIcon={<Icons.Plus className='h-[20px] w-[20px] fill-white' />}
                      className='!rounded-1 !h-[40px] !w-[154px] !bg-[#23C29D] !text-center text-[18px] !font-bold !leading-[25px] !text-white !shadow-lg'
                      onClick={() => this.toggleCreateTreePanel()}
                    >
                      Add New Tree
                    </Button>
                  </>
                )}
              </Grid>
            </div>

            <Box className='flex-no-wrap flex flex-row'>
              <Box className='w-full'>
                {isAdminTeam && (
                  <React.Fragment>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Box className='mt-3 flex flex-row items-center'>
                          <Box className='flex flex-col'>
                            <Box className='d-flex align-items-center flex-row'>
                              <BehaviourButton onClick={() => this.expandAndCollapse(true)}>
                                {' '}
                                Expand All
                              </BehaviourButton>
                              <BehaviourButton onClick={() => this.expandAndCollapse(false)}>
                                {' '}
                                Collapse All
                              </BehaviourButton>
                              <Box className='search-box flex h-10 w-[157px] flex-row items-center rounded-lg border border-gray-300 bg-white pl-[10px] pr-[5px]'>
                                <input
                                  placeholder='Search'
                                  value={searchString}
                                  onChange={(event) =>
                                    this.setState({
                                      searchString: event.target.value,
                                    })
                                  }
                                  className='tag_searchBar search-bar inline-block w-[110px] border-none text-base leading-5 text-gray-600 outline-none'
                                />
                                <div className='ml-auto mr-[10px] text-gray-400'>
                                  <SearchIcon />
                                </div>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <div className='mt-4'>
                  {this.state.showNotification && (
                    <NotificationBox>
                      <InfoIcon className='mr-4' />
                      <Typography className='mr-[5px] text-base font-bold leading-5 tracking-tighter text-gray-700'>
                        Note:
                      </Typography>
                      <Typography className='pl-3 text-base font-normal leading-5 tracking-tighter text-gray-700'>
                        Admin-created tag trees may be used, but deletion results in removal of all
                        related tags.
                      </Typography>
                      <CloseIcon
                        className='ml-auto cursor-pointer text-black'
                        onClick={() => this.setState({ showNotification: false })}
                      />
                    </NotificationBox>
                  )}
                  {Array.isArray(tagTrees) && tagTrees.length > 0 ? (
                    tagTrees.map((tagTree, index) => {
                      const adminTreeInOtherTeam =
                        tagTree.is_admin_tree && tagTree.team !== team?.id;
                      const enableView = (isAdmin && !adminTreeInOtherTeam) || false;

                      const iOSSwitchProps = {
                        classes: {},
                        checked: tagTree.is_published,
                        onClick: () => this.handlePublishTeams(index, tagTree.id),
                      };

                      return (
                        <StyledContainer
                          key={`tree${index}`}
                          fixed
                          className='mx-0 h-auto w-full border-b border-dashed border-gray-300 px-0 pb-5'
                        >
                          <Box className='d-flex align-items-center mb-4 w-full'>
                            <Typography className='d-flex align-items-center text-blue-gray-600 ml-[15px] flex items-center text-xl font-bold uppercase leading-6'>
                              {tagTree.is_admin_tree && isAdmin && isAdminTeam && (
                                <div className='flex items-center'>
                                  <IOSSwitch
                                    {...iOSSwitchProps}
                                    // checked={tagTree.is_published}
                                    // onClick={() => this.handlePublishTeams(index, tagTree.id)}
                                  />
                                  <Typography className='text-blue-gray-600 text-xs font-semibold normal-case leading-4 tracking-tight'>
                                    Publish to all teams
                                  </Typography>
                                </div>
                              )}
                            </Typography>{' '}
                          </Box>

                          <SortableTree
                            onDragStateChanged={(e) => {
                              !e.isDragging && this.updateTagTree(tagTree.id, index);
                            }}
                            canDrag={enableView}
                            isVirtualized={false}
                            rowHeight={75}
                            searchQuery={searchString}
                            searchMethod={this.customSearchMethod}
                            searchFocusOffset={searchFocusIndex}
                            treeData={tagTree.tag_tree}
                            onChange={(treeData) => this.handleChange(treeData, index, tagTree.id)}
                            nodeContentRenderer={NodeRendererDefault}
                            generateNodeProps={(rowInfo) => {
                              const isCustomNode = rowInfo.node?.is_custom_node || false;
                              const showEditDelete =
                                isAdmin &&
                                ((adminTreeInOtherTeam && isCustomNode) || !adminTreeInOtherTeam);
                              return {
                                buttons: [
                                  showEditDelete && (
                                    <EditIcon
                                      className='cursor-pointer text-teal-500'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        this.toggleCreateNodePanel(
                                          rowInfo,

                                          index,
                                          tagTree.id,
                                          true
                                        );
                                      }}
                                    />
                                  ),
                                  showEditDelete && (
                                    <DeleteIcon
                                      className='ml-8 mr-4 cursor-pointer text-red-500'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                          rowInfo: rowInfo,
                                          currentIndex: index,
                                          currentID: tagTree.id,
                                        });
                                        this.toggleDeleteNodeModal();
                                      }}
                                    />
                                  ),
                                ],

                                title: (
                                  <form
                                    className='cursor-default'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.selectThis(rowInfo.node, rowInfo.path);
                                    }}
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        width: 300,
                                        outline: 'none',
                                        border: '0px solid white',
                                        fontStyle: 'normal',
                                        fontWeight: rowInfo.parentNode ? '400' : '600',
                                        fontSize: rowInfo.parentNode ? '16px' : '18px',
                                        lineHeight: '20px',
                                        marginLeft: !enableView ? 20 : undefined,
                                      }}
                                    >
                                      {rowInfo.node.title}
                                    </Typography>
                                  </form>
                                ),
                                addButton: isAdmin && (
                                  <Box
                                    style={{
                                      background: '#FFFFFF',
                                      boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
                                      borderRadius: '6px',
                                      height: '100%',
                                      width: '60px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginLeft: 20,
                                      cursor: 'pointer',
                                      position: 'relative',
                                      zIndex: '2',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.toggleCreateNodePanel(rowInfo, index, tagTree.id, false);
                                    }}
                                  >
                                    <button
                                      className='focus:bg-transparent'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.toggleCreateNodePanel(
                                          rowInfo,
                                          index,
                                          tagTree.id,
                                          false
                                        );
                                      }}
                                    >
                                      <Icons.Plus className='h-[20px] w-[20px] fill-[#2E3438]' />
                                    </button>
                                  </Box>
                                ),
                              };
                            }}
                          />
                        </StyledContainer>
                      );
                    })
                  ) : (
                    <Box className='box-border flex h-[582px] w-full flex-col items-center justify-center rounded-lg '>
                      <p className='text-center text-[22px] font-normal leading-[30px] text-[#ACB6C9]'>
                        No Tags yet!
                      </p>
                      <p className='flex flex-row text-center text-[22px] font-normal leading-[30px] text-[#5A6170]'>
                        Click on <b className='ml-2 mr-2'>Add New Tree </b> to Start
                      </p>
                    </Box>
                  )}
                </div>
              </Box>
              <SlidingPane
                isOpen={this.state.createTreePanel}
                onRequestClose={this.closeTreePanel}
                from='right'
                overlayClassName='modal-backdrop'
                className='shadow-tag-management'
                width='620px'
                hideHeader
              >
                <div className='h-[95%] overflow-x-visible bg-white'>
                  <Header title='Add Tag Tree' toggleModal={this.closeTreePanel} />
                  <Box className='mt-2 flex h-full flex-col pl-[13px] pr-[13px]'>
                    <Divider className='text-[#E6E6E6]' />
                    <Box className='d-flex flex-column'>
                      <p className='leading-base mt-[30px] flex items-center text-base font-bold text-gray-900'>
                        Add Title{' '}
                        <StyledBadge variant='dot' overlap='circular' style={{ marginLeft: 5 }} />
                      </p>
                      <p className='mt-[5px] text-base font-normal leading-5 text-gray-500'>
                        Dummy Text will be populated later
                      </p>
                      <StyledInput
                        type='text'
                        name='title'
                        id='title'
                        className='mt-[13px]'
                        onChange={this.onChange}
                        placeholder='Title'
                        value={this.state.treeData[0].title}
                      />
                    </Box>
                    <Box className='d-flex align-items-center ml-auto mt-auto flex-row'>
                      <CancelButton
                        className='mr-[25px] text-black'
                        onClick={() => this.closeTreePanel()}
                      >
                        Cancel
                      </CancelButton>
                      <CreateButton onClick={() => this.createTagTree()}>Create</CreateButton>
                    </Box>
                  </Box>
                </div>
              </SlidingPane>
              <SlidingPane
                isOpen={this.state.createNodePanel}
                onRequestClose={this.closeNodePanel}
                from='right'
                overlayClassName='modal-backdrop'
                className='shadow-tag-management'
                width='620px'
                hideHeader
              >
                <div className='h-full overflow-x-visible bg-white'>
                  <Box className='mt-2 flex h-[95%] flex-col pl-[13x] pr-[13px]'>
                    <Header
                      title={this.state.editMode ? 'Update Node' : 'Add Node'}
                      toggleModal={this.closeNodePanel}
                    />

                    <Divider className='text-[#E6E6E6]' />
                    <Box className='d-flex flex-column'>
                      <p className='leading-base mt-8 flex items-center text-base font-bold text-gray-900'>
                        Add Title{' '}
                        <StyledBadge variant='dot' overlap='circular' style={{ marginLeft: 5 }} />
                      </p>
                      <p className='mt-[5px] text-base font-normal leading-5 text-gray-500'>
                        Dummy Text will be populated later
                      </p>
                      <StyledInput
                        type='text'
                        name='title'
                        id='title'
                        className='mt-[13px]'
                        onChange={(e) => this.onNodeTitleChange(e)}
                        placeholder='Title'
                        value={this.state.title}
                      />
                    </Box>
                    <Box className='d-flex align-items-center ml-auto mt-auto flex-row'>
                      <CancelButton
                        className='ml-[25px] text-black'
                        onClick={() => {
                          this.closeNodePanel();
                        }}
                      >
                        Cancel
                      </CancelButton>
                      <CreateButton
                        onClick={() => {
                          if (this.state.editMode) {
                            this.pushNode(getNodeKey);
                          } else {
                            this.insertNodeUnderParent(this.state.rowInfo, this.state.currentIndex);
                          }
                          this.closeNodePanel();
                        }}
                      >
                        {this.state.editMode ? 'Update' : 'Create'}
                      </CreateButton>
                    </Box>
                  </Box>
                </div>
              </SlidingPane>
              <ConfirmModal
                preset='delete'
                open={this.state.modal}
                content='Are you sure you want to delete this Tag Tree?'
                toggleModal={() => this.toggleModal(0)}
                performAction={this.deleteSearchTag}
              />
              <ConfirmModal
                preset='delete'
                open={this.state.deleteNodeModal}
                content='Are you sure you want to delete this Node?'
                toggleModal={() => this.toggleDeleteNodeModal()}
                performAction={() => {
                  this.removeNode(
                    this.state.rowInfo.path,
                    this.state.currentIndex,
                    this.state.rowInfo
                  );
                  this.toggleDeleteNodeModal();
                }}
              />
            </Box>
            <div className='separate mt-[40px]' />
          </div>
          {this.state.loading && <EntireScreenLoading />}
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withTeam(TagsTreeListing);
