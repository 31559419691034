import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import React, { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { Trigger } from './Trigger';

interface SectionDividerProps {
  editMode: any;
  editorTrigger: any;
  trigger: any;
  container: any;
  containerCard: any;
  type?: any;
  update: () => void;
  editorTitle: any;
  title: any;
  editorSubtitle?: any;
  subtitle?: any;
  panelId: any;
  deleteCard: (panelId?: any) => void;
}
interface SectionDividerState {
  modal: boolean;
  deleteModal: boolean;
  editMode: boolean;
  anchorEl: any;
}
class SectionDivider extends Component<SectionDividerProps, SectionDividerState> {
  constructor(props: SectionDividerProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      editMode: false,
      anchorEl: null,
    };
  }
  setAnchorEl = (e) => {
    this.setState({ anchorEl: e.target });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };
  handleEditMode = () => {
    this.setState((previous) => ({ editMode: !previous.editMode }));
  };
  // for open/close modal
  toggleModal = () => {
    this.setState((previous) => ({
      modal: !previous.modal,
    }));
  };

  render() {
    const trigger =
      this.state.editMode || this.props.editMode ? this.props.editorTrigger : this.props.trigger;
    let cardStyle = {
      marginTop: this.props.container ? '20px' : '5px',
      marginBottom: this.props.container ? '0px' : '20px',
      width: this.props.container ? '100%' : '576px',
      // borderRadius: '10px',
      border: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: 0,
      paddingBottom: '10px',
      backgroundColor: 'transparent',
      // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
    };
    let questionStyle = {
      fontStyle: 'normal',
      marginTop: 4,
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '30px',
      textTransform: 'uppercase',
      color: '#000000',
    };

    return (
      <div
        style={
          {
            position: this.state.editMode || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editMode || this.props.editMode ? '12' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            // marginLeft: !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <Trigger trigger={trigger} containerCard={this.props.containerCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 16,
              paddingTop: 5,
            }}
          >
            <Typography
              component={'div'}
              color='textPrimary'
              style={questionStyle as React.CSSProperties}
            >
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.toggleDeleteModal();
                    this.handleClose();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {this.state.editMode || this.props.editMode
                ? this.props.editorTitle
                : this.props.title}
            </Typography>
            <Typography
              className='pb-2'
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#5B5B5B',
              }}
            >
              {this.state.editMode || this.props.editMode
                ? this.props.editorSubtitle
                : this.props.subtitle}
            </Typography>
          </CardContent>
        </Card>
        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleDeleteModal();
          }}
        />
      </div>
    );
  }
}
export default SectionDivider;
