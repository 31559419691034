import { Card, CardContent, Chip, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { APIResource } from 'components/resources/api/types';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { questionStyle } from '../utils/styles';

interface APIBoxProps {
  editMode: any;
  editorTrigger: any;
  trigger: any;
  answerForm?: any;
  containerCard: any;
  type?: any;
  update: () => void;
  editorTitle: any;
  title: any;
  apiResource: APIResource;
  editorPrompt: any;
  prompt: any;
  panelId: any;
  deleteCard: (panelId?: any) => void;
  container: any;
  editorSubtitle?: any;
}
interface APIBoxState {
  modal: boolean;
  deleteModal: boolean;
  anchorEl: any;
  editor: boolean;
}
class APIBox extends Component<APIBoxProps, APIBoxState> {
  constructor(props: APIBoxProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      anchorEl: null,
      editor: false,
    };
  }

  setAnchorEl = (e) => this.setState({ anchorEl: e.target });

  handleClose = () => this.setState({ anchorEl: null });

  handleEditMode = () => {
    this.setState((previous) => ({ editor: !previous.editor }));
  };

  toggleModal = (modalName) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<APIBoxState, any>
    );
  };

  render() {
    const trigger =
      this.state.editor || this.props.editMode ? this.props.editorTrigger : this.props.trigger;

    let cardStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '10px',
      marginBottom: '0px',
      paddingBottom: '10px',
      border: 'none',
      borderRadius: '30px',
      width: this.props.container ? '100%' : '576px',
      backgroundColor: this.props.container ? 'transparent' : '#FFFFFF',
      boxShadow: this.props.container
        ? 'none'
        : '0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.06)',
    };

    return (
      <div
        data-testid='choice-panel__test'
        style={
          {
            position: this.state.editor || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editor || this.props.editMode ? '12' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            marginLeft: this.props.answerForm ? '' : !this.props.container && '25px',
            marginBottom: 15,
          } as React.CSSProperties
        }
      >
        <Typography
          component={'div'}
          color='textPrimary'
          style={{
            padding: '10px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#FFFFFF',
          }}
        >
          {!(this.props.containerCard && trigger?.title === 'Always On') && (
            <Chip
              style={{
                backgroundColor: trigger ? '#08A88E' : 'red',
                color: 'white',
                marginLeft: '22px',
                height: '24px',
              }}
              size='small'
              label={trigger?.title || 'Not Assigned'}
            />
          )}
        </Typography>

        <Card
          className='item-to-hover'
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}

              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.toggleModal('deleteModal');
                    this.handleClose();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>

              {this.state.editor || this.props.editMode
                ? this.props.editorTitle || 'Title'
                : this.props.title}
            </Typography>
            {this.props.apiResource && (
              <div className='mt-3'>
                <p>Title: {this.props.apiResource.title}</p>
                <p>URL: {this.props.apiResource.url}</p>
              </div>
            )}
          </CardContent>
        </Card>

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => this.toggleModal('deleteModal')}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleModal('deleteModal');
          }}
        />
      </div>
    );
  }
}

export default APIBox;
