import { Box, CircularProgress } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getCalculators } from 'actions/calculators/getCalculators';
import { getTriggers } from 'actions/resources/getTriggers';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import AppearingSituation from 'components/utils/AppearingSituation';
import AnswerSection from 'components/utils/answers/AnswerSection';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { GreenCheckbox, StyledFormGroupMB30 } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, CARD_CHECKBOXES, SAVE_BUTTON } from 'constants/variables';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import {
  MODULE_TYPES,
  MULTI_CHOICE_PANEL_API_URL,
  RETRIEVE_CHOICE_ITEM_API_URL,
} from '../../constants';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { MultiChoicePanelFormDispatchProps } from './types';

interface MultiChoicePanelFormState {
  infoboxModal: boolean;
  textModal: boolean;
  choiceModal: boolean;
  updateChoiceModal: boolean;
  isChangeAble: boolean;

  createInfobox: boolean;
  pk: string;
  title: string;
  subtitle: string;
  choices: any;
  question_panel: any;
  question: string;
  trigger: string;
  module: string;
  imageModal: boolean;
  variable: any;
  variable_code: any;
  images: any;
  examples: any;
  tools: any;
  infoboxes: any;
  calculatorModal: boolean;
  position: any;
  is_checked_submit: boolean;
  isLoading: boolean;
}

interface MultiChoicePanelFormProps {
  choiceCoefficient: boolean;
  multiChoicePanelId: string;
  startPreview: (component: string) => void;
  triggerState: any;
  handleSelectedItem: () => void;
  formState: any;
  handleLivePreview: (
    choices: any,
    question: any,
    examples: any,
    subtitle: any,
    trigger: any,
    tools: any,
    renderPreview: any
  ) => void;
  handleAddChoices: (choice: any) => void;
  handleAddQuestion: (question: any) => void;
  handleAddSubtitle: (subtitle: any) => void;
  handleAddVariable: (variable: any) => void;
  handleAddExample: (example: any) => void;
  handleAddTools: (tools: any) => void;
  infoboxState: any;
  mediaState: any;
  calculatorState: any;
  teamCalculatorState: any;
  handleAddTrigger: (trigger: any) => void;
  toggleModal: () => void;
  calculatorId: string;
  resetState: () => void;
  resetAnswerPages: () => void;
  moduleId: number;
  position: number;
  container: boolean;
  handleShowSubmit: (e: boolean) => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}

type Props = MultiChoicePanelFormDispatchProps & MultiChoicePanelFormProps;

class MultiChoicePanelForm extends Component<Props, MultiChoicePanelFormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      infoboxModal: false,
      textModal: false,
      choiceModal: false,
      updateChoiceModal: false,
      isChangeAble: false,

      createInfobox: false,
      pk: '',
      title: '',
      subtitle: '',
      choices: [],
      question_panel: [],
      question: '',
      trigger: '',
      module: '',
      imageModal: false,
      variable: '',
      variable_code: null,
      images: [],
      examples: [],
      tools: [],
      infoboxes: [],
      calculatorModal: false,
      position: null,
      is_checked_submit: !this.props.calculatorId,
      isLoading: false,
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.checked } as Pick<
      MultiChoicePanelFormState,
      any
    >);
    this.props.handleShowSubmit(event.target.checked);
  };

  helpInfoData = getHelpInfoData('MultipleChoicePanel');

  // checks url and updates state accordingly (edit or create)
  componentDidMount() {
    const { calculatorId, getTriggers, moduleId, getInfoBoxes } = this.props;
    // TODO: remove when redux data is depracated
    const moduleType = calculatorId ? MODULE_TYPES.CALCULATOR : MODULE_TYPES.ALGO;
    const id = calculatorId || moduleId;

    getTriggers(moduleType, null, false, id);
    getInfoBoxes(moduleType, null, id);

    if (!this.props.multiChoicePanelId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.props.handleSelectedItem();
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
        position: this.props.position,
      });
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
    }

    if (this.props.container) this.setState({ is_checked_submit: false });

    // if update request encountered
    if (this.props.multiChoicePanelId) {
      axios
        .get(MULTI_CHOICE_PANEL_API_URL + this.props.multiChoicePanelId + '/')
        .then((res) => this.updateState(res.data));
    } else {
      let choices = [
        {
          sortable_id: 1,
          name: 'Item 1',
          coefficient: 1,
          infobox: null,
          position: 0,
        },
        {
          sortable_id: 2,
          name: 'Item 2',
          coefficient: 1,
          infobox: null,
          position: 1,
        },
        {
          sortable_id: 3,
          name: 'Item 3',
          coefficient: 1,
          infobox: null,
          position: 2,
        },
      ];

      this.setState({ choices });
      this.props.handleAddChoices(choices);
      this.props.startPreview('renderMultiChoicePreview');
    }
  }

  updateState = (data: any) => {
    axios.get(RETRIEVE_CHOICE_ITEM_API_URL + this.props.multiChoicePanelId).then((res) => {
      let choices = res.data.filter(
        (choice: any) => choice.name !== 'submitted' && choice.name !== 'count'
      );
      let choicesObj: any = [];
      choices.forEach((element: any) => {
        choicesObj.push({
          id: element.id,
          name: element.name,
          coefficient: element.normalized_coefficient,
          position: element.position,
          sortable_id: element.id,
          infobox: element.infobox ? element.infobox.id : null,
        });
      });

      choicesObj.sort((a: any, b: any) => (a.position > b.position ? 1 : -1));

      this.setState({ choices: choicesObj });
      this.props.handleAddChoices(choicesObj);
    });

    this.setState({
      pk: data.id,
      title: data.title,
      subtitle: data.subtitle,
      question: data.question,
      trigger: data.trigger ? data.trigger : '',
      module: data.module,
      examples: data.examples,
      tools: data.tools,
      variable: data.variable_item?.name,
      variable_code: data?.variable_item?.unique_code,
      position: data.position,
      is_checked_submit: data.is_submissable,
    });
    this.props.handleAddSubtitle(data.subtitle);
    this.props.handleAddQuestion(data.question);
    this.props.handleAddTrigger(data.trigger ? data.trigger : '');
    this.props.handleAddExample(data.examples);
    this.props.handleAddTools(data.tools);
    this.props.handleShowSubmit(data.is_submissable);
  };

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'subtitle') {
      this.props.handleAddSubtitle(e.target.value);
    }
    if (e.target.name === 'question') {
      this.props.handleAddQuestion(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<MultiChoicePanelFormState, any>);
  };

  setVariableName = (variable: string) => {
    this.setState({ variable });
    this.props.handleAddVariable?.(variable);
  };

  // add choice in choices array
  addChoice = () => {
    // create choice obj
    let obj = {
      sortable_id: generate_ID(),
      name: 'Item ' + (this.state.choices.length + 1),
      coefficient: 1,
      infobox: null,
      position: this.state.choices.length,
    };

    let concatedArray = this.state.choices.concat(obj);
    this.setState({ choices: concatedArray });

    this.props.handleAddChoices(concatedArray);
  };

  updateChoice = (id: number, value: string, inputName: string) => {
    const updatedChoices = this.state.choices.map((choice: any) => {
      let updatedItem = choice;

      if (choice.sortable_id === id) {
        if (inputName === 'name') {
          updatedItem = {
            ...choice,
            name: value,
          };
        } else if (inputName === 'coefficient') {
          updatedItem = {
            ...choice,
            coefficient: value,
          };
        } else if (inputName === 'infobox') {
          updatedItem = {
            ...choice,
            infobox: value === '-----' ? null : value,
          };
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ choices: updatedChoices });
    this.props.handleAddChoices(updatedChoices);
  };

  updateChoices = (newList: any) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }

    this.setState({
      choices: newList,
    });
    this.props.handleAddChoices(newList);
  };

  deleteChoice = (choiceId: number) => {
    let updatedChoices = this.state.choices.filter((el) => el.sortable_id !== choiceId);
    for (let i = 0; i < updatedChoices.length; i++) {
      updatedChoices[i].position = i;
    }

    this.setState({ choices: updatedChoices });
    this.props.handleAddChoices(updatedChoices);
  };

  getSelectedTrigger = (data: any) => {
    this.setState({
      trigger: data,
    });
    this.props.handleAddTrigger(data);
  };

  getPayload = () => {
    let payload = {
      title: this.state.title,
      subtitle: this.state.subtitle,
      variable: this.state.variable,
      choices: this.state.choices,
      question: this.state.question,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      calculator: this.props.calculatorId,
      question_items: this.state.examples.concat(this.state.tools),
      position: this.state.position,
      container: this.props.container,
      is_submissable: this.state.is_checked_submit,
    };
    return payload;
  };

  // creates a choicepanel via api
  createMultiChoicePanel = () => {
    let isEmptyValueFound = false;
    for (let i = 0; i < this.state.choices.length; i++) {
      if (
        !this.state.choices[i].name ||
        this.state.choices[i].coefficient === null ||
        this.state.choices[i].coefficient === undefined ||
        this.state.choices[i].coefficient === ''
      ) {
        isEmptyValueFound = true;
        break;
      }
    }

    if (isEmptyValueFound) {
      toast.error(CustomToast, { data: 'Label or coefficient is missing in answers' });
      return;
    }

    this.props.toggleModal();
    axios.post(MULTI_CHOICE_PANEL_API_URL, this.getPayload()).then(async () => {
      // reset unique codes mapping to get latest result
      if (this.props.calculatorId) {
        await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
        this.props.getTriggers(MODULE_TYPES.CALCULATOR, null, false, this.props.calculatorId);
      } else {
        await this.props.getUniqueCode(this.props.moduleId);
        this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
      }
      this.props.resetState();
    });
  };

  editMultiChoicePanel = () => {
    let isEmptyValueFound = false;
    for (let i = 0; i < this.state.choices.length; i++) {
      if (
        !this.state.choices[i].name ||
        this.state.choices[i].coefficient === null ||
        this.state.choices[i].coefficient === undefined ||
        this.state.choices[i].coefficient === ''
      ) {
        isEmptyValueFound = true;
        break;
      }
    }

    if (isEmptyValueFound) {
      toast.error(CustomToast, { data: 'Label or coefficient is missing in answers' });
      return;
    }

    this.props.toggleModal();
    axios
      .put(MULTI_CHOICE_PANEL_API_URL + this.state.pk + '/', this.getPayload())
      .then(async () => {
        // reset unique codes mapping to get latest result
        if (this.props.calculatorId) {
          await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
          this.props.getTriggers(MODULE_TYPES.CALCULATOR, null, false, this.props.calculatorId);
        } else {
          await this.props.getUniqueCode(this.props.moduleId);
          this.props.getTriggers(MODULE_TYPES.ALGO, null, false, this.props.moduleId);
        }

        // fetch triggers to update used in field of triggers

        this.props.resetState();
      });
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { question, variable, choices } = this.state;
    const { multiChoicePanelId, modal, closeUiElementPane } = this.props;

    const hasEmptyCoefficientOrName = choices.some(
      (obj) => obj.name === '' || obj.coefficient === ''
    );

    if (question === '') {
      toast.error(CustomToast, { data: 'Please fill out the checkboxes question field.' });
    } else if (hasEmptyCoefficientOrName) {
      toast.error(CustomToast, { data: 'Please fill out all the Answers fields.' });
    } else {
      this.setState({ isLoading: true });
      if (!variable) {
        const variable = await getVariableName(question);
        this.setState({ variable });
      }

      if (multiChoicePanelId) {
        this.editMultiChoicePanel();
      } else {
        this.createMultiChoicePanel();
      }
    }

    modal && closeUiElementPane?.();
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    // Check if any object has an empty "coefficient" or "label" value
    const hasEmptyCoefficientOrName = this.state.choices.some(
      (obj) => obj.name === '' || obj.coefficient === ''
    );

    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    const greenCheckboxProps = {
      checked: this.state.is_checked_submit,
      onChange: this.handleChange,
      name: 'is_checked_submit',
    };

    return (
      <div className='row mt-14'>
        <div className='side-panel-form'>
          <Header title={CARD_CHECKBOXES} toggleModal={this.props.toggleModal} />
          <Form
            autoComplete='off'
            onKeyPress={this.onKeyPress}
            className='choice flex h-full flex-col px-3 pb-0'
            onSubmit={this.handleSubmit}
          >
            {this.state.variable_code && (
              <div className='row' style={{ marginBottom: 0 }}>
                <div className='col-md-10 offset-md-1'>
                  <Chip
                    style={{ float: 'right', borderRadius: '10px' }}
                    label={this.state.variable_code}
                    disabled
                  />
                </div>
              </div>
            )}
            <Box className='mb-5'>
              <AppearingSituation
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
                calculatorId={this.props.calculatorId}
                type={this.props.calculatorId && 'calculator'}
              />
            </Box>

            <Box style={{ marginTop: '15px' }}>
              <InputField
                name='question'
                required={true}
                value={this.state.question}
                onChange={this.onChange}
                label={helpInfoData?.question?.label}
                detail={helpInfoData?.question?.detail}
                placeholder={helpInfoData?.question?.placeholder}
                maxLength={helpInfoData?.question?.character_limit}
              />
            </Box>

            <VariableAutoGenerator
              tagTitle='Checkbox'
              variable={this.state.variable}
              question={this.state.question}
              setVariableName={(variable) => this.setVariableName(variable)}
            />

            <Box style={{ marginTop: '20px' }}>
              <InputField
                name='subtitle'
                onChange={this.onChange}
                value={this.state.subtitle}
                label={helpInfoData?.subtitle?.label}
                detail={helpInfoData?.subtitle?.detail}
                placeholder={helpInfoData?.subtitle?.placeholder}
                maxLength={helpInfoData?.subtitle?.character_limit}
              />
            </Box>

            <StyledFormGroupMB30>
              <FieldLabel
                required
                label={helpInfoData?.choices?.label}
                detail={helpInfoData?.choices?.detail}
              />
              <AnswerSection
                answers={this.state.choices}
                infoboxes={this.props.infoboxState.infoBoxes}
                moduleId={this.props.moduleId}
                calculatorId={this.props.calculatorId}
                type={this.props.calculatorId && 'calculator'}
                addChoice={this.addChoice}
                updateChoices={this.updateChoices}
                updateChoice={this.updateChoice}
                deleteChoice={this.deleteChoice}
              />
            </StyledFormGroupMB30>
            {!this.props.container && (
              <FormControlLabel
                control={<GreenCheckbox {...greenCheckboxProps} />}
                label='Submit Button'
              />
            )}
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton
                  id='canel-btn'
                  onClick={this.props.toggleModal}
                  className='ml-auto mr-4'
                >
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton
                  disabled={!this.state.question || hasEmptyCoefficientOrName}
                  type='submit'
                  name='action'
                >
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>

        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch, MultiChoicePanelFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getCalculators: () => dispatch(getCalculators()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiChoicePanelForm);
