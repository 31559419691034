import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import {
  CALC_EQUATIONS_ROUTE,
  CALC_FLOW_ROUTE,
  CALC_OUTPUTS_ROUTE,
  CALC_RESOURCES_ROUTE,
} from '../../constants';
import { ConditionalTextResourceTable } from '../resources/conditional-texts/ConditionalTextTable';
import { CustomNumericResourceTable } from '../resources/custom-numerics/CustomNumericTable';
import { FormulaResourceTable } from '../resources/formula-board/FormulaTable';
import { InfoboxResourceTable } from '../resources/infobox/InfoboxTable';
import { ReferenceManagerResourceTable } from '../resources/reference-manager/ReferenceManagerTable';
import { SmartNoteResourceTable } from '../resources/smart-note/SmartNoteTable';
import { TriggerResourceTable } from '../resources/triggers/TriggerTable';
import { H3 } from '../utils/typo';
import Flow from './board/Flow';
import OutPutPanel from './board/OutputPanel';

export type ResourceTypes =
  | 'triggers'
  | 'infoboxes'
  | 'references'
  | 'smart-notes'
  | 'custom-numbers'
  | 'conditional-texts';

export const CALCULATOR_RESOURCES: Record<ResourceTypes, ResourceTabInfo> = {
  triggers: {
    label: 'Triggers',
    component: <TriggerResourceTable />,
  },
  infoboxes: {
    label: 'Infoboxes / Media',
    component: <InfoboxResourceTable />,
  },
  references: {
    label: 'Reference Managers',
    component: <ReferenceManagerResourceTable />,
  },
  'smart-notes': {
    label: 'Smart Notes',
    component: <SmartNoteResourceTable />,
  },
  'custom-numbers': {
    label: 'Custom Numbers',
    component: <CustomNumericResourceTable />,
  },
  'conditional-texts': {
    label: 'Conditional Texts',
    component: <ConditionalTextResourceTable />,
  },
  // TODO: add 'API (Beta)',
};

interface ResourceTabInfo {
  label: string;
  component: ReactNode;
}

export enum MainResources {
  FLOW = 'flow',
  EQUATIONS = 'equations',
  OUTPUTS = 'outputs',
}

export const CalculatorTabs = () => {
  const { resource } = useParams<{ resource: ResourceTypes | undefined }>();

  const { pathname } = useLocation();
  const mainResource = pathname.split('/')[3];

  const { moduleId } = useParams<{ moduleId: string }>();

  return (
    <div className='flex w-full'>
      <div className='mt-[80px] w-[200px]'>
        <div className='fixed flex w-44 flex-col items-end space-y-5'>
          {mainResource === MainResources.FLOW ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Flow</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(CALC_FLOW_ROUTE, { moduleId })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Flow</H3>
              </div>
            </Link>
          )}
          {mainResource === MainResources.EQUATIONS ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Equations</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(CALC_EQUATIONS_ROUTE, { moduleId })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Equations</H3>
              </div>
            </Link>
          )}
          {mainResource === MainResources.OUTPUTS ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Outputs</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(CALC_OUTPUTS_ROUTE, { moduleId })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Outputs</H3>
              </div>
            </Link>
          )}
          {!!resource ? (
            <div className='flex items-center'>
              <H3 className='!mr-7 text-primary-600'>Resources</H3>
              <span className='h-[50px] w-[5px] bg-primary-600' />
            </div>
          ) : (
            <Link
              to={generatePath(CALC_RESOURCES_ROUTE, { moduleId, resource: 'triggers' })}
              className='!mr-7 text-gray-700 hover:text-primary-600'
            >
              <div className='flex h-[50px] items-center'>
                <H3>Resources</H3>
              </div>
            </Link>
          )}
          {!!resource &&
            Object.keys(CALCULATOR_RESOURCES).map((resourceName, index) =>
              resource === resourceName ? (
                <p className='text-primary-600'>{CALCULATOR_RESOURCES[resourceName].label}</p>
              ) : (
                <Link
                  to={generatePath(CALC_RESOURCES_ROUTE, { moduleId, resource: resourceName })}
                  className='text-black hover:text-primary-600'
                >
                  {CALCULATOR_RESOURCES[resourceName].label}
                </Link>
              )
            )}
        </div>
      </div>

      <div className='w-full'>
        {resource ? (
          CALCULATOR_RESOURCES[resource].component
        ) : mainResource === MainResources.FLOW ? (
          <Flow calcId={moduleId} />
        ) : mainResource === MainResources.EQUATIONS ? (
          <FormulaResourceTable />
        ) : (
          <OutPutPanel calculatorId={moduleId} type='calculator' />
        )}
      </div>
    </div>
  );
};
