export const CARD_MULTIPLE_CHOICE = 'Multiple Choice';
export const CARD_GPT_BOX = 'GPT Box';
export const CARD_API_BOX = 'API Box';
export const CARD_AMBIENT_LISTENING = 'Ambient Listening';
export const CARD_DATA_EXTRACTOR = 'Data Extractor';
export const CARD_CHECKBOXES = 'Checkboxes';
export const CARD_MESSAGE = 'Message';
export const CARD_INTRODUCTION = 'Introduction';
export const CARD_DESCRIPTION = 'Description';
export const CARD_NUMBERS = 'Numbers';
export const CARD_TEXT_INPUT = 'Text Input';
export const CARD_SECTION_DIVIDER = 'Section Divider';
export const CARD_GROUP = 'Group';
export const ANSWER_PAGE = 'Answer Page';
export const CARD_CUSTOM_NUMERIC = 'Custom Numeric';
export const CARD_CONDITIONAL_TEXT = 'Conditional Text';
export const CARD_FORMULA = 'Formula';

// button
export const CARD_EDIT_BUTTON = 'Edit';
export const CARD_DELETE_BUTTON = 'Delete';
export const CARD_CANCEL = 'Cancel';

export const SAVE_BUTTON = 'Save';
export const CANCEL_BUTTON = 'Cancel';
export const UPDATE = 'Update';
export const CREATE = 'Create';

export const ADD_ITEM_BUTTON_IN_GROUP_CARD = 'Add an Item';

// error message
export const ERR_MSG_MODULE_NOT_FOUND_REDIRECT_TO_MAIN =
  'Redirecting to main page due to module unavailability';

// Value panel gender
export enum GENDER {
  SEX = 'sex',
  MALE = 'Male',
  FEMALE = 'Female',
}
export const GENDER_VALUES = {
  [GENDER.MALE]: 1,
  [GENDER.FEMALE]: 2,
};
