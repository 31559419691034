import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Backdrop from 'components/utils/Backdrop';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';

import { getFormulas } from 'actions/resources/getFormulas';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getTriggers } from 'actions/resources/getTriggers';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import ChoicePanel from 'components/app-previews/ChoicePanel';
import DescriptionPanelPreview from 'components/app-previews/DescriptionPanel';
import DescriptionPanelBetaPreview from 'components/app-previews/DescriptionPanelBeta';
import MultiChoice from 'components/app-previews/MultiChoice';
import ValuePanel from 'components/app-previews/ValuePanel';
import { DraggableChips } from 'components/module-detail/DraggableChips';
import ContainerCards from 'components/module-detail/container/ContainerCards';
import ChoicePanelForm from 'components/panels/ChoicePanelForm';
import MultiChoiceForm from 'components/panels/MultiChoicePanelForm';
import ValuePanelForm from 'components/panels/ValuePanelForm';
import { compareCardPosition } from 'components/utils/general/compareCardPosition';
import { hasViewOnlyPermission } from 'utils/permissions';
import { addMirrorId } from 'utils/utilityFunctions';

import { CustomToast } from 'components/utils/toast-message';
import {
  CALCULATOR_API_URL,
  CONTAINER_CARD_API_URL,
  UI_ELEMENTS_API_URL,
  UI_ELEMENT_DUPLICATE_API_URL,
} from 'constants';
import { toast } from 'react-toastify';
import { MODULE_TYPES } from '../../../constants';

const FILTERED_CHIPS = [];

class Flow extends Component {
  constructor(props) {
    super(props);

    this.choiceReferences = [];
    this.valueReferences = [];
    this.descriptionReferences = [];
    this.descriptionv2References = [];
    this.multiChoiceReferences = [];
    this.dividerReferences = [];
    this.editorRef = React.createRef();

    this.state = {
      isResoucesLoading: true,
      choices: [],
      question: '',
      rationale: '',
      examples: [],
      trigger: '',
      createContainerCard: false,
      multipleChoice: false,
      numbers: false,
      selectedItem: '',
      newPreviewItem: {
        choices: [],
        panel_type: '',
        editMode: true,
        position: 1,
        resourcetype: '',
      },
      checkboxes: false,
      uiElementId: '',
      createTrigger: false,
      items: [],
      tools: [],
      editor: false,
      closeContainer: false,
      closeUiElement: false,
      currentPane: '',
      stopEditor: false,
      value: 0,
      description_text: '',
      descriptionType: 'action',
      panel_type: 'O',
      images: [],
      renderPreview: false,
      editorTitle: '',
      draggableAnswerColumns: {},
      isLongDescEnabled: false,
      longDescription: '',
      showSubmit: true,
      shortDescription: '',
      subtitle: '',
      draggableColumns: {
        // eslint-disable-next-line
        ['ConversationCard']: {
          items: [],
        },
      },
    };
  }

  async componentDidMount() {
    await this.resetContainersAndUi();
    this.setState({ isResoucesLoading: false });
  }

  resetContainersAndUi = async (isDelete) => {
    const calcId = this.props.calcId;
    const CONTAINER_API_URL = addMirrorId(
      `${CALCULATOR_API_URL}${calcId}/container_cards`,
      'calculator'
    );
    const UI_ELEM_API_URL = addMirrorId(`${CALCULATOR_API_URL}${calcId}/ui_elements`, 'calculator');

    const container = await axios.get(CONTAINER_API_URL);
    const uiElements = await axios.get(UI_ELEM_API_URL);

    // add container and cards into one list
    let containerAndCardList = [...uiElements.data, ...container.data];

    // assign position to container for sorting purpose
    containerAndCardList.forEach((elem) => {
      // container element
      if ('ui_elements' in elem) {
        let min = Number.POSITIVE_INFINITY;
        elem.ui_elements.forEach((ui) => {
          if (min > ui.position) {
            min = ui.position;
          }
        });
        elem.position = min;
      }
    });

    // sort based on position
    containerAndCardList.sort(compareCardPosition);

    this.setState(
      (previous) => ({
        isLoading: false,

        draggableColumns: {
          ...previous.draggableColumns,
          // eslint-disable-next-line
          ['ConversationCard']: {
            items: containerAndCardList,
          },
        },
      }),
      // to update postion after deletion of card
      isDelete === true
        ? () => this.updatePosition(this.state.draggableColumns['ConversationCard'].items)
        : () => null
    );
  };
  handleSelectedItem = () => {
    this.setState({ selectedItem: null });
  };

  deleteUiElement = (id) => {
    // delete ui_element
    axios.delete(UI_ELEMENTS_API_URL + id + '/').then(() => {
      this.props.getFormulas(MODULE_TYPES.CALCULATOR, null, this.props.calcId);
      this.props.getTriggers(MODULE_TYPES.CALCULATOR, null, false, this.props.calcId);
      this.props.getInfoBoxes(MODULE_TYPES.CALCULATOR, null, this.props.calcId);
      this.props.getNoteGenerator(MODULE_TYPES.CALCULATOR, null, this.props.calcId);
      this.resetContainersAndUi(true);
    });
  };

  deleteContainer = (id) => {
    axios.delete(CONTAINER_CARD_API_URL + id + '/').then(() => {
      this.props.getTriggers(MODULE_TYPES.CALCULATOR, null, false, this.props.calcId);
      this.resetContainersAndUi(true);
    });
  };

  // update card position
  updatePosition = (uiElements) => {
    let position = 2;
    let payload = {};

    for (let i = 0; i < uiElements.length; i++) {
      // card item
      if ('resourcetype' in uiElements[i]) {
        uiElements[i].position = position;
        payload[uiElements[i].id] = position;
        position++;
      }
      // container item
      // eslint-disable-next-line
      else {
        // eslint-disable-next-line
        uiElements[i].ui_elements.forEach((element) => {
          element.position = position;
          payload[element.id] = position;
          position++;
        });
      }
    }

    this.savePosition(payload);

    // update items in state
    this.setState((previous) => ({
      draggableColumns: {
        // eslint-disable-next-line
        ...previous.draggableColumns,
        // eslint-disable-next-line
        ['ConversationCard']: {
          items: uiElements,
        },
      },
    }));
  };

  // send api request to save cards position in db when component unmount
  savePosition = (payload) => {
    axios.put(`${CALCULATOR_API_URL}${this.props.calcId}/ui_element_position/`, {
      positions: payload,
    });
  };

  setSelectedItem = (item) => {
    this.setState({ selectedItem: item });
  };
  toggleCreateContainerCard = (id) => {
    this.setState((previous) => ({
      createContainerCard: !previous.createContainerCard,
      containerId: '',
    }));
    if (typeof id !== 'object') {
      this.setState({
        containerId: id,
      });
    }
  };

  toggleUiElement = (modal, id, i) => {
    this.clearEditorStates();

    this.setState((previous) => ({
      [modal]: !previous[modal],
      uiElementId: id,
      selectedItem: i,
    }));

    if (i) {
      if (modal === 'multipleChoice') this.choiceReferences[i].handleEditMode();
      if (modal === 'numbers') this.valueReferences[i].handleEditMode();
      if (modal === 'checkboxes') this.multiChoiceReferences[i].handleEditMode();
    }
  };

  stopLivePreview = () => this.setState({ renderPreview: false });

  onDragEnd = (result, columns) => {
    if (!result.destination || result.destination.droppableId === 'ConversationItem') return;
    if (hasViewOnlyPermission('calculator')) {
      toast.error(CustomToast, { data: "You're not allowed to perform this action" });
      return;
    }

    const { source, destination } = result;
    // move from one container to another.
    if (source.droppableId !== destination.droppableId) {
      // conversation card shouldn't be move toward conversation items column
      if (source.droppableId === 'ConversationCard') return;

      this.setState({ draggedIndex: destination.index });
      if (
        result.draggableId !== 'createContainerCard' &&
        result.draggableId !== 'createAnswerPage'
      ) {
        const { newPreviewItem } = this.state;
        let tempObj = { ...newPreviewItem };

        switch (result.draggableId) {
          case 'numbers':
            tempObj.resourcetype = 'ValuePanel';
            break;
          case 'multipleChoice':
            tempObj.resourcetype = 'ChoicePanel';
            break;
          case 'message':
            tempObj.resourcetype = 'DescriptionPanel';
            tempObj.panel_type = 'B';
            break;
          case 'checkboxes':
            tempObj.resourcetype = 'MultiChoicePanel';
            break;
          default:
            break;
        }
        this.setState({ newPreviewItem: tempObj });
        const modArray = this.state.draggableColumns;
        modArray['ConversationCard'].items.splice(destination.index, 0, tempObj);
        this.setState({ draggableColumns: modArray });
      }

      // get moved item to open related form
      // const sourceColumn = columns[source.droppableId];
      const sourceItems = [...FILTERED_CHIPS];
      const [movedItem] = sourceItems.splice(source.index, 1);

      // Get the upper UI Element where the choice/multi_choice etc button is dropped
      const upperElemIndex = destination.index - 1;
      let uiElemCount = 0,
        noOfForms = 0;

      // count the all the group's ui_elements above given index
      for (let i = upperElemIndex; i >= 0; i--) {
        const upperElem = this.state.draggableColumns['ConversationCard'].items[i];

        // if upper element is form/group get the no of ui_elements the group have
        if (upperElem.hasOwnProperty('ui_elements')) {
          uiElemCount += upperElem['ui_elements'].length;
          noOfForms += 1;
        }
      }

      // if forms/groups found
      if (uiElemCount > 0) {
        // destination.index + 2
        // +1 because first element is always introduction
        // +1 because array index starts from zero and positions starts from 1
        // -noOfForms don't consider form card because we have already added
        // forms' individual ui_elements
        this.setState({
          questionPosition: destination.index + 2 + uiElemCount - noOfForms,
          [movedItem.id]: true,
        });
      } else {
        // destination.index + 2
        // +1 because first element is always introduction
        // +1 because array index starts from zero and positions starts from 1
        this.setState({
          questionPosition: destination.index + 2,
          [movedItem.id]: true,
        });
      }
    }

    // move inside a container (source and destination is same)
    else {
      // if any of conversation_item is dragged
      if (source.droppableId === 'ConversationItem') {
        // get moved items and update item position
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        // update items in state
        this.setState({
          draggableColumns: {
            ...columns,
            [source.droppableId]: {
              ...column,
              items: copiedItems,
            },
          },
        });
      }
      // if conversation card is dragged
      else {
        // if container or card (that is not associated with any container) is dragged
        if (source.droppableId === 'ConversationCard') {
          // get moved items and update item position
          const column = columns[source.droppableId];
          const copiedItems = [...column.items];
          const [removed] = copiedItems.splice(source.index, 1);
          copiedItems.splice(destination.index, 0, removed);

          // update position
          if (source.index !== destination.index) {
            this.updatePosition(copiedItems);
          }
        }
        // if card inside container is dragged
        else {
          const ui_elements = Array.from(
            this.state.draggableColumns['ConversationCard'].items[parseInt(result.type, 10)]
              .ui_elements
          );
          const [removed] = ui_elements.splice(source.index, 1);
          ui_elements.splice(destination.index, 0, removed);

          const containers = JSON.parse(
            JSON.stringify(this.state.draggableColumns['ConversationCard'].items)
          );

          containers[result.type].ui_elements = ui_elements;

          // update position
          if (source.index !== destination.index) {
            this.updatePosition(containers);
          }
        }
      }
    }
  };

  closeContainerPane = () => {
    this.setState((previous) => ({
      closeContainer: !previous.closeContainer,
    }));
  };

  closeUiElementPane = (element) => {
    this.setState((previous) => ({
      closeUiElement: !previous.closeUiElement,
      currentPane: element,
    }));
  };

  handleChange = (event, value) => {
    this.setState({ value: value });
  };

  clearEditorStates = () => {
    this.setState({
      renderPreview: false,
      description_text: '',
      longDescription: '',
      shortDescription: '',
      descriptionType: '',
      images: [],
      editorTitle: '',
      choices: [],
      question: '',
      examples: [],
      items: [],
      rationale: '',
      trigger: '',
      tools: [],
      showSubmit: false,
      variable: null,
    });
    const tempArray = this.state.draggableColumns;
    if (this.state.draggedIndex > -1 && this.state.renderPreview) {
      tempArray['ConversationCard'].items.splice(this.state.draggedIndex, 1);
      this.setState({ draggableColumns: tempArray });
    }
  };

  //live preview handlers
  handleAddTitle = (title) => {
    this.setState({ editorTitle: title });
  };

  handleAddChoices = (choice) => {
    this.setState({ choices: choice });
  };

  handleAddDescriptionType = (type) => {
    this.setState({ descriptionType: type });
  };

  handleShowSubmit = (bool) => {
    this.setState({ showSubmit: bool });
  };

  handleAddQuestion = (question) => {
    this.setState({ question: question });
  };

  handleLongDescription = (long) => {
    this.setState({ longDescription: long });
  };

  handleShortDescription = (short) => {
    this.setState({ shortDescription: short });
  };

  handleAddExample = (example) => {
    this.setState({ examples: example });
  };

  handleAddItems = (item) => {
    this.setState({ items: item });
  };

  handleAddRationale = (rationale) => {
    this.setState({ rationale: rationale });
  };

  startBackdrop = () => {
    this.setState({ isResoucesLoading: true });
  };

  stopBackdrop = () => {
    this.setState({ isResoucesLoading: false });
  };

  handleAddTrigger = (trigger) => {
    this.setState({ trigger: trigger });
  };

  handleAddDescription = (desc) => {
    this.setState({ description_text: desc });
  };

  handleAddSubtitle = (sub) => {
    this.setState({ subtitle: sub });
  };

  handleAddTools = (tools) => {
    this.setState({ tools: tools });
  };

  handleAddVariable = (variable) => this.setState({ variable });

  startPreview = () => this.setState({ renderPreview: true });

  duplicateUiElement = (id, queryParam) => {
    axios
      .post(`${UI_ELEMENT_DUPLICATE_API_URL}${id}?${queryParam}=true`, {
        calculator: this.props.teamCalculatorState?.calculator?.id,
      })
      .then(() => {
        this.props.getTriggers(MODULE_TYPES.CALCULATOR, null, false, this.props.calcId);
        this.resetContainersAndUi(true);
      });
  };

  handleLivePreview = (
    choices,
    question,
    examples,
    rationale,
    trigger,
    tools,

    renderPreview
  ) => {
    this.setState({
      choices: choices,
      question: question,
      examples: examples,
      rationale: rationale,
      trigger: trigger,
      tools: tools,
      renderPreview: renderPreview,
    });
  };

  render() {
    const columns = this.state.draggableColumns;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    // moveable ui_elements
    let uiElements = columns['ConversationCard'].items;
    let conversationCards = {};
    for (let i in uiElements) {
      // for choice panel
      if (uiElements[i].resourcetype === 'ChoicePanel') {
        conversationCards[uiElements[i].id] = (
          <ChoicePanel
            editMode={uiElements[i].editMode}
            ref={(ref) => (this.choiceReferences[i] = ref)}
            key={uiElements[i].id}
            elements={uiElements[i].question_items}
            panelId={uiElements[i].id}
            choices={uiElements[i].choices}
            question={uiElements[i].question}
            subtitle={uiElements[i].subtitle}
            trigger={uiElements[i].trigger}
            variable={this.state.variable || uiElements[i]?.variable_item?.name}
            editor={this.state.editor}
            containerCard={false}
            editorChoice={this.state.choices}
            editorQuestion={this.state.question}
            editorElements={this.state.examples.concat(this.state.tools)}
            editorSubtitle={this.state.subtitle}
            editorTrigger={
              this.state.trigger
                ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                : null
            }
            deleteCard={this.deleteUiElement}
            duplicateCard={this.duplicateUiElement}
            update={() => this.toggleUiElement('multipleChoice', uiElements[i].id, i)}
            type='calculator'
          />
        );
      }
      // for value panel
      else if (uiElements[i].resourcetype === 'ValuePanel') {
        conversationCards[uiElements[i].id] = (
          <ValuePanel
            editMode={uiElements[i].editMode}
            ref={(ref) => (this.valueReferences[i] = ref)}
            key={uiElements[i].id}
            deleteCard={this.deleteUiElement}
            panelId={uiElements[i].id}
            name={uiElements[i].name}
            items={uiElements[i].panel_item}
            trigger={uiElements[i].trigger}
            showNext={uiElements[i].is_submitable}
            editorShowNext={this.state.showSubmit}
            containerCard={false}
            editorItems={this.state.items}
            editorTitle={this.state.editorTitle}
            editorTrigger={
              this.state.trigger
                ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                : null
            }
            update={() => this.toggleUiElement('numbers', uiElements[i].id, i)}
            type='calculator'
          />
        );
      }
      // for DescriptionPanel
      //v2x
      else if (uiElements[i].resourcetype === 'DescriptionPanel') {
        conversationCards[uiElements[i].id] =
          uiElements[i].panel_type === 'B' ? (
            <DescriptionPanelBetaPreview
              editMode={uiElements[i].editMode}
              ref={(ref) => (this.descriptionv2References[i] = ref)}
              title={uiElements[i].title}
              long_description={uiElements[i].description_text_long}
              short_description={uiElements[i].description_text_short} // TODO: seems not used
              elements={uiElements[i].question_items}
              trigger={uiElements[i].trigger}
              containerCard={false}
              deleteCard={this.deleteUiElement}
              panelId={uiElements[i].id}
              status={uiElements[i].status}
              action={uiElements[i].is_action}
              update={() => this.toggleUiElement('createDescriptionBetaPanel', uiElements[i].id, i)}
              editorLongDesc={this.state.longDescription}
              editorShortDesc={this.state.shortDescription}
              editorTitle={this.state.editorTitle}
              editorType={this.state.descriptionType}
              editorAction={this.state.descriptionType === 'action' ? true : false}
              editorElements={this.state.examples.concat(this.state.tools)}
              type='calculator'
              editorTrigger={
                this.state.trigger
                  ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                  : null
              }
            />
          ) : (
            <DescriptionPanelPreview
              type='calculator'
              ref={(ref) => (this.descriptionReferences[i] = ref)}
              title={uiElements[i].title}
              description={uiElements[i].description_text}
              elements={uiElements[i].question_items}
              trigger={uiElements[i].trigger}
              containerCard={false}
              deleteCard={this.deleteUiElement}
              panelId={uiElements[i].id}
              action={uiElements[i].is_action}
              update={() => this.toggleUiElement('createDescriptionPanel', uiElements[i].id, i)}
              editorTitle={this.state.editorTitle}
              editorDescription={this.state.description_text}
              editorType={this.state.descriptionType}
              editorAction={this.state.descriptionType === 'action' ? true : false}
              editorElements={this.state.examples.concat(this.state.tools)}
              editorTrigger={
                this.state.trigger
                  ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                  : null
              }
            />
          );
      }
      // for MultiChoicePanel
      else if (uiElements[i].resourcetype === 'MultiChoicePanel') {
        conversationCards[uiElements[i].id] = (
          <MultiChoice
            type='calculator'
            editMode={uiElements[i].editMode}
            ref={(ref) => (this.multiChoiceReferences[i] = ref)}
            key={uiElements[i].id}
            elements={uiElements[i].question_items}
            subtitle={uiElements[i].subtitle}
            update={() => this.toggleUiElement('checkboxes', uiElements[i].id, i)}
            deleteCard={this.deleteUiElement}
            question={uiElements[i].question}
            variable={this.state.variable || uiElements[i]?.variable_item?.name}
            choices={uiElements[i].choices}
            panelId={uiElements[i].id}
            trigger={uiElements[i].trigger}
            containerCard={false}
            editorShowSubmit={this.state.showSubmit}
            showSubmit={uiElements[i]?.is_submissable}
            editorChoice={this.state.choices}
            editorQuestion={this.state.question}
            editorElements={this.state.examples.concat(this.state.tools)}
            editorSubtitle={this.state.subtitle}
            duplicateCard={this.duplicateUiElement}
            editorTrigger={
              this.state.trigger
                ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                : null
            }
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className='mt-[51px] p-8 pt-0' style={{ minHeight: '90vh' }}>
          <div>
            {this.state.isLoading ? (
              <div className='offset-md-6 col-md-1'>
                <CircularProgress id='spinner' />
              </div>
            ) : (
              <DragDropContext onDragEnd={(result) => this.onDragEnd(result, columns)}>
                {!hasViewOnlyPermission('calculator') && <DraggableChips />}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                    overflowX: 'visible',
                  }}
                  className='flow-cards-calc'
                  key={'ConversationCard'}
                >
                  <Droppable droppableId={'ConversationCard'} key={'ConversationCard'}>
                    {(provided, snapshot) => {
                      return (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            minWidth: '300px',
                            maxWidth: '900px',
                            overflow: 'visible',
                            height: '100%',
                            paddingBottom: 70,
                          }}
                        >
                          {columns['ConversationCard'].items.map((item, index) => {
                            return (
                              <Draggable
                                key={
                                  item.editMode
                                    ? item.id
                                    : 'resourcetype' in item
                                    ? item.id
                                    : 'container_' + item.id.toString()
                                }
                                draggableId={
                                  item.editMode
                                    ? item.id
                                    : 'resourcetype' in item
                                    ? item.id.toString()
                                    : 'container_' + item.id.toString()
                                }
                                index={index}
                                isDragDisabled={hasViewOnlyPermission('calculator')}
                              >
                                {(provided, snapshot) => {
                                  return 'resourcetype' in item ? (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {conversationCards[item.id]}
                                    </div>
                                  ) : (
                                    <div
                                      className='container-row'
                                      style={{
                                        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
                                        marginLeft: '50px',
                                      }}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <Typography
                                        component={'div'}
                                        color='textPrimary'
                                        style={{
                                          padding: '10px',
                                          fontStyle: 'normal',
                                          fontWeight: 'bold',
                                          fontSize: '13px',
                                          lineHeight: '18px',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {item.trigger ? (
                                          <Chip
                                            style={{
                                              backgroundColor: '#08A88E',
                                              color: 'white',
                                              marginLeft: '22px',
                                            }}
                                            size='small'
                                            label={item.trigger.title || 'Title is not assigned'}
                                          />
                                        ) : (
                                          <Chip
                                            style={{
                                              backgroundColor: 'red',
                                              color: 'white',
                                              marginLeft: '22px',
                                            }}
                                            size='small'
                                            label='Not Assigned'
                                          />
                                        )}
                                      </Typography>

                                      <div className='container-card col-md-12'>
                                        <div className='card-content text-justify'>
                                          <ContainerCards
                                            deleteContainer={this.deleteContainer}
                                            stopLivePreview={this.stopLivePreview}
                                            handleLivePreview={this.handleLivePreview}
                                            handleAddChoices={this.handleAddChoices}
                                            handleAddQuestion={this.handleAddQuestion}
                                            handleAddExample={this.handleAddExample}
                                            handleAddRationale={this.handleAddRationale}
                                            handleAddTools={this.handleAddTools}
                                            handleAddTrigger={this.handleAddTrigger}
                                            toggleUiElement={this.toggleUiElement}
                                            resetState={this.resetContainersAndUi}
                                            containerNum={index}
                                            container={item}
                                            handleSelectedItem={this.handleSelectedItem}
                                            containerCardId={this.state.containerId}
                                            calculatorId={this.props.calcId}
                                            deleteUiElement={this.deleteUiElement}
                                            resetContainersAndUi={this.resetContainersAndUi}
                                            toggleCreateContainerCard={
                                              this.toggleCreateContainerCard
                                            }
                                            handleShowNext={this.handleShowSubmit}
                                            startBackdrop={this.startBackdrop}
                                            stopBackdrop={this.stopBackdrop}
                                            handleShowSubmit={this.handleShowSubmit}
                                            startPreview={this.startPreview}
                                            handleAddTitle={this.handleAddTitle}
                                            handleAddDescription={this.handleAddDescription}
                                            handleAddDescriptionType={this.handleAddDescriptionType}
                                            handleAddItems={this.handleAddItems}
                                            selectedItem={this.state.selectedItem}
                                            setSelectedItem={this.setSelectedItem}
                                            uiElemCount={Object.keys(conversationCards).length + 2}
                                            resetAnswerPages={this.resetAnswerPages}
                                            editorLongDesc={this.state.longDescription}
                                            editorShortDesc={this.state.shortDescription}
                                            editorShowSubmit={this.state.showSubmit}
                                            editorChoice={this.state.choices}
                                            editorQuestion={this.state.question}
                                            editorElements={this.state.examples.concat(
                                              this.state.tools
                                            )}
                                            editorRationale={this.state.rationale}
                                            editorSDJson={this.state.shortDescriptionJson}
                                            editorLDJson={this.state.longDescriptionJson}
                                            isLongDescEnabledEdit={this.state.isLongDescEnabled}
                                            editorTitle={this.state.editorTitle}
                                            editorType={this.state.descriptionType}
                                            editorItems={this.state.items}
                                            editorShowNext={this.state.showSubmit}
                                            duplicateCard={this.duplicateUiElement}
                                            editorAction={
                                              this.state.descriptionType === 'action' ? true : false
                                            }
                                            editorTrigger={
                                              this.state.trigger
                                                ? triggers.find(
                                                    (e) =>
                                                      e.id.toString() ===
                                                      this.state.trigger.toString()
                                                  )
                                                : null
                                            }
                                            editorSubtitle={this.state.subtitle}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Box>
                      );
                    }}
                  </Droppable>
                </div>
              </DragDropContext>
            )}
          </div>
          <br />
          <Divider />
          <br />
        </div>
        {this.state.isResoucesLoading && (
          <Backdrop>
            <LoadingSpinner />
          </Backdrop>
        )}
        <SlidingPane
          isOpen={this.state.multipleChoice}
          onRequestClose={() => this.closeUiElementPane('multipleChoice')}
          from='right'
          className='no-padding sliding-panel-shadow'
          width='620px'
          hideHeader
        >
          <div style={{ backgroundColor: 'white', overflowX: 'visible' }}>
            <ChoicePanelForm
              handleSelectedItem={this.handleSelectedItem}
              handleLivePreview={this.handleLivePreview}
              handleAddChoices={this.handleAddChoices}
              handleAddQuestion={this.handleAddQuestion}
              handleAddExample={this.handleAddExample}
              handleAddSubtitle={this.handleAddSubtitle}
              handleAddTools={this.handleAddTools}
              handleAddVariable={this.handleAddVariable}
              handleAddTrigger={this.handleAddTrigger}
              calculatorId={this.props.calcId}
              position={this.state.questionPosition}
              toggleModal={() =>
                this.toggleUiElement(
                  'multipleChoice',
                  null,
                  this.state.selectedItem ? this.state.selectedItem : null
                )
              }
              choiceId={this.state.uiElementId}
              resetState={this.resetContainersAndUi}
              resetAnswerPages={this.resetAnswerPages}
              modal={this.state.closeUiElement}
              closeUiElementPane={this.closeUiElementPane}
            />
          </div>
          {/* </Drawer> */}
        </SlidingPane>
        {/* Sliding Panel for Multi choice Panel Form */}
        <SlidingPane
          isOpen={this.state.checkboxes}
          onRequestClose={() => this.closeUiElementPane('checkboxes')}
          from='right'
          className='no-padding sliding-panel-shadow'
          width='620px'
          hideHeader
        >
          <div style={{ backgroundColor: 'white', overflowX: 'visible' }}>
            <MultiChoiceForm
              handleSelectedItem={this.handleSelectedItem}
              handleAddChoices={this.handleAddChoices}
              handleShowSubmit={this.handleShowSubmit}
              startPreview={this.startPreview}
              handleAddQuestion={this.handleAddQuestion}
              handleAddExample={this.handleAddExample}
              handleAddSubtitle={this.handleAddSubtitle}
              handleAddTools={this.handleAddTools}
              handleAddVariable={this.handleAddVariable}
              handleAddTrigger={this.handleAddTrigger}
              calculatorId={this.props.calcId}
              position={this.state.questionPosition}
              toggleModal={() =>
                this.toggleUiElement(
                  'checkboxes',
                  null,
                  this.state.selectedItem ? this.state.selectedItem : null
                )
              }
              multiChoicePanelId={this.state.uiElementId}
              resetState={this.resetContainersAndUi}
              modal={this.state.closeUiElement}
              closeUiElementPane={this.closeUiElementPane}
            />
          </div>
        </SlidingPane>
        {/* Sliding Panel for Value Panel Form */}
        <SlidingPane
          isOpen={this.state.numbers}
          onRequestClose={() => this.closeUiElementPane('numbers')}
          from='right'
          className='no-padding sliding-panel-shadow'
          hideHeader
          width='620px'
        >
          <ValuePanelForm
            handleSelectedItem={this.handleSelectedItem}
            startBackdrop={this.startBackdrop}
            stopBackdrop={this.stopBackdrop}
            startPreview={this.startPreview}
            handleAddTitle={this.handleAddTitle}
            handleAddTrigger={this.handleAddTrigger}
            handleAddItems={this.handleAddItems}
            calculatorId={this.props.calcId}
            position={this.state.questionPosition}
            toggleModal={() =>
              this.toggleUiElement(
                'numbers',
                null,
                this.state.selectedItem ? this.state.selectedItem : null
              )
            }
            valuePanelId={this.state.uiElementId}
            resetState={this.resetContainersAndUi}
            handleShowNext={this.handleShowSubmit}
            modal={this.state.closeUiElement}
            closeUiElementPane={this.closeUiElementPane}
          />
        </SlidingPane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  triggerState: state.triggerState,
  teamCalculatorState: state.teamCalculatorState,
});

export default connect(mapStateToProps, {
  getTriggers,
  getInfoBoxes,
  getNoteGenerator,
  getFormulas,
})(Flow);
