import { DocumentType, MarkType, NodeType, NodeMapping } from '@tiptap/draftjs-to-tiptap';
import { DraftInlineStyleType, RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { SuggestionTypeEnum } from '../tiptapInterfaces';

// In the existing implementation, a node with type some types cannot be matched,
// so the type and function have been redefined.
// https://github.com/ueberdosis/draft-js-to-tiptap/blob/main/src/utils.ts#L109
export interface AvoNodeMapping extends NodeMapping {
  mention: NodeType<'mention', Record<string, any>, MarkType, never>;
  image: NodeType<
    'image',
    {
      src: string;
      alt?: string;
      title: string | null;
      width: number;
      height: number;
      float: 'left' | 'center' | 'right';
    }
  >;
  linkPreview: NodeType<'linkPreview', Record<string, any>, MarkType, never>;
}

export type ExpandedDraftInlineStyleType = DraftInlineStyleType | 'HEADER';

export interface Options {
  /**
   * The Draft.js block to render.
   */
  block: RawDraftContentBlock;
  /**
   * The entity map of the Draft.js content.
   */
  entityMap: RawDraftContentState['entityMap'];
  /**
   * The current document tree
   */
  doc: DocumentType;
}

export enum MentionType {
  multi_variable = 'multi_variable',
  module = 'module',
  embedded = 'embedded',
  unembedded = 'unembedded',
  calculator = 'calculator',
  knowledge_base = 'knowledge_base',
  gptbox_variable = 'gptbox_variable',
  progress_note = 'progress_note', // @deprecated into 'note'
  note = 'note',
  lab_data = 'lab_data',
  vital_sign = 'vital_sign',
  medication = 'medication',
  text_input_variable = 'text_input_variable',
  ambient_variable = 'ambient_variable',
  message_variable = 'message_variable',
  apibox = 'apibox_variable',
  ehr_order = 'ehr_order',
  data_extractor = SuggestionTypeEnum.DATA_EXTRACTOR,
}

export type RoundingMethod = 'ceil' | 'round' | 'floor';
export interface MentionData {
  name: string;
  decimalPlaces: number;
  decimalRounding: RoundingMethod;
  code: string;
  type?: MentionType;
  id?: number;
  aliasName?: string;
}
