import { useMutation } from '@tanstack/react-query';
import setTeam from 'actions/team/selectedTeamAction';
import { teamAPI } from 'api/team';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import CircleButton from 'components/utils/RadioButton';
import SectionCard from 'components/utils/SectionCard';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import RequiredMark from 'components/utils/requiredMark';
import { CustomToast } from 'components/utils/toast-message';
import { Body2 } from 'components/utils/typo';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { HOME_ROUTE } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';
export enum SortingMethod {
  RELEVANT = 'RELEVANT',
  ALPHABETICAL = 'ALPHABETICAL',
}

interface TeamSettingFormVariable {
  name: string;
  display_name: string;
  icon: FileList | string | null;
  default_sorting: SortingMethod;
  ambient_listening_enabled: boolean;
  askavo_link_enabled: boolean;
}

interface TeamSettingFormProps {
  isEditing?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const TeamSettingForm = ({ onSuccess, onCancel, isEditing }: TeamSettingFormProps) => {
  const { authentication } = useAuthentication();
  const { team } = useTeam();
  const dispatch = useDispatch();

  const history = useHistory();
  const defaultValues = useMemo<TeamSettingFormVariable>(
    () => ({
      name: (isEditing && team.name) || '',
      display_name: (isEditing && (team.display_name || team.name)) || '',
      default_sorting: (isEditing && team.default_sorting) || SortingMethod.RELEVANT,
      icon: (isEditing && team.icon) || null,
      ambient_listening_enabled: (isEditing && team.ambient_listening_enabled) || false,
      askavo_link_enabled: (isEditing && team.askavo_link_enabled) || false,
    }),
    [team, isEditing]
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const initialRender = useRef(true);
  const [iconPreview, setIconPreview] = useState(isEditing ? team?.icon : null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { isDirty },
    reset,
  } = useForm<TeamSettingFormVariable>({
    defaultValues,
  });
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { default_sorting, name, display_name, ambient_listening_enabled, askavo_link_enabled } =
    watch();

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setValue('display_name', name);
  }, [name]);

  useEffect(() => {
    if (!default_sorting) setValue('default_sorting', SortingMethod.RELEVANT);
  }, [default_sorting]);

  const { refetchTeamList, refetchTeam } = useTeam();
  const { mutate, isLoading: isMutating } = useMutation(
    !isEditing
      ? teamAPI.postTeam
      : (data: FormData | TeamSettingFormVariable) => teamAPI.putTeam(data, team?.id),
    {
      onSuccess: async () => {
        onSuccess?.();
        await Promise.all([refetchTeamList(), refetchTeam()]).then(() => dispatch(setTeam()));
        toast.success(CustomToast, {
          data: 'Team successfully' + isEditing ? 'Updated' : 'Created',
        });
        if (!isEditing) history.push(HOME_ROUTE);
      },
    }
  );

  const onSubmit = (data: TeamSettingFormVariable) => {
    if (isMutating) return;

    const formData = new FormData();
    if (data.icon) {
      if (data.icon[0] instanceof File) formData.append('icon', data.icon[0]);
      formData.append('name', data.name);
      formData.append('display_name', data.display_name);
      formData.append('default_sorting', data.default_sorting);
      formData.append('ambient_listening_enabled', data.ambient_listening_enabled + '');
      formData.append('askavo_link_enabled', data.askavo_link_enabled + '');

      mutate(formData);
    } else {
      mutate(data);
    }
  };

  const { mutate: deleteTeam, isLoading: isDeleting } = useMutation(
    () => teamAPI.deleteTeam(team?.id),
    {
      onSuccess: async () => {
        onSuccess?.();
        await Promise.all([refetchTeamList(), refetchTeam()]).then(() => dispatch(setTeam()));
        toast.success(CustomToast, { data: 'Team successfully Deleted' });
      },
    }
  );

  const setPreview = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setIconPreview(null);
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setIconPreview(URL.createObjectURL(file));
    };
    reader.readAsDataURL(file);
  };

  const clearIcon = () => {
    setValue('icon', null, { shouldDirty: true });
    setIconPreview(null);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionCard>
          <div className='max-w-[600px] space-y-4'>
            <Label className='w-full'>
              <div className='flex items-center justify-between'>
                <div className={`flex space-x-1 ${!isEditing ? 'mb-3' : ''}`}>
                  <Body2 className='text-gray-700'>Team Name</Body2>
                  <RequiredMark />
                </div>
                {!!authentication.user?.is_staff && isEditing && (
                  <div
                    className='mb-3 cursor-text select-text rounded bg-gray-200 px-2 py-1 text-sm text-gray-500'
                    onClick={(e) => e.preventDefault()}
                  >
                    {team?.unique_code}
                  </div>
                )}
              </div>
              <Input
                {...register('name', {
                  required: true,
                  maxLength: 255,
                })}
                maxLength={255}
                required
              />
              <div className='text-right text-[12px]'>{name.length}/255 characters</div>
            </Label>
            <Label className='block w-full space-y-2'>
              <div className='flex space-x-1'>
                <Body2 className='text-gray-700'>Display Name</Body2>
              </div>
              <Input
                {...register('display_name', {
                  maxLength: 255,
                })}
                maxLength={255}
              />
              <div className='text-right text-[12px]'>{display_name.length}/255 characters</div>
            </Label>
            <div className='space-y-2 pb-4'>
              <Body2 className='text-gray-700'>Default Module List Sorting</Body2>
              <div className='flex space-x-8'>
                <Label className='flex items-center space-x-2'>
                  {default_sorting === SortingMethod.RELEVANT ? (
                    <CircleButton.Selected />
                  ) : (
                    <CircleButton />
                  )}
                  <span>By relevance score</span>
                  <input
                    type='radio'
                    {...register('default_sorting')}
                    value={SortingMethod.RELEVANT}
                    className='hidden'
                  />
                </Label>
                <Label className='flex items-center space-x-2'>
                  {default_sorting === SortingMethod.ALPHABETICAL ? (
                    <CircleButton.Selected />
                  ) : (
                    <CircleButton />
                  )}
                  <span>By alphabetical</span>
                  <input
                    type='radio'
                    {...register('default_sorting')}
                    value={SortingMethod.ALPHABETICAL}
                    className='hidden'
                  />
                </Label>
              </div>
            </div>
            <div className='space-y-2 pb-4'>
              <Body2 className='text-gray-700'>Team Icon</Body2>
              {iconPreview ? (
                <div className='flex items-end space-x-4'>
                  <img
                    src={iconPreview + ''}
                    alt={'author preview'}
                    className='h-[60px] w-[60px] overflow-hidden rounded'
                  />
                  <div className='cursor-pointer font-bold text-primary-600' onClick={clearIcon}>
                    Delete
                  </div>
                </div>
              ) : (
                <Label className='block space-y-3'>
                  <div className='flex'>
                    <div>
                      <Button.Outline
                        className='py-2 font-bold'
                        type='button'
                        onClick={() => fileInputRef.current?.click()}
                      >
                        Choose File
                      </Button.Outline>
                    </div>
                  </div>
                  <span className='block text-sm text-gray-700'>
                    *png, jpeg format allowed, image size 00*00 recommended
                  </span>
                  <Controller
                    name='icon'
                    control={control}
                    render={({ field }) => (
                      <input
                        ref={fileInputRef}
                        type='file'
                        className='hidden'
                        accept='image/*'
                        onChange={(e) => {
                          setPreview(e);
                          field.onChange(e.target.files);
                        }}
                      />
                    )}
                  />
                </Label>
              )}
            </div>
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <Label className='flex items-center justify-between text-gray-900'>
                <span>Use Ambient Listening</span>
                <AvoSwitch
                  checked={ambient_listening_enabled}
                  onChange={(check) =>
                    setValue('ambient_listening_enabled', check, { shouldDirty: true })
                  }
                />
              </Label>
            </StaffOnlyWrapper>
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <Label className='flex items-center justify-between text-gray-900'>
                <span>Enable Ask Avo</span>
                <AvoSwitch
                  checked={askavo_link_enabled}
                  onChange={(check) =>
                    setValue('askavo_link_enabled', check, { shouldDirty: true })
                  }
                />
              </Label>
            </StaffOnlyWrapper>

            {isEditing && (
              <div className='flex pt-4'>
                <div>
                  <Button.Outline
                    className='!border-red-500 py-2 font-bold text-red-500 hover:text-red-600'
                    type='button'
                    disabled={isMutating || isDeleting}
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    {isDeleting ? <LoadingSpinner /> : 'Delete Team'}
                  </Button.Outline>
                </div>
              </div>
            )}
          </div>
        </SectionCard>
        <div className='mt-[28px] flex justify-end space-x-2'>
          {onCancel && (
            <div>
              <Button.Reverse className='py-2 font-bold' type='button' onClick={onCancel}>
                Cancel
              </Button.Reverse>
            </div>
          )}
          <div>
            <Button
              className='border-gray flex h-[40px] !w-[100px] items-center justify-center border-2 border-solid px-4 py-2 font-bold'
              disabled={!isDirty || isMutating || isDeleting}
            >
              {isMutating ? <LoadingSpinner /> : 'Save'}
            </Button>
          </div>
        </div>
      </form>
      <ConfirmModal
        preset='delete'
        open={openDeleteModal}
        content='Are you sure you want to delete this team?'
        toggleModal={() => setOpenDeleteModal(false)}
        performAction={() => (setOpenDeleteModal(false), deleteTeam())}
      />
    </>
  );
};

export default TeamSettingForm;
