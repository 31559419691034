import {
  type DraftJSContent,
  mapBlockToNode,
  mapEntityToMark,
  mapEntityToNode,
  mapInlineStyleToMark,
} from '@tiptap/draftjs-to-tiptap';
import { AvoDraftConverter } from './AvoDraftConverter';
import { decorateListAttributes, removeEmptyMarks } from './utils';
import { mapAvoEntityToNode } from './mappings/avoEntityToNode';
import './redeclartion';
import { toast } from 'react-toastify';
import { CustomToast } from '../../toast-message';

/**
 * Use this to test out your custom mappings.
 * This function is called by the JSONEntry component in the visualizer.
 * So, you can test your custom mappings by pasting Draft.js JSON into the JSONEntry component.
 */
export function convertFromJSON(json: DraftJSContent) {
  const converter = new AvoDraftConverter({
    /**
     * You have full control on how to map a Draft.js block to a ProseMirror node.
     * @returns null if the block was not mapped, undefined or the new node if it was mapped
     */
    mapBlockToNode(context) {
      // Use the default mapping for Draft.js built-in block types
      const defaultMapping = mapBlockToNode(context);
      if (defaultMapping) {
        if ('content' in defaultMapping) {
          removeEmptyMarks(defaultMapping);
          decorateListAttributes(defaultMapping);
        }
        return defaultMapping;
      }

      // If nothing was matched, return null to add to the unmatched.blocks list
      return null;
    },
    /**
     * You have full control on how to map a Draft.js entity to a ProseMirror mark.
     * @returns null if the entity was not mapped, undefined or the new mark if it was mapped
     */
    mapEntityToMark(context) {
      const defaultMapping = mapEntityToMark(context);
      if (defaultMapping) {
        return defaultMapping;
      }

      return null;
    },
    /**
     * You have full control on how to map a Draft.js entity to a ProseMirror node.
     * @returns null if the entity was not mapped, undefined or the new node if it was mapped
     */
    mapEntityToNode(context) {
      // Sometimes, you may want to map an entity to a node instead of a mark.
      const defaultMapping = mapEntityToNode(context);
      if (defaultMapping) {
        return defaultMapping;
      }

      const mentionMapping = mapAvoEntityToNode(context);
      if (mentionMapping) {
        return mentionMapping;
      }

      return null;
    },
    /**
     * You have full control on how to map a Draft.js inline style to a ProseMirror mark.
     * @returns null if the inline style was not mapped, undefined or the new mark if it was mapped
     */
    mapInlineStyleToMark(context) {
      const defaultMapping = mapInlineStyleToMark(context);
      if (defaultMapping) {
        return defaultMapping;
      }
      return null;
    },
  });

  const document = converter.convert(json);
  const { blocks, entities, inlineStyles } = converter.unmatched;
  if (blocks.length > 0 || Object.keys(entities).length > 0 || inlineStyles.length > 0) {
    toast.error(CustomToast, {
      data: 'There are some unmatched content after converting',
    });
    console.log(converter.unmatched);
  }
  return {
    document: document,
    unmatched: converter.unmatched,
  };
}
