import axios from 'axios';
import { ResetPasswordFormVariable } from 'components/accounts/forgot-password/ForgotPassword';
import {
  PASSWORD_CHANGE_API_V2_URL,
  PASSWORD_RESET_LINK_API_URL,
  USER_API_URL,
  USER_INFO_API_URL,
} from '../constants';

export interface UserInfo {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  institution: string;
  medical_specialties: string[];
  role: string[];
  subscribed_to_newsletter: boolean;
  profile_picture?: string;
  is_staff: boolean;
  job_title: string;
}

interface PasswordRequest {
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

export const profileApi = {
  getProfile: (): Promise<UserInfo> =>
    axios.get<UserInfo>(USER_INFO_API_URL).then((response) => response.data),
  putProfile: (request: FormData) => axios.put(USER_API_URL, request),
  putPassword: (data: PasswordRequest) => axios.put(PASSWORD_CHANGE_API_V2_URL, data),
  resetPassword: (data: ResetPasswordFormVariable) => axios.post(PASSWORD_RESET_LINK_API_URL, data),
};
