import { Menu } from '@headlessui/react';
import { AlgoMoreIcon } from 'assets/icons/algoCardIcons';
import clsx from 'clsx';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Body2, H4 } from 'components/utils/typo';
import { useState } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { VariablesPreview } from './common/variable';

interface AmbientListeningProps {
  editMode: any;
  container?: any;
  deleteCard: (panelId?: any) => void;
  panelId: any;
  title: string;
  subtitle: string;
  variable?: string;
  trigger: any;
  update: () => void;
  type?: string;
}

const AmbientListening = ({
  title,
  subtitle,
  trigger,
  update,
  variable,
  editMode,
  container,
  panelId,
  deleteCard,
  type,
}: AmbientListeningProps) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const variables = [
    {
      type: 'Record',
      name: variable,
    },
  ];

  return (
    <div
      data-testid='ambient_listening__test'
      className={clsx(
        '!mb-4 max-h-fit',
        editMode ? 'relative z-[13]' : '',
        container ? 'w-full' : 'max-w-[580px]'
      )}
    >
      <div
        className={clsx(
          'item-to-hover max-h-fit !rounded-xl !border-2 border-none !p-4 shadow-06 hover:!bg-primary-200',
          container
            ? 'w-full !border-none !bg-transparent !shadow-none'
            : '!bg-hasViewOnlyPerhasViewOnlyPermissionmissionwhite max-w-[580px] !bg-opacity-90',
          editMode ? 'dark:border-08A88E  !border-primary-600' : ''
        )}
        onDoubleClick={!hasViewOnlyPermission(type) ? update : undefined}
      >
        <div className='!m-0 !p-0'>
          <div>
            <div className='right item-to-show'>
              <Menu>
                {!hasViewOnlyPermission(type) && (
                  <Menu.Button>
                    <AlgoMoreIcon />
                  </Menu.Button>
                )}
                <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
                  <Menu.Item
                    className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                    as='p'
                    onClick={update}
                  >
                    <Body2>Edit</Body2>
                  </Menu.Item>
                  <Menu.Item
                    as='p'
                    className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                    onClick={() => setDeleteModal((prev) => !prev)}
                  >
                    <Body2>Delete</Body2>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>

            {!container && (
              <div className='flex flex-row items-center'>
                <span className='max-w-fit rounded-sm bg-green-500 px-2 py-[4px] text-caption-2 text-white'>
                  {trigger
                    ? container
                      ? trigger.title !== 'Always On' && (trigger.title || 'Title is not assigned')
                      : trigger.title || 'Title is not assigned'
                    : 'Not Assigned'}
                </span>
              </div>
            )}
            <H4 className='my-2'>{title}</H4>
            <Body2 className='text-gray-900'>{subtitle}</Body2>
          </div>
        </div>
      </div>

      {!container && <VariablesPreview variables={variables} />}

      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this card?'
        toggleModal={() => setDeleteModal((prev) => !prev)}
        performAction={() => {
          deleteCard(panelId);
          setDeleteModal((prev) => !prev);
        }}
      />
    </div>
  );
};

export default AmbientListening;
