import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import DropdownArrowIcon from 'assets/icons/tiptap/arrow';
import clsx from 'clsx';
import React, { Fragment, ReactNode, useMemo } from 'react';
import { MENTION_TYPES } from '../../../constants';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from 'components/utils/StaffOnlyWrapper';
import {
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../hooks/useEHRVariables';

interface OptionType {
  id: number | string;
  title: ReactNode;
}

interface DropdownSingleProps {
  placeholder: string;
  options: OptionType[];
  value: number | string;
  onChange: (value: number | string) => void;
}

const DropdownSingle = ({ value, options, onChange, placeholder }: DropdownSingleProps) => {
  const option = useMemo(() => options.find((option) => option.id === value), [options, value]);

  return (
    <Listbox as='div' className='relative mb-2 w-full' onChange={onChange} value={value}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={clsx(
              'focus:bg-transparent', // due to material ui
              'flex w-full items-center justify-between rounded !border border-solid border-gray-300 !px-3 py-2.5'
            )}
          >
            <div
              className={clsx(
                'truncate text-body-2',
                option?.title ? 'text-gray-900' : 'text-gray-300'
              )}
            >
              {option?.title || placeholder}
            </div>
            <div className={clsx('transition-all', { 'rotate-180': open })}>
              <DropdownArrowIcon />
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <Listbox.Options className='absolute z-10 max-h-72 w-full overflow-y-auto rounded-b border-gray-300 bg-white shadow-04'>
              {options.map((option) =>
                StaffOnlyWrapIf({
                  condition: option.id === MENTION_TYPES.MESSAGE_VARIABLE,
                  options: {
                    type: StaffOnlyBadgeType.BETA,
                  },
                  element: (
                    <Listbox.Option
                      key={option.id}
                      value={option.id}
                      className={({ active }) =>
                        clsx('cursor-pointer !px-3 !py-2.5', {
                          'bg-primary-200': active,
                        })
                      }
                    >
                      {({ selected }) => (
                        <>
                          {StaffOnlyWrapIf({
                            condition:
                              typeof option.id === 'string' &&
                              [VITAL_SIGN_TYPE, MEDICATION_TYPE, PROBLEM_LIST_TYPE].includes(
                                option.id
                              ),
                            options: { y: -5, type: StaffOnlyBadgeType.BETA },
                            element: (
                              <div className='flex justify-between'>
                                <div className='truncate text-gray-900'>{option.title}</div>
                                {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                              </div>
                            ),
                          })}
                        </>
                      )}
                    </Listbox.Option>
                  ),
                })
              )}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
};

export default DropdownSingle;
