export const formatJson = (jsonString: string, indent = 2) => {
  try {
    const parsedJson = JSON.parse(jsonString);
    return JSON.stringify(parsedJson, null, indent);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return jsonString;
  }
};

export const generateOptions = (obj: object, parentKey = '') => {
  let options: any[] = [];

  for (const key in obj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      options.push({
        label: key,
        value: currentKey,
        children: generateOptions(obj[key], currentKey),
      });
    } else {
      options.push({ label: key, value: currentKey });
    }
  }

  return options;
};

export const flattenOptions = (options) => {
  let flattened: any[] = [];
  options.forEach((option) => {
    flattened.push(option);
    if (option.children) {
      flattened = flattened.concat(flattenOptions(option.children));
    }
  });
  return flattened;
};
