import { Box, FormControlLabel } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import FormulaForm from 'components/resources/formula-board/formulaForm';
import { AvoEditorTextInput } from 'components/utils/avoeditor/AvoEditorTextInput';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import ButtonProgress from 'components/utils/button-progress';
import InputField from 'components/utils/form-input/field';
import { compareCardPosition } from 'components/utils/general/compareCardPosition';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import 'components/utils/modals/modal.css';
import {
  CreateButton,
  PanelLabel2,
  StyledFormGroupMB16,
  StyledInputWithButton3,
} from 'components/utils/styled-components/FormStyle';
import { SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { Component } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { hasViewOnlyPermission } from 'utils/permissions';
import { addMirrorId, convertCodeToText } from 'utils/utilityFunctions';
import { CALCULATOR_API_URL, MODULE_TYPES, OUTPUT_API_URL } from '../../../constants';
import {
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../hooks/useEHRVariables';
import ConditionBuilder from '../sections/ConditionBuilder';
import OutputCategory from '../sections/OutputCategory';
import { OutputPanelProps } from '../types';
import {
  IOSSwitch,
  OutputTypeButton,
  StyledButton,
  StyledEditButton,
  StyledExpandIcon,
  StyledSelect,
} from './outputStyles';

interface OutputPanelState {
  id: any;
  name: any;
  isConditionMandatory: boolean;
  outputValue: any;
  outputValueObj: any;
  outPutValButton: any;
  formulaModal: boolean;
  editMode: boolean;
  isSaving: boolean;
  isFetching: boolean;

  condition: any;
  condition_logic: any;
  new_condition_json: any;
  prev_cond_content: any;

  conditionModal: boolean;
  warningModal: boolean;
  builderCondition: any;
  readableCondition: any;
  condition_json_tiptap: any;
  builder_condition_json: any;

  outputCategories: any[];
}

class OutputPanel extends Component<OutputPanelProps, OutputPanelState> {
  constructor(props: OutputPanelProps) {
    super(props);

    this.state = {
      id: null,
      name: 'Default Output',
      isConditionMandatory: false,
      outputValue: null,
      outputValueObj: {},
      outPutValButton: 'both',
      formulaModal: false,
      editMode: false,
      isSaving: false,
      isFetching: false,

      condition: '',
      condition_logic: undefined,
      new_condition_json: null,
      condition_json_tiptap: {},
      prev_cond_content: '',

      conditionModal: false,
      warningModal: false,
      builderCondition: '',
      readableCondition: '',
      builder_condition_json: null,

      outputCategories: [
        {
          sortable_id: 1,
          label: 'Label 1',
          description: '',
          description_content: null,
          condition: '',
          new_condition_json: null,
          builder_condition_json: null,
          condition_json_tiptap: {},
          description_json_tiptap: {},
          readableCondition: '',
          position: 0,
          is_default: false,
        },
        {
          sortable_id: 2,
          label: 'None Of The Above (Default)',
          description: '',
          description_content: null,
          condition: '',
          new_condition_json: null,
          builder_condition_json: null,
          condition_json_tiptap: {},
          description_json_tiptap: {},
          readableCondition: '',
          position: 1,
          is_default: true,
        },
      ],
    };
  }

  helpInfoData = getHelpInfoData('Output');

  componentDidMount() {
    // get the output object of calc, if any
    this.fetchOutput();
  }

  fetchOutput = () => {
    const calculatorId = this.props.calculatorId;
    const API_URL = addMirrorId(`${CALCULATOR_API_URL}${calculatorId}/output`, 'calculator');
    this.setState({ isFetching: true });

    axios.get(API_URL).then((res) => {
      this.setState({ isFetching: false });
      if (res.data.name) {
        let outputCategories = [];
        outputCategories = res.data.output_category.map((category) => {
          return {
            sortable_id: category.id,
            id: category.id,
            label: category.label,
            is_default: category.is_default,
            description: category.description,
            condition_json_tiptap: category.condition_json_tiptap,
            description_json_tiptap: category.description_json_tiptap,
            description_content: category.description_content,
            prev_desc_content: category.description_content,
            condition: category.condition,
            condition_logic: category.condition_logic,
            new_condition_json: category.new_condition_json,
            prev_cond_content: category.new_condition_json,
            builder_condition_json: category.builder_condition_json,
            readableCondition: convertCodeToText(category.condition || ''),
            position: category.position,
          };
        });

        outputCategories.sort(compareCardPosition);
        const outputValueObj = this.props.formulaState.formulas.find(
          (formula) => formula.id === res.data.output_value
        );

        this.setState({
          id: res.data.id,
          name: res.data.name,
          condition: res.data.condition,
          condition_logic: res.data.condition_logic,
          condition_json_tiptap: res.data.condition_json_tiptap,
          isConditionMandatory: res.data.condition ? true : false,
          outPutValButton:
            res.data.output_type === 'N'
              ? 'numerical'
              : res.data.output_type === 'C'
              ? 'categorical'
              : res.data.output_type === 'B'
              ? 'both'
              : '',
          outputValue: res.data.output_value,
          outputValueObj: outputValueObj || {},
          new_condition_json: res.data.new_condition_json,
          prev_cond_content: res.data.new_condition_json,
          builder_condition_json: res.data.builder_condition_json,
          readableCondition: convertCodeToText(res.data.condition || ''),
          editMode: true,
          outputCategories: outputCategories,
        });
      }
    });
  };

  componentWillUnmount() {
    if (!hasViewOnlyPermission('calculator')) {
      this.saveOutput();
    }
  }

  handleChange = (event) =>
    this.setState({ [event.target.name]: event.target.value } as Pick<OutputPanelState, any>);

  setOutputValue = (selectedFormula) => {
    // get selected the formula object
    const outputValueObj = this.props.formulaState.formulas.find(
      (formula) => formula.id === selectedFormula
    );

    this.setState({
      outputValue: selectedFormula,
      outputValueObj: outputValueObj || {},
    });
  };

  toggleFlag = (flagName) => {
    this.setState(
      (prevState) =>
        ({
          [flagName]: !prevState[flagName],
        }) as Pick<OutputPanelState, any>
    );
  };

  handleTypeClick = (item) => {
    if (
      (item === 'categorical' || item === 'both') &&
      (!this.state.outputCategories || this.state.outputCategories.length === 0)
    ) {
      this.setState({
        outputCategories: [
          {
            sortable_id: 1,
            label: 'Label 1',
            description: '',
            condition: '',
            new_condition_json: null,
            builder_condition_json: null,
            readableCondition: '',
            position: 0,
            is_default: false,
          },
          {
            sortable_id: 2,
            label: 'None Of The Above (Default)',
            description: '',
            condition: '',
            new_condition_json: null,
            builder_condition_json: null,
            readableCondition: '',
            position: 1,
            is_default: true,
          },
        ],
        outPutValButton: item,
      });
    } else {
      this.setState({ outPutValButton: item });
    }
  };

  setCondition = (e) => {
    this.setState({
      condition: e.blocks[0].text,
      new_condition_json: e,
      builder_condition_json: null,
    });
  };

  setConditionTiptap = (editor) => {
    this.setState({ condition: editor.getText(), condition_json_tiptap: editor.getJSON() });
  };

  setBuilderCondition = (condition, conditionLogic, jsonTree) => {
    const readableCondition = convertCodeToText(condition);

    this.setState({
      condition: condition,
      condition_logic: conditionLogic,
      readableCondition,
      builder_condition_json: jsonTree,
      new_condition_json: null,
    });
  };

  addCategory = () => {
    // create choice obj
    let obj = {
      sortable_id: generate_ID(),
      label: 'Label ' + this.state.outputCategories.length,
      is_default: false,
      description: '',
      description_content: null,
      condition: '',
      readableCondition: '',
      condition_json_tiptap: {},
      description_json_tiptap: {},
      position: this.state.outputCategories.length - 1,
      new_condition_json: null,
      builder_condition_json: null,
    };

    // keep the "None Of The Above (Default)" category at the end and insert new category
    // above default category.
    let defaultCategory = this.state.outputCategories[this.state.outputCategories.length - 1];
    defaultCategory['position'] += 1;

    let outputCategories = this.state.outputCategories;
    outputCategories.splice(outputCategories.length - 1, 1, defaultCategory);
    outputCategories.splice(outputCategories.length - 1, 0, obj);

    this.setState({ outputCategories });
  };

  updateCategories = (newList) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }

    this.setState({ outputCategories: newList });
  };

  updateCategory = (id, value, inputName) => {
    const newList = this.state.outputCategories.map((item) => {
      let updatedItem = item;

      if (item.sortable_id === id) {
        if (inputName === 'label') {
          updatedItem = {
            ...item,
            label: value,
          };
        } else if (inputName === 'draftjs') {
          let text = '';
          value.blocks.forEach((block) => {
            text += block.text + '\n';
          });

          updatedItem = {
            ...item,
            description: text,
            description_content: value,
          };
        } else if (inputName === 'tiptap') {
          updatedItem = {
            ...item,
            description_json_tiptap: value,
          };
        }
        return updatedItem;
      }

      return item;
    });

    this.setState({ outputCategories: newList });
  };

  setCategoryCondition = (id, condType, cond, conditionLogic, jsonTree) => {
    const newList = this.state.outputCategories.map((item) => {
      let updatedItem = item;

      if (item.sortable_id === id) {
        if (condType === 'builder') {
          updatedItem = {
            ...item,
            condition: cond,
            condition_logic: conditionLogic,
            readableCondition: convertCodeToText(cond),
            builder_condition_json: jsonTree,
            new_condition_json: null,
          };
        }

        // in case of draft js cond contains both condition and json
        else if (condType === 'draftjs') {
          updatedItem = {
            ...item,
            condition: cond.blocks[0].text,
            condition_logic: conditionLogic,
            new_condition_json: cond,
            readableCondition: '',
            builder_condition_json: null,
          };
        } else if (condType === 'tiptap') {
          updatedItem = {
            ...item,
            condition: cond.getText(),
            condition_json_tiptap: cond.getJSON(),
          };
        }
        return updatedItem;
      }

      return item;
    });

    this.setState({ outputCategories: newList });
  };

  deleteCatgeory = (id) => {
    const newList = this.state.outputCategories.filter((item) => item.sortable_id !== id);
    this.setState({ outputCategories: newList });
  };

  getPayload = () => {
    let payload = {
      name: this.state.name,
      condition: this.state.condition || this.state.builderCondition,
      condition_logic: this.state.condition_logic,
      new_condition_json: this.state.new_condition_json,
      condition_json_tiptap: this.state.condition_json_tiptap,
      builder_condition_json: this.state.builder_condition_json,
      calculator: this.props.calculatorId,
    };

    if (this.state.outPutValButton === 'numerical') {
      payload['output_type'] = 'N';
      payload['output_value'] = this.state.outputValue;
    } else if (this.state.outPutValButton === 'categorical') {
      payload['output_type'] = 'C';
      payload['output_categories'] = this.state.outputCategories;
    } else if (this.state.outPutValButton === 'both') {
      payload['output_type'] = 'B';
      payload['output_value'] = this.state.outputValue;
      payload['output_categories'] = this.state.outputCategories;
    }

    return payload;
  };

  saveOutput = () => {
    this.setState({ isSaving: true });
    let payload = this.getPayload();

    if (this.state.isConditionMandatory && !payload['condition']) {
      toast.error(CustomToast, { data: 'Output condition cannot be empty' });
      this.setState({ isSaving: false });
      return;
    }

    if (this.state.editMode) {
      axios
        .put(OUTPUT_API_URL + this.state.id + '/', payload)
        .then(() => {
          this.fetchOutput();
          this.setState({ isSaving: false }, () => {
            toast.success(CustomToast, { data: 'All changes saved.' });
          });
        })
        .catch(() => this.setState({ isSaving: false }));
    } else if (!this.state.editMode && !this.state.isFetching) {
      axios
        .post(OUTPUT_API_URL, payload)
        .then(() => {
          this.fetchOutput();
          this.setState({ isSaving: false }, () => {
            toast.success(CustomToast, { data: 'All changes saved.' });
          });
        })
        .catch(() => this.setState({ isSaving: false }));
    }
  };

  render() {
    const { formulaState } = this.props;
    const { name, prev_cond_content, outPutValButton, outputValue, outputValueObj } = this.state;

    const helpInfoData = this.helpInfoData;

    return (
      <>
        {/* TODO: Remove the global style for materialize and bootstrap in future */}
        {globalStyles}
        <div className='mt-[51px] p-8' style={{ minHeight: '90vh' }}>
          {helpInfoData['name'] && (
            <Box className='ml-1'>
              <StyledFormGroupMB16>
                <Box style={{ width: 550 }}>
                  <InputField
                    name='name'
                    value={name}
                    onChange={this.handleChange}
                    label={helpInfoData?.name?.label}
                    detail={helpInfoData?.name?.detail}
                    placeholder={helpInfoData?.name?.placeholder}
                    maxLength={helpInfoData?.name?.character_limit}
                  />
                </Box>
              </StyledFormGroupMB16>
            </Box>
          )}

          {helpInfoData['condition'] && (
            <Box className='ml-1' style={{ marginTop: '2rem' }}>
              <StyledFormGroupMB16>
                <PanelLabel2 style={{ fontSize: '16px !important' }} for='question'>
                  {helpInfoData['condition'].label}
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={this.state.isConditionMandatory}
                        onChange={() => this.toggleFlag('isConditionMandatory')}
                        name='isConditionMandatory'
                        disabled={hasViewOnlyPermission('calculator')}
                      />
                    }
                    disabled={hasViewOnlyPermission('calculator')}
                    label=''
                  />
                </PanelLabel2>

                <Box>
                  {this.state.builder_condition_json ? (
                    <div className='flex'>
                      <StyledInputWithButton3
                        type='text'
                        name='readable_condition'
                        value={this.state.readableCondition}
                        maxLength={helpInfoData['condition'].character_limit}
                        disabled={true}
                      />
                      <StyledButton
                        onClick={() => this.toggleFlag('conditionModal')}
                        disabled={
                          !this.state.isConditionMandatory || hasViewOnlyPermission('calculator')
                        }
                        style={{ height: 'fit-content' }}
                      >
                        Edit
                      </StyledButton>
                    </div>
                  ) : (
                    <>
                      <div className='flex'>
                        <AvoEditorTextInput
                          moduleId={this.props.calculatorId}
                          moduleType={MODULE_TYPES.CALCULATOR}
                          width={425}
                          conditionContent={prev_cond_content}
                          setCondition={this.setCondition}
                          isReadOnly={
                            !this.state.isConditionMandatory || hasViewOnlyPermission('calculator')
                          }
                          wrapperClassNames='flex-grow !max-h-[45px] max-w-[425px]'
                          placeholder='This is tiptap editor'
                          onUpdate={this.setConditionTiptap}
                          initialContent={this.state.condition_json_tiptap}
                          suggestionsToExclude={suggestionsToExclude}
                        />
                        <StyledButton
                          onClick={() => this.toggleFlag('conditionModal')}
                          disabled={
                            !this.state.isConditionMandatory || hasViewOnlyPermission('calculator')
                          }
                        >
                          Build
                        </StyledButton>
                      </div>
                    </>
                  )}
                </Box>
              </StyledFormGroupMB16>
            </Box>
          )}

          {helpInfoData['output_type'] && (
            <Box className='ml-1'>
              <StyledFormGroupMB16>
                <PanelLabel2 style={{ fontSize: '16px !important' }} for='question'>
                  {helpInfoData['output_type'].label}
                </PanelLabel2>
                <div style={{ marginTop: '1em' }}>
                  <OutputTypeButton
                    onClick={() => this.handleTypeClick('numerical')}
                    disabled={hasViewOnlyPermission('calculator')}
                    style={{
                      background:
                        this.state.outPutValButton === 'numerical'
                          ? hasViewOnlyPermission('calculator')
                            ? '#E5EDF0'
                            : '#08A88E'
                          : hasViewOnlyPermission('calculator')
                          ? '#F4F4F4'
                          : '#ffffff',
                      color:
                        this.state.outPutValButton === 'numerical'
                          ? hasViewOnlyPermission('calculator')
                            ? '#ffffff'
                            : '#ffffff'
                          : hasViewOnlyPermission('calculator')
                          ? '#161616'
                          : '#000000',
                    }}
                  >
                    Numerical
                  </OutputTypeButton>
                  <OutputTypeButton
                    onClick={() => this.handleTypeClick('categorical')}
                    disabled={hasViewOnlyPermission('calculator')}
                    style={{
                      background:
                        this.state.outPutValButton === 'categorical'
                          ? hasViewOnlyPermission('calculator')
                            ? '#E5EDF0'
                            : '#08A88E'
                          : hasViewOnlyPermission('calculator')
                          ? '#F4F4F4'
                          : '#ffffff',
                      color:
                        this.state.outPutValButton === 'categorical'
                          ? hasViewOnlyPermission('calculator')
                            ? '#ffffff'
                            : '#ffffff'
                          : hasViewOnlyPermission('calculator')
                          ? '#161616'
                          : '#000000',
                    }}
                  >
                    Categorical
                  </OutputTypeButton>
                  <OutputTypeButton
                    onClick={() => this.handleTypeClick('both')}
                    disabled={hasViewOnlyPermission('calculator')}
                    style={{
                      background:
                        this.state.outPutValButton === 'both'
                          ? hasViewOnlyPermission('calculator')
                            ? '#E5EDF0'
                            : '#08A88E'
                          : hasViewOnlyPermission('calculator')
                          ? '#F4F4F4'
                          : '#ffffff',
                      color:
                        this.state.outPutValButton === 'both'
                          ? hasViewOnlyPermission('calculator')
                            ? '#ffffff'
                            : '#ffffff'
                          : hasViewOnlyPermission('calculator')
                          ? '#161616'
                          : '#000000',
                    }}
                  >
                    Both
                  </OutputTypeButton>
                </div>
              </StyledFormGroupMB16>
            </Box>
          )}

          {helpInfoData['output_value'] &&
            (outPutValButton === 'numerical' || outPutValButton === 'both') && (
              <Box className='ml-1 mt-5'>
                <StyledFormGroupMB16>
                  <PanelLabel2 for='output name'>
                    {helpInfoData['output_value'].label}
                    <span style={{ marginLeft: '1em' }}></span>
                    <StyledSelect
                      id='select-output-val'
                      name='outputValue'
                      value={outputValue || '---'}
                      IconComponent={StyledExpandIcon}
                      onChange={(event) => this.setOutputValue(event.target.value)}
                      className={
                        hasViewOnlyPermission('calculator')
                          ? 'app-situation-dropdown disabled-select'
                          : 'app-situation-dropdown'
                      }
                      label='Select Output Value'
                      disableUnderline
                      disabled={hasViewOnlyPermission('calculator')}
                    >
                      <StyledMenuItem disabled value='---'>
                        Select
                      </StyledMenuItem>
                      {formulaState.formulas.map((formula) => (
                        <StyledMenuItem key={formula.id} value={formula.id}>
                          {formula.name}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                    <span
                      style={{
                        fontFamily: 'SofiaPro',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        lineHeight: '18px',
                        marginLeft: '19px',
                      }}
                    >
                      {outputValueObj.unit}
                    </span>

                    {!(
                      outputValueObj &&
                      outputValueObj.constructor === Object &&
                      Object.keys(outputValueObj).length === 0
                    ) &&
                      !hasViewOnlyPermission('calculator') && (
                        <StyledEditButton onClick={() => this.toggleFlag('formulaModal')}>
                          Edit
                        </StyledEditButton>
                      )}
                  </PanelLabel2>
                </StyledFormGroupMB16>
              </Box>
            )}

          {helpInfoData['output_categories'] &&
            (outPutValButton === 'categorical' || outPutValButton === 'both') && (
              <Box className='ml-1'>
                <StyledFormGroupMB16>
                  <PanelLabel2 for='output name'>
                    {helpInfoData['output_categories'].label}
                  </PanelLabel2>
                  <OutputCategory
                    outputCategories={this.state.outputCategories}
                    addCategory={this.addCategory}
                    updateCategory={this.updateCategory}
                    updateCategories={this.updateCategories}
                    deleteCatgeory={this.deleteCatgeory}
                    calcId={this.props.calculatorId}
                    setCategoryCondition={this.setCategoryCondition}
                  />
                </StyledFormGroupMB16>
              </Box>
            )}

          {!hasViewOnlyPermission('calculator') && (
            <CreateButton onClick={this.saveOutput} disabled={this.state.isSaving}>
              Save
              <ButtonProgress isLoading={this.state.isSaving} />
            </CreateButton>
          )}

          <SlidingPane
            isOpen={this.state.formulaModal}
            onRequestClose={() => this.toggleFlag('formulaModal')}
            from='right'
            // hideHeader
            className='sliding-pan-modal  side-popup-shadow'
            title={
              <div>
                Edit Formula
                <HighlightOffIcon
                  className='backIcon'
                  onClick={() => this.toggleFlag('formulaModal')}
                ></HighlightOffIcon>
              </div>
            }
            //   subtitle='Build a Trigger'
            width='587px'
            closeIcon={
              <div>
                <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
              </div>
            }
          >
            <FormulaForm
              type={this.props.type}
              toggleModal={() => this.toggleFlag('formulaModal')}
              formulaId={this.state.outputValueObj.id}
              savePosition={() => []}
              setOutputValue={this.setOutputValue}
            />
          </SlidingPane>

          <SlidingPane
            isOpen={this.state.conditionModal}
            onRequestClose={() => this.toggleFlag('conditionModal')}
            from='right'
            // hideHeader
            className='sliding-pan-modal  sliding-panel-shadow'
            title={
              <div>
                Condition Builder
                <HighlightOffIcon
                  className='backIcon'
                  onClick={() => this.toggleFlag('conditionModal')}
                ></HighlightOffIcon>
              </div>
            }
            //   subtitle='Build a Trigger'
            width='587px'
            closeIcon={
              <div>
                <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
              </div>
            }
          >
            <ConditionBuilder
              calculatorId={this.props.calculatorId}
              type={'calculator'}
              toggleModal={() => this.toggleFlag('conditionModal')}
              setCondition={this.setBuilderCondition}
              condition={this.state.condition}
              conditionLogic={this.state.condition_logic}
              builderJsonTree={this.state.builder_condition_json}
            />
          </SlidingPane>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.formState,
  formulaState: state.formulaState,
});

const suggestionsToExclude: SuggestionTypes[] = [
  'link',
  'reference',
  'knowledge_base',
  'conditional_text',
  'infobox',
  'ehr_data',
  'ehr_order',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
];

export default connect(mapStateToProps, {})(OutputPanel);
