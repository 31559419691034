import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export const conditionalTextStyle = {
  textDecorationLine: 'none',
  color: '#08A88E',
  marginRight: '5px',
  paddingLeft: '11px',
  paddingRight: '8px',
  paddingTop: '5px',
  paddingBottom: '2px',
  background: '#FFFFFF',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  borderRadius: '3px',
};

export const styleMap = {
  HIGHLIGHT: {
    color: '#EA873F',
  },
  HEADER: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000000',
    display: 'block',
    marginTop: 15,
    marginBottom: 18,
  },
};

export const editorPreviewStyle = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '135%',
  color: '#515151',
};

export const toolbarStyle = {
  display: 'flex',
  borderTop: '1px solid #E7E7E7',
  marginTop: '10px',
  alignItems: 'center',
  padding: '9px 12px 0px 12px',
};

export const pasteBtn = {
  fontWeight: 'bold',
  width: 17,
  color: 'black',
  minWidth: 17,
  paddingTop: 7,
  paddingBottom: 0,
  fontSize: 22,
  textTransform: 'none',
};

export const dividerStyle = {
  border: '1px solid #CCCCCC',
  height: 24,
  width: 0,
};

export const toolbarStyles = () => ({
  infoboxMenuContainer: {
    maxHeight: '250px',
    overflow: 'scroll',
    marginRight: '20px',
  },
  confirmBtnContainer: {
    borderTop: '1px solid #EBECF0',
    width: '25vw',
    textAlign: 'center',
    marginTop: 20,
    paddingTop: 19,
    paddingBottom: 18,
  },
  variableMenuContainer: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #EDEDED',
    width: 330,
    maxHeight: 500,
  },
  variableSearchContainer: {
    maxWidth: 300,
    height: '45px',
    backgroundColor: '#f6f6f6',
    background: '#F6F6F6',
    borderRadius: '6px',
    marginBottom: 20,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingRight: '15px',
  },
  input: {
    textIndent: '22px',
    outline: 'none !important',
    borderBottom: 'none !important',
    boxShadow: 'none !important',
    display: 'inline-block',
    border: 'none !important',
    margin: '0 !important',
  },
  showBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
  },
  optionsLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#939393',
    marginRight: 9,
  },
  selectedOption: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#08A88E',
    marginRight: 9,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  menuItem: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '36px',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#ffffff !important',
    },
    '&:focus': {
      color: '#08A88E',
    },
  },
  variableConfirmBtn: {
    borderTop: '1px solid #EBECF0',
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 10,
  },
});

export const HeadingButton = withStyles((theme) => ({
  root: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'none',
    maxWidth: 100,
    minWidth: 100,
    height: 24,
    color: '#1E1F20',
    borderRadius: '4px',
    padding: '3px 8px 0px 8px',
    '&:hover': {
      background: '#F5F8F9',
    },
  },
  endIcon: {
    marginLeft: '10px',
  },
  disabled: {
    color: '#000000 !important',
  },
}))(Button);

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#2E3438',
    color: '#FFFFFF',
    borderRadius: '2px',
    paddingTop: '7px',
    height: '26px',
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'normal',
    marginBottom: '15px',
  },
  tooltipPlacementTop: {
    margin: '4px 0',
  },
}))(Tooltip);
