import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NOTE_GEN_SIMULATOR_VARIABLES_API_URL } from '../../../../constants';
import VariableItem from './VariableItem';
import NotePreview from './preview';
import { useStyles } from './simulator.styles';
import './simulator.styles.css';
interface EditorVariable {
  code: string;
  // Add any other properties related to EditorVariable here
}
interface VariableObject {
  code: string;
  name: string;
  type: string;
  option_type: string;
  values: any; // Replace 'any' with the actual type for 'values'
}
interface MappingObject {
  val: any; // Replace 'any' with the actual type for 'val'
  key: string;
}
interface NoteGeneratorSimulatorProps {
  open: boolean;
  handleClose: () => void;
  editorVariables: EditorVariable[];
  calculatorId: string;
  moduleId: string;
  previewContent: any; // Replace 'any' with the actual type for 'previewContent'
  setPreviewContent: (content: any) => void; // Replace 'any' with the actual type for 'content'
}
function NoteGeneratorSimulator(props: NoteGeneratorSimulatorProps): JSX.Element {
  const {
    open,
    handleClose,
    editorVariables,
    calculatorId,
    moduleId,
    previewContent,
    setPreviewContent,
  } = props;

  const [iterableVariables, setIterableVariables] = useState<any[]>([]);

  const classes = useStyles();

  const getVariableInfo = async () => {
    const variablesArray = editorVariables.map((obj) => obj.code);
    const queryParams = String(variablesArray);
    try {
      const res = await axios.get(NOTE_GEN_SIMULATOR_VARIABLES_API_URL, {
        params: {
          codes: queryParams,
          module_id: moduleId,
          calculator_id: calculatorId,
        },
      });

      generateVariableMapping(res.data);
    } catch (error) {
      console.error('Error fetching variable information:', error);
      // Handle the error here, such as showing an error message to the user.
      // You can also choose to set a default value for 'iterableVariables' in case of an error.
    }
  };
  const generateVariableMapping = (varData: VariableObject[]) => {
    let mappings: MappingObject[] = [];

    _.mapKeys(previewContent?.entityMap, (val, key) => {
      mappings.push({ val, key });
    });
    let newDataset: any[] = [];

    varData.forEach((obj) => {
      mappings.forEach((mapObj) => {
        if (
          obj.code === mapObj?.val?.data?.mention?.code &&
          !newDataset.some((filterObj) => filterObj.key.includes(mapObj.key))
        ) {
          const foundIndex = newDataset.findIndex((obj1) => obj1.obj.code === obj.code);
          if (foundIndex !== -1 && newDataset[foundIndex].key.length >= 1) {
            if (!newDataset[foundIndex].key.includes(mapObj.key))
              newDataset[foundIndex].key = [...newDataset[foundIndex].key, mapObj.key];
          } else if (!newDataset.find((obj2) => obj2.obj.code === obj.code)) {
            newDataset.push({ obj, key: [mapObj.key] });
          }
        }
      });
    });
    console.log({ newDataset });

    setIterableVariables(newDataset);
  };
  const resetInputs = () => {
    setIterableVariables([]);
    getVariableInfo();
  };

  useEffect(() => {
    getVariableInfo();
  }, []);

  return (
    <Modal open={open} onClose={handleClose} className='grid-center-modal'>
      <Box className='modal-body'>
        <Box className='flex-column inner-section'>
          <Box className='modal-title'>
            {' '}
            <Typography className={classes.modalTitle}>Note Generator Simulator</Typography>
          </Box>
          <Box className='flex-row flex-nowrap d-flex section-wrapper'>
            <Box className='left-section'>
              {' '}
              <Typography className={classes.sectionTitle}>
                Variables{' '}
                <button className={classes.resetBtn} onClick={() => resetInputs()}>
                  Reset
                </button>
              </Typography>
              {Array.isArray(iterableVariables) &&
                iterableVariables.map((obj, index) => (
                  <VariableItem
                    code={obj.obj.code}
                    name={obj.obj.name}
                    arrayIndex={index}
                    key={`var-${obj.obj.code}`}
                    type={obj.obj.type}
                    optionType={obj.obj.option_type}
                    values={obj.obj.values}
                    entityKey={obj.key}
                    setPreviewContent={setPreviewContent}
                    previewContent={previewContent}
                  />
                ))}
            </Box>
            <Box className='right-section'>
              <Typography className={classes.sectionTitle}>Preview</Typography>
              <NotePreview previewContent={previewContent} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default NoteGeneratorSimulator;
