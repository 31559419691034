import { QueryFunctionContext } from '@tanstack/react-query';
import { JSONContent } from '@tiptap/react';
import axios from 'axios';
import { DATA_EXTRACTOR_API_URL } from '../constants';

export interface DataToConsume {
  label: string;
  content: JSONContent;
}

export enum VariablesToGenerateTypes {
  CATEGORICAL = 'categorical',
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  LIST = 'list',
}

interface Variable {
  code?: string;
  name: string;
  description: JSONContent;
}

export interface CategoricalVariable extends Variable {
  variable_type: VariablesToGenerateTypes.CATEGORICAL;
  categories: string[];
}

export interface TextVariable extends Variable {
  variable_type: VariablesToGenerateTypes.TEXT;
  default_value?: string | null;
}

export interface NumberVariable extends Variable {
  variable_type: VariablesToGenerateTypes.NUMBER;
  default_value?: number | null;
}

export interface BooleanVariable extends Variable {
  variable_type: VariablesToGenerateTypes.BOOLEAN;
  default_value?: false | null;
}

export interface ListVariable extends Variable {
  variable_type: VariablesToGenerateTypes.LIST;
  default_value?: [] | null;
}

export interface DataExtractorFormVariable {
  id?: number;
  container?: string | number;
  title: string;
  description: JSONContent;
  data_to_consume: DataToConsume[];
  variables_to_generate: (
    | CategoricalVariable
    | TextVariable
    | NumberVariable
    | BooleanVariable
    | ListVariable
  )[];
  variable_name?: string;
  trigger: number;
  module: number;
  position: number;
}

export const dataExtractorAPI = {
  getDataExtractor: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${DATA_EXTRACTOR_API_URL}${queryKey[1]}`).then((response) => response.data),
  upsertDataExtractor: (data: DataExtractorFormVariable) =>
    !!data.id
      ? axios
          .put(DATA_EXTRACTOR_API_URL + data.id + '/', { ...data, id: undefined })
          .then((res) => res.data)
      : axios.post(DATA_EXTRACTOR_API_URL, data).then((response) => response.data),
};
