import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { MODULE_TYPES, MODULES_SUGGESTIONS_URL, RESOURCES_SUGGESTIONS_URL } from '../constants';
import { Mirror, Module } from '../hooks/useModules';
import { Suggestion } from '../hooks/useSuggestions';

export interface ResourceSuggestions {
  conditional_text: Suggestion[];
  formula: Suggestion[];
  infobox: Suggestion[];
  numeric: Suggestion[];
  reference: Suggestion[];
  variable: Suggestion[];
}

interface ModuleSuggestion extends Suggestion {
  id: number;
  is_in_current_team: boolean;
  last_updated: string;
  module?: Module;
  title?: string;
}

export interface ModuleSuggestions {
  [MODULE_TYPES.ALGO]: ModuleSuggestion[];
  [MODULE_TYPES.CALCULATOR]: ModuleSuggestion[];
  [MODULE_TYPES.KNOWLEDGE_BASE]: ModuleSuggestion[];
  mirrors: Mirror[];
}

export const mentionAPI = {
  getResourcesSuggestions: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ResourceSuggestions>(
        `${RESOURCES_SUGGESTIONS_URL}?module_type=${queryKey[1]}&module_id=${queryKey[2]}`
      )
      .then((resp) => resp.data),
  getModulesSuggestions: ({ queryKey }: QueryFunctionContext) =>
    axios.get<ModuleSuggestions>(MODULES_SUGGESTIONS_URL).then((resp) => resp.data),
};
