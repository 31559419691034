import axios from 'axios';
import { UNIVERSAL_CALCULATORS_API_URL } from 'constants';
import { GET_UNIVERSAL_CALCULATORS } from '../actionsTypes';

export const getUniversalCalculators = () => async (dispatch) => {
  axios.get(UNIVERSAL_CALCULATORS_API_URL).then((res) => {
    dispatch({
      type: GET_UNIVERSAL_CALCULATORS,
      payload: res.data.map((item) => ({ ...item, title: item.name })),
    });
  });
};
