// popup component
import { MENTION_TYPES } from '../../../constants';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from '../StaffOnlyWrapper';
import {
  MEDICATION_CODE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
} from '../../../hooks/useEHRVariables';
import { SuggestionTypeEnum } from '../tiptap/tiptapInterfaces';

export default function Entry(props) {
  const {
    mention,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  let unselectableStyle = {
    opacity: '0.4',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  };

  // made headings unselectable
  const headings = [
    'VARIABLES',
    'INFOBOXES',
    'REFERENCES',
    'FORMULAE',
    'NUMERICS',
    'MEDIA',
    'CUSTOM NUMERICS',
    'KNOWLEDGE_BASES',
    'heading',
  ];

  let mentionName = mention.aliasName ?? mention.name;
  if (mention.code === MEDICATION_CODE) {
    mentionName = 'Medications';
  }

  return (
    <div {...parentProps} style={headings.includes(mention.code) ? unselectableStyle : {}}>
      {StaffOnlyWrapIf({
        condition: [
          PROBLEM_LIST_TYPE,
          MEDICATION_TYPE,
          MENTION_TYPES.MESSAGE_VARIABLE,
          SuggestionTypeEnum.DATA_EXTRACTOR,
        ].includes(mention.type),
        options: {
          type: StaffOnlyBadgeType.BETA,
          y: -5,
        },
        element: <>{mentionName}</>,
      })}
    </div>
  );
}
