import axios from 'axios';
import { FETCH_VARIABLE_NAME } from '../constants';

export const getVariableName = async (question: string): Promise<string> => {
  let variableName: string;
  try {
    const res = await axios.post(FETCH_VARIABLE_NAME, { question });
    variableName = res.data.variable_name;
  } catch (err) {
    variableName = question
      .toLowerCase()
      .trim()
      .replace(/\s/g, '-')
      .replace(/[^a-zA-z0-9-]/g, '');
    console.log(err);
  }
  return variableName;
};
