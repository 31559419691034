import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getFormulas } from 'actions/resources/getFormulas';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import {
  CancelButton,
  ConditionalFormulaButton,
  CreateButton,
} from 'components/utils/styled-components/FormStyle';
import {
  RichTextMenuButtonTypes,
  SuggestionTypes,
  VariableTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, CARD_FORMULA, SAVE_BUTTON } from 'constants/variables';
import { debounce } from 'lodash';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form, FormGroup } from 'reactstrap';
import {
  getCustomNumericSuggestions,
  getFormulaSuggestions,
  getNumericSuggestions,
  getNumerics,
  getSuggestions,
  getVariables,
} from 'utils/suggestions';
import { convertCodeToText, getRepresentationPhrase } from 'utils/utilityFunctions';
import { CALCULATOR_API_URL, FORMULA_API_URL, MODULE_API_URL } from '../../../constants';
import {
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../hooks/useEHRVariables';
import ConditionalFormula from './conditional-formula/conditionalFormula';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface FormulaFormProps {
  formState: any;
  formulaId: any;
  type: any;
  isFullHeight: any;
  toggleModal: (id?: any) => void;
  setIsFormDisabled: (val) => void;
  savePosition: Function;
  getFormulas: Function;
  getUniqueCode: Function;
  getInfoBoxes: Function;
  getNoteGenerator: Function;
  setOutputValue: Function;
  modal?: boolean;
  closeUiElementPane?: () => void;

  // for withRouter
  match: any;
}

interface FormulaFormState {
  name: any;
  formula: any;
  module: any;
  new_formula_content: any;
  formulaSuggestions: any;
  text: any;
  new_text_content: any;
  formulaJSONTiptap: any;
  textJSONTiptap: any;
  textEditorsuggestions: any;
  variables: any;
  numerics: any;
  formulas: any;
  unit: any;
  temp: any;
  modal: boolean;
  cusrsorPosition: any;
  refModal: boolean;
  infoModal: boolean;
  isConditional: boolean;
  conditionalFormulas: any;
  temp_text: any;

  moduleId: string;
}

class FormulaForm extends Component<FormulaFormProps, FormulaFormState> {
  debOnTextChangeTiptap: any;
  debounceOnFormulaChangeTiptap: any;

  constructor(props: FormulaFormProps) {
    super(props);

    this.state = {
      name: '',
      formula: '',
      module: '',
      new_formula_content: '',
      formulaSuggestions: [],
      text: '',
      new_text_content: '',
      textEditorsuggestions: [],
      variables: [],
      numerics: [],
      formulas: [],
      unit: '',
      temp: '',
      modal: false,
      cusrsorPosition: '',
      refModal: false,
      infoModal: false,
      isConditional: false,
      conditionalFormulas: [],
      temp_text: null,
      formulaJSONTiptap: {},
      textJSONTiptap: {},

      moduleId: this.props.match.params.moduleId,
    };
    this.debOnTextChangeTiptap = debounce(this.onTextChangeTiptap, 300);
    this.debounceOnFormulaChangeTiptap = debounce(this.onFormulaChangeTiptap, 300);
  }

  onFormulaChangeTiptap = (editor) => {
    this.setState({ formulaJSONTiptap: editor.getJSON(), formula: editor.getText() });
  };

  onTextChangeTiptap = (editor) => this.setState({ textJSONTiptap: editor.getJSON() });

  helpInfoData = getHelpInfoData('Formula');

  componentDidMount() {
    this.props.setIsFormDisabled(false);

    this.populateSuggestions();

    if (this.props.formulaId) {
      axios.get(FORMULA_API_URL + this.props.formulaId + '/').then((res) => {
        const conditionalFormulas = res.data.conditional_formulas.map(
          (conditionalFormula, index) => {
            return {
              id: conditionalFormula.id,
              sortable_id: conditionalFormula.id,
              label: conditionalFormula.is_default
                ? 'None Of The Above (Default)'
                : 'Formula ' + (index + 1),
              is_default: conditionalFormula.is_default,
              condition: conditionalFormula.condition,
              readableCondition: convertCodeToText(conditionalFormula.condition || ''),
              formula_text: conditionalFormula.formula_text,
              new_formula_text_json: conditionalFormula.new_formula_text_json,
              formula_text_json_tiptap: conditionalFormula.formula_text_json_tiptap,
              prev_formula_content: conditionalFormula.new_formula_text_json,
              builder_condition_json: conditionalFormula.builder_condition_json,
            };
          }
        );

        this.setState({
          name: res.data.name || '',
          formula: res.data.formula,
          module: res.data.module,
          new_formula_content: res.data.new_formula_content,
          formulaJSONTiptap: res.data.formula_json_tiptap,
          textJSONTiptap: res.data.text_json_tiptap,
          temp: res.data.new_formula_content,
          text: res.data.text ? res.data.text : '',
          new_text_content: res.data.new_text_content,
          temp_text: res.data.new_text_content,
          unit: res.data.unit ? res.data.unit : '',
          isConditional: res.data.is_conditional,
          conditionalFormulas,
        });
      });
    }
  }

  populateSuggestions = async () => {
    // name field is compulsory for draft js mention plugin and is used to generate text
    // which is stored in db. That's why we place "unique_code" in name field and for display
    // we place "name" in code field.

    const formulaSuggestions = getFormulaSuggestions(this.props.formulaId);
    const customNumericSuggestions = getCustomNumericSuggestions();
    const numericSuggestions = await getNumericSuggestions(this.state.moduleId, this.props.type);

    let VARIABLES_API_URL;
    if (this.props.type === 'calculator') {
      VARIABLES_API_URL = `${CALCULATOR_API_URL}${this.state.moduleId}/variables`;
    } else {
      VARIABLES_API_URL = `${MODULE_API_URL}${this.state.moduleId}/variables?for_formula=True`;
    }

    const vars = await axios.get(VARIABLES_API_URL);
    let variableSuggestions = [{ code: 'VARIABLES', name: 'VARIABLES' }];
    vars.data.forEach((obj) => {
      let new_obj = {
        code: obj.unique_code,
        name: obj.name,
      };
      variableSuggestions.push(new_obj);
    });

    // suggestions for formula field
    let suggestionsForFormula = [
      ...formulaSuggestions,
      ...variableSuggestions,
      ...numericSuggestions,
      ...customNumericSuggestions,
    ];

    // text editor suggestions
    const textEditorsuggestions = await getSuggestions(
      this.state.moduleId,
      false,
      this.props.type,
      false,
      this.props.formulaId,
      true,
      true
    );

    const variables = await getVariables(this.state.moduleId, this.props.type);

    const numerics = await getNumerics(this.state.moduleId, this.props.type);

    this.setState({
      textEditorsuggestions,
      formulaSuggestions: suggestionsForFormula,
      variables,
      numerics,
    });
  };

  toggleConditional = (buttonName) => {
    if (buttonName === 'conditional') {
      this.setState({
        isConditional: true,
        formula: '',
        conditionalFormulas: [
          {
            sortable_id: generate_ID(),
            label: 'Formula 1',
            formula_text: '',
            new_formula_text_json: null,
            formula_text_json_tiptap: null,
            condition: '',
            builder_condition_json: null,
            readableCondition: '',
            is_default: false,
          },
          {
            sortable_id: generate_ID(),
            label: 'None Of The Above (Default)',
            formula_text: '',
            new_formula_text_json: null,
            formula_text_json_tiptap: null,
            condition: '',
            builder_condition_json: null,
            readableCondition: '',
            is_default: true,
          },
        ],
      });
    } else if (buttonName === 'nonConditional') {
      this.setState({
        isConditional: false,
        formula: '',
        conditionalFormulas: [],
      });
    }
  };

  addConditionalFormula = () => {
    let obj = {
      sortable_id: generate_ID(),
      label: 'Formula ' + this.state.conditionalFormulas.length,
      is_default: false,
      formula_text: '',
      new_formula_text_json: null,
      condition: '',
      readableCondition: '',
      builder_condition_json: null,
      formula_text_json_tiptap: null,
    };

    // keep the "None Of The Above (Default)" formula at the end and insert new formula
    // above default formula.
    let conditionalFormulas = [...this.state.conditionalFormulas];
    conditionalFormulas.splice(conditionalFormulas.length - 1, 0, obj);

    this.setState({ conditionalFormulas });
  };

  updateConditionalFormulas = (newList?: any[]) => {
    if (newList) {
      this.setState({ conditionalFormulas: newList });
    }
  };

  updateConditionalFormula = (id, value, inputName, conditionLogic, jsonTree = {}) => {
    const newList = this.state.conditionalFormulas.map((item) => {
      let updatedItem = item;

      if (item.sortable_id === id) {
        if (inputName === 'formula') {
          let text = '';

          value.blocks.forEach((block) => {
            if (block.text) {
              text += block.text + '\n';
            }
          });

          updatedItem = {
            ...item,
            formula_text: text,
            new_formula_text_json: value,
          };
        } else if (inputName === 'formula_tiptap') {
          updatedItem = {
            ...item,
            formula_text: value.getText(),
            formula_text_json_tiptap: value.getJSON(),
          };
        } else if (inputName === 'condition') {
          updatedItem = {
            ...item,
            condition: value,
            condition_logic: conditionLogic,
            readableCondition: convertCodeToText(value),
            builder_condition_json: jsonTree,
          };
        }
        return updatedItem;
      }

      return item;
    });

    this.setState({ conditionalFormulas: newList });
  };

  deleteConditionalFormula = (id) => {
    const newList = this.state.conditionalFormulas.filter((item) => item.sortable_id !== id);
    this.setState({ conditionalFormulas: newList });
  };

  // updates the state on field input
  onTextChange = (e) => {
    let text = '';
    // eslint-disable-next-line
    e.blocks.map((block) => {
      text += block.text + '\n';
    });

    this.setState({
      text: text,
      new_text_content: e,
    });
  };

  toggleModal = (modalName?: any) => {
    this.setState(
      (prev) =>
        ({
          [modalName]: !prev[modalName],
        }) as Pick<FormulaFormState, any>
    );
  };

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ [e.target.name]: e.target.value } as Pick<FormulaFormState, any>);

  getPayload = () => {
    let payload = {
      name: this.state.name,
      shortened_name: getRepresentationPhrase(
        this.state.name,
        this.helpInfoData['shortened_name'].character_limit
      ),
      formula: this.state.formula,
      new_formula_content: this.state.new_formula_content,
      text: this.state.text,
      new_text_content: this.state.new_text_content,
      unit: this.state.unit,
      is_conditional: this.state.isConditional,
      conditional_formulas: this.state.conditionalFormulas,
      formula_json_tiptap: this.state.formulaJSONTiptap,
      text_json_tiptap: this.state.textJSONTiptap,
    };
    if (this.props.type === 'calculator') {
      payload['calculator'] = this.state.moduleId;
    } else {
      payload['module'] = this.state.moduleId;
    }
    return payload;
  };

  isConditionalFormulaEmpty = () => {
    const conditionalFormulas = this.state.conditionalFormulas;
    for (let i = 0; i < conditionalFormulas.length; i++) {
      if (
        !conditionalFormulas[i].formula_text ||
        (!conditionalFormulas[i].condition && conditionalFormulas[i].is_default === false)
      ) {
        return true;
      }
    }
    return false;
  };

  areConditionsSame = () => {
    const conditionalFormulas = this.state.conditionalFormulas;

    let conditionArray = conditionalFormulas.map((conditionalFormula) => {
      return conditionalFormula.condition;
    });

    let isDuplicate = conditionArray.some((item, idx) => {
      return conditionArray.indexOf(item) !== idx;
    });

    return isDuplicate;
  };

  createFormula = async () => {
    if (!this.state.name.trim()) {
      toast.error(CustomToast, { data: 'Name cannot be empty' });
      return;
    }

    if (this.state.isConditional) {
      if (this.isConditionalFormulaEmpty()) {
        toast.error(CustomToast, { data: 'Conditional Formula must have formula and condition' });
        return;
      } else if (this.areConditionsSame()) {
        toast.error(CustomToast, { data: 'Conditional Formulas cannot have same conditions' });
        return;
      }
    }

    let payload = this.getPayload();
    await this.props.savePosition();

    axios.post(FORMULA_API_URL, payload).then(async () => {
      this.props.getFormulas(this.props.type, null, this.state.moduleId);
      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }

      this.props.toggleModal();
    });
  };

  editFormula = async () => {
    if (!this.state.name.trim()) {
      toast.error(CustomToast, { data: 'Name cannot be empty' });
      return;
    }

    if (this.state.isConditional) {
      if (this.isConditionalFormulaEmpty()) {
        toast.error(CustomToast, { data: 'Conditional Formula must have formula and condition' });
        return;
      } else if (this.areConditionsSame()) {
        toast.error(CustomToast, { data: 'Conditional Formulas cannot have same conditions' });
        return;
      }
    }

    let payload = this.getPayload();
    await this.props.savePosition();

    axios.put(FORMULA_API_URL + this.props.formulaId + '/', payload).then(async () => {
      this.props.getFormulas(this.props.type, null, this.state.moduleId);

      // refresh infoboxes list to get update the name of formulas used in infoboxes
      this.props.getInfoBoxes(this.props.type, null, this.state.moduleId);
      this.props.getNoteGenerator(this.props.type, null, this.state.moduleId);

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);

        // if formula form opened from calculator output component
        if (this.props.setOutputValue) this.props.setOutputValue(this.props.formulaId);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }

      this.props.toggleModal(this.props.formulaId);
    });
  };

  setFormula = (e) => {
    this.setState({
      formula: e.blocks[0].text,
      new_formula_content: e,
    });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.formulaId) {
      this.props.modal && this.props.closeUiElementPane?.();
      this.editFormula();
    } else {
      this.props.modal && this.props.closeUiElementPane?.();
      this.createFormula();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    let formula_content = this.state.temp;
    let text_content = this.state.temp_text;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const RefManagerFormProps = {
      type: this.props.type,
      toggleModal: () => this.toggleModal('refModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };
    const InfoBoxFormProps = {
      type: this.props.type,
      toggleModal: () => this.toggleModal('infoModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
      isFullHeight: true,
      createModal: true,
    };

    let buttonStyle: React.CSSProperties = {
      color: '#FD9954',
      float: 'right',
      textTransform: 'none',
      fontSize: 15,
    };

    return (
      <Form
        onKeyPress={this.onKeyPress}
        className='trigger flex flex-col'
        autoComplete='off'
        onSubmit={this.handleSubmit}
      >
        <Overlay show={isFormDisabled} />
        {this.props.isFullHeight ? (
          <Header className='px-0' title={CARD_FORMULA} toggleModal={this.props.toggleModal} />
        ) : (
          ''
        )}
        <div className='row'>
          <div className='col-md-12'>
            <InputField
              name='name'
              required={true}
              value={this.state.name}
              onChange={this.onChange}
              label={helpInfoData?.name?.label}
              detail={helpInfoData?.name?.detail}
              placeholder={helpInfoData?.name?.placeholder}
              maxLength={helpInfoData?.name?.character_limit}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <InputField
              name='unit'
              value={this.state.unit}
              onChange={this.onChange}
              label={helpInfoData?.unit?.label}
              detail={helpInfoData?.unit?.detail}
              placeholder={helpInfoData?.unit?.placeholder}
              maxLength={helpInfoData?.unit?.character_limit}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <FormGroup>
              <FieldLabel label={'Conditional Assignment'} />

              <div style={{ marginTop: 10 }}>
                <ConditionalFormulaButton
                  onClick={() => this.toggleConditional('conditional')}
                  style={{
                    marginRight: 10,
                    width: 134,
                    background: this.state.isConditional ? '#08A88E' : '#FFFFFF',
                    color: this.state.isConditional ? '#FFFFFF' : '#000000',
                  }}
                >
                  Conditional
                </ConditionalFormulaButton>

                <ConditionalFormulaButton
                  onClick={() => this.toggleConditional('nonConditional')}
                  style={{
                    background: !this.state.isConditional ? '#08A88E' : '#FFFFFF',
                    color: !this.state.isConditional ? '#FFFFFF' : '#000000',
                  }}
                >
                  Not Conditional
                </ConditionalFormulaButton>
              </div>
            </FormGroup>
          </div>
        </div>

        {!this.state.isConditional && (
          <div className='row'>
            <div className='col-md-12'>
              {helpInfoData['formula'] && (
                <FormGroup>
                  <FieldLabel
                    detail={helpInfoData?.formula?.detail}
                    label={helpInfoData?.formula?.label}
                  />

                  <AvoEditor
                    moduleId={this.state.moduleId}
                    hasEHRVariables={false}
                    suggestions={this.state.formulaSuggestions}
                    setValue={this.setFormula}
                    prevValue={formula_content}
                    isFormula={true}
                    richTextButtonShowList={[]}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    onUpdate={this.debounceOnFormulaChangeTiptap}
                    suggestionsToExclude={suggestionsToExcludeFormula}
                    variablesToExclude={variablesToExcludeFormula}
                    initialContent={this.state.formulaJSONTiptap}
                  />
                </FormGroup>
              )}
            </div>
          </div>
        )}

        {this.state.isConditional && (
          <ConditionalFormula
            // TODO: integrate moduleId & calculatorId
            moduleId={this.state.moduleId}
            calculatorId={this.state.moduleId}
            type={this.props.type}
            conditionalFormulas={this.state.conditionalFormulas}
            addConditionalFormula={this.addConditionalFormula}
            updateConditionalFormula={this.updateConditionalFormula}
            updateConditionalFormulas={this.updateConditionalFormulas}
            deleteConditionalFormula={this.deleteConditionalFormula}
            formulaSuggestions={this.state.formulaSuggestions}
          />
        )}

        <div className='row' style={{ marginBottom: '20px', marginTop: '25px' }}>
          <div className='col-md-12'>
            {helpInfoData['text'] && (
              <FormGroup>
                <FieldLabel detail={helpInfoData?.text?.detail} label={helpInfoData?.text?.label} />

                <div className='row' style={{ marginBottom: 5 }}>
                  <div className='col-md-12'>
                    <Button style={buttonStyle} onClick={() => this.toggleModal('infoModal')}>
                      Create Infobox
                    </Button>
                    <Button style={buttonStyle} onClick={() => this.toggleModal('refModal')}>
                      Create Reference
                    </Button>
                  </div>
                </div>

                <AvoEditor
                  hasEHRVariables={false}
                  moduleId={this.state.moduleId}
                  moduleType={this.props.type}
                  suggestions={this.state.textEditorsuggestions}
                  setValue={this.onTextChange}
                  prevValue={text_content}
                  variables={this.state.variables}
                  numerics={this.state.numerics}
                  richTextButtonShowList={[
                    ToolbarButton.TEXT_STYLE,
                    ToolbarButton.BOLD,
                    ToolbarButton.ITALIC,
                    ToolbarButton.HIGHLIGHT,
                    ToolbarButton.BULLETED_LIST,
                    ToolbarButton.NUMBERED_LIST,
                    ToolbarButton.INSERT_LINK,
                    ToolbarButton.PHONE_NUMBER,
                    ToolbarButton.VARIABLES,
                    ToolbarButton.CONDITIONAL_TEXT,
                  ]}
                  wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                  onUpdate={this.debOnTextChangeTiptap}
                  initialContent={this.state.textJSONTiptap}
                  suggestionsToExclude={suggestionsToExclude}
                  richTextButtonsShowListTiptap={richTextButtonsShowList}
                />
              </FormGroup>
            )}
          </div>
        </div>

        <div className='row' style={{ marginLeft: 'auto', marginTop: '60px' }}>
          <CancelButton
            style={{ color: 'black', marginRight: '10px' }}
            onClick={this.props.toggleModal}
          >
            {CANCEL_BUTTON}
          </CancelButton>
          <CreateButton type='submit' name='action'>
            {SAVE_BUTTON}
          </CreateButton>
        </div>

        <SlidingPane
          isOpen={this.state.refModal}
          onRequestClose={() => this.toggleModal('refModal')}
          from='bottom'
          // hideHeader
          className='sliding-pan-modal side-popup-shadow'
          title={
            <div>
              Create a Reference Manager
              <HighlightOffIcon
                className='backIcon'
                onClick={() => this.toggleModal('refModal')}
              ></HighlightOffIcon>
            </div>
          }
          //   subtitle='Build a Trigger'
          width='587px'
          closeIcon={
            <div>
              <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
            </div>
          }
        >
          <RefManagerForm {...RefManagerFormProps} />
        </SlidingPane>

        <SlidingPane
          isOpen={this.state.infoModal}
          onRequestClose={() => this.toggleModal('infoModal')}
          from='right'
          hideHeader
          className='no-padding add-info-box sliding-panel-shadow mt-5'
          width='1210px'
        >
          <InfoBoxForm {...InfoBoxFormProps} />
        </SlidingPane>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch, FormulaFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getFormulas: (type, mirrorId, moduleId) => dispatch(getFormulas(type, mirrorId, moduleId)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getNoteGenerator: (moduleType, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(moduleType, mirrorId, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

const suggestionsToExclude: SuggestionTypes[] = [
  'knowledge_base',
  'infobox',
  'media',
  'ehr_data',
  'ehr_order',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
];

const suggestionsToExcludeFormula: SuggestionTypes[] = [
  'knowledge_base',
  'infobox',
  'media',
  'reference',
  'conditional_text',
  'ehr_data',
  'ehr_order',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
];

const variablesToExcludeFormula: VariableTypes[] = [
  'ambient_variable',
  'message_variable',
  'text_input_variable',
];

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormulaForm));
