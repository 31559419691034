import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import '../simulator.styles.css';

function NotePreview({ previewContent }) {
  return (
    <div className='preview-box'>
      <AvoEditorPreview contentTiptap={''} contentDraftJS={previewContent} isSimulator={true} />
    </div>
  );
}

export default NotePreview;
