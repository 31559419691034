import { LinkIcon, ViewfinderCircleIcon } from '@heroicons/react/20/solid';
import { NodeViewWrapper } from '@tiptap/react';
import { Icons } from 'components/utils/Icons';
import { FC, useContext, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import {
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../../../hooks/useEHRVariables';
import { TiptapNodePreview, TiptapReadOnlyNode } from '../../TiptapNodePreview';
import { AddNewItem } from '../../tiptap-expand-options/AddNewItem';
import { MentionContext } from '../../../module/MentionContext';
import { MENTION_TYPES } from '../../../../../constants';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from '../../../StaffOnlyWrapper';
import { SuggestionTypeEnum } from '../../tiptapInterfaces';

// lab_data, note type suggestion's type : display title
const ShortcutNames = {
  [NOTE_TYPE]: 'Notes',
  [LAB_DATA_TYPE]: 'Labs',
  [VITAL_SIGN_TYPE]: 'Vital',
  [MEDICATION_TYPE]: 'Meds',
};

const PreviewNames = {
  [NOTE_TYPE]: 'Notes',
  [LAB_DATA_TYPE]: 'Lab Results',
  [VITAL_SIGN_TYPE]: 'Vital Signs',
  [MEDICATION_TYPE]: 'Medications',
};

// lab_data, note type suggestion's id : display date
const ShortcutDates = {
  1: 'Last 24h',
  2: 'Last 3mos',
  3: 'Last 6mos',
  4: 'Last 1yr',
  5: 'Most Recent',
};

const PreviewDates = {
  1: 'Last 24 hours',
  2: 'Last 3 months',
  3: 'Last 6 months',
  4: 'Last 1 year',
  5: 'Most Recent',
};

export const MentionNode = ({ node, extension, deleteNode, editor }) => {
  const nodeType = node?.attrs?.type;
  const [isEditing, setIsEditing] = useState(false);
  const onEdit = () => setIsEditing(true);

  const { suggestions } = useContext(MentionContext);
  const suggestionsArray = useMemo(() => {
    return [...suggestions];
  }, [suggestions]);
  const mention = findMentionByCode(
    suggestionsArray,
    node?.attrs?.code || node?.attrs?.unique_code
  );
  const isInvalid = nodeType !== 'phone_number' && !mention;
  const title = useMemo(() => {
    const nodeType = node?.attrs?.type;
    if (nodeType === 'infobox' || !mention) {
      return node?.attrs?.name;
    }
    if (Object.keys(ShortcutNames).includes(nodeType)) {
      const shortcutDate =
        nodeType === MEDICATION_TYPE
          ? editor.isEditable
            ? `Max ${node.attrs?.amount}`
            : `Maximum ${node.attrs?.amount}`
          : editor.isEditable
          ? ShortcutDates[mention.id] ?? `Last ${node?.attrs?.days}d`
          : PreviewDates[mention.id] ?? `Last ${node?.attrs?.days} days`;
      return (
        <div>
          {editor.isEditable ? ShortcutNames[mention.type] : PreviewNames[mention.type]}{' '}
          <span className={twJoin(editor.isEditable && 'text-[10px]')}>[{shortcutDate}]</span>
        </div>
      );
    }
    return mention?.name;
  }, [node, mention, editor]);

  const getIcon = () => {
    switch (nodeType) {
      case 'infobox':
        return Icons.TiptapInfoboxNode;
      case 'phone_number':
        return Icons.TiptapPhoneNode;
      case 'knowledge_base':
        return Icons.TiptapKnowledegeBaseNode;
      case 'conditional_text':
        return Icons.TiptapIfIcon;
      case 'algo':
      case 'calculator':
        return LinkIcon as FC;
      case NOTE_TYPE:
      case LAB_DATA_TYPE:
      case VITAL_SIGN_TYPE:
      case MEDICATION_TYPE:
      case PROBLEM_LIST_TYPE:
        return Icons.EHRNode;
      case 'number':
      case 'formula':
      case 'multi_variable':
      case 'choice_variable':
      case 'gptbox_variable':
      case 'text_input_variable':
      case 'message_variable':
      case 'ambient_variable':
      case SuggestionTypeEnum.DATA_EXTRACTOR:
        return Icons.TiptapVariableNode;
      case 'reference':
        return Icons.TiptapRefNode;
      case 'ehr_order':
        return Icons.EHROrder;
      default:
        return ViewfinderCircleIcon as FC;
    }
  };

  const getReadOnlyIcon = () => {
    switch (nodeType) {
      case NOTE_TYPE:
      case LAB_DATA_TYPE:
      case VITAL_SIGN_TYPE:
      case MEDICATION_TYPE:
      case PROBLEM_LIST_TYPE:
        return Icons.EHRNode;
      case 'phone_number':
        return Icons.TiptapPhoneReadOnly;
      case 'algo':
      case 'calculator':
        return Icons.TiptapLinkReadOnly;
      case 'ehr_order':
        return Icons.EhrOrder;
      default:
        return null;
    }
  };

  return (
    <>
      <NodeViewWrapper className='inline'>
        <span {...extension?.options?.HTMLAttributes}>
          {StaffOnlyWrapIf({
            condition: nodeType === MENTION_TYPES.MESSAGE_VARIABLE,
            options: {
              type: StaffOnlyBadgeType.BETA,
            },
            element: (
              <>
                {editor.isEditable ? (
                  <TiptapNodePreview
                    title={title}
                    Icon={getIcon()}
                    onDelete={deleteNode}
                    isInvalid={isInvalid}
                    onEdit={
                      ['infobox', 'reference', 'conditional_text'].includes(nodeType)
                        ? onEdit
                        : undefined
                    }
                  />
                ) : (
                  <TiptapReadOnlyNode
                    isInvalid={isInvalid}
                    title={title}
                    Icon={getReadOnlyIcon()}
                  />
                )}
              </>
            ),
          })}
        </span>
      </NodeViewWrapper>
      <AddNewItem
        editor={editor}
        open={isEditing}
        itemType={nodeType}
        id={node?.attrs?.id}
        toggleModal={() => setIsEditing((prev) => !prev)}
      />
    </>
  );
};

const findMentionByCode = (suggestions, code) => {
  return !code
    ? null
    : suggestions.find((suggestion) => (suggestion.code || suggestion.unique_code) === code);
};
