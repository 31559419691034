import { useRef, useState } from 'react';
import SituationBuilder from '../../resources/triggers/SituationBuilder';
import { ComboBox, ComboBoxProps } from '../../utils/ComboBox';
import { SlidePanel } from '../../utils/panels/SlidePanel';

export const TriggerComboBox = (props: Omit<ComboBoxProps, 'createButtonLabel' | 'onCreate'>) => {
  const [modal, setModal] = useState(false);
  const [triggerVal, setTrigger] = useState<string | number>('');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const createButtonLabel = 'Create New Trigger';
  const onCreate = () => {
    buttonRef.current?.click();
  };

  const comboBoxProps = {
    ...props,
    onCreate,
    createButtonLabel,
    ...(triggerVal ? { selectedValue: triggerVal } : {}),
  };
  // Reset the new created trigger value once it has been used.
  triggerVal && setTrigger('');
  return (
    <>
      <ComboBox {...comboBoxProps} />
      <div className='hidden'>
        <SlidePanel.Child>
          <SlidePanel.ChildButton ref={buttonRef} />
          <SlidePanel.ChildBody className='w-[622px] pt-[10px]'>
            {(close) => (
              <>
                <SituationBuilderWrapper
                  toggleModal={close}
                  isFullHeight={true}
                  selectTrigger={(value) => {
                    setTrigger(value);
                    // Set the value of the ComboBox in the form.
                    props.onChange?.(value as string | number);
                  }}
                  createModal={true}
                  modal={modal}
                  toggleModalPane={() => setModal(false)}
                  savePosition={() => {}}
                />
              </>
            )}
          </SlidePanel.ChildBody>
        </SlidePanel.Child>
      </div>
    </>
  );
};

// If It's used without wrapping, an error occurs when applying props (probably because it is a JS file).
interface SituationBuilderWrapperProps {
  toggleModal: () => void;
  selectTrigger: (value) => void;
  isFullHeight: boolean;
  createModal: boolean;
  modal: boolean;
  toggleModalPane: () => void;
  savePosition: () => void;
}

const SituationBuilderWrapper: React.FC<SituationBuilderWrapperProps> = (props) => {
  return <SituationBuilder {...props} />;
};
