import { Divider } from '@material-ui/core';
import APIBox from 'components/app-previews/APIBox';
import AmbientListening from 'components/app-previews/AmbientListening';
import ChoicePanel from 'components/app-previews/ChoicePanel';
import DescriptionPanelBetaPreview from 'components/app-previews/DescriptionPanelBeta';
import GPTBox from 'components/app-previews/GPTBox';
import MultiChoice from 'components/app-previews/MultiChoice';
import SectionDividerPreview from 'components/app-previews/SectionDivider';
import TextInput from 'components/app-previews/TextInput';
import ValuePanel from 'components/app-previews/ValuePanel';
import { setDraggableId } from 'components/module-detail/ConversationBoardUtils';
import { compareCardPosition } from 'components/utils/general/compareCardPosition';
import { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { TOGGLE_UI_ELEMENT_COMMAND } from './ContainerCards';
import { GroupCardChildProps } from './types';

export const GroupCardChild = (props: GroupCardChildProps) => {
  let uiElements = [...props.uiElements];
  uiElements.sort(compareCardPosition);

  return (
    <>
      {uiElements.map((uiElement, index) => (
        <Draggable
          key={setDraggableId(uiElement)}
          draggableId={setDraggableId(uiElement)}
          index={index}
          isDragDisabled={hasViewOnlyPermission()}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
              <Fragment key={uiElement.id}>
                {renderCardComponentByResourceType(uiElement, props, index)}
              </Fragment>
              {uiElements[index + 1] && (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: 33,
                    paddingRight: 33,
                  }}
                >
                  <Divider />
                </div>
              )}
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

// TODO: as there's editMode props, ref seems not needed anymore
const renderCardComponentByResourceType = (uiElement, props, i) => {
  switch (uiElement.resourcetype) {
    case 'ChoicePanel':
      return (
        <ChoicePanel
          // ref={(ref) => (this.choiceReferences[i] = ref)}
          editMode={uiElement.editMode}
          container
          key={uiElement.id}
          elements={uiElement.question_items}
          panelId={uiElement.id}
          choices={uiElement.choices}
          question={uiElement.question}
          subtitle={uiElement.subtitle}
          trigger={uiElement.trigger}
          containerCard={true}
          editorChoice={props.editorChoice}
          editorQuestion={props.editorQuestion}
          editorElements={props.editorElements}
          editorSubtitle={props.editorSubtitle}
          editorTrigger={props.editorTrigger}
          deleteCard={props.deleteUiElement}
          duplicateCard={props.duplicateCard}
          update={() => props.toggleUiElement('createChoicePanel', uiElement.id, i)}
        />
      );
    case 'ValuePanel':
      return (
        <ValuePanel
          // ref={(ref) => (this.valueReferences[i] = ref)}
          editMode={uiElement.editMode}
          container
          key={uiElement.id}
          deleteCard={props.deleteUiElement}
          panelId={uiElement.id}
          name={uiElement.name}
          items={uiElement.panel_item}
          trigger={uiElement.trigger}
          containerCard={true}
          update={() => props.toggleUiElement('createValuePanel', uiElement.id, i)}
          editorItems={props.editorItems}
          editorTitle={props.editorTitle}
          editorShowNext={props.editorShowNext}
          editorTrigger={props.editorTrigger}
        />
      );
    case 'DescriptionPanel':
      return (
        <DescriptionPanelBetaPreview // TODO: beta??
          // ref={(ref) => (this.descriptionv2References[i] = ref)}
          editMode={uiElement.editMode}
          container
          title={uiElement.title}
          long_description={uiElement.description_text_long}
          short_description={uiElement.description_text_short} // TODO: seems not used
          shortDescriptionJson={uiElement.new_description_text_content_short}
          longDescriptionJson={uiElement.new_description_text_content_long}
          shortDescriptionTiptap={props.shortDescriptionTiptap || uiElement.short_desc_json_tiptap}
          longDescriptionTiptap={props.longDescriptionTiptap || uiElement.long_desc_json_tiptap}
          isLongDescEnabled={uiElement.is_long_desc_enabled}
          elements={uiElement.question_items}
          trigger={uiElement.trigger}
          containerCard={true}
          deleteCard={props.deleteUiElement}
          duplicateCard={props.duplicateCard}
          panelId={uiElement.id}
          status={uiElement.status}
          action={uiElement.is_action}
          update={() => props.toggleUiElement('createDescriptionBetaPanel', uiElement.id, i)}
          editorLongDesc={props.editorLongDesc}
          editorShortDesc={props.editorShortDesc}
          editorSDJson={props.editorSDJson}
          editorLDJson={props.editorLDJson}
          isLongDescEnabledEdit={props.isLongDescEnabledEdit}
          editorTitle={props.editorTitle}
          editorType={props.editorType}
          editorAction={props.editorAction}
          editorElements={props.editorElements}
          editorTrigger={props.editorTrigger}
        />
      );
    case 'SectionDivider':
      return (
        <SectionDividerPreview
          // ref={(ref) => (this.dividerReferences[i] = ref)}
          editMode={uiElement.editMode}
          container
          key={uiElement.id}
          title={uiElement.title}
          subtitle={uiElement.subtitle}
          trigger={uiElement.trigger}
          deleteCard={props.deleteUiElement}
          update={() => props.toggleUiElement('createSectionDivider', uiElement.id, null)}
          panelId={uiElement.id}
          containerCard={true}
          editorTitle={props.editorTitle}
          editorSubtitle={props.editorSubtitle}
          editorTrigger={props.editorTrigger}
        />
      );
    case 'MultiChoicePanel':
      return (
        <MultiChoice
          // ref={(ref) => (this.multiChoiceReferences[i] = ref)}
          container
          editMode={uiElement.editMode}
          key={uiElement.id}
          update={() => props.toggleUiElement('createMultiChoicePanel', uiElement.id, i)}
          deleteCard={props.deleteUiElement}
          duplicateCard={props.duplicateCard}
          question={uiElement.question}
          choices={uiElement.choices.filter((choice) => choice.name !== 'submitted')}
          panelId={uiElement.id}
          subtitle={uiElement.subtitle}
          trigger={uiElement.trigger}
          containerCard={true}
          editorShowSubmit={props.editorShowSubmit}
          editorChoice={props.editorChoice}
          editorQuestion={props.editorQuestion}
          editorElements={props.editorElements}
          editorSubtitle={props.editorSubtitle}
          editorTrigger={props.editorTrigger}
        />
      );
    case 'GPTBox':
      return (
        <GPTBox
          container
          editMode={uiElement.editMode}
          // ref={(ref) => (this.gptBoxReferences[i] = ref)}
          key={uiElement.id}
          title={uiElement.title}
          prompt={uiElement.prompt_json}
          promptTiptap={props.richTextTiptap || uiElement.prompt_json_tiptap}
          trigger={uiElement.trigger}
          deleteCard={props.deleteUiElement}
          update={() => props.toggleUiElement('createGPTBox', uiElement.id, i)}
          editorTitle={props.editorTitle}
          editorSubtitle={props.subtitle}
          editorTrigger={props.editorTrigger}
          editorPrompt={props.richText}
          panelId={uiElement.id}
          containerCard={false} // TODO: why false?
        />
      );
    case 'APIBox':
      return (
        <APIBox
          container
          editMode={uiElement.editMode}
          // ref={(ref) => (this.apiBoxReferences[i] = ref)}
          key={uiElement.id}
          title={uiElement.title}
          apiResource={uiElement.api_resource}
          prompt={uiElement.payload_json}
          trigger={uiElement.trigger}
          deleteCard={props.deleteUiElement}
          update={() => props.toggleUiElement('createAPIBox', uiElement.id, i)}
          editorTitle={props.editorTitle}
          editorSubtitle={props.subtitle}
          editorTrigger={props.editorTrigger}
          editorPrompt={props.richText}
          panelId={uiElement.id}
          containerCard={false} // TODO: why false?
        />
      );
    case 'TextInput':
      return (
        <TextInput
          editMode={uiElement.id === props.cardOnEdit.id ? true : false}
          // ref={(ref) => (this.textInputReferences[i] = ref)}
          key={uiElement.id}
          title={uiElement.title}
          subtitle={uiElement.subtitle}
          isOptional={uiElement.is_optional}
          defaultValue={uiElement.default_value}
          defaultValueTiptap={uiElement.default_value_tiptap}
          editorDefaultValue={props.richText}
          trigger={uiElement.trigger}
          deleteCard={props.deleteUiElement}
          update={() =>
            props.toggleUiElementTextInput(
              'createTextInput',
              uiElement.id,
              i,
              TOGGLE_UI_ELEMENT_COMMAND.EDIT
            )
          }
          /*
            TODO: in TextInput, below prop is not used
            search entire project and delete all after check again
          */
          // editorTitle={props.editorTitle}
          editorTrigger={props.editorTrigger}
          panelId={uiElement.id}
          container
          containerCard={true}
          cardOnEdit={props.cardOnEdit}
        />
      );
    case 'AmbientListening':
      const isEditMode = props?.uiElementId === uiElement.id;
      return (
        <AmbientListening
          container
          key={uiElement.id}
          editMode={isEditMode}
          panelId={uiElement.id}
          deleteCard={props.deleteUiElement}
          title={isEditMode ? props?.editorTitle : uiElement.title}
          subtitle={isEditMode ? props?.editorSubtitle : uiElement.subtitle}
          trigger={isEditMode ? props.editorTrigger : uiElement.trigger}
          variable={isEditMode ? props?.variable : uiElement.variable_item?.name}
          update={() => props.toggleUiElement('createAmbientListening', uiElement.id, i)}
        />
      );
    default:
      return null;
  }
};
