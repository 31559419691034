import { Editor } from '@tiptap/core';
import { CellSelection } from '@tiptap/pm/tables';

export const getSelectedNode = (editor: Editor | null) => {
  const selection = editor?.state?.selection;
  const node = selection?.$anchor?.nodeAfter;
  const isCellSelection = selection instanceof CellSelection;

  const isTableContentSelected = selection?.content()?.content?.toString().includes('table');
  const isTableSelected = isCellSelection || isTableContentSelected;

  const isImageSelected = editor?.isActive('image');
  const isLinkSelected = editor?.isActive('linkPreview');
  const isContactNoSelected = editor?.isActive('mention') && node?.attrs?.type === 'phone_number';

  return {
    attrs: node?.attrs,
    type: isTableSelected
      ? 'table_cell'
      : isLinkSelected
      ? 'link'
      : isContactNoSelected
      ? 'contact_no'
      : isImageSelected
      ? 'image'
      : '',
  };
};
