import { Switch, withStyles } from '@material-ui/core';

const GreenSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },

    '&$checked + $track': {
      backgroundColor: '#23C29D',
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

function ToggleSwitch({ checked, toggleSwitch }) {
  return <GreenSwitch checked={checked} onClick={() => toggleSwitch()} disableRipple />;
}

export default ToggleSwitch;
