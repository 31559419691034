import {
  DraftConverter,
  type TextType,
  addMark,
  MarkType,
  isInlineStyleRange,
  DocumentType,
} from '@tiptap/draftjs-to-tiptap';
import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';
import { addAttribute, createAvoNode } from './utils';
import { ExpandedDraftInlineStyleType, Options } from './types';

// The method splitTextByEntityRangesAndInlineStyleRanges lacks the functionality
// to convert mention entities to tiptap nodes. The class is extended and modified
// to include this functionality.
// https://github.com/ueberdosis/draft-js-to-tiptap/blob/main/src/draftConverter.ts#L164
export class AvoDraftConverter extends DraftConverter {
  // override for removing custom filtered match range or entity
  mapRangeToMark({
    range,
    entityMap,
    doc,
    block,
  }: {
    range: RawDraftInlineStyleRange | RawDraftEntityRange;
    entityMap: RawDraftContentState['entityMap'];
    doc: DocumentType;
    block: RawDraftContentBlock;
  }): MarkType | null {
    if (isInlineStyleRange(range)) {
      try {
        const inlineStyle =
          this.options.mapInlineStyleToMark({
            range,
            converter: this,
            doc,
            block,
          }) ?? null;

        if (inlineStyle) {
          return inlineStyle;
        }
      } catch (e) {
        console.error(e);
      }

      if ((range.style as ExpandedDraftInlineStyleType) !== 'HEADER') {
        this.unmatched.inlineStyles.push(range);
      }
      return null;
    }

    try {
      const entity =
        this.options.mapEntityToMark({
          range,
          entityMap,
          converter: this,
          doc,
          block,
        }) ?? null;

      if (entity) {
        return entity;
      }
    } catch (e) {
      console.error(e);
    }

    if (entityMap[range.key].type !== '#mention') {
      this.unmatched.entities[range.key] = entityMap[range.key];
    }
    return null;
  }

  /**
   * This function splits a text into Nodes based on the entity ranges and inline style ranges.
   * Applying them as marks to the text. Which may overlap in their ranges.
   */
  splitTextByEntityRangesAndInlineStyleRanges(options: Options): TextType[] {
    const allRanges = [...options.block.entityRanges, ...options.block.inlineStyleRanges].sort(
      (a, b) => {
        // sort by range, then by length
        if (a.offset === b.offset) {
          return a.length - b.length;
        }
        return a.offset - b.offset;
      }
    );

    let result: {
      text: string;
      ranges: (RawDraftEntityRange | RawDraftInlineStyleRange)[];
    }[] = [];
    let stylesAtPosition: {
      [key: number]: (RawDraftEntityRange | RawDraftInlineStyleRange)[];
    } = {};

    // Create a map of styles at each position
    for (let range of allRanges) {
      for (let i = range.offset; i < range.offset + range.length; i++) {
        if (!stylesAtPosition[i]) {
          stylesAtPosition[i] = [];
        }
        stylesAtPosition[i].push(range);
      }
    }

    // Split the text into groups by their range
    let currentRanges: (RawDraftEntityRange | RawDraftInlineStyleRange)[] = [];
    let currentText: string = '';
    for (let i = 0; i < options.block.text.length; i++) {
      let styles = stylesAtPosition[i] || [];
      if (
        styles.length !== currentRanges.length ||
        !styles.every((style) => currentRanges.includes(style))
      ) {
        if (currentText) {
          result.push({ text: currentText, ranges: currentRanges });
        }
        currentText = '';
        currentRanges = styles;
      }
      currentText += options.block.text[i];
    }

    if (currentText) {
      result.push({ text: currentText, ranges: currentRanges });
    }

    return result.map(({ text, ranges }) => {
      const textNode = createAvoNode(text, ranges, options);

      ranges.forEach((range) => {
        addMark(
          textNode,
          this.mapRangeToMark({
            range,
            entityMap: options.entityMap,
            doc: options.doc,
            block: options.block,
          })
        );
        if (!isInlineStyleRange(range)) {
          addAttribute(
            textNode,
            this.mapEntityToNode({
              range: range as any,
              converter: this,
              ...options,
            }) as any
          );
        }
      });

      return textNode;
    });
  }
}
