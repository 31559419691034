import { DeviceSelector } from '@avomd/shared-library';
import { useLocation } from 'react-router';
export default function Simulate() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const link = decodeURIComponent(params.get('link') || '{}');
  return (
    <DeviceSelector>
      <iframe
        src={link}
        width='100%'
        height='100%'
        style={{ border: 'none' }}
        allow='clipboard-write; microphone'
      />
    </DeviceSelector>
  );
}
