import { useContext, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { ConvertContext } from '../../layout/ModuleLayout';
import DraftJSEditorPreview from '../draftJS/DraftJSEditorPreview';
import { MentionProvider } from '../module/MentionContext';
import { ModuleContext } from '../module/ModuleContext';
import { convertFromJSON } from '../tiptap/converter/convertFromJSON';
import { TiptapPreview } from '../tiptap/Tiptap';
import { getEditorType } from './AvoEditorTextInput';

interface AvoEditorPreviewProps {
  contentDraftJS: string;
  contentTiptap: string;
  key?: any; // used only in ConditionalTextTable
  isSimulator?: boolean; // used only in NotePreview
  isLineClampTwo?: boolean;
}

export const AvoEditorPreview = (props: AvoEditorPreviewProps) => {
  const { contentTiptap, contentDraftJS, key, isSimulator, isLineClampTwo } = props;
  const { module } = useContext(ModuleContext);

  if (!contentTiptap && !contentDraftJS) return null;
  if (!module) return null;

  const editorType = getEditorType(module);

  switch (editorType) {
    case 'Tiptap':
      return (
        <MentionProvider excludedSuggestionTypes={[]}>
          <TiptapPreview content={contentTiptap} isLineClampTwo={isLineClampTwo} />
        </MentionProvider>
      );
    case 'AutoSuggest':
      return <ConvertableEditorPreview {...props} />;
  }
};

const ConvertableEditorPreview = (props: AvoEditorPreviewProps) => {
  const { contentDraftJS, key, isSimulator, isLineClampTwo } = props;
  const isConverted = useContext(ConvertContext);
  const [convertedTiptapContent, setConvertedTiptapContent] = useState(null);
  useEffect(() => {
    if (isConverted && props.contentDraftJS) {
      const convertedContent = convertFromJSON(props.contentDraftJS as any);
      if (convertedContent?.document) {
        setConvertedTiptapContent(convertedContent.document as any);
      }
    }
  }, [isConverted]);

  return (
    <MentionProvider excludedSuggestionTypes={[]}>
      <div className={twJoin(isConverted && 'hidden')}>
        <DraftJSEditorPreview
          contentJson={contentDraftJS}
          key={key}
          isSimulator={isSimulator}
          isLineClampTwo={isLineClampTwo}
        />
      </div>
      {isConverted && (
        <TiptapPreview content={convertedTiptapContent} isLineClampTwo={isLineClampTwo} />
      )}
    </MentionProvider>
  );
};
