import { SuggestionTypeEnum } from 'components/utils/tiptap/tiptapInterfaces';
import { SuggestionHandlerProps } from '../MentionPopover';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MentionContext } from '../../../module/MentionContext';
import { ComboBox } from '../../../ComboBox';
import Input from '../../../Input';
import { isValidURL } from '../../../../../utils/utilityFunctions';

export const LinkHandler = ({ onChange, onDisabledChange }: SuggestionHandlerProps) => {
  const { suggestions } = useContext(MentionContext);

  const filteredSuggestions = useMemo(() => {
    const suggestionType = SuggestionTypeEnum.INFOBOX;

    return suggestions
      .filter((suggestion) => ['algo', 'calculator'].includes(suggestion.type))
      .map((option) => ({
        value: option.code,
        label: option.name,
      }));
  }, [suggestions]);

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [textToDisplay, setTextToDisplay] = useState<string>('');
  const isOptionAvailable = useMemo(
    () => !!selectedOption && suggestions.find((option) => option.code === selectedOption),
    [suggestions, selectedOption]
  );

  useEffect(() => {
    const option = suggestions.find((option) => option.code === selectedOption);
    if (option) {
      onChange({ ...option, name: textToDisplay });
      onDisabledChange(false);
    } else if (textToDisplay && isValidURL(selectedOption)) {
      onChange({
        href: selectedOption,
        title: textToDisplay,
      });
      onDisabledChange(false);
    } else {
      onDisabledChange(true);
    }
  }, [suggestions, selectedOption, textToDisplay]);

  return (
    <>
      <ComboBox
        options={filteredSuggestions}
        selectedValue={selectedOption}
        allowCustomValue
        onChange={(value) => setSelectedOption(value as string)}
      />
      {!isOptionAvailable && (
        <Input
          type='text'
          value={textToDisplay}
          placeholder='Text to display'
          className='!px-3 !py-2.5 text-body-2 text-gray-900'
          onChange={(event) => setTextToDisplay(event.target.value)}
        />
      )}
    </>
  );
};
