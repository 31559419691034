import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

interface LinkPreviewOptions {
  HTMLAttributes: {
    [key: string]: any;
  };
  renderText: (options: { node: ProseMirrorNode }) => string;
}

export type LinkPreviewData = {
  href: string;
  title: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    linkPreview: {
      setCustomLink: (data: LinkPreviewData) => ReturnType;
    };
  }
}

export const LinkNode = Node.create<LinkPreviewOptions>({
  name: 'linkPreview',
  group: 'inline',
  inline: true,

  addOptions() {
    return {
      protocols: [],
      HTMLAttributes: {
        class: null,
      },
      renderText({ node }) {
        return `${node.attrs.title}`;
      },
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  renderText({ node }) {
    return this.options.renderText({ node });
  },

  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
  },

  renderHTML({ node, HTMLAttributes }) {
    // False positive; we're explicitly checking for javascript: links to ignore them
    // eslint-disable-next-line no-script-url
    if (HTMLAttributes.href?.startsWith('javascript:')) {
      // strip out the href
      return [
        'a',
        mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, href: '' }),
        this.options.renderText({ node: node }),
      ];
    }
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderText({ node: node }),
    ];
  },

  addCommands() {
    return {
      setCustomLink:
        (data: LinkPreviewData) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create(data);

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },
});
