import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getUniversalCalculators } from 'actions/calculators/getUniversalCalculators';
import { moduleAPI } from 'api/module';
import { ModuleHeader } from 'components/bars/ModuleHeader';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import SyncModal from 'components/utils/firebase/sync-modal';
import { ModuleContext, ModuleProvider } from 'components/utils/module/ModuleContext';
import { CustomToast } from 'components/utils/toast-message';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { getCalculators } from '../../actions/calculators/getCalculators';
import { getResources } from '../../actions/resources/loadAllResources';
import { MODULE_TYPES } from '../../constants';
import { withTeam } from '../../hooks/useTeam';
import NotFoundPage from '../home/NotFoundPage';
import { simulateInWebapp } from './ConversationBoardUtils';
import AlgoTabs, { ALGO_RESOURCES, ResourceTypes } from './tabs/AlgoTabs';

const AlgoBoardUi = ({ getResources, getUniversalCalculators, getCalculators }) => {
  const { resource } = useParams<{ resource: ResourceTypes | undefined }>();
  const [syncModal, setSyncModal] = useState(false);
  const { module: selectedModule } = useContext(ModuleContext);
  const { moduleId } = useParams<{ moduleId: string }>();
  const queryClient = useQueryClient();

  const { mutate: verify } = useMutation(moduleAPI.verifyModule, {
    onSuccess: () => {
      toast.success(CustomToast, { data: 'Algo verified successfully' });
    },
  });

  useEffect(() => {
    if (!moduleId) return;

    getUniversalCalculators();
    getCalculators();

    // TODO: remove when redux resources data are removed
    getResources(moduleId, MODULE_TYPES.ALGO);
  }, [selectedModule]);

  const simulate = () => simulateInWebapp(moduleId, MODULE_TYPES.ALGO);

  if (!!resource && !Object.keys(ALGO_RESOURCES).includes(resource)) {
    return <NotFoundPage />;
  }

  return (
    <>
      {/* TODO: Remove the global style for materialize and bootstrap in future */}
      {globalStyles}
      <div className='box-border flex h-full w-full flex-wrap items-start'>
        <ModuleHeader />

        <div
          className={twMerge(!!resource && 'bg-primary-bg-resource', ' flex min-h-screen w-full')}
        >
          <AlgoTabs />
        </div>
        {selectedModule && (
          <SyncModal
            module={selectedModule}
            modal={syncModal}
            toggleModal={() => setSyncModal(false)}
            isAIKnowledgeBase={false}
            syncStatus={() => {
              // TODO After converting the sync modal to a functional component, this prop will no longer be needed.
              queryClient.invalidateQueries([MODULE_TYPES.ALGO, moduleId]);
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const AlgoBoardWithRedux = withTeam(
  connect(mapStateToProps, {
    getUniversalCalculators,
    getCalculators,
    getResources,
  })(AlgoBoardUi)
);
// AlgoBoard with module provider
const AlgoBoard = () => (
  <ModuleProvider moduleType={MODULE_TYPES.ALGO}>
    <AlgoBoardWithRedux />
  </ModuleProvider>
);
// Export AlgoBoard as the default export
export default AlgoBoard;
