import { Backdrop, Chip, Divider } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { VariablesPreview } from 'components/app-previews/common/variable';
import { AddItemButton } from 'components/module-detail/container/AddItemButton';
import {
  calculatePositionOfNewChildCard,
  getGroupVariables,
} from 'components/module-detail/container/GroupCardUtil';
import APIBoxForm from 'components/panels/APIBoxForm';
import AmbientListeningForm from 'components/panels/AmbientListeningForm';
import ChoicePanelForm from 'components/panels/ChoicePanelForm';
import DescriptionPanelBetaForm from 'components/panels/DescriptionPanelBetaForm';
import GPTBoxForm from 'components/panels/GPTBoxForm';
import MultiChoiceForm from 'components/panels/MultiChoicePanelForm';
import TextInputForm from 'components/panels/TextInputForm';
import ValuePanelForm from 'components/panels/ValuePanelForm';
import {
  createDefaultTextInputCardForContainerCard,
  findCardById,
  initialValuesForCard,
  initialValuesForTextInputCard,
} from 'components/utils/ConversationBoardUtil';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { CARD_DELETE_BUTTON, CARD_EDIT_BUTTON } from 'constants/variables';
import { Card } from 'interfaces/Card';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { GroupCardChild } from './GroupCardChild';
import {
  ContainerCardsProps,
  ContainerCardsState,
  ToggleUiElementTextInputType,
  ToggleUiElementType,
} from './types';

export const TOGGLE_UI_ELEMENT_COMMAND = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CANCEL: 'cancel',
};

class ContainerCards extends React.Component<ContainerCardsProps, ContainerCardsState> {
  // TODO: consider to delete all the references as editMode props seems be able to substitute
  choiceReferences: any[];
  valueReferences: any[];
  descriptionReferences: any[];
  introCardReference: any[];
  descriptionv2References: any[];
  multiChoiceReferences: any[];
  dividerReferences: any[];
  textInputReferences: any[];
  gptBoxReferences: any[];
  apiBoxReferences: any[];

  constructor(props) {
    super(props);
    this.choiceReferences = [];
    this.valueReferences = [];
    this.descriptionReferences = [];
    this.introCardReference = [];
    this.descriptionv2References = [];
    this.multiChoiceReferences = [];
    this.dividerReferences = [];
    this.textInputReferences = [];
    this.gptBoxReferences = [];
    this.apiBoxReferences = [];

    this.state = {
      createChoicePanel: false,
      createAmbientListening: false,
      createGPTBox: false,
      createAPIBox: false,
      createMultiChoicePanel: false,
      createDescriptionBetaPanel: false,
      createTextInput: false,
      createValuePanel: false,
      closeUiElement: false,
      paneToClose: '',
      panelOpen: false,
      uiElementId: '',
      anchorElForm: null,
      deleteContainerModal: false,
      cardOnEdit: initialValuesForCard,
      selectedItem: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorElForm: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorElForm: null });
  };

  closeUiElementPane = (paneToClose) => {
    this.setState((previous) => ({
      closeUiElement: !previous.closeUiElement,
      paneToClose: paneToClose,
    }));
  };

  /* 
    TODO: this method is for temporary solution only for TextInput. 
    Other cards use 'this.props.handleAddTitle'. Need a refactoring later.
  */
  handleAddTitleTextInput = (title) => {
    this.setState((prev) => ({
      cardOnEdit: {
        ...prev.cardOnEdit,
        title: title,
      },
    }));
  };
  handleAddSubtitleTextInput = (subtitle) => {
    this.setState((prev) => ({
      cardOnEdit: {
        ...prev.cardOnEdit,
        subtitle,
      },
    }));
  };
  handleTextInputOptional = (textInputOptional) => {
    this.setState((prev) => ({
      cardOnEdit: {
        ...prev.cardOnEdit,
        textInputOptional,
      },
    }));
  };
  handleAmbientListeningOptional = (ambientListeningOptional) => {
    this.setState((prev) => ({
      cardOnEdit: {
        ...prev.cardOnEdit,
        ambientListeningOptional,
      },
    }));
  };
  handleDefaultValueTiptap = (json) => {
    this.setState((prev) => ({
      cardOnEdit: {
        ...prev.cardOnEdit,
        default_value_tiptap: json,
      },
    }));
  };

  startEditTextInput = (cardId: string) => {
    let card: Card = initialValuesForTextInputCard;

    if (cardId && cardId !== initialValuesForTextInputCard.id) {
      const containerCard = this.props.container;
      const cards = containerCard.ui_elements;
      card = findCardById(cards, cardId);
    }

    this.setState({
      createTextInput: true,
      uiElementId: card.id,
      cardOnEdit: card,
      panelOpen: true,
    });
  };

  endEditTextInput = () => {
    this.setState({
      createTextInput: false,
      uiElementId: '',
      cardOnEdit: initialValuesForCard,
      panelOpen: false,
    });
  };

  /* 
    TODO: this method is for temporary solution only for TextInput. 
    Other cards use 'toggleUiElement'. Need a refactoring later.
  */
  toggleUiElementTextInput: ToggleUiElementTextInputType = (modal, cardId: any, i, command) => {
    if (modal === 'createTextInput' && command === TOGGLE_UI_ELEMENT_COMMAND.ADD) {
      const posOfContainerCardInContainerBoard = this.props.containerNum + 2;
      const newCard = createDefaultTextInputCardForContainerCard(
        this.props.container,
        posOfContainerCardInContainerBoard
      );
      this.props.onCardChangeInContainerCard(command, this.props.container, newCard);
      this.startEditTextInput(newCard.id);
    }
    if (modal === 'createTextInput' && command === TOGGLE_UI_ELEMENT_COMMAND.EDIT) {
      const curCard = findCardById(this.props.container.ui_elements, cardId);
      this.startEditTextInput(curCard.id);
    }
    if (modal === 'createTextInput' && command === 'cancel_from_create') {
      const containerCard = this.props.container;
      const cards = containerCard.ui_elements;
      const orgCard = findCardById(cards, cardId);
      if (orgCard) {
        this.props.onCardChangeInContainerCard('cancel_from_create', containerCard, orgCard);
      }
      this.endEditTextInput();
    }
    if (modal === 'createTextInput' && command === 'cancel_from_edit') {
      const containerCard = this.props.container;
      const cards = containerCard.ui_elements;
      const orgCard = findCardById(cards, cardId);
      if (orgCard) {
        this.props.onCardChangeInContainerCard('cancel_from_edit', containerCard, orgCard);
      }
      this.endEditTextInput();
    }
    if (modal === 'createTextInput' && command === 'apply_from_edit') {
      this.endEditTextInput();
    }
    if (modal === 'createTextInput' && command === 'apply_from_create') {
      this.endEditTextInput();
    }
  };

  toggleUiElement: ToggleUiElementType = (
    modal: string,
    cardId: string,
    i: string | number | null
  ) => {
    // this.clearEditorStates();
    //panel open state is used to open/close overlay that doesnt conflict with sidepanels
    //selected item is the current card selected/opened.. we use it to later turn the edit mode off for the current card
    this.setState((previous) => ({
      uiElementId: cardId,
      panelOpen: !previous.panelOpen,
      selectedItem: i,
    }));
    this.toggleModal(modal);
    this.props.stopLivePreview();
    // i is the index of the card currently being edited..we use it to fetch its reference from the references array and enabled editing on the card
    // TODO: to be deleted
    // if (i) {
    //   //using references to toggle editModes
    //   if (modal === 'createChoicePanel') this.choiceReferences[i].handleEditMode();
    //   if (modal === 'createValuePanel') this.valueReferences[i].handleEditMode();
    //   if (modal === 'createDescriptionPanel') {
    //     this.descriptionReferences[i].handleEditMode();
    //   }
    //   if (modal === 'createDescriptionBetaPanel') {
    //     this.descriptionv2References[i].handleEditMode();
    //   }
    //   if (modal === 'createMultiChoicePanel') {
    //     this.multiChoiceReferences[i].handleEditMode();
    //   }
    //   if (modal === 'createSectionDivider') {
    //     this.dividerReferences[i].handleEditMode();
    //   }
    //   if (modal === 'createTextInput') {
    //     this.textInputReferences[i].handleEditMode();
    //   }
    //   if (modal === 'createGPTBox') {
    //     this.gptBoxReferences[i].handleEditMode();
    //   }
    // }
  };

  toggleModal = (cardType: string) => {
    switch (cardType) {
      case 'createChoicePanel':
        this.setState((prev) => ({ createChoicePanel: !prev.createChoicePanel }));
        break;
      case 'createValuePanel':
        this.setState((prev) => ({ createValuePanel: !prev.createValuePanel }));
        break;
      case 'createDescriptionBetaPanel':
        this.setState((prev) => ({ createDescriptionBetaPanel: !prev.createDescriptionBetaPanel }));
        break;
      case 'createMultiChoicePanel':
        this.setState((prev) => ({ createMultiChoicePanel: !prev.createMultiChoicePanel }));
        break;
      case 'createTextInput':
        this.setState((prev) => ({ createTextInput: !prev.createTextInput }));
        break;
      case 'createGPTBox':
        this.setState((prev) => ({ createGPTBox: !prev.createGPTBox }));
        break;
      case 'createAPIBox':
        this.setState((prev) => ({ createAPIBox: !prev.createAPIBox }));
        break;
      case 'createAmbientListening':
        this.setState((prev) => ({ createAmbientListening: !prev.createAmbientListening }));
        break;
      case 'deleteContainerModal':
        this.setState((prev) => ({ deleteContainerModal: !prev.deleteContainerModal }));
        break;
    }
  };

  render() {
    const container = this.props.container;
    const uiElements = container.ui_elements;
    const positioinOfNewCard = calculatePositionOfNewChildCard(uiElements, this.props.uiElemCount);
    const variables = getGroupVariables(uiElements);

    return (
      <div className='container-row'>
        <Typography className='p-2.5 !font-bold'>
          {container?.trigger ? (
            <Chip
              size='small'
              className='!ml-5 !bg-primary-600 !text-white'
              label={container?.trigger?.title || 'Title is not assigned'}
            />
          ) : (
            <Chip size='small' className='!ml-5 !bg-red-600 !text-white' label='Not Assigned' />
          )}
        </Typography>
        <div className='container-card col-md-12 '>
          <div className='card-content text-justify'>
            <div>
              <Backdrop
                style={{
                  zIndex: 12,
                  color: '#08A88E',
                  background: 'rgba(255, 255, 255, 0.8)',
                }}
                open={this.state.panelOpen}
              />
              {/* Sliding Panel for Choice Panel Form */}
              <SlidingPane
                isOpen={this.state.createChoicePanel}
                onRequestClose={() => this.closeUiElementPane('createChoicePanel')}
                from='right'
                className='no-padding sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div style={{ backgroundColor: 'white' }}>
                  <ChoicePanelForm
                    calculatorId={this.props.calculatorId}
                    handleSelectedItem={this.props.handleSelectedItem}
                    handleLivePreview={this.props.handleLivePreview}
                    handleAddChoices={this.props.handleAddChoices}
                    handleAddQuestion={this.props.handleAddQuestion}
                    handleAddExample={this.props.handleAddExample}
                    handleAddSubtitle={this.props.handleAddSubtitle}
                    handleAddTools={this.props.handleAddTools}
                    handleAddTrigger={this.props.handleAddTrigger}
                    moduleId={this.props.moduleId}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createChoicePanel',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    choiceId={this.state.uiElementId}
                    container={container.id}
                    position={positioinOfNewCard}
                    resetState={this.props.resetContainersAndUi}
                    resetAnswerPages={this.props.resetAnswerPages}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for Value Panel Form */}
              <SlidingPane
                isOpen={this.state.createValuePanel}
                onRequestClose={() => this.closeUiElementPane('createValuePanel')}
                from='right'
                className='no-padding sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div style={{ backgroundColor: 'white' }}>
                  <ValuePanelForm
                    calculatorId={this.props.calculatorId}
                    startPreview={this.props.startPreview}
                    handleShowNext={this.props.handleShowNext}
                    startBackdrop={this.props.startBackdrop}
                    stopBackdrop={this.props.stopBackdrop}
                    handleAddItems={this.props.handleAddItems}
                    handleSelectedItem={this.props.handleSelectedItem}
                    container={container.id}
                    handleLivePreview={this.props.handleLivePreview}
                    handleAddExample={this.props.handleAddExample}
                    handleAddTitle={this.props.handleAddTitle}
                    valuePanelId={this.state.uiElementId}
                    handleAddTools={this.props.handleAddTools}
                    handleAddTrigger={this.props.handleAddTrigger}
                    moduleId={this.props.moduleId}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createValuePanel',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    position={positioinOfNewCard}
                    resetState={this.props.resetContainersAndUi}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for MultiChoice Form */}
              <SlidingPane
                isOpen={this.state.createMultiChoicePanel}
                onRequestClose={() => this.closeUiElementPane('createMultiChoicePanel')}
                from='right'
                className='no-padding sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div style={{ backgroundColor: 'white' }}>
                  <MultiChoiceForm
                    calculatorId={this.props.calculatorId}
                    handleSelectedItem={this.props.handleSelectedItem}
                    handleAddChoices={this.props.handleAddChoices}
                    handleShowSubmit={this.props.handleShowSubmit}
                    startPreview={this.props.startPreview}
                    handleAddQuestion={this.props.handleAddQuestion}
                    handleAddExample={this.props.handleAddExample}
                    handleAddSubtitle={this.props.handleAddSubtitle}
                    handleAddTools={this.props.handleAddTools}
                    handleAddTrigger={this.props.handleAddTrigger}
                    moduleId={this.props.moduleId} // TODO: it seems not used
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createMultiChoicePanel',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    multiChoicePanelId={this.state.uiElementId}
                    position={positioinOfNewCard}
                    resetState={this.props.resetContainersAndUi}
                    container={container.id}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for Description v2 Form */}
              <SlidingPane
                isOpen={this.state.createDescriptionBetaPanel}
                onRequestClose={() => this.closeUiElementPane('createDescriptionBetaPanel')}
                from='right'
                className='no-padding sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '100%',
                  }}
                >
                  <DescriptionPanelBetaForm
                    handleSelectedItem={this.props.handleSelectedItem}
                    moduleId={this.props.moduleId}
                    position={positioinOfNewCard}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createDescriptionBetaPanel',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    descriptionPanelId={this.state.uiElementId}
                    resetState={this.props.resetContainersAndUi}
                    handleLongDescription={this.props.handleLongDescription}
                    handeLongDescriptionToggle={this.props.handeLongDescriptionToggle}
                    handleShortDescription={this.props.handleShortDescription}
                    handleDescriptionTiptap={this.props.handleDescriptionTiptap}
                    startPreview={this.props.startPreview}
                    handleAddTrigger={this.props.handleAddTrigger}
                    handleAddTitle={this.props.handleAddTitle}
                    handleAddExamples={this.props.handleAddExample}
                    handleAddDescription={this.props.handleAddDescription}
                    handleAddDescriptionType={this.props.handleAddDescriptionType}
                    handleAddTools={this.props.handleAddTools}
                    container={container.id}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for TextInput Form */}
              <SlidingPane
                isOpen={this.state.createTextInput}
                onRequestClose={() => {
                  this.closeUiElementPane('createTextInput');
                }}
                from='right'
                className='padding-remove sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '100%',
                  }}
                >
                  <TextInputForm
                    handleSelectedItem={this.props.handleSelectedItem}
                    handleRichText={this.props.handleRichText}
                    handleAddTitle={this.handleAddTitleTextInput}
                    handleAddSubtitle={this.handleAddSubtitleTextInput}
                    handleDefaultValueTiptap={this.handleDefaultValueTiptap}
                    handleAddTrigger={this.props.handleAddTrigger}
                    handleTextInputOptional={this.handleTextInputOptional}
                    moduleId={this.props.moduleId}
                    position={positioinOfNewCard}
                    toggleModalOnEdit={(command) =>
                      this.toggleUiElementTextInput(
                        'createTextInput',
                        this.state.cardOnEdit.id,
                        this.state.selectedItem ? this.state.selectedItem : null,
                        command
                      )
                    }
                    toggleModalOnCreate={(command) =>
                      this.toggleUiElementTextInput(
                        'createTextInput',
                        this.state.cardOnEdit.id,
                        this.state.selectedItem ? this.state.selectedItem : null,
                        command
                      )
                    }
                    textInputId={this.state.cardOnEdit.id}
                    resetState={this.props.resetContainersAndUi}
                    container={container.id}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for GPTBox Form */}
              <SlidingPane
                isOpen={this.state.createGPTBox}
                onRequestClose={() => this.closeUiElementPane('createGPTBox')}
                // style={{ padding: '0' }}
                from='right'
                className='padding-remove sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '100%',
                  }}
                >
                  <GPTBoxForm
                    handleRichText={this.props.handleRichText}
                    handleRichTextTiptap={this.props.handleRichTextTiptap}
                    handleSelectedItem={this.props.handleSelectedItem}
                    startPreview={this.props.startPreview}
                    handleAddTitle={this.props.handleAddTitle}
                    handleAddTrigger={this.props.handleAddTrigger}
                    moduleId={this.props.moduleId}
                    position={positioinOfNewCard}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createGPTBox',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    gptBoxId={this.state.uiElementId}
                    resetState={this.props.resetContainersAndUi}
                    container={container.id}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>
              {/* Sliding Panel for APIBox Form */}
              <SlidingPane
                isOpen={this.state.createAPIBox}
                onRequestClose={() => this.closeUiElementPane('createAPIBox')}
                // style={{ padding: '0' }}
                from='right'
                className='padding-remove sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '100%',
                  }}
                >
                  <APIBoxForm
                    handleRichText={this.props.handleRichText}
                    handleSelectedItem={this.props.handleSelectedItem}
                    startPreview={this.props.startPreview}
                    handleAddTitle={this.props.handleAddTitle}
                    handleAddTrigger={this.props.handleAddTrigger}
                    moduleId={this.props.moduleId}
                    position={positioinOfNewCard}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createAPIBox',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    apiBoxId={this.state.uiElementId}
                    resetState={this.props.resetContainersAndUi}
                    container={container.id}
                    modal={this.state.closeUiElement}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>

              <SlidingPane
                isOpen={this.state.createAmbientListening}
                onRequestClose={() => this.closeUiElementPane('createAmbientListening')}
                from='right'
                className='padding-remove sliding-panel-shadow'
                overlayClassName='sliding-panel-cards'
                width='620px'
                hideHeader
              >
                <div className='m-[-16px] h-full overflow-x-visible bg-white'>
                  <AmbientListeningForm
                    handleSelectedItem={this.props.handleSelectedItem}
                    startPreview={this.props.startPreview}
                    handleAddTitle={this.props.handleAddTitle}
                    handleAddSubtitle={this.props.handleAddSubtitle}
                    handleAddTrigger={this.props.handleAddTrigger}
                    handleAmbientListeningOptional={this.handleAmbientListeningOptional}
                    moduleId={this.props.moduleId}
                    position={positioinOfNewCard}
                    toggleModal={() =>
                      this.toggleUiElement(
                        'createAmbientListening',
                        '',
                        this.state.selectedItem ? this.state.selectedItem : null
                      )
                    }
                    ambId={this.state.uiElementId}
                    resetState={this.props.resetContainersAndUi}
                    modal={this.state.closeUiElement}
                    container={container.id}
                    closeUiElementPane={this.closeUiElementPane}
                  />
                </div>
              </SlidingPane>

              <ConfirmModal
                preset='delete'
                open={this.state.deleteContainerModal}
                content='Are you sure you want to delete this container?'
                toggleModal={() => this.toggleModal('deleteContainerModal')}
                performAction={() => {
                  this.props.deleteContainer(container.id);
                  this.toggleModal('deleteContainerModal');
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  height: '100px',
                  left: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
                className='form-to-hover'
              >
                {!hasViewOnlyPermission() && (
                  <div
                    onClick={(e) => this.handleClick(e)}
                    style={{
                      background: '#08A88E',
                      width: '33px',
                      height: '33px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      color: 'white',
                      marginLeft: 'auto',
                      marginRight: 31,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                    }}
                    className='right form-to-show'
                  >
                    <MoreHorizIcon style={{ fontSize: 20 }} />
                  </div>
                )}
              </div>
              <Typography
                style={{
                  paddingLeft: '25px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '26px',
                  lineHeight: '30px',
                  color: '#000000',
                }}
              >
                {container.name}
                <Menu
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  id='simple-menu'
                  anchorEl={this.state.anchorElForm}
                  keepMounted
                  open={Boolean(this.state.anchorElForm)}
                  onClose={() => this.handleClose()}
                >
                  <MenuItem
                    onClick={() => {
                      this.props.toggleCreateContainerCard(
                        container.id,
                        TOGGLE_UI_ELEMENT_COMMAND.EDIT
                      );
                      this.handleClose();
                    }}
                  >
                    {CARD_EDIT_BUTTON}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.toggleModal('deleteContainerModal');
                      this.handleClose();
                    }}
                  >
                    {CARD_DELETE_BUTTON}
                  </MenuItem>
                </Menu>
              </Typography>
              {container.name && (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: 33,
                    paddingRight: 33,
                  }}
                >
                  <Divider
                    style={{
                      marginTop: '30px',
                    }}
                  />
                </div>
              )}

              <Droppable
                droppableId={`droppable${container.id}`}
                type={`${this.props.containerNum}`}
              >
                {(provided) => (
                  <div ref={provided.innerRef}>
                    <GroupCardChild
                      uiElements={uiElements}
                      containerNum={this.props.containerNum}
                      deleteUiElement={this.props.deleteUiElement}
                      toggleUiElement={this.toggleUiElement}
                      editorChoice={this.props.editorChoice}
                      editorQuestion={this.props.editorQuestion}
                      editorElements={this.props.editorElements}
                      editorRationale={this.props.editorRationale}
                      editorTrigger={this.props.editorTrigger}
                      deleteCard={this.props.deleteUiElement}
                      duplicateCard={this.props.duplicateCard}
                      editorItems={this.props.editorItems}
                      editorTitle={this.props.editorTitle}
                      editorShowNext={this.props.editorShowNext}
                      editorSubtitle={this.props.editorSubtitle}
                      editorShowSubmit={this.props.editorShowSubmit}
                      editorLongDesc={this.props.editorLongDesc}
                      editorShortDesc={this.props.editorShortDesc}
                      editorSDJson={this.props.editorSDJson}
                      editorLDJson={this.props.editorLDJson}
                      shortDescriptionTiptap={this.props.shortDescriptionTiptap}
                      longDescriptionTiptap={this.props.longDescriptionTiptap}
                      editorType={this.props.editorType}
                      editorAction={this.props.editorAction}
                      subtitle={this.props.subtitle} // TODO: this is only for gptbox but is it needed as we already have editorSubtitle?
                      richText={this.props.richText}
                      richTextTiptap={this.props.richTextTiptap}
                      toggleUiElementTextInput={this.toggleUiElementTextInput}
                      isLongDescEnabledEdit={this.props.isLongDescEnabledEdit}
                      cardOnEdit={this.state.cardOnEdit}
                      uiElementId={this.state.uiElementId}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {!hasViewOnlyPermission() && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                    marginTop: '20px',
                  }}
                >
                  <AddItemButton
                    toggleUiElement={this.toggleUiElement}
                    toggleUiElementTextInput={this.toggleUiElementTextInput}
                    // calculatorId={this.props.calculatorId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <VariablesPreview variables={variables} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(ContainerCards);
