import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#566267',
  },
  buttonContainer: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
  },
  button: {
    height: '36px',
    padding: '10px 10px 8px 10px',
    borderRadius: '4px',
    justifyContent: 'center',
    background: '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  nameEditor: {
    padding: '4px 8px',
    gap: '4px',
    marginLeft: 15,
    background: '#F5F8F9',
    borderRadius: '2px',
    height: '28px',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.2px',
    color: '#566267',
  },
  varNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2px',
    background: '#F5F8F9',
  },
  variableName: {
    maxWidth: '200px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
