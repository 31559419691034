import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoBoxIcon from 'assets/icons/infoboxIcon';
import calIcon from 'assets/images/cal.png';
import mediaIcon from 'assets/images/media.png';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { InfoBoxChip } from 'components/utils/styled-components/FormStyle';
import React, { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { withFeaturePermission } from '../../hooks/useAuthentication';
import { Trigger } from './Trigger';
import { VariablesPreview } from './common/variable';

const StyledChip = withStyles({
  root: {
    marginTop: '17px',
    backgroundColor: 'transparent',
    color: '#08A88E',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    alignItems: 'center',
    lineHeight: '22px',
  },
})(Chip);
interface DescriptionPanelBetaProps {
  editMode: any;
  editorTrigger: any;
  editorShortDesc: any;
  editorLongDesc: any;
  shortDescriptionTiptap?: any;
  longDescriptionTiptap?: any;
  container: any;
  editorElements: any;
  elements: any;
  containerCard: any;
  status: any;
  type?: any;
  editorAction: any;
  action: any;
  editorTitle: any;
  title: any;
  update: () => void;
  shortDescriptionJson: any;
  editorSDJson: any;
  isLongDescEnabledEdit: any;
  isLongDescEnabled: any;
  editorLDJson: any;
  longDescriptionJson: any;
  deleteCard: Function;
  panelId: any;
  duplicateCard: Function;
  trigger: any;
  long_description?: any;
  short_description?: any;
  editorType?: any;
  variable?: string;
}
interface DescriptionPanelBetaState {
  modal: boolean;
  anchorEl: any;
  deleteModal: boolean;
  duplicateModal: boolean;
  showMore: boolean;
  editMode: boolean;
}
class DescriptionPanelBeta extends Component<DescriptionPanelBetaProps, DescriptionPanelBetaState> {
  constructor(props: DescriptionPanelBetaProps) {
    super(props);

    this.state = {
      modal: false,
      anchorEl: null,
      deleteModal: false,
      duplicateModal: false,
      showMore: true,
      editMode: false,
    };
  }

  setAnchorEl = (e) => {
    this.setState({ anchorEl: e.target });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDeleteModal = () => {
    this.setState((previous) => ({
      deleteModal: !previous.deleteModal,
    }));
  };

  // for open/close modal
  toggleModal = (modal) => {
    this.setState(
      (previous) =>
        ({
          [modal]: !previous[modal],
        }) as Pick<DescriptionPanelBetaState, any>
    );
  };

  showMore = () => {
    this.setState((previous) => ({
      showMore: !previous.showMore,
    }));
  };

  handleEditMode = () => {
    this.setState((previous) => ({ editMode: !previous.editMode }));
  };

  renderTools = (tools) => {
    return tools.map((tool) => {
      return tool.type === 'infobox' ? (
        <StyledChip
          key={tool.id}
          style={{ textOveflow: 'ellipsis' } as React.CSSProperties}
          size='small'
          icon={<InfoBoxIcon />}
          label={tool.label}
        />
      ) : tool.type === 'image' ? (
        <StyledChip
          key={tool.id}
          size='small'
          label={tool.label}
          icon={
            <img
              src={mediaIcon}
              alt='media-icon'
              style={{
                width: '42px',
                height: '43px',
                paddingBottom: '5px',
              }}
            />
          }
        />
      ) : tool.type === 'legacy_calculator' ? (
        <StyledChip
          key={tool.id}
          size='small'
          label={tool.label}
          icon={
            <img
              src={calIcon}
              alt='calculator-icon'
              style={{
                width: '18px',
                height: '24px',
                marginRight: '2px',
              }}
            />
          }
        />
      ) : tool.type === 'calculator' ? (
        <StyledChip
          key={tool.id}
          label={tool.label}
          size='small'
          icon={
            <img
              src={calIcon}
              alt='calculator-icon'
              style={{
                width: '18px',
                height: '24px',
                marginRight: '2px',
              }}
            />
          }
        />
      ) : null;
    });
  };

  renderNoteGenerators = (noteGenerators) => {
    return (
      noteGenerators.length > 0 && (
        <React.Fragment>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '20px',
              lineHeight: '18px',
              margin: '20px 0px 5px 5px',
            }}
          >
            Smart Links
          </Typography>

          {noteGenerators.map((item, index) => (
            <InfoBoxChip
              style={{
                backgroundColor: 'transparent',
                color: '#08A88E',
                fontSize: '16px',
              }}
              className='infobox-chip'
              key={index}
              icon={<InfoBoxIcon />}
              label={item}
            />
          ))}
        </React.Fragment>
      )
    );
  };

  render() {
    const trigger =
      this.state.editMode || this.props.editMode ? this.props.editorTrigger : this.props.trigger;

    let cardStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginBottom: '10px',
      width: this.props.container ? '100%' : '576px',
      borderRadius: '30px',
      backgroundColor: this.props.container ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      boxShadow: this.props.container ? 'none' : '0px 4px 25px rgba(0, 0, 0, 0.15)',
    };

    let questionStyle = {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      display: 'flex',
      alignItems: 'center',
    };

    let elements =
      this.state.editMode || this.props.editMode
        ? this.props.editorElements
        : this.props.elements
        ? this.props.elements
        : undefined;

    let examples: Array<{ label: any; type: any }> = [];
    let tools: Array<{ label: any; type: any }> = [];
    let noteGenerators: Array<string> = [];

    elements?.forEach((item) => {
      if (item?.section === 'T') {
        if (item?.infobox?.infobox_type === 'NG') {
          noteGenerators.push(item?.label || item?.infobox?.shortened_title);
        } else {
          tools.push({
            label:
              item?.label ||
              item?.image?.representation_phrase ||
              item?.legacy_calculator?.title ||
              item?.calculator?.name ||
              item?.infobox?.shortened_title,
            type: item?.image
              ? 'image'
              : item?.legacy_calculator
              ? 'legacy_calculator'
              : item?.calculator
              ? 'calculator'
              : item?.infobox
              ? 'infobox'
              : 'No type found',
          });
        }
      } else if (item?.section === 'E') {
        examples.push({
          label:
            item?.text?.statement ||
            item?.label ||
            item?.infobox?.shortened_title ||
            item?.image?.representation_phrase,
          type: item?.text
            ? 'text'
            : item?.infobox
            ? 'infobox'
            : item?.image
            ? 'image'
            : 'No type found',
        });
      }
    });

    const variables = [
      {
        type: 'message',
        name: this.props.variable,
      },
    ];

    return (
      <div
        data-testid='description-panel__test'
        className='!mb-5'
        style={
          {
            position: this.state.editMode || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editMode || this.props.editMode ? '12' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            // marginLeft: !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <Trigger trigger={trigger} containerCard={this.props.containerCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '13px',
              }}
            >
              <Typography component={'div'} color='textPrimary' style={questionStyle}>
                {(this.state.editMode || this.props.editMode
                  ? this.props.editorAction
                  : this.props.action) && (
                  <ErrorOutlineIcon
                    style={{
                      fill: 'orange',
                      marginRight: '9px',
                      marginBottom: '5px',
                    }}
                  />
                )}{' '}
                {this.state.editMode || this.props.editMode
                  ? this.props.editorTitle
                  : this.props.title}
              </Typography>
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',

                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.toggleDeleteModal();
                    this.handleClose();
                  }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.toggleModal('duplicateModal');
                    this.handleClose();
                  }}
                >
                  Duplicate
                </MenuItem>
              </Menu>
            </Box>
            {(this.props.shortDescriptionJson || this.props.editorSDJson) && (
              <Typography component={'span'} className='pb-2' color='textSecondary'>
                <AvoEditorPreview
                  contentTiptap={this.props.shortDescriptionTiptap}
                  contentDraftJS={
                    this.state.editMode || this.props.editMode
                      ? this.props.editorSDJson
                      : this.props.shortDescriptionJson
                  }
                />
                {(this.state.editMode || this.props.editMode
                  ? this.props.isLongDescEnabledEdit === true
                  : this.props.isLongDescEnabled === true) &&
                  this.state.showMore === true && (
                    <React.Fragment>
                      <div
                        style={{
                          marginTop: 16,
                          fontSize: '14px',
                          lineHeight: '18.9px',
                          fontWeight: 400,
                          color: '#BFBFBF',
                        }}
                      >
                        Details
                        <Divider
                          style={{
                            marginBottom: 20,
                            color: '#E4E4E4',
                          }}
                        />{' '}
                      </div>
                      <AvoEditorPreview
                        contentTiptap={this.props.longDescriptionTiptap}
                        contentDraftJS={
                          this.state.editMode || this.props.editMode
                            ? this.props.editorLDJson
                            : this.props.longDescriptionJson
                        }
                      />
                    </React.Fragment>
                  )}
              </Typography>
            )}

            {examples.length > 0 && (
              <Box
                style={{
                  border: '0.75px solid #B3B3B3',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '58px',
                  marginTop: 10,
                  marginBottom: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                {examples.map((example, index) => {
                  return example.type === 'text' ? (
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#000000',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {example.label}

                      {examples[index + 1] && <span style={{ marginRight: 4 }}>,</span>}
                    </Typography>
                  ) : example.type === 'infobox' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : example.type === 'image' ? (
                    <Typography
                      style={{
                        backgroundColor: 'transparent',
                        color: ' #18C16E',
                        fontSize: '16px',
                        marginRight: 6,
                      }}
                    >
                      {example.label}
                      {examples[index + 1] && ','}
                    </Typography>
                  ) : null;
                })}
              </Box>
            )}

            {(
              this.state.editMode || this.props.editMode
                ? this.props.isLongDescEnabledEdit === true
                : this.props.isLongDescEnabled === true
            ) ? (
              this.state.showMore === true && (
                <React.Fragment>
                  {this.renderTools(tools)}
                  {this.renderNoteGenerators(noteGenerators)}
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                {this.renderTools(tools)}
                {this.renderNoteGenerators(noteGenerators)}
              </React.Fragment>
            )}

            {(this.state.editMode || this.props.editMode
              ? this.props.isLongDescEnabledEdit === true
              : this.props.isLongDescEnabled === true) &&
              this.state.showMore === true && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    cursor: 'pointer',
                  }}
                >
                  <ExpandLessIcon onClick={this.showMore} />
                </div>
              )}

            {(this.state.editMode || this.props.editMode
              ? this.props.isLongDescEnabledEdit === true
              : this.props.isLongDescEnabled === true) &&
              this.state.showMore === false && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ExpandMoreIcon onClick={this.showMore} />
                </div>
              )}
          </CardContent>
        </Card>

        {!this.props.container && <FeaturedVariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={this.toggleDeleteModal}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleDeleteModal();
          }}
        />
        <ConfirmModal
          preset='duplicate'
          open={this.state.duplicateModal}
          content='Are you sure you want to duplicate this card?'
          toggleModal={() => this.toggleModal('duplicateModal')}
          performAction={() => {
            this.props.duplicateCard(this.props.panelId, 'is_description');
            this.toggleModal('duplicateModal');
          }}
        />
      </div>
    );
  }
}

const FeaturedVariablesPreview = withFeaturePermission(VariablesPreview);
export default DescriptionPanelBeta;
