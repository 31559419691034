import { Box, Button, Container, Divider, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Theme, ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from 'assets/icons/addIcon';
import DragIcon from 'assets/icons/dragIcon';
import SearchIcon from 'assets/icons/searchIcon';
import axios from 'axios';
import NavBar from 'components/bars/NavBar';
import Footer from 'components/layout/Footer';
import { EntireScreenLoading } from 'components/loader/EntireScreenLoading';
import { StyledBadge } from 'components/module-detail/AvoAssistantForm';
import { FlowResourcesTab, FlowResourcesTabs } from 'components/module-detail/styles';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import {
  CancelButton,
  CreateButton,
  StyledInput,
} from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import 'css/custom.css';
import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from 'react-sortable-tree';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ROLES, TeamWithMates } from '../../api/team';
import { SEARCH_TAG_API_URL, SYNC_TAG_TREES_API_URL, TEAM_API_URL } from '../../constants';
import { withTeam } from '../../hooks/useTeam';
import './overrides.css';
import NodeRendererDefault from './treeNode';

const theme = createTheme({
  palette: {
    primary: {
      main: '#08A88E',
    },
    secondary: {
      main: '#08A88E',
    },
  },
});

export const StyledContainer = withStyles((theme) => ({
  root: {
    paddingTop: '10px',
    borderRadius: '0px',
    width: '100%',

    marginTop: '17px',
    color: 'black',
  },
}))(Container);
export const NotificationBox = withStyles((theme) => ({
  root: {
    width: '734px',
    minHeight: '48px',
    border: '1px solid #8FAEEB',
    background: '#EDF5FF',
    borderLeft: '3px solid #0043CE',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center ',
    paddingLeft: 17,
    paddingRight: 21,
  },
}))(Box);

interface IOSSwitchProps {
  classes: {
    root: string;
    switchBase: string;
    thumb: string;
    track: string;
    checked: string;
    focusVisible: string;
  };
  // Add any other props that the Switch component accepts
  [key: string]: any;
}

const IOSSwitch = withStyles((theme: Theme) => ({
  root: {
    marginLeft: 16,
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#23C29D',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#23C29D',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#C5D1D8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: IOSSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const BehaviourButton = withStyles((theme) => ({
  root: {
    background: '#08A88E',
    borderRadius: '6px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    //maxWidth: '123px',
    minWidth: '111px',
    height: '40px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'white',
    marginRight: '10px',
    // marginTop: '20px',
    '&:hover': {
      background: '#08A88E',
    },
    '&:focus': {
      background: '#08A88E',
    },
  },
}))(Button);

interface tagsListingState {
  tagTrees: any;
  value: number;
  loading: boolean;
  currentNode: any;
  tagId: string;
  treeData: [
    {
      expanded?: boolean;
      code?: any;
      title: string;
      children: any[];
    },
  ];
  showNotification: boolean;
  rowInfo: any;
  currentIndex: any;
  currentID: any;
  dummmy: any;
  createTreePanel: boolean;
  createNodePanel: boolean;
  modal: boolean;
  activeState: string;
  isLoading: boolean;
  editMode: boolean;
  deleteNodeModal: boolean;
  title: string;
  searchFocusIndex: number;
  searchString: string;
}

interface tagsListingProps {
  team: TeamWithMates;
}

class TagsLitsing extends Component<tagsListingProps, tagsListingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tagTrees: [],
      value: 0,
      loading: false,
      currentNode: {},
      tagId: '',
      treeData: [
        {
          expanded: false,
          code: '',
          title: '',
          children: [],
        },
      ],
      showNotification: false,
      rowInfo: null,
      currentIndex: null,
      currentID: null,
      dummmy: [],
      createTreePanel: false,
      createNodePanel: false,
      modal: false,
      activeState: 'TeamManagement',
      isLoading: true,
      editMode: false,
      deleteNodeModal: false,
      title: '',
      searchFocusIndex: 0,
      searchString: '',
    };
  }

  componentDidMount() {
    this.resetState();
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copy = this.state.treeData;
    copy[0].title = e.target.value;
    this.setState({ treeData: copy });
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.team !== this.props.team) {
      this.resetState();
    }
  }
  closeTreePanel = () => {
    this.setState(
      (previous) =>
        ({
          createTreePanel: !previous.createTreePanel,
          title: '',
          treeData: [{ title: '', children: [] }],
        }) as Pick<tagsListingState, any>
    );
  };
  closeNodePanel = () => {
    this.setState((previous) => ({
      createNodePanel: !previous.createNodePanel,
      currentID: null,
      currentIndex: null,
      rowInfo: null,
      editMode: false,
      title: '',
    }));
  };
  // for open/close modal
  toggleModal = (id: number) => {
    this.setState(
      (previous) =>
        ({
          modal: !previous.modal,
          tagId: id,
        }) as Pick<tagsListingState, any>
    );
  };
  onNodeTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  resetState() {
    // send request to django endpoint to fetch list of all s of selected team
    axios.get(TEAM_API_URL + this.props.team.id + '/serach_tags').then((res) => {
      this.setState({
        tagTrees: res.data,
        isLoading: false,
        treeData: [{ title: '', children: [] }],
        title: '',
      });
    });
  }
  customSearchMethod = ({ node, searchQuery }) => {
    return searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  };
  handlePublishTeams = (treeIndex: number, id: string) => {
    let treeCopy = this.state.tagTrees;
    treeCopy[treeIndex].is_published = !treeCopy[treeIndex].is_published;
    this.setState({ tagTrees: treeCopy }, () => {
      this.updateTagTree(id, treeIndex);
    });
  };

  removeNode = (path, index, info) => {
    if (info.parentNode) {
      const copyData = this.state.tagTrees;
      copyData[index].tag_tree = removeNodeAtPath({
        treeData: copyData[index].tag_tree,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      this.setState({
        tagTrees: copyData,
      });
      this.updateTagTree(this.state.currentID, this.state.currentIndex);
    } else {
      axios.delete(`${SEARCH_TAG_API_URL}${this.state.currentID}/`).then(() => {
        this.resetState();
      });
    }
  };
  expandAndCollapse = (expanded) => {
    let newList = this.state.tagTrees;
    let toggledList = newList.map((tree) => {
      tree.tag_tree = toggleExpandedForAll({
        treeData: tree.tag_tree,
        expanded,
      });
      return tree;
    });
    this.setState({
      tagTrees: toggledList,
    });
  };
  toggleCreateTreePanel = () => {
    this.setState((previous) => ({
      createTreePanel: !previous.createTreePanel,
    }));
  };
  toggleCreateNodePanel = (rowInfo, index, id, editMode) => {
    this.setState((previous) => ({
      createNodePanel: !previous.createNodePanel,
      rowInfo: rowInfo,
      currentIndex: index,
      currentID: id,
      title: editMode ? rowInfo.node.title : '',
      editMode: editMode,
    }));
  };
  toggleDeleteNodeModal = () => {
    this.setState((previous) => ({
      deleteNodeModal: !previous.deleteNodeModal,
    }));
  };
  deleteSearchTag = () => {
    this.toggleModal(0);

    // delete channel object and reload list
    axios.delete(`${SEARCH_TAG_API_URL}${this.state.tagId}/`).then(() => {
      this.resetState();
    });
  };
  // customSearchMethod = ({ node, searchQuery }) => {
  //   return searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  // };
  selectThis = (node: any, path: any) => {
    this.setState({ currentNode: node, path: path } as Pick<tagsListingState, any>);
  };
  handleChange = (treeData: any, index: number, id: string) => {
    const copyData = this.state.tagTrees;
    copyData[index].tag_tree = treeData;
    this.setState({ tagTrees: copyData });
  };

  insertNewNode = () => {
    this.setState((prevState) => ({
      treeData: insertNode({
        treeData: prevState.treeData,
        depth: 0,
        minimumTreeIndex: prevState.treeData.length,
        newNode: { title: this.state.title, children: [] },
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData,
    }));
  };

  insertNodeUnderParent(rowInfo, index) {
    const copyTreeData = this.state.tagTrees;
    const isAdminTree = this.state.tagTrees[index].is_admin_tree;
    const isAdminTeam = this.props.team.team_type === 'A' ? true : false;

    const newTree = addNodeUnderParent({
      treeData: this.state.tagTrees[index].tag_tree,
      newNode: {
        title: this.state.title,
        children: [],
        is_custom_node: isAdminTree && !isAdminTeam ? true : false,
        code: uuidv4(),
        expanded: true,
      },
      expandParent: true,
      parentKey: rowInfo ? rowInfo.treeIndex : undefined,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    copyTreeData[index].tag_tree = newTree.treeData;
    this.setState({ tagTrees: copyTreeData, showNotification: !isAdminTeam ? true : false });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 6000);

    // if (this.state.currentIndex && this.state.currentID)
    this.updateTagTree(this.state.currentID, this.state.currentIndex);
  }
  updateTagTree = (id, index) => {
    let payload = {
      tag_tree: this.state.tagTrees[index].tag_tree,
      team: this.props.team.id,
      is_published: this.state.tagTrees[index].is_published,
    };
    //payload.tag_tree[0].checked = false;

    axios.put(`${SEARCH_TAG_API_URL}${id}/`, payload).then((res) => {
      this.resetState();
    });
  };
  handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState({ value: value });
  };
  pushNode = (getNodeKey: any) => {
    const treeData = this.state.treeData;
    treeData[0].title = this.state.title;
    this.setState({ treeData: treeData });
    const title = this.state.title;
    // const checked = false;
    const copyData = this.state.tagTrees;
    copyData[this.state.currentIndex].tag_tree = changeNodeAtPath({
      treeData: copyData[this.state.currentIndex].tag_tree,
      path: this.state.rowInfo.path,
      getNodeKey,
      newNode: { ...this.state.rowInfo.node, title },
    });

    this.setState({ tagTrees: copyData });
    this.updateTagTree(this.state.currentID, this.state.currentIndex);
  };

  syncTagTrees = () => {
    this.setState({ loading: true });
    axios.post(SYNC_TAG_TREES_API_URL + this.props.team.id).then(() => {
      this.setState({ loading: false });
      toast.success(CustomToast, { data: 'Tags are synced successfully' });
    });
  };
  createTagTree = () => {
    const tree = this.state.treeData;

    tree[0].code = uuidv4();
    tree[0].expanded = true;
    let payload = {
      tag_tree: tree,
      team: this.props.team.id,
    };

    axios.post(SEARCH_TAG_API_URL, payload).then((res) => {
      this.resetState();
    });
    this.closeTreePanel();
  };
  render() {
    const tagTrees = this.state.tagTrees;
    const { searchString, searchFocusIndex } = this.state;
    const getNodeKey = ({ treeIndex }) => treeIndex;

    const team = this.props.team || {};
    const isAdmin = this.props.team.current_teammate?.role === ROLES.Admin;
    const isAdminTeam = this.props.team.team_type === 'A' ? true : false;

    return (
      <React.Fragment>
        {/* // TODO: Remove the global style for materialize and bootstrap in future */}
        {globalStyles}
        <style>{'body { background-color: white; }'}</style>
        <div className='ht-100'>
          <div className='second-container'>
            <NavBar activeState={this.state.activeState} />
            <Box
              style={{
                width: isAdminTeam ? '83%' : '90%',
                marginLeft: isAdminTeam ? '10vw' : '0px',
                marginTop: '25px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              {!isAdminTeam && (
                <ThemeProvider theme={theme}>
                  <Box
                    style={{
                      position: 'relative',
                      width: '170px',
                      height: '100px',
                    }}
                    className='flow-tabs-wrapper'
                  >
                    <FlowResourcesTabs
                      className='position-fixed'
                      orientation='vertical'
                      centered
                      value={this.state.value}
                      onChange={this.handleTabChange}
                      indicatorColor='primary'
                      textColor='primary'
                    >
                      <FlowResourcesTab label='Default' {...a11yProps(0)} color='#08A88E' />
                      <FlowResourcesTab label='Custom' {...a11yProps(1)} color='#08A88E' />
                    </FlowResourcesTabs>
                  </Box>
                </ThemeProvider>
              )}
              <Box style={{ width: '100%' }}>
                {isAdminTeam && (
                  <React.Fragment>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '3.2vw',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '22px',
                                lineHeight: '30px',
                                color: '#000000',
                                marginBottom: '21px',
                              }}
                            >
                              Tag Management
                            </Typography>

                            <Box className='d-flex align-items-center flex-row'>
                              <BehaviourButton onClick={() => this.expandAndCollapse(true)}>
                                {' '}
                                Expand All
                              </BehaviourButton>
                              <BehaviourButton onClick={() => this.expandAndCollapse(false)}>
                                {' '}
                                Collapse All
                              </BehaviourButton>
                              <Box
                                style={{
                                  background: '#FFFFFF',
                                  border: '1px solid #E0E0E0',
                                  boxSizing: 'border-box',
                                  borderRadius: '6px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingLeft: '10px',
                                  paddingRight: '5px',
                                  alignItems: 'center',
                                  width: 157,
                                  height: 40,
                                }}
                                className='search-box'
                              >
                                <input
                                  placeholder='Search'
                                  value={searchString}
                                  style={{
                                    display: 'inline-block',
                                    border: 'none',
                                    outline: 'none',
                                    width: '110px',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    color: '#919191',
                                    margin: '0',
                                  }}
                                  onChange={(event) =>
                                    this.setState({
                                      searchString: event.target.value,
                                    })
                                  }
                                  className='tag_searchBar search-bar'
                                />
                                <div
                                  style={{
                                    color: '#B0B0B0',
                                    marginLeft: 'auto',
                                    marginRight: '10px',
                                  }}
                                >
                                  <SearchIcon />
                                </div>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <div style={{ marginTop: '25px', marginLeft: '3.2vw' }}>
                  {this.state.showNotification && (
                    <NotificationBox>
                      <InfoIcon style={{ marginRight: 17 }} />
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.16px',
                          color: '#161616',
                          marginRight: 5,
                        }}
                      >
                        Note:
                      </Typography>
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.16px',
                          color: '#161616',
                        }}
                      >
                        Admin-created tag trees may be used, but deletion results in removal of all
                        related tags.
                      </Typography>
                      <CloseIcon
                        style={{ marginLeft: 'auto', color: 'black', cursor: 'pointer' }}
                        onClick={() => this.setState({ showNotification: false })}
                      />
                    </NotificationBox>
                  )}
                  {Array.isArray(tagTrees) && tagTrees.length > 0 ? (
                    tagTrees.map((tagTree, index) => {
                      const adminTreeInOtherTeam =
                        tagTree.is_admin_tree && tagTree.team !== team?.id;
                      const enableView = (isAdmin && !adminTreeInOtherTeam) || false;

                      const iOSSwitchProps = {
                        classes: {},
                        checked: tagTree.is_published,
                        onClick: () => this.handlePublishTeams(index, tagTree.id),
                      };

                      return (
                        <StyledContainer
                          key={`tree${index}`}
                          fixed
                          style={{
                            display:
                              this.state.value === 0 && tagTree.is_admin_tree
                                ? 'block'
                                : this.state.value === 1 && !tagTree.is_admin_tree
                                ? 'block'
                                : 'none',
                            marginTop: 20,
                            paddingBottom: '20px',
                            height: 'auto',
                            width: '100%',
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderBottom: '1px dashed #D6D6D6',
                          }}
                        >
                          <Box
                            className='d-flex align-items-center'
                            style={{ marginBottom: '20px', width: '100%' }}
                          >
                            <DragIcon style={{ color: '#687588' }} />
                            <Typography
                              className='d-flex align-items-center'
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                verticalAlign: 'middle',
                                fontSize: '20px',
                                lineHeight: '20px',
                                textTransform: 'uppercase',
                                marginLeft: '15px',
                                color: '#687588',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {tagTree.tag_tree[0] ? tagTree.tag_tree[0].title : 'Title Not Found'}
                              {tagTree.is_admin_tree && isAdmin && isAdminTeam && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <IOSSwitch
                                    {...iOSSwitchProps}
                                    // checked={tagTree.is_published}
                                    // onClick={() => this.handlePublishTeams(index, tagTree.id)}
                                  />
                                  <Typography
                                    style={{
                                      textTransform: 'none',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      letterSpacing: '-0.2px',
                                      color: '#85959D',
                                    }}
                                  >
                                    Publish to all teams
                                  </Typography>
                                </div>
                              )}
                            </Typography>{' '}
                          </Box>

                          <SortableTree
                            onDragStateChanged={(e) => {
                              !e.isDragging && this.updateTagTree(tagTree.id, index);
                            }}
                            canDrag={enableView}
                            isVirtualized={false}
                            rowHeight={75}
                            searchQuery={searchString}
                            searchMethod={this.customSearchMethod}
                            searchFocusOffset={searchFocusIndex}
                            treeData={tagTree.tag_tree}
                            onChange={(treeData) => this.handleChange(treeData, index, tagTree.id)}
                            nodeContentRenderer={NodeRendererDefault}
                            generateNodeProps={(rowInfo) => {
                              const isCustomNode = rowInfo.node?.is_custom_node || false;
                              const showEditDelete =
                                isAdmin &&
                                ((adminTreeInOtherTeam && isCustomNode) || !adminTreeInOtherTeam);
                              return {
                                buttons: [
                                  showEditDelete && (
                                    <EditIcon
                                      style={{
                                        color: '#08A88E',
                                        cursor: 'pointer',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        this.toggleCreateNodePanel(
                                          rowInfo,

                                          index,
                                          tagTree.id,
                                          true
                                        );
                                      }}
                                    />
                                  ),
                                  showEditDelete && (
                                    <DeleteIcon
                                      style={{
                                        color: 'red',
                                        marginLeft: '30px',
                                        cursor: 'pointer',
                                        marginRight: '15px',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                          rowInfo: rowInfo,
                                          currentIndex: index,
                                          currentID: tagTree.id,
                                        });
                                        this.toggleDeleteNodeModal();
                                      }}
                                    />
                                  ),
                                ],

                                title: (
                                  <form
                                    style={{ cursor: 'default' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.selectThis(rowInfo.node, rowInfo.path);
                                    }}
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        width: 300,
                                        outline: 'none',
                                        border: '0px solid white',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        fontSize: '18px',
                                        lineHeight: '20px',
                                        color: '#000000',
                                        marginLeft: !enableView ? 20 : undefined,
                                      }}
                                    >
                                      {rowInfo.node.title}
                                    </Typography>
                                  </form>
                                ),
                                addButton: isAdmin && (
                                  <Box
                                    style={{
                                      background: '#FFFFFF',
                                      boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
                                      borderRadius: '6px',
                                      height: '100%',
                                      width: '60px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginLeft: 20,
                                      cursor: 'pointer',
                                      position: 'relative',
                                      zIndex: '2',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.toggleCreateNodePanel(rowInfo, index, tagTree.id, false);
                                    }}
                                  >
                                    <AddCircleOutlineIcon
                                      style={{
                                        color: '#08A88E',
                                        cursor: 'pointer',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.toggleCreateNodePanel(
                                          rowInfo,
                                          index,
                                          tagTree.id,
                                          false
                                        );
                                      }}
                                    />
                                  </Box>
                                ),
                              };
                            }}
                          />
                        </StyledContainer>
                      );
                    })
                  ) : (
                    <Box
                      style={{
                        width: '100%',
                        height: '582px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        background: '#F8FBFF',
                        opacity: 0.7,
                        border: '1px dashed #AFAFAF',
                        boxSizing: 'border-box',
                        borderRadius: '20px',
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 'normal',
                          fontSize: '22px',
                          lineHeight: '30px',
                          textAlign: 'center',
                          color: '#ACB6C9',
                        }}
                      >
                        No Tags yet!
                      </Typography>
                      <Typography
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '22px',
                          lineHeight: '30px',
                          textAlign: 'center',
                          color: '#5A6170',
                        }}
                      >
                        Click on{' '}
                        <b style={{ marginLeft: '8px', marginRight: '8px' }}>Add New Tree</b> to
                        Start
                      </Typography>
                    </Box>
                  )}
                  <Grid item style={{ marginLeft: 'auto' }}>
                    {(this.state.value === 1 || (isAdminTeam && this.state.value === 0)) && (
                      <>
                        <Button
                          startIcon={<AddIcon />}
                          style={{
                            marginTop: 40,
                            background: '#08A88E',
                            borderRadius: '100px',
                            textTransform: 'none',
                            width: '190px',
                            height: '60px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            lineHeight: '25px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            filter: 'drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.15))',
                          }}
                          onClick={() => this.toggleCreateTreePanel()}
                        >
                          Add New Tree
                        </Button>

                        {/* <CreateButton
                          onClick={() => this.syncTagTrees()}
                          color='#08A88E'
                          style={{ marginTop: 40, marginLeft: 20 }}
                        >
                          Save
                        </CreateButton> */}
                      </>
                    )}
                  </Grid>
                </div>
              </Box>
              <SlidingPane
                isOpen={this.state.createTreePanel}
                onRequestClose={this.closeTreePanel}
                from='right'
                overlayClassName='modal-backdrop'
                className='shadow-tag-management'
                width='620px'
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '95%',
                  }}
                >
                  <Box
                    style={{
                      marginTop: '20px',
                      height: '100%',
                      paddingRight: 13,
                      paddingLeft: 13,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      className='d-flex align-items-center flex-row'
                      style={{ marginBottom: '  25px' }}
                    >
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '22px',
                          lineHeight: '22px',
                          color: '#000000',
                        }}
                      >
                        Add Tag Tree
                      </Typography>
                      <HighlightOffOutlined
                        onClick={() => this.closeTreePanel()}
                        style={{
                          color: '#C7C7C7',
                          marginLeft: 'auto',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                    <Divider style={{ color: '#E6E6E6' }} />
                    <Box className='d-flex flex-column'>
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          lineHeight: '16px',
                          color: '#191919',
                          marginTop: '30px',
                          display: 'flex',

                          alignItems: 'center',
                        }}
                      >
                        Add Title{' '}
                        <StyledBadge variant='dot' overlap='circular' style={{ marginLeft: 5 }} />
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: '#7E7E7E',
                          marginTop: '5px',
                        }}
                      >
                        Dummy Text will be populated later
                      </Typography>
                      <StyledInput
                        type='text'
                        name='title'
                        id='title'
                        style={{ marginTop: '13px' }}
                        onChange={this.onChange}
                        placeholder='Title'
                        value={this.state.treeData[0].title}
                      />
                    </Box>
                    <Box
                      className='d-flex align-items-center flex-row'
                      style={{ marginTop: 'auto', marginLeft: 'auto' }}
                    >
                      <CancelButton
                        style={{ color: 'black', marginRight: '25px' }}
                        onClick={() => this.closeTreePanel()}
                      >
                        Cancel
                      </CancelButton>
                      <CreateButton onClick={() => this.createTagTree()}>Create</CreateButton>
                    </Box>
                  </Box>
                </div>
              </SlidingPane>
              <SlidingPane
                isOpen={this.state.createNodePanel}
                onRequestClose={this.closeNodePanel}
                from='right'
                overlayClassName='modal-backdrop'
                className='shadow-tag-management'
                width='620px'
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    overflowX: 'visible',
                    height: '100%',
                  }}
                >
                  <Box
                    style={{
                      marginTop: '20px',
                      height: '95%',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 13,
                      paddingRight: 13,
                    }}
                  >
                    <Box
                      className='d-flex align-items-center flex-row'
                      style={{ marginBottom: '  25px' }}
                    >
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '22px',
                          lineHeight: '22px',
                          color: '#000000',
                        }}
                      >
                        {this.state.editMode ? 'Update Node' : 'Add Node'}
                      </Typography>
                      <HighlightOffOutlined
                        onClick={() => this.closeNodePanel()}
                        style={{
                          color: '#C7C7C7',
                          marginLeft: 'auto',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                    <Divider style={{ color: '#E6E6E6' }} />
                    <Box className='d-flex flex-column'>
                      <Typography
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          lineHeight: '16px',
                          color: '#191919',
                          marginTop: '30px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Add Title{' '}
                        <StyledBadge variant='dot' overlap='circular' style={{ marginLeft: 5 }} />
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: '#7E7E7E',
                          marginTop: '5px',
                        }}
                      >
                        Dummy Text will be populated later
                      </Typography>
                      <StyledInput
                        type='text'
                        name='title'
                        id='title'
                        style={{ marginTop: '13px' }}
                        onChange={(e) => this.onNodeTitleChange(e)}
                        placeholder='Title'
                        value={this.state.title}
                      />
                    </Box>
                    <Box
                      className='d-flex align-items-center flex-row'
                      style={{ marginTop: 'auto', marginLeft: 'auto' }}
                    >
                      <CancelButton
                        style={{ color: 'black', marginRight: '25px' }}
                        onClick={() => {
                          this.closeNodePanel();
                        }}
                      >
                        Cancel
                      </CancelButton>
                      <CreateButton
                        onClick={() => {
                          if (this.state.editMode) {
                            this.pushNode(getNodeKey);
                          } else {
                            this.insertNodeUnderParent(this.state.rowInfo, this.state.currentIndex);
                          }
                          this.closeNodePanel();
                        }}
                      >
                        {this.state.editMode ? 'Update' : 'Create'}
                      </CreateButton>
                    </Box>
                  </Box>
                </div>
              </SlidingPane>
              <ConfirmModal
                preset='delete'
                open={this.state.modal}
                content='Are you sure you want to delete this Tag Tree?'
                toggleModal={() => this.toggleModal(0)}
                performAction={this.deleteSearchTag}
              />
              <ConfirmModal
                preset='delete'
                open={this.state.deleteNodeModal}
                content='Are you sure you want to delete this Node?'
                toggleModal={() => this.toggleDeleteNodeModal()}
                performAction={() => {
                  this.removeNode(
                    this.state.rowInfo.path,
                    this.state.currentIndex,
                    this.state.rowInfo
                  );
                  this.toggleDeleteNodeModal();
                }}
              />
            </Box>
            <div className='separate' style={{ marginTop: '40px' }} />
          </div>
          {this.state.loading && <EntireScreenLoading />}
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withTeam(TagsLitsing);
