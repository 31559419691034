import clsx from 'clsx';
import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, PropsWithChildren {
  icon?: React.JSX.Element;
}

export const Button = ({ className, children, icon, ...props }: ButtonProps) => {
  return (
    <button className={clsx('button', className)} {...props}>
      {icon && <span className='w-5 h-5 mr-[2px]'>{icon}</span>}
      {children}
    </button>
  );
};
