import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TRIGGER_ALWAYS_ON_TEXT } from 'v2/utils/constant';

export const TriggerBadge = ({
  triggerId,
  isContainer,
}: {
  isContainer: boolean;
  triggerId: number | string;
}) => {
  const { triggers = [], candidate_triggers = [] } = useSelector(
    (state: any) => state.triggerState || {}
  );

  const triggerList = [...triggers, ...candidate_triggers];

  const triggerTitle = useMemo(
    () => triggerList.find((trigger) => trigger.id === triggerId)?.title ?? 'Not Assigned',
    [triggerId]
  );
  return (
    !(isContainer && triggerTitle === TRIGGER_ALWAYS_ON_TEXT) && (
      <div className='flex items-center'>
        <span className='rounded-sm bg-green-500 px-2 py-[4px] text-caption-2 text-white'>
          {triggerTitle}
        </span>
      </div>
    )
  );
};
