import { AutoSuggestTextInputCondition } from 'components/calculator/sections/AutoSuggestTextInputCondition';
import { AutoSuggestTextInputTrigger } from 'components/resources/triggers/AutoSuggestTextInputTrigger';
import { Module } from 'hooks/useModules';
import { useContext, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { MODULE_TYPES } from '../../../constants';
import { AvoSwitch } from '../AvoSwitch';
import { StaffOnlyBadgeType, StaffOnlyWrapIf } from '../StaffOnlyWrapper';
import { MentionProvider } from '../module/MentionContext';
import { ModuleContext } from '../module/ModuleContext';
import { Tiptap } from '../tiptap/Tiptap';
import { convertFromJSON } from '../tiptap/converter/convertFromJSON';
import { Caption2 } from '../typo';

interface AvoEditorTexInputProps {
  moduleId: string;
  moduleType: MODULE_TYPES.ALGO | MODULE_TYPES.CALCULATOR;
  width?: number;
  conditionContent: string;
  setCondition?: (content: any) => void;
  setTrigger?: (content: any) => void;
  isReadOnly?: boolean;
  wrapperClassNames: string;
  placeholder: string;
  initialContent: any;
  onUpdate: (content: any) => void;
  suggestionsToExclude: any;
  variablesToExclude?: any;
}

export const AvoEditorTextInput = (props: AvoEditorTexInputProps) => {
  const { module } = useContext(ModuleContext);
  const excludedSuggestionTypes = [
    ...(props.suggestionsToExclude ?? []),
    ...(props.variablesToExclude ?? []),
  ];

  if (!module) return null;
  const editorType = getEditorType(module);

  switch (editorType) {
    case 'Tiptap':
      return (
        <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
          <Tiptap
            wrapperClassNames={props.wrapperClassNames}
            placeholder={props.placeholder}
            onUpdate={props.onUpdate}
            initialContent={props.initialContent}
            suggestionsToExclude={props.suggestionsToExclude}
            variablesToExclude={props.variablesToExclude}
          />
        </MentionProvider>
      );
    case 'AutoSuggest':
      return <ConvertableEditorTextInput {...props} />;
  }
};

export function getEditorType(module: Module): 'Tiptap' | 'AutoSuggest' {
  if (module.use_new_editor) return 'Tiptap';
  return 'AutoSuggest';
}

const ConvertableEditorTextInput = (props: AvoEditorTexInputProps) => {
  const { module } = useContext(ModuleContext);
  const [isConverted, setIsConverted] = useState(false);
  const [convertedTiptapContent, setConvertedTiptapContent] = useState(null);
  const excludedSuggestionTypes = [
    ...(props.suggestionsToExclude ?? []),
    ...(props.variablesToExclude ?? []),
  ];

  useEffect(() => {
    if (isConverted) {
      let convertedContent: any = null;
      if (props.conditionContent) {
        convertedContent = convertFromJSON(props.conditionContent as any);
      } else if (props.initialContent) {
        convertedContent = convertFromJSON(props.initialContent as any);
      }

      if (convertedContent?.document) {
        setConvertedTiptapContent(convertedContent.document as any);
      }
    }
  }, [isConverted]);

  return (
    <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
      <div className={twJoin(module?.type === MODULE_TYPES.CALCULATOR && 'max-w-[425px] grow')}>
        {StaffOnlyWrapIf({
          condition: true,
          options: { type: StaffOnlyBadgeType.STAFF, y: -10, x: -10 },
          element: (
            <div className='flex flex-row-reverse'>
              <AvoSwitch checked={isConverted} onChange={setIsConverted} />
              <Caption2>Convert DraftJS to Tiptap</Caption2>
            </div>
          ),
        })}
        <div className={twJoin(isConverted && 'hidden')}>
          {props.setCondition ? (
            <AutoSuggestTextInputCondition
              width={props.width ?? 425}
              calculatorId={props.moduleId}
              setCondition={props.setCondition}
              conditionContent={props.conditionContent}
              isReadOnly={props.isReadOnly ?? false}
            />
          ) : (
            <AutoSuggestTextInputTrigger
              moduleId={props.moduleId}
              calculatorId={props.moduleId}
              type={props.moduleType}
              setTrigger={props.setTrigger}
              conditionContent={props.initialContent}
            />
          )}
        </div>
        {isConverted && (
          <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
            <Tiptap
              wrapperClassNames={props.wrapperClassNames}
              placeholder={props.placeholder}
              onUpdate={props.onUpdate}
              initialContent={convertedTiptapContent as any}
              suggestionsToExclude={props.suggestionsToExclude}
              variablesToExclude={props.variablesToExclude}
            />
          </MentionProvider>
        )}
      </div>
    </MentionProvider>
  );
};
