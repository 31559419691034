import { Menu } from '@material-ui/core';
import { TOGGLE_UI_ELEMENT_COMMAND } from 'components/module-detail/container/ContainerCards';
import { StyledMenuItem } from 'components/module-detail/container/styles';
import { CreateButton } from 'components/utils/styled-components/FormStyle';
import {
  ADD_ITEM_BUTTON_IN_GROUP_CARD,
  CARD_AMBIENT_LISTENING,
  CARD_API_BOX,
  CARD_CHECKBOXES,
  CARD_DATA_EXTRACTOR,
  CARD_GPT_BOX,
  CARD_MESSAGE,
  CARD_MULTIPLE_CHOICE,
  CARD_NUMBERS,
  CARD_TEXT_INPUT,
} from 'constants/variables';
import { ForwardedRef, forwardRef, useState } from 'react';
import { withFeaturePermission } from '../../../hooks/useAuthentication';
import { ToggleUiElementTextInputType, ToggleUiElementType } from './types';

interface Props {
  toggleUiElementTextInput: ToggleUiElementTextInputType;
  /* TODO: only need cardType but not to casue err, add 2nd, 3rd params for temporary solution */
  toggleUiElement: ToggleUiElementType;
  /* TODO: delete below comment as Group Card is not used in Calculator */
  // calculatorId?: number;
}

export const AddItemButton = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const clickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuPopup = () => {
    setAnchorEl(null);
  };

  const handleClickItemInList = (cardType: string) => {
    if (cardType === 'createTextInput') {
      props.toggleUiElementTextInput(cardType, null, null, TOGGLE_UI_ELEMENT_COMMAND.ADD);
    } else {
      props.toggleUiElement(cardType, '', null);
    }
    closeMenuPopup();
  };

  return (
    <div ref={ref}>
      <CreateButton onClick={clickButton}>{ADD_ITEM_BUTTON_IN_GROUP_CARD}</CreateButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenuPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null} // to prevent warning message
      >
        <StyledMenuItem onClick={() => handleClickItemInList('createChoicePanel')}>
          {CARD_MULTIPLE_CHOICE}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItemInList('createGPTBox')}>
          {CARD_GPT_BOX}
        </StyledMenuItem>
        <FeaturedStyledMenuItem onClick={() => handleClickItemInList('createAPIBox')}>
          {CARD_API_BOX}
        </FeaturedStyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItemInList('createMultiChoicePanel')}>
          {CARD_CHECKBOXES}
        </StyledMenuItem>
        {/* TODO: delete below comment as Group Card is not used in Calculator */}
        {/* {!props.calculatorId && ( */}
        <StyledMenuItem onClick={() => handleClickItemInList('createDescriptionBetaPanel')}>
          {CARD_MESSAGE}
        </StyledMenuItem>
        {/* )} */}
        <StyledMenuItem onClick={() => handleClickItemInList('createValuePanel')}>
          {CARD_NUMBERS}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItemInList('createTextInput')}>
          {CARD_TEXT_INPUT}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItemInList('createAmbientListening')}>
          {CARD_AMBIENT_LISTENING}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItemInList('createDataExtractor')}>
          {CARD_DATA_EXTRACTOR}
        </StyledMenuItem>
      </Menu>
    </div>
  );
});

const FeaturedStyledMenuItem = withFeaturePermission(StyledMenuItem);
