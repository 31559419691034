import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { debounce } from 'lodash';

export const APIResourceBody = ({ moduleId, initBody, onChange, suggestions }) => {
  const onUpdate = debounce((editor) => {
    onChange(editor.getJSON());
  }, 300);

  return (
    <AvoEditor
      suggestions={suggestions}
      setValue={() => {}}
      onUpdate={onUpdate}
      initialContent={initBody}
      moduleId={moduleId}
      richTextButtonShowList={[]}
    />
  );
};

export const APIResourceAuthorization = ({ moduleId, initBody, onChange, suggestions }) => {
  const onUpdate = debounce((editor) => {
    onChange(editor.getJSON());
  }, 300);

  return (
    <AvoEditor
      suggestions={suggestions}
      setValue={() => {}}
      onUpdate={onUpdate}
      initialContent={initBody}
      moduleId={moduleId}
      richTextButtonShowList={[]}
    />
  );
};
