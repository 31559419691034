import { Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BooleanVariable,
  CategoricalVariable,
  ListVariable,
  NumberVariable,
  TextVariable,
  VariablesToGenerateTypes,
} from '../../../../api/data-extractor';
import { useDataExtractorFormContext } from '../../../module/cards/DataExtractorCard';
import Input from '../../../utils/Input';
import { ConfirmModal } from '../../../utils/modals/ConfirmModal';
import { MentionProvider } from '../../../utils/module/MentionContext';
import { Footer } from '../../../utils/panels/Footer';
import { Header } from '../../../utils/panels/Header';
import RequiredMark from '../../../utils/requiredMark';
import { Tiptap } from '../../../utils/tiptap/Tiptap';
import {
  RichTextMenuButtonTypes,
  SuggestionTypeEnum,
} from '../../../utils/tiptap/tiptapInterfaces';
import { CustomToast } from '../../../utils/toast-message';
import { Body2 } from '../../../utils/typo';
import { CategoryListInput } from './CategoryListInput';
import { DefaultValueInput } from './DefaultValueInput';
import { DefaultVariableToGenerate } from './VariableToGenerateController';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface VariableToGeneratePanelProps {
  modalOpen: boolean;
  onModalClose: () => void;
  onClose: () => void;
  index: number;
}

type VariableTypes =
  | CategoricalVariable
  | TextVariable
  | NumberVariable
  | BooleanVariable
  | ListVariable;

export const VariablesToGeneratePanel = ({
  modalOpen,
  onModalClose,
  onClose,
  index,
}: VariableToGeneratePanelProps) => {
  const { watch, setValue } = useDataExtractorFormContext();
  const variableToGenerates = watch('variables_to_generate');

  const item = variableToGenerates[index] || DefaultVariableToGenerate;
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(
    Object.keys(item.description).length === 0
  );
  const [originalItem, setOriginalItem] = useState<VariableTypes>({
    ...DefaultVariableToGenerate,
  });
  useEffect(() => {
    setOriginalItem({ ...item });
  }, []);

  const type = item.variable_type;
  const title = type.substring(0, 1).toUpperCase() + type.substring(1);
  const handleChange = (data: VariableTypes) => {
    setValue(
      'variables_to_generate',
      variableToGenerates.map((variableToGenerate, targetIndex) =>
        index === targetIndex ? data : variableToGenerate
      )
    );
  };

  const handleClose = () => {
    onClose();
    onModalClose();
  };
  const handleCancel = () => {
    if (!originalItem.name && Object.keys(originalItem.description).length === 0) {
      setValue(
        'variables_to_generate',
        variableToGenerates.filter((_, targetIndex) => index !== targetIndex)
      );
    } else {
      setValue(
        'variables_to_generate',
        variableToGenerates.map((variableToGenerate, targetIndex) =>
          index === targetIndex ? originalItem : variableToGenerate
        )
      );
    }
    handleClose();
  };

  const handleSubmit = () => {
    if (!item.name || isDescriptionEmpty) {
      toast.error(CustomToast, { data: 'Please fill out the name/description field.' });
      onModalClose();
      return;
    }
    if (
      variableToGenerates.some(
        (variableToGenerate, targetIndex) =>
          targetIndex !== index && variableToGenerate.name === item.name
      )
    ) {
      toast.error(CustomToast, { data: 'The name cannot be the same as other item.' });
      return;
    }
    handleClose();
  };

  const isDisabled =
    !item.name ||
    isDescriptionEmpty ||
    (item.variable_type === VariablesToGenerateTypes.CATEGORICAL &&
      (!item.categories || item.categories.length < 1 || item.categories.some((item) => !item))) ||
    (item.variable_type !== VariablesToGenerateTypes.CATEGORICAL &&
      item.variable_type !== VariablesToGenerateTypes.LIST &&
      item.default_value === undefined);

  return (
    <>
      <div className='flex h-full flex-col px-[16px] pt-[20px]'>
        <div className='flex items-center justify-between'>
          <Header className='!px-0 !pr-3' title={title} toggleModal={handleCancel} />
        </div>
        <div className='flex grow flex-col gap-[24px]'>
          <div className='pt-[16px]'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Name</Body2>
                <RequiredMark />
              </div>
              <Input
                tabIndex={1}
                value={item.name}
                required
                maxLength={200}
                onChange={(e) => {
                  handleChange({ ...item, name: e.target.value });
                }}
              />
              <div className='text-right text-[12px] text-gray-700'>{item.name.length}/200</div>
            </div>
          </div>
          <div className='!mt-3'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Variable Description</Body2>
                <RequiredMark />
              </div>
              <MentionProvider excludedSuggestionTypes={[SuggestionTypeEnum.DATA_EXTRACTOR]}>
                <Tiptap
                  onUpdate={(editor: Editor) => {
                    setIsDescriptionEmpty(!editor.getText().trim().length);
                    handleChange({ ...item, description: editor.getJSON() });
                  }}
                  initialContent={item.description as any}
                  placeholder={'Type ‘#’ to add data content.'}
                  richTextButtonsShowList={richTextButtonsShowList}
                  wrapperClassNames='max-h-[600px] min-h-[280px] !h-auto'
                />
              </MentionProvider>
            </div>
          </div>
          <div className='!mt-3'>
            <div className='space-y-[5px]'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>
                  {item.variable_type === VariablesToGenerateTypes.CATEGORICAL
                    ? 'Output Category'
                    : "Default if Data isn't Captured"}
                </Body2>
                <RequiredMark />
              </div>
              {item.variable_type === VariablesToGenerateTypes.CATEGORICAL ? (
                <CategoryListInput item={item} onChange={handleChange} />
              ) : (
                <DefaultValueInput item={item} onChange={handleChange} />
              )}
            </div>
          </div>
        </div>
        <Footer disabled={isDisabled} onClick={handleSubmit} onClose={handleCancel} />
      </div>
      <ConfirmModal
        preset='unsaved'
        open={modalOpen}
        toggleModal={onModalClose}
        toggleModalPanel={handleCancel}
        handleSubmit={handleSubmit}
        panelForm
      />
    </>
  );
};
