import { createContext, useContext, useEffect, useState } from 'react';
import { moduleElementAPI, ResourceTypes, UIElement } from '../../../api/moduleElement';
import { ModuleContext } from './ModuleContext';
import { MODULE_TYPES } from '../../../constants';
import { useQuery } from '@tanstack/react-query';

interface Context {
  uiElements: UIElement[];
  refetch: () => void;
  isLoading: boolean;
}

export const UiElementContext = createContext<Context>({
  uiElements: [],
  refetch: () => null,
  isLoading: true,
});

export function UiElementProvider({ children }) {
  const { module } = useContext(ModuleContext);
  const moduleType =
    module?.type === MODULE_TYPES.ALGO
      ? 'modules'
      : module?.type === MODULE_TYPES.CALCULATOR
      ? 'calculators'
      : null;
  const { data: uiElements, refetch: refetchUiElements } = useQuery(
    [moduleType, module?.id, 'uiElements'],
    moduleElementAPI.getUIElements,
    {
      enabled: !!module,
      initialData: [],
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const { data: containers, refetch: refetchContainers } = useQuery(
    [moduleType, module?.id, 'containers'],
    moduleElementAPI.getContainers,
    {
      enabled: !!module,
      initialData: [],
      staleTime: Infinity,
      cacheTime: Infinity,
      select: (containers): UIElement[] =>
        containers.map((container) => ({
          ...container,
          resourcetype: ResourceTypes.group,
        })),
    }
  );

  // to check if both queries have been fetched (ui element, container)
  const [isLoading, setIsLoading] = useState(true);
  const refetchModuleElements = () => {
    setIsLoading(true);
    Promise.allSettled([refetchUiElements(), refetchContainers()]).then(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!module) return;
    refetchModuleElements();
  }, [module]);

  const contextValue = {
    uiElements: [...uiElements, ...containers],
    refetch: refetchModuleElements,
    isLoading,
  };

  return <UiElementContext.Provider value={contextValue}>{children}</UiElementContext.Provider>;
}
