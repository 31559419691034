import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ConditionBuilder from 'components/calculator/sections/ConditionBuilder';
import { onSortEnd } from 'components/utils/ListItemUtil';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { Accordion } from 'components/utils/example-n-tools/styles';
import { StyledInputWithButton } from 'components/utils/styled-components/FormStyle';
import { SuggestionTypes, VariableTypes } from 'components/utils/tiptap/tiptapInterfaces';
import SlidingPane from 'react-sliding-pane';
import {
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../../hooks/useEHRVariables';

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#F4F6F9',
    marginBottom: -1,
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid #D3D3D3',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

interface SortableItemProps {
  item: any;
  indexNo: number;
  handleDeleteClick: (id: string) => void;
  handleListChange: (
    id: string,
    json: any,
    type: string,
    conditionLogic: any,
    jsonTree?: any
  ) => void;
  formulaSuggestions: any[];
  calcId: string;
  moduleId: string;
  type: string;
}

const suggestionsToExclude: SuggestionTypes[] = [
  'link',
  'reference',
  'knowledge_base',
  'conditional_text',
  'infobox',
  'ehr_order',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
];
const variablesToExclude: VariableTypes[] = [
  'ambient_variable',
  'message_variable',
  'text_input_variable',
];

const SortableItem = SortableElement<SortableItemProps>(
  ({
    item,
    indexNo,
    handleDeleteClick,
    handleListChange,
    formulaSuggestions,
    calcId,
    moduleId,
    type,
  }) => {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [conditionModal, setConditionModal] = React.useState(false);

    const handleChange =
      (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
      <React.Fragment>
        <Accordion
          square
          expanded={expanded === `panel${indexNo + 1}`}
          onChange={handleChange(`panel${indexNo + 1}`)}
          key={item.sortable_id}
        >
          <AccordionSummary
            aria-controls={`panel${indexNo + 1}d-content`}
            id={`panel${indexNo + 1}d-header`}
          >
            {expanded === `panel${indexNo + 1}` ? (
              <KeyboardArrowDownIcon style={{ color: '#A6A6A6' }} />
            ) : (
              <ChevronRightIcon style={{ color: '#A6A6A6' }} />
            )}
            <Typography id={`label${indexNo}`} style={{ marginLeft: '5px', color: '#191919' }}>
              {item.label}
            </Typography>

            {item.is_default === false && (
              <span style={{ position: 'absolute', right: '1em' }}>
                <DeleteIcon
                  style={{ color: 'red' }}
                  onClick={(e) => handleDeleteClick(item.sortable_id)}
                />
              </span>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ width: '549px' }}>
              {item.is_default === false && (
                <React.Fragment>
                  <label
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#969696',
                    }}
                  >
                    Conditional Logic
                  </label>
                  <br />

                  <StyledInputWithButton
                    type='text'
                    name='readable_condition'
                    value={item.readableCondition}
                    disabled={true}
                  />
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setConditionModal((prevState) => !prevState);
                    }}
                    style={{
                      padding: '10px 40px',
                      marginLeft: '0.4em',
                      background: '#08A88E',
                      border: '1px solid #CCCCCC',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      color: 'white',
                    }}
                  >
                    {item.builder_condition_json ? 'Edit' : 'Build'}
                  </button>
                  <br />
                </React.Fragment>
              )}

              <AvoEditor
                moduleId={moduleId ?? calcId} // TODO: will be refactored only to use moduleId to cover both algo and calc
                hasEHRVariables={false}
                suggestions={formulaSuggestions}
                prevValue={item.prev_formula_content}
                setValue={(json) => handleListChange(item.sortable_id, json, 'formula')}
                isFormula={true}
                isNested={true}
                placeholder='Type #'
                richTextButtonShowList={[]}
                wrapperClassNames='flex-grow !min-h-[70px]'
                onUpdate={(editor) => handleListChange(item.sortable_id, editor, 'formula_tiptap')}
                initialContent={item.formula_text_json_tiptap}
                suggestionsToExclude={suggestionsToExclude}
                variablesToExclude={variablesToExclude}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <SlidingPane
          isOpen={conditionModal}
          onRequestClose={() => setConditionModal((prevState) => !prevState)}
          from='right'
          className='sliding-pan-modal'
          title={
            <div>
              Condition Builder
              <HighlightOffIcon
                className='backIcon'
                onClick={() => setConditionModal((prevState) => !prevState)}
              ></HighlightOffIcon>
            </div>
          }
          width='587px'
          closeIcon={
            <div>
              <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
            </div>
          }
        >
          <ConditionBuilder
            moduleId={moduleId}
            calculatorId={calcId}
            type={type}
            toggleModal={() => setConditionModal((prevState) => !prevState)}
            setCondition={(condition, conditionLogic, jsonTree) =>
              handleListChange(item.sortable_id, condition, 'condition', conditionLogic, jsonTree)
            }
            condition={item.condition}
            conditionLogic={item.condition_logic}
            builderJsonTree={item.builder_condition_json}
          />
        </SlidingPane>
      </React.Fragment>
    );
  }
);

interface SortableListProps {
  items: any[];
  handleDeleteClick: (id: string) => void;
  handleListChange: (
    id: string,
    json: any,
    type: string,
    conditionLogic: any,
    jsonTree?: any
  ) => void;
  formulaSuggestions: any[];
  calcId: string;
  moduleId: string;
  type: string;
}

const SortableList = SortableContainer<SortableListProps>(
  ({ items, handleDeleteClick, handleListChange, formulaSuggestions, calcId, moduleId, type }) => {
    return (
      <div>
        {items.map((val, index) => (
          <SortableItem
            key={val.sortable_id}
            index={index}
            indexNo={index}
            item={val}
            calcId={calcId}
            moduleId={moduleId}
            type={type}
            formulaSuggestions={formulaSuggestions}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

interface ListItemProps {
  list: any[];
  handleDeleteClick: (id: string) => void;
  handleListChange: (
    id: string,
    json: any,
    type: string,
    conditionLogic: any,
    jsonTree?: any
  ) => void;
  calcId: string;
  moduleId: string;
  type: string;
  formulaSuggestions: any[];
  updateListItems: (updatedList?: any[]) => void;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  return (
    <SortableList
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      handleDeleteClick={props.handleDeleteClick}
      handleListChange={props.handleListChange}
      calcId={props.calcId}
      moduleId={props.moduleId}
      type={props.type}
      formulaSuggestions={props.formulaSuggestions}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
    />
  );
};

export default ListItem;
