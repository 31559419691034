import { getConditionalTexts } from './getConditionalTexts';
import { getCustomNumerics } from './getCustomNumerics';
import { getFormulas } from './getFormulas';
import { getMedia } from './getMedia';
import { getNoteGenerator } from './getNoteGenerator';
import { getTriggers } from './getTriggers';
import { getInfoBoxes } from './infoBoxes';
import { getRefManagers } from './refManager';

export const getResources =
  (moduleId, module_type = '', mirrorId = null) =>
  async (dispatch, getState) => {
    dispatch(getMedia(module_type, mirrorId, moduleId));
    dispatch(getFormulas(module_type, mirrorId, moduleId));
    dispatch(getInfoBoxes(module_type, mirrorId, moduleId));
    dispatch(getRefManagers(module_type, mirrorId, moduleId));
    dispatch(getNoteGenerator(module_type, mirrorId, moduleId));
    dispatch(getCustomNumerics(module_type, mirrorId, moduleId));
    dispatch(getConditionalTexts(module_type, mirrorId, moduleId));
    dispatch(getTriggers(module_type, mirrorId, false, moduleId));
  };
