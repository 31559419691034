import { Box, Card, CardContent, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Body2, H4 } from 'components/utils/typo';
import 'css/custom.css';
import React, { Component } from 'react';
import { hasViewOnlyPermission } from 'utils/permissions';
import { questionStyle } from '../utils/styles';
import { Trigger } from './Trigger';
import { VariablesPreview } from './common/variable';

interface TextInputProps {
  editMode: any;
  title: any;
  subtitle: any;
  trigger: any;
  variable?: string;
  defaultValueTiptap?: any;
  isOptional: boolean;
  update: () => void;
  deleteCard: (panelId?: any) => void;
  panelId: any;
  editorTrigger: any;
  container: any;
  cardOnEdit: any;
  containerCard: any;
  answerForm?: any;
  type?: any;
  defaultValue: string;
  editorDefaultValue: string;
}
interface TextInputState {
  modal: boolean;
  deleteModal: boolean;
  anchorEl: any;
  editor: boolean;
}
class TextInput extends Component<TextInputProps, TextInputState> {
  constructor(props: TextInputProps) {
    super(props);

    this.state = {
      modal: false,
      deleteModal: false,
      anchorEl: null,
      editor: false,
    };
  }

  setAnchorEl = (e) => this.setState({ anchorEl: e.target });

  handleClose = () => this.setState({ anchorEl: null });

  handleEditMode = () => {
    this.setState((previous) => ({ editor: !previous.editor }) as Pick<TextInputState, any>);
  };

  toggleModal = (modalName) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<TextInputState, any>
    );
  };

  render() {
    const hasDefaultValue =
      (this.props.editMode && !!this.props.editorDefaultValue) ||
      (!this.props.editMode && !!this.props.defaultValue);
    const trigger =
      this.state.editor || this.props.editMode ? this.props.editorTrigger : this.props.trigger;

    let title = this.props.title || 'Title';
    let subtitle = this.props.subtitle || '';
    let textInputOptional = this.props.isOptional || false;
    let defaultValueTiptap = this.props.defaultValueTiptap || '';

    if (this.props.editMode) {
      title = this.props.cardOnEdit.title;
      subtitle = this.props.cardOnEdit.subtitle;
      textInputOptional = this.props.cardOnEdit.textInputOptional;
      defaultValueTiptap = this.props.cardOnEdit.default_value_tiptap;
    }

    const variables = [
      {
        type: 'text',
        name: this.props.variable,
      },
    ];

    let cardStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '10px',
      marginBottom: '0px',
      paddingBottom: '10px',
      border: 'none',
      borderRadius: '30px',
      width: this.props.container ? '100%' : '576px',
      backgroundColor: this.props.container ? 'transparent' : '#FFFFFF',
      boxShadow: this.props.container
        ? 'none'
        : '0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.06)',
    };

    return (
      <div
        data-testid='choice-panel__test'
        style={
          {
            position: this.state.editor || this.props.editMode ? 'relative' : '',
            zIndex: this.state.editor || this.props.editMode ? '13' : '',
            maxWidth: this.props.container ? '100%' : '580px',
            // marginLeft: this.props.answerForm ? '' : !this.props.container && '25px',
            marginBottom: 15,
          } as React.CSSProperties
        }
      >
        <Trigger trigger={trigger} containerCard={this.props.containerCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={!hasViewOnlyPermission(this.props.type) ? this.props.update : undefined}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent>
            <Typography component={'span'} style={questionStyle}>
              {!hasViewOnlyPermission(this.props.type) && (
                <div
                  onClick={(e) => this.setAnchorEl(e)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => {
                    this.props.update();
                    this.handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.toggleModal('deleteModal');
                    this.handleClose();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Typography>
            <div className='flex text-center'>
              <H4>{title}</H4>
              {textInputOptional && (
                <Body2 className='ml-1 pt-0.5 text-[#566267]'>(Optional)</Body2>
              )}
            </div>
            <Typography
              className='pb-2'
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#ADADAD',
              }}
            >
              {subtitle}
            </Typography>
            <Box
              style={{
                border: '1px solid #C5D1D8',
                borderRadius: '6px',
                height: 40,
                padding: '9px 11px 15px',
                marginTop: '12px',
              }}
            >
              <Typography
                style={{
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#515151',
                }}
              >
                {hasDefaultValue ? (
                  <AvoEditorPreview
                    contentTiptap={defaultValueTiptap}
                    contentDraftJS={
                      this.props.editMode ? this.props.editorDefaultValue : this.props.defaultValue
                    }
                  />
                ) : (
                  'Sample text'
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {!this.props.container && <VariablesPreview variables={variables} />}

        <ConfirmModal
          preset='delete'
          open={this.state.deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => this.toggleModal('deleteModal')}
          performAction={() => {
            this.props.deleteCard(this.props.panelId);
            this.toggleModal('deleteModal');
          }}
        />
      </div>
    );
  }
}

export default TextInput;
