import aiKnowledgeBaseRoutes from './aiKnowledgeBaseRoutes';
import authRoutes from './authRoutes';
import avoAssistantRoutes from './avoAssistantRoutes';
import calculatorRoutes from './calculatorRoutes';
import channelRoutes from './channelRoutes';
import indexRoute from './indexRoute';
import numericRoutes from './numericRoutes';
import tagRoutes from './tagRoutes';
import teamRoutes from './teamRoutes';

export const routes = [
  ...authRoutes,
  ...avoAssistantRoutes,
  ...aiKnowledgeBaseRoutes,
  ...calculatorRoutes,
  ...channelRoutes,
  ...indexRoute,
  ...numericRoutes,
  ...tagRoutes,
  ...teamRoutes,
];
